import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { Clock, PlayCircleIcon, WrenchIcon } from 'lucide-react';
import { getTaskInformation } from './helpersInvestigation';
import type { RealInvestigationTask } from '../../types/investigation';
import { BellAlertIcon, HandRaisedIcon, BoltIcon, BoltSlashIcon, ClockIcon, NoSymbolIcon, BugAntIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Badge } from '@/components/ui/badge';
import { useHQContext } from '../../contexts/HQContext';

interface InvestigationCardProps {
  investigationTask: RealInvestigationTask;
}

export function ChildSuitesCard({ investigationTask }: InvestigationCardProps) {
  const { user, allUsers: users } = useHQContext();
  const taskInformation = getTaskInformation(investigationTask, user, users);
  const suiteUrl = `https://app.qawolf.com/suites/${investigationTask.suite.id}?investigationStatus=investigating&tab=failed`;

  // const queuedWorkflows = investigationTask.workflows.filter((wf) => wf.status === 'queued').length;
  // const runningWorkflows = investigationTask.workflows.filter((wf) => wf.status === 'running').length;
  // const queuedAndRunning = investigationTask.workflows.filter((wf) => wf.status === 'queued' || wf.status === 'running').length;
  // const queuedAndRunningPercentage = Math.round((queuedAndRunning / investigationTask.workflows.length) * 100);

  const deployTypeIcon = taskInformation.isDeployRun ? (
    <BellAlertIcon className="w-4 h-4 text-red-500 mr-1" title="Deploy Trigger" />
  ) : taskInformation.isManualRun ? (
    <HandRaisedIcon className="w-4 h-4 text-yellow-500 mr-1" title={`Manual run deployed by ${investigationTask.suite.trigger?.manuallyCreatedBy}`} />
  ) : taskInformation.isTimedRun ? (
    <ClockIcon className="w-4 h-4 text-gray-900 mr-1" title="Timer Trigger" />
  ) : null;

  const slaIcon = investigationTask.team.slaReady ? (
    <BoltIcon
      className="w-4 h-4 text-green-500 mr-1"
      title={`SLA Ready${!taskInformation.nested ? `\n\nSort Info:\n\n${investigationTask.sortingScore.reasoning.join(`\n`)}` : ''}`}
    />
  ) : (
    <BoltSlashIcon
      className="w-4 h-4 text-red-500 mr-1"
      title={`Not SLA Ready${!taskInformation.nested ? `\n\nSort Info:\n\n${investigationTask.sortingScore.reasoning.join(`\n`)}` : ''}`}
    />
  );

  const renderPriorityBadge = (priority: string) => {
    switch (priority) {
      case 'urgent':
        return (
          <Badge variant="default" className="text-xs px-1 py-0">
            Urgent
          </Badge>
        );
      case 'high':
        return (
          <Badge variant="destructive" className="text-xs px-1 py-0">
            High
          </Badge>
        );
      default:
        return null; // No badge for default priority
    }
  };

  return (
    <div
      key={investigationTask.id}
      className={`w-full text-left p-3 transition-colors rounded-md ${
        investigationTask.sortingScore.isUrgentTeam === 1 ||
        investigationTask.sortingScore.isUrgentTrigger === 1 ||
        investigationTask.priority === 'urgent'
          ? `border-2 ${taskInformation.borderColor}`
          : `border ${taskInformation.borderColor}`
      } text-foreground hover:bg-accent hover:text-accent-foreground`}
    >
      <div className="flex justify-between items-start mb-1">
        <div className="flex items-center">
          <h3 className="font-semibold text-sm mr-2"> {investigationTask.team.name} </h3>
          {renderPriorityBadge(investigationTask.priority)}
        </div>
        <div className="flex items-center text-xs">
          <span>{investigationTask.suite.environment.name} </span>
          <button
            onClick={(e) => {
              e.stopPropagation();
              window.open(suiteUrl, '_blank');
            }}
            className="text-xs px-2 py-1 rounded-md bg-accent hover:bg-accent/80 transition-colors ml-2 border border-border"
          >
            See
          </button>
        </div>
      </div>
      <div className="flex justify-between items-center text-xs mb-1">
        <div className="flex items-center">
          <span className="text-muted-foreground"> {investigationTask.suite.trigger.name} </span>
          {slaIcon}
          {deployTypeIcon}
        </div>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger className="flex items-center justify-end">
              <div className="w-32 h-2 bg-gray-200 rounded-full overflow-hidden mr-2">
                <div className="h-full bg-indigo-600" style={{ width: `${taskInformation.investigationProgress}%` }} />
              </div>
              <span className="whitespace-nowrap">{taskInformation.investigationProgress}%</span>
            </TooltipTrigger>
            <TooltipContent>
              <p>
                {taskInformation.alreadyReviewedCount} / {taskInformation.originalFailures} investigated
              </p>
              <p>{taskInformation.failuresTitle}</p>
              {/* {queuedWorkflows > 0 && <p>{queuedWorkflows} queued</p>}
                {runningWorkflows > 0 && <p>{runningWorkflows} running</p>} */}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <div className="flex justify-between items-center text-xs">
        <div className="flex items-center">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <div className={`flex items-center ${taskInformation.nested ? 'text-red-500' : ''}`}>
                  <Clock className="w-3 h-3 mr-1" />
                  <span>{taskInformation.thisSuiteAge} </span>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>Suite Age: {taskInformation.thisSuiteAge} </p>
                {taskInformation.nested && <p>Oldest Failure: {taskInformation.suiteAge} </p>}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <div className="flex justify-end" title={taskInformation.failuresTitle}>
          <XMarkIcon className="w-6 h-6 text-red-500" />
          <p className="-ml-0.5 text-base">{Math.max(0, taskInformation.investigationsToDo)}</p>
          {investigationTask.suite.statusCounts.bug > 0 && (
            <>
              <BugAntIcon className="w-5 h-5 mt-0.5 mx-1 text-red-800" />
              <p className="-ml-0.5 text-base">{investigationTask.suite.statusCounts.bug}</p>
            </>
          )}
          {investigationTask.suite.statusCounts.maintenance > 0 && (
            <>
              <WrenchIcon className="w-5 h-5 mt-0.5 mx-1 text-blue-500" />
              <p className="-ml-0.5 text-base">{investigationTask.suite.statusCounts.maintenance}</p>
            </>
          )}
          {taskInformation.canceled > 0 && (
            <>
              <NoSymbolIcon className="w-5 h-5 text-red-500 mt-0.5 ml-1" />
              <p className="ml-0.5 text-base">{taskInformation.canceled}</p>
            </>
          )}
          {taskInformation.running > 0 && (
            <>
              <PlayCircleIcon className="w-6 h-6 text-yellow-500 ml-1" />
              <p className="ml-0.5 text-base">{taskInformation.running}</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
