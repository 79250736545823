import { sendPostRequest } from '../../utils/network';
import PageError from '../../utils/pageErrorHandler';

export async function getSuiteInfo({ params }) {
  try {
    let suiteId = params.suiteId;

    // handles case where someone tries to access '/flow-explorer' without a valid suiteId
    if (suiteId === ':suiteId') throw new PageError('Please use a valid Suite ID in the URL.', { statusText: 'Invalid Suite ID' });

    let result = await sendPostRequest(`/fetchSuiteData`, { suiteId });
    return result.data;
  } catch (error) {
    if (error.response.status === 404) {
      throw new PageError("Please make sure you're using a valid Suite ID.", { statusText: 'Suite Not Found' });
    } else {
      throw Error('Sorry, something went wrong. Please notify #qa-dragons if you need help.');
    }
  }
}

export async function insertOrUpdateSuite(suiteId) {
  try {
    const result = await sendPostRequest('/insertOrUpdateSuiteData', { suiteId });
    return { success: true, message: result.data.message };
  } catch (error) {
    console.error('Error in insertOrUpdateSuite:', error);
    return { success: false, error: error.message };
  }
}

export async function getTeamSuiteInfo({ params }) {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    let daysBack = urlParams.get('daysBack');
    if (!daysBack) {
      daysBack = 30;
      window.location.search += 'daysBack=30';
    } else {
      daysBack = Number(daysBack);
    }
    let teamId = params.teamId;

    let result = await sendPostRequest(`/fetchTeamSuiteData`, { teamId, daysBack });
    return result.data;
  } catch (error) {
    return { error: 'Failed to fetch new tasks info. Please refresh the page.' };
  }
}
