import { FileDownload } from '@mui/icons-material';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import timezonePlugin from 'dayjs/plugin/timezone';
import utcPlugin from 'dayjs/plugin/utc';
import ExcelJS from 'exceljs';

dayjs.extend(timezonePlugin);
dayjs.extend(utcPlugin);

export default function ExportSuitesDetailsToXLSXButton({ filteredSuiteData, customerNameOrId }) {
  // Define the headers
  const headers = [
    'ID',
    'Trigger Name',
    'Start Time (PT)',
    'Initial Run Completed At (PT)',
    'Last Updated At Time (PT)',
    'Run Time (Minutes)',
    'Investigation Time (Minutes)',
    'Total Time (Minutes)',
    'Superseded Total Time (Minutes)',
    'CI/CD Overhead',
    'Bugged Workflows (New)',
    'Flake Percent',
    'Success Percent',
    'Workflows Run',
    'Concurrency',
    'Status',
  ];

  // Define column widths
  const columnWidths = {
    ID: 15,
    'Trigger Name': 30,
    'Start Time (PT)': 25,
    'Initial Run Completed At (PT)': 25,
    'Last Updated At Time (PT)': 25,
    'Run Time (Minutes)': 15,
    'Investigation Time (Minutes)': 15,
    'Total Time (Minutes)': 15,
    'Superseded Total Time (Minutes)': 15,
    'CI/CD Overhead': 15,
    'Bugged Workflows (New)': 15,
    'Flake Percent': 15,
    'Success Percent': 15,
    'Workflows Run': 15,
    Concurrency: 15,
    Status: 15,
  };

  const downloadXLSX = async () => {
    // Helper function to determine meaning of null concurrency
    const determineConcurrency = (concurrency, startTime) => {
      if (concurrency === null && dayjs(startTime).isSameOrBefore(dayjs('2025-02-25'))) {
        return 'N/A';
      } else if (concurrency === null && dayjs(startTime).isAfter(dayjs('2025-02-25'))) {
        return 'Unlimited';
      } else {
        return concurrency;
      }
    };

    // Helper function to determine suite status
    const getSuiteStatus = (suite) =>
      suite.isCompleted
        ? 'Completed'
        : suite.isSuperseded
        ? 'Superseded'
        : suite.isMovedToNewerSuite
        ? 'Moved to Newer Suite'
        : suite.isCanceled
        ? 'Canceled'
        : 'Incomplete';

    // Group suites by trigger name
    const suitesByTrigger = {};
    filteredSuiteData.forEach((suite) => {
      const triggerName = suite.triggerName || 'Manual';
      if (!suitesByTrigger[triggerName]) {
        suitesByTrigger[triggerName] = [];
      }
      suitesByTrigger[triggerName].push(suite);
    });

    // Create workbook
    const workbook = new ExcelJS.Workbook();
    const usedSheetNames = new Set();

    // Helper function to sanitize trigger names for worksheet names
    const sanitizeSheetName = (name) => {
      let sanitized = name.replace(/[*?:\\/[\]]/g, '|').substring(0, 31);

      // Special handling for European Time Zone triggers
      if (sanitized.startsWith('CI_Weekly_(European_Time_Zone)_')) {
        const dayNumber = sanitized.match(/\d+/)[0];
        const day = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][parseInt(dayNumber) - 1];
        sanitized = `EU_TZ_${day}`;
      }

      // Ensure unique sheet names
      let uniqueSanitized = sanitized;
      let counter = 1;
      while (usedSheetNames.has(uniqueSanitized)) {
        uniqueSanitized = `${sanitized.substring(0, 28)}_${counter}`.substring(0, 31);
        counter++;
      }
      usedSheetNames.add(uniqueSanitized);

      return uniqueSanitized;
    };

    // Process each trigger group
    Object.entries(suitesByTrigger).forEach(([triggerName, suites]) => {
      // Create a new worksheet with sanitized name
      const sanitizedTriggerName = sanitizeSheetName(triggerName);
      const worksheet = workbook.addWorksheet(sanitizedTriggerName);

      // Add headers
      worksheet.addRow(headers);

      // Style the header row
      worksheet.getRow(1).font = { bold: true };
      worksheet.getRow(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFE0E0E0' },
      };

      // Set column widths based on the predefined widths
      headers.forEach((header, i) => {
        worksheet.getColumn(i + 1).width = columnWidths[header];
      });

      // Add data rows
      suites.forEach((suite) => {
        worksheet.addRow([
          suite.id,
          suite.triggerName,
          suite.startTimeString,
          dayjs
            .tz(suite.startTime, 'America/Los_Angeles')
            .add(suite.runTimeInMinutes, 'minute')
            .tz('America/Los_Angeles')
            .format('MMMM D, YYYY, h:mm A'),
          dayjs
            .tz(suite.startTime, 'America/Los_Angeles')
            .add(suite.totalTimeInMinutes, 'minute')
            .tz('America/Los_Angeles')
            .format('MMMM D, YYYY, h:mm A'),
          suite.runTimeInMinutes,
          suite.triageTimeInMinutes,
          suite.totalTimeInMinutes,
          suite.supersededTotalTimeInMinutes,
          suite.ciCdOverhead,
          suite.numberOfRunsTriagedAsBug,
          suite.flakePercent,
          suite.successPercent,
          suite.numberOfRuns,
          determineConcurrency(suite.suiteRunConcurrency, suite.startTime),
          getSuiteStatus(suite),
        ]);
      });

      // Auto-filter the header row
      worksheet.autoFilter = {
        from: { row: 1, column: 1 },
        to: { row: 1, column: headers.length },
      };
    });

    try {
      // Generate and download the file
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${customerNameOrId} Suites Runs Details.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error generating Excel file:', error);
      alert('Error generating Excel file. Please try again.');
    }
  };

  return (
    <Button
      variant="contained"
      sx={{
        backgroundColor: '#2f2fc1',
        '&:hover': {
          backgroundColor: '#1e1ea1',
        },
        marginLeft: '10px',
        padding: '6px 12px',
        fontSize: '0.8rem',
        minWidth: '160px',
      }}
      startIcon={<FileDownload />}
      onClick={downloadXLSX}
    >
      Runs Details XLSX
    </Button>
  );
}
