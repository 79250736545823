import { Pencil2Icon } from '@radix-ui/react-icons';

import { FileX2, Asterisk, AlertCircle, ToyBrick, ArrowDown, ArrowUp, CheckCircle, Wrench, XCircle, Flag } from 'lucide-react';
import { Warning } from '../types/types';

export default function WarningIcon({ warning, size = 20 }: { warning: Warning; size?: number }) {
  if (warning.type === 'pilot') {
    return <Asterisk size={size} className={warning.color} />;
  }

  if (warning.type === 'pilotAtRisk') {
    return <AlertCircle size={size} className={warning.color} />;
  }

  if (warning.type === 'milestoneTrendingDown') {
    return <ArrowDown size={size} className={warning.color} />;
  }

  if (warning.type === 'milestoneTrendingUp') {
    return <ArrowUp size={size} className={warning.color} />;
  }

  if (warning.type === 'milestoneBlocker') {
    return <ToyBrick size={size} className={warning.color} />;
  }

  if (warning.type === 'milestoneBlockerSevere') {
    return <ToyBrick size={size} className={warning.color} />;
  }

  if (warning.type === 'outOfDraftsMedium') {
    return <FileX2 size={size} className={warning.color} />;
  }

  if (warning.type === 'outOfDraftsSevere') {
    return <FileX2 size={size} className={warning.color} />;
  }

  if (warning.type === 'milestoneOnTrack') {
    return <CheckCircle size={size} className={warning.color} />;
  }

  if (warning.type === 'milestoneOffTrack') {
    return <XCircle size={size} className={warning.color} />;
  }

  if (warning.type === 'stillNeedsOutliningMedium') {
    return <Pencil2Icon className={warning.color} width={size} height={size} />;
  }

  if (warning.type === 'stillNeedsOutliningSevere') {
    return <Pencil2Icon className={warning.color} width={size} height={size} />;
  }

  if (warning.type === 'noActiveMilestone') {
    return <Flag className={warning.color} width={size} height={size} />;
  }

  return <Wrench size={size} className={warning.color} />;
}
