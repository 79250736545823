import axios from 'axios';

// eslint-disable-next-line no-undef
const baseUrl = `${process.env.REACT_APP_URL}/apis/task-wolf`;

export async function sendTSGetRequest(url: string) {
  const targetUrl = `${baseUrl}${url}`;

  const token = localStorage.getItem('token');

  const result = await axios.get(targetUrl, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`,
    },
  });

  return result.data;
}

export async function sendTSPostRequest(url: string, body?: object) {
  const targetUrl = `${baseUrl}${url}`;
  const stringifiedData = JSON.stringify(body);

  const token = localStorage.getItem('token');

  const result = await axios.post(targetUrl, stringifiedData, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`,
    },
  });

  return result.data;
}

export async function sendTSPutRequest(url: string, body: object) {
  const targetUrl = `${baseUrl}${url}`;
  const stringifiedData = JSON.stringify(body);

  const token = localStorage.getItem('token');

  const result = await axios.put(targetUrl, stringifiedData, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`,
    },
  });

  return result.data;
}

type JobStatus = 'active' | 'delayed' | 'prioritized' | 'waiting' | 'waiting-children' | 'completed' | 'failed';
type JobPollError = 'SERVER_ERROR' | 'JOB_NOT_FOUND' | 'MISSING_JOB_ID' | 'JOB_FAILED';

interface PollJobSuccessResponse<TData> {
  success: true;
  message: string;
  jobStatus: JobStatus;
  jobProgress: number | object;
  data?: TData;
}

interface PollJobErrorResponse {
  success: false;
  message: string;
  error: JobPollError;
  reason?: string;
  jobProgress?: number | object;
}

export type PollJobResponse<TData> = PollJobSuccessResponse<TData> | PollJobErrorResponse;

/**
 * Polls for the status of a job in the `worker-queue` BullMQ queue
 */
export async function sendTSPollRequest<TData>(jobId: string): Promise<PollJobResponse<TData>> {
  const targetUrl = `${process.env.REACT_APP_URL}/apis/poll-job/${jobId}`;

  const token = localStorage.getItem('token');

  const result = await axios.get(targetUrl, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`,
    },
  });

  return result.data;
}
