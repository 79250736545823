import dayjs from 'dayjs';

export const getStartDateAndEndDate = (filterType, customDates) => {
  let startDate, endDate;
  const today = dayjs();

  switch (filterType) {
    case 'pastDue':
      startDate = null;
      endDate = today.subtract(1, 'day');
      break;
    case 'thisWeek':
      startDate = today.startOf('week');
      endDate = today.endOf('week');
      break;
    case 'nextWeek':
      startDate = today.add(1, 'week').startOf('week');
      endDate = today.add(1, 'week').endOf('week');
      break;
    case 'noDueAt':
      startDate = today;
      endDate = today;
      break;
    case 'custom':
      // Get dates from existing filter if available
      startDate = customDates[0] ? dayjs(customDates[0]) : null;
      endDate = customDates[1] ? dayjs(customDates[1]) : null;
      break;
    default:
      startDate = null;
      endDate = null;
  }

  // Format dates to remove time
  startDate = startDate ? dayjs(startDate.format('YYYY-MM-DD')) : null;
  endDate = endDate ? dayjs(endDate.format('YYYY-MM-DD')) : null;
  return { startDate, endDate };
};

export const adjustDateBasedOnCurrentColumnFilterType = (state) => {
  // Filter out dueDate filter from columnFilters
  const newFilters = state.columnFilters.filter((f) => f.id !== 'dueDate');

  // Get start and end dates for the filter type
  const { startDate, endDate } = getStartDateAndEndDate(
    state.currentColumnFilterType.dueDate,
    state.columnFilters.find((f) => f.id === 'dueDate')?.value, // Provide the value of the dueDate filter incase of custom date range
  );

  // Only add dueDate filter if at least one date is not null
  if (startDate !== null || endDate !== null) {
    newFilters.push({
      id: 'dueDate',
      value: [startDate, endDate],
    });
  }

  // Reassign the columnFilters to equal the newFilters
  state.columnFilters = newFilters;
  return state;
};
