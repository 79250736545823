import { useState } from 'react';
import { useChecklistState } from './useChecklistState';
import { useWorkflowDisplay } from './useWorkflowDisplay';
import { useSubmitReadyForReview } from './useSubmitReadyForReview';

// TODO: Improve hook return types
export type ReadyForReviewChecklistHookReturn = {
  // RFR Checklist modal state
  closeReadyForReviewChecklistModal: () => void;
  openReadyForReviewChecklistModal: () => void;
  showReadyForReviewChecklistModal: boolean;

  // RFR Checklist progress state
  checkedQuestions: Record<string, boolean>;
  checklistCompletionProgress: number;
  handleQuestionChange: (questionId: string) => void;
  isChecklistComplete: boolean;
  submitTaskReadyForReview: () => void;

  // RFR Checklist workflow display data
  setReadyForReviewTaskData: (data: any) => void;
  workflowDisplayData: any;
};

export function useReadyForReviewChecklist({
  setToastData,
}: {
  setToastData: (toastData: { title: string; message: string; isSuccess: boolean }) => void;
}): ReadyForReviewChecklistHookReturn {
  const [showReadyForReviewChecklistModal, setShowReadyForReviewChecklistModal] = useState(false);
  const { checkedQuestions, isChecklistComplete, handleQuestionChange, checklistCompletionProgress, resetChecklist } = useChecklistState();
  const { readyForReviewTaskData, setReadyForReviewTaskData, workflowDisplayData, resetWorkflowDisplay } = useWorkflowDisplay();

  const closeReadyForReviewChecklistModal = () => {
    setShowReadyForReviewChecklistModal(false);
    resetChecklist();
    resetWorkflowDisplay();
  };

  const openReadyForReviewChecklistModal = () => {
    setShowReadyForReviewChecklistModal(true);
  };

  const submitMutation = useSubmitReadyForReview({
    onSuccess: () => {
      setToastData({
        title: 'Creation task successfully submitted for review 🎉',
        message: 'The "Status" column will reflect updates shortly.',
        isSuccess: true,
      });
    },
    onError: () => {
      setToastData({
        title: 'Updating task failed',
        message: 'There was a problem! The Dragons team has been notified',
        isSuccess: false,
      });
    },
  });

  const submitTaskReadyForReview = () => {
    if (!readyForReviewTaskData) return;

    const {
      row: { original },
      data: { status },
    } = readyForReviewTaskData;

    const updatedTask = {
      ...original,
      status,
    };

    closeReadyForReviewChecklistModal();
    submitMutation.mutate(updatedTask);
  };

  return {
    // RFR Checklist modal state
    closeReadyForReviewChecklistModal,
    openReadyForReviewChecklistModal,
    showReadyForReviewChecklistModal,

    // RFR Checklist progress state
    checkedQuestions,
    checklistCompletionProgress,
    handleQuestionChange,
    isChecklistComplete,
    submitTaskReadyForReview,

    // RFR Checklist workflow display data
    setReadyForReviewTaskData,
    workflowDisplayData,
  };
}
