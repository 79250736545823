import type React from 'react';
// import { useState } from 'react';
// import { IssueCard } from './IssueCard';
// import { ScrollArea } from '@/components/ui/scroll-area';
// import { TeamInfo } from './TeamInfo';
// import { ResourceSection } from './ResourceSection';
// import { AddItemModal } from './AddItemModal';
// import { InternalLinks } from './InternalLinks';
// import { ExternalLink, Trash2 } from 'lucide-react';
// import { Badge } from '@/components/ui/badge';
// import { Progress } from '@/components/ui/progress';
// import { Button } from '../../ui/button';
// import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
// import { Card, CardContent } from '@/components/ui/card';
// import { Textarea } from '@/components/ui/textarea';
import { useHQContext } from '../contexts/HQContext';
import { getTaskFromInvestigations } from '../utils/helpers';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import InvestigationActionMenu from './InvestigationActionMenu';
import { ChildSuitesCard } from './Cards/temp_ChildSuitesCard';
import { getElapsed } from '@/components/InvestigationBoard/helpers';
import { parseISO } from 'date-fns';
import { RealInvestigationTask } from '../types/investigation';
import { SelectedItem } from './HQMainPage';
type InvestigationNotesProps = {
  selectedItem: SelectedItem;
  // onComplete: (investigation: RealInvestigationTask) => void;
  // investigations: RealInvestigationTask[];
};

export const InvestigationView: React.FC<InvestigationNotesProps> = ({ selectedItem /**onComplete, investigations */ }) => {
  if (typeof selectedItem.id !== 'string') {
    return <div className="flex-1 p-4">Investigation not found</div>;
  }
  const { investigations, suiteAction, selectedTaskInformation, user } = useHQContext();

  const investigationTask = getTaskFromInvestigations(investigations, selectedItem.id);

  if (investigationTask === null) {
    return <div className="flex-1 p-4">Investigation not found</div>;
  }

  if (selectedTaskInformation === null) {
    return <div className="flex-1 p-4">Cannot find current task information</div>;
  }

  const findUniqueSuites = (investigationTask: RealInvestigationTask) => {
    const uniqueSuitesByTriggerId: Record<string, { name: string; suiteId: string; suiteUrl: string }> = {};
    uniqueSuitesByTriggerId[investigationTask.suite.trigger.id] = {
      name: investigationTask.name,
      suiteId: investigationTask.suite.id,
      suiteUrl: `https://app.qawolf.com/suites/${investigationTask.suite.id}?investigationStatus=investigating&tab=failed`,
    };
    investigationTask.childTasks?.forEach((childTask) => {
      const includedSuites = Object.keys(uniqueSuitesByTriggerId);
      if (!includedSuites.includes(childTask.suite.trigger.id)) {
        uniqueSuitesByTriggerId[childTask.suite.trigger.id] = {
          name: childTask.name,
          suiteId: childTask.suite.id,
          suiteUrl: `https://app.qawolf.com/suites/${childTask.suite.id}?investigationStatus=investigating&tab=failed`,
        };
      }
    });

    const uniqueSuitesComponent = (
      <span>
        {Object.keys(uniqueSuitesByTriggerId).length > 1 ? 'Suites: ' : 'Suite: '}
        {Object.keys(uniqueSuitesByTriggerId).map((triggerId, index) => {
          const suite = uniqueSuitesByTriggerId[triggerId];
          return (
            <>
              {index > 0 && ' | '}
              <a href={suite.suiteUrl} target="_blank" rel="noopener noreferrer" className="underline hover:text-blue-600">
                {suite.name}
              </a>
            </>
          );
        })}
      </span>
    );
    return uniqueSuitesComponent;
  };

  const environmentUrl = `https://app.qawolf.com/${investigationTask.team.slug}/environments/${investigationTask.suite.environment.id}/runs`;
  // const explorerUrl = `https://www.task-wolf.com/flow-explorer/${investigationTask.suite.id}`;

  // const { /**addInternalResource, */ claimInvestigation, unclaimInvestigation } = useHQContext();
  // const investigation = investigations.find((inv) => inv.id === investigationId);
  // // const [notes, setNotes] = useState<string>(investigation?.notes || '');
  // const [isEditingNotes, setIsEditingNotes] = useState(false);
  // const [networkScore] = useState(52); // This should be dynamic in a real application

  // // const queuedWorkflows = investigation?.workflows.filter((wf) => wf.status === 'queued').length || 0;
  // // const runningWorkflows = investigation?.workflows.filter((wf) => wf.status === 'running').length || 0;

  // if (!investigation) {
  //   return <div className="flex-1 p-4">Investigation not found</div>;
  // }

  // const formatRelativeTime = (timestamp: string | undefined) => {
  //   if (!timestamp) return 'N/A';
  //   const diff = Date.now() - new Date(timestamp).getTime();
  //   const minutes = Math.floor(diff / 60000);
  //   const hours = Math.floor(minutes / 60);
  //   const days = Math.floor(hours / 24);

  //   if (days > 0) return `${days} days ago`;
  //   if (hours > 0) return `${hours} hours ago`;
  //   if (minutes > 0) return `${minutes} minutes ago`;
  //   return 'Just now';
  // };

  // const handleClaim = () => {
  //   claimInvestigation(investigationId, 'current-user-id'); // Replace with actual user ID
  // };

  // const handleUnclaim = () => {
  //   unclaimInvestigation(investigationId);
  // };

  // const handleSaveNotes = () => {
  //   // TODO: Implement actual save functionality
  //   if (notes.trim()) {
  //     setIsEditingNotes(false);
  //   } else {
  //     setNotes('');
  //     setIsEditingNotes(false);
  //   }
  // };

  // const handleDeleteNotes = () => {
  //   setNotes('');
  //   setIsEditingNotes(false);
  // };

  // const handleRetry = (issueId: string, workflowId: string) => {
  //   const updatedIssues = investigation?.issues?.map((issue) => {
  //     if (issue.id === issueId) {
  //       const updatedWorkflows = issue.workflows.map((workflow) => {
  //         if (workflow.id === workflowId) {
  //           return { ...workflow, status: 'running', startTime: new Date().toISOString() };
  //         }
  //         return workflow;
  //       });
  //       return { ...issue, workflows: updatedWorkflows };
  //     }
  //     return issue;
  //   });

  //   // Update the investigation with the new issues
  //   const updatedInvestigation = { ...investigation, issues: updatedIssues };
  //   // You would typically update this in your state management system
  //   console.log('Updated investigation:', updatedInvestigation);
  // };

  // const handleRetryAll = (issueId: string) => {
  //   const updatedIssues = investigation?.issues?.map((issue) => {
  //     if (issue.id === issueId) {
  //       const updatedWorkflows = issue.workflows.map((workflow) => {
  //         if (workflow.status === 'failed') {
  //           return { ...workflow, status: 'running', startTime: new Date().toISOString() };
  //         }
  //         return workflow;
  //       });
  //       return { ...issue, workflows: updatedWorkflows };
  //     }
  //     return issue;
  //   });

  //   // Update the investigation with the new issues
  //   const updatedInvestigation = { ...investigation, issues: updatedIssues };
  //   // You would typically update this in your state management system
  //   console.log('Updated investigation after retry all:', updatedInvestigation);
  // };

  // const handleOverrideBaseline = (issueId: string, workflowId: string) => {
  //   const updatedIssues = investigation?.issues?.map((issue) => {
  //     if (issue.id === issueId) {
  //       const updatedWorkflows = issue.workflows.map((workflow) => {
  //         if (workflow.id === workflowId) {
  //           return { ...workflow, baselineOverride: true };
  //         }
  //         return workflow;
  //       });
  //       return { ...issue, workflows: updatedWorkflows };
  //     }
  //     return issue;
  //   });

  //   // Update the investigation with the new issues
  //   const updatedInvestigation = { ...investigation, issues: updatedIssues };
  //   // You would typically update this in your state management system
  //   console.log('Updated investigation after baseline override:', updatedInvestigation);
  // };

  // const handleCompleteInvestigation = () => {
  //   const updatedInvestigation = {
  //     ...investigation,
  //     status: 'completed',
  //     assignee: investigation.assignee,
  //     completedAt: new Date().toISOString(),
  //     lastCompletedBy: investigation.assignee?.name || 'Unknown',
  //   };
  //   onComplete(updatedInvestigation as InvestigationTask);
  // };

  // // const allIssuesResolved = investigation.issues?.every((issue) => issue.status === 'resolved') ?? false;
  // const progressPercentage = Math.round((investigation.failures.investigated / investigation.failures.total) * 100);
  // const queuedAndRunningPercentage =
  //   queuedWorkflows + runningWorkflows > 0 ? Math.round(((queuedWorkflows + runningWorkflows) / investigation.workflows.length) * 100) : 0;

  return (
    <div className="flex h-screen overflow-hidden">
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="bg-white p-4 border-b border-gray-200 shadow-sm">
          <div className="flex justify-between items-start mb-2">
            <div>
              <h2 className="text-xl font-bold text-gray-800 flex items-center">
                {/* TODO: Implement link to suite environment */}
                <span>{investigationTask.team.name}</span>
              </h2>
              <div className="text-md text-gray-600 mt-1 flex items-center">
                <span>
                  Env:{' '}
                  <a href={environmentUrl} target="_blank" rel="noopener noreferrer" className="underline hover:text-blue-600">
                    {investigationTask.suite.environment.name}
                  </a>{' '}
                  <Badge variant={investigationTask.priority === 'high' ? 'destructive' : 'secondary'}>{investigationTask.priority}</Badge>
                </span>
              </div>
              <div className="text-md text-gray-600 mt-1 flex items-center">
                {findUniqueSuites(investigationTask)}

                {/* TODO: Add badge for suite type Deploy, PR, Scheduled, Manual, etc. */}
                {/* <Badge variant="outline" className="ml-2">
                  {investigation.suiteType}
                </Badge> */}
              </div>
            </div>
            <div className="flex items-center space-x-2">
              {investigationTask.assignedTo.id !== undefined ? (
                <div className="flex items-center">
                  <img
                    src={investigationTask.assignedTo.avatarUrl || '/placeholder.svg'}
                    alt={investigationTask.assignedTo.name}
                    className="w-6 h-6 rounded-full mr-2"
                  />
                  <span className="text-sm text-gray-600 mr-2">{investigationTask.assignedTo.name}</span>
                  {investigationTask.assignedTo.id === user.qawId ? (
                    <Button variant="outline" size="sm" onClick={() => suiteAction(investigationTask, '/unclaim')}>
                      Unclaim
                    </Button>
                  ) : (
                    <Button size="sm" onClick={() => suiteAction(investigationTask, '/claim')}>
                      Claim
                    </Button>
                  )}
                </div>
              ) : (
                <Button size="sm" onClick={() => suiteAction(investigationTask, '/claim')}>
                  Claim
                </Button>
              )}
              <InvestigationActionMenu parentComponent="investigationView" investigationTask={investigationTask} />
            </div>
          </div>
          <div className="mt-2 mb-4">
            <div className="flex justify-between items-center mb-1">
              <span className="text-sm font-medium text-gray-700">Investigation Progress</span>
              <span className="text-sm font-medium text-gray-700">
                {selectedTaskInformation.alreadyReviewedCount} / {selectedTaskInformation.originalFailures} (
                {selectedTaskInformation.investigationProgress}% Complete)
              </span>
            </div>
            <div className="w-full h-2 bg-gray-400 rounded-full overflow-hidden border border-gray-400">
              <div className="h-full bg-gray-600" style={{ width: `${selectedTaskInformation.investigationProgress}%` }} />
              {/* TODO: Add queued and running workflows */}
              {/* <div className="h-full bg-gray-500 -mt-2" style={{ width: `${queuedAndRunningPercentage}%` }} /> */}
            </div>
          </div>
          <div className="mb-4">
            <div className="flex items-center justify-between mb-2">
              <div className="w-1/3 flex items-center space-x-4">
                <span className="text-sm font-medium text-gray-700">Network Score:</span>
                <span className="text-sm font-medium text-red-600">FEATURE COMING SOON</span>
                {/* <span className="text-sm font-medium text-red-600">{networkScore}%</span> */}
              </div>
              {/* <div className="w-2/3 h-2 flex rounded-full overflow-hidden">
                {Array.from({ length: 50 }).map((_, index) => {
                  const colors = ['bg-green-500', 'bg-yellow-400', 'bg-red-500', 'bg-blue-500', 'bg-purple-500'];
                  const randomColor = Math.random() < 0.52 ? colors[0] : colors[Math.floor(Math.random() * 4) + 1];
                  return <div key={index} className={`w-[2%] ${randomColor}`} />;
                })}
                {Array.from({ length: 50 }).map((_, index) => {
                  return <div key={index} className={`w-[2%] bg-gray-500`} />;
                })}
              </div> */}
            </div>
          </div>
          <div className="flex justify-between items-center text-xs text-gray-600">
            {investigationTask.assignedTo.id !== undefined && (
              <div>
                First Claimed: <span className="font-semibold">{selectedTaskInformation.timeSinceClaimed}</span>
              </div>
            )}
            <div>
              Suite Age: <span className="font-semibold">{selectedTaskInformation.thisSuiteAge}</span>
            </div>
            {/* TODO: Implement oldest failure */}
            {investigationTask.childTasks && investigationTask.childTasks.length > 0 && (
              <div>
                Oldest Failure:{' '}
                <span className="font-semibold">
                  {getElapsed(parseISO(investigationTask.childTasks[investigationTask.childTasks.length - 1].createdAt))}
                </span>
              </div>
            )}
          </div>
        </div>
        <ScrollArea className="flex-1">
          {investigationTask.childTasks &&
            investigationTask.childTasks.map((oldSuite) => (
              <div key={oldSuite.id} className="p-2">
                <ChildSuitesCard investigationTask={oldSuite} />
              </div>
            ))}
          {/* <div className="p-4 space-y-4">
            {(notes || isEditingNotes) && (
              <Card className="mb-4">
                <CardContent className="p-4">
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="text-lg font-semibold">Investigation Notes</h3>
                  </div>
                  {isEditingNotes ? (
                    <>
                      <Textarea
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        className="w-full h-32 mb-2"
                        placeholder="Enter investigation notes here..."
                      />
                      <div className="flex justify-between">
                        <Button onClick={handleSaveNotes}>Save Notes</Button>
                        <Button variant="destructive" onClick={handleDeleteNotes}>
                          <Trash2 className="w-4 h-4 mr-2" />
                          Delete Notes
                        </Button>
                      </div>
                    </>
                  ) : (
                    <div>
                      <p className="text-sm text-gray-700 whitespace-pre-wrap">{notes}</p>
                    </div>
                  )}
                </CardContent>
              </Card>
            )}
            <div>
              <IssueCard
                issues={investigation.issues || []}
                onRetry={handleRetry}
                onRetryAll={handleRetryAll}
                onOverrideBaseline={handleOverrideBaseline}
                networkScore={networkScore}
                onAllIssuesResolved={handleCompleteInvestigation}
              />
            </div>
          </div> */}
        </ScrollArea>
      </div>
      {/* <TeamInfo
        investigation={investigation}
        queuedWorkflows={queuedWorkflows}
        runningWorkflows={runningWorkflows}
        notes={notes}
        setIsEditingNotes={setIsEditingNotes}
      /> */}
    </div>
  );
};
