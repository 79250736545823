import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';
import { Warning, WarningType } from '../types/types';

type TableContextType = {
  warningFilters: Warning[];
  setWarningFilters: Dispatch<SetStateAction<Warning[]>>;
  selectAllWarningFilters: boolean;
  setSelectAllWarningFilters: Dispatch<SetStateAction<boolean>>;
  sortDirection: 'asc' | 'desc';
  setSortDirection: Dispatch<SetStateAction<'asc' | 'desc'>>;
  handleToggleAllWarningFilters: () => void;
  handleToggleWarningFilter: (warningFilter: WarningType) => void;
  sortType: 'magic' | 'alphabetical' | 'weeks' | 'outlines' | 'creation' | 'maintenance';
  setSortType: Dispatch<SetStateAction<'magic' | 'alphabetical' | 'weeks' | 'outlines' | 'creation' | 'maintenance'>>;
  hideFullyUtilized: boolean;
  setHideFullyUtilized: Dispatch<SetStateAction<boolean>>;
  hideNoMaintenance: boolean;
  setHideNoMaintenance: Dispatch<SetStateAction<boolean>>;
  excludeUnselectedWarnings: boolean;
  setExcludeUnselectedWarnings: Dispatch<SetStateAction<boolean>>;
};

export const defaultWarnings: Warning[] = [
  { type: 'pilot', weight: 1, title: 'Pilot', description: 'Customer is less than 3 months old', color: 'text-yellow-500' },
  {
    type: 'pilotAtRisk',
    weight: 3,
    title: 'Pilot At Risk',
    description: 'Customer has at least 1 additional high severity warning',
    color: 'text-red-500',
  },
  {
    type: 'milestoneBlocker',
    weight: 0,
    title: 'Milestone Blocker',
    description: 'Customer has is blocked on creation due to reaching their test budget limit',
    color: 'text-green-500',
  },
  {
    type: 'milestoneBlockerSevere',
    weight: 3,
    title: 'Milestone Blocker',
    description: 'Customer is blocked on creation for a reason other than reaching their test budget limit',
    color: 'text-red-500',
  },
  {
    type: 'milestoneTrendingUp',
    weight: 0,
    title: 'Milestone Trending Up',
    description: 'The last two weeks of velocity and overall test count are at or above their respective targets',
    color: 'text-green-500',
  },
  {
    type: 'milestoneTrendingDown',
    weight: 3,
    title: 'Milestone Trending Down',
    description: 'The last two weeks of velocity and overall test count are below their respective targets',
    color: 'text-red-500',
  },
  {
    type: 'milestoneOnTrack',
    weight: 0,
    title: 'Milestone Creation Velocity On Track',
    description: 'Required creation velocity is at or above the planned velocity',
    color: 'text-green-500',
  },
  {
    type: 'milestoneOffTrack',
    weight: 3,
    title: 'Milestone Creation Velocity Off Track',
    description: 'Required creation velocity is below the planned velocity',
    color: 'text-red-500',
  },
  { type: 'outOfDraftsSevere', weight: 3, title: 'Out of Drafts', description: 'Customer has no draft tests remaining', color: 'text-red-500' },
  {
    type: 'outOfDraftsMedium',
    weight: 1,
    title: 'Running Out of Drafts',
    description: 'Customer will run out of drafts within the next 2 weeks at the required creation velocity',
    color: 'text-yellow-500',
  },
  {
    type: 'stillNeedsOutliningSevere',
    weight: 3,
    title: 'Outlining Goal Not Met',
    description: 'Customer has passed the planned fully outlined week and has not met the goal',
    color: 'text-red-500',
  },
  {
    type: 'stillNeedsOutliningMedium',
    weight: 1,
    title: 'Milestone Outlining Velocity Off Track',
    description: 'Outlining velocity is below the planned velocity',
    color: 'text-yellow-500',
  },
  { type: 'maintenanceMedium', weight: 1, title: 'Maintenance', description: '10 - 20% of test budget in maintenance', color: 'text-yellow-500' },
  {
    type: 'maintenanceSevere',
    weight: 3,
    title: 'Maintenance',
    description: 'More than 20% of test budget is in maintenance',
    color: 'text-red-500',
  },
  { type: 'noActiveMilestone', weight: 3, title: 'No Milestone', description: 'Customer has no active milestone', color: 'text-red-500' },
];

export const TableContext = createContext<TableContextType | null>(null);

export const TableContextProvider = ({ children }: { children: ReactNode }) => {
  const [warningFilters, setWarningFilters] = useState<Warning[]>(defaultWarnings);
  const [selectAllWarningFilters, setSelectAllWarningFilters] = useState(true);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [sortType, setSortType] = useState<'magic' | 'alphabetical' | 'weeks' | 'outlines' | 'creation' | 'maintenance'>('magic');
  const [hideFullyUtilized, setHideFullyUtilized] = useState(false);
  const [hideNoMaintenance, setHideNoMaintenance] = useState(false);
  const [excludeUnselectedWarnings, setExcludeUnselectedWarnings] = useState(false);

  const handleToggleAllWarningFilters = () => {
    if (selectAllWarningFilters) {
      setWarningFilters([]);
    } else {
      setWarningFilters(defaultWarnings);
    }
    setSelectAllWarningFilters((prev) => !prev);
  };

  const handleToggleWarningFilter = (warningFilter: WarningType) => {
    setWarningFilters((prev) => {
      if (prev.find((warning) => warning.type === warningFilter)) {
        return prev.filter((filter) => filter.type !== warningFilter);
      } else {
        const warning = defaultWarnings.find((warning) => warning.type === warningFilter) as Warning;
        return [...prev, warning];
      }
    });
  };

  return (
    <TableContext.Provider
      value={{
        warningFilters,
        setWarningFilters,
        selectAllWarningFilters,
        setSelectAllWarningFilters,
        sortDirection,
        setSortDirection,
        sortType,
        setSortType,
        handleToggleAllWarningFilters,
        handleToggleWarningFilter,
        hideFullyUtilized,
        setHideFullyUtilized,
        hideNoMaintenance,
        setHideNoMaintenance,
        excludeUnselectedWarnings,
        setExcludeUnselectedWarnings,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

export const useTableContext = () => {
  const context = useContext(TableContext);

  if (!context) {
    throw new Error('useTableContext must be used within a TableContextProvider');
  }
  return context;
};
