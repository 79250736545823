import { FailureSignatureArray } from '../types';
import { transformToGraphElements } from '../helpers';
import { ElementDefinition } from 'cytoscape';

/**
 * Transforms failure signature data into Cytoscape graph elements
 */
export const getGraphElements = (currentData: FailureSignatureArray | null, selectedAttempt: { index: number; id: string | null }): ElementDefinition[] => {
  if (!currentData) {
    return [];
  }

  return transformToGraphElements(currentData, selectedAttempt);
};
