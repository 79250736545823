import { Box, Tooltip, Typography, Divider } from '@mui/material';
import { getScaledBarWidth } from '../utils';

interface MaintenanceProgressBarProps {
  incompleteTestCount: number;
  scheduledTestCount: number;
  completedTestCount: number;
}

const MAINTENANCE_PROGRESS_BAR_WIDTH_OPTIONS = {
  minValue: 10,
  maxValue: 50,
};

function BottomProgressBar({ completedTestCount, scheduledTestCount, incompleteTestCount }: MaintenanceProgressBarProps) {
  // Calculate work to be done based on the total tests that were in maintenance in the selected week
  const workToBeDone = incompleteTestCount + completedTestCount;
  const barWidthPercent = getScaledBarWidth(workToBeDone, MAINTENANCE_PROGRESS_BAR_WIDTH_OPTIONS);

  // Get the width percentages for the sections of the maintenance progress bar
  const scheduledWidthPercent = Math.min(Math.round((scheduledTestCount / workToBeDone) * 100), 100);
  const completedWidthPercent = Math.min(Math.round((completedTestCount / workToBeDone) * 100), 100);

  return (
    <Box className="font-bold" style={{ width: `${barWidthPercent}%` }}>
      <Tooltip
        arrow
        placement="top"
        slotProps={{ tooltip: { sx: { p: 0, maxWidth: 'unset' } } }}
        title={
          <BottomToolTipContent
            completedTestCount={completedTestCount}
            scheduledTestCount={scheduledTestCount}
            incompleteTestCount={incompleteTestCount}
          />
        }
      >
        {/* GRAY BAR: Represents number of tests in maintenance */}
        <Box sx={{ bgcolor: 'gray.main' }} className="w-full h-7 overflow-hidden relative">
          {/* MEDIUM BLUE BAR: Represents number of tests scheduled for maintenance in the selected week */}
          <Box sx={{ bgcolor: 'blue.main' }} className="absolute left-0 bottom-0 h-full" style={{ width: `${scheduledWidthPercent}%` }} />
          {/* DARK BLUE BAR: Represents number of tests maintained in the selected week */}
          <Box sx={{ bgcolor: 'blue.dark' }} className="absolute left-0 bottom-0 h-full" style={{ width: `${completedWidthPercent}%` }} />

          {/* BLACK DIVIDER: Indicates the end of the scheduled bar */}
          <Box
            className="absolute left-0 bottom-0 h-full"
            style={{
              width: `${scheduledWidthPercent || 1}%`,
              borderRight: '2px solid black',
            }}
          />
        </Box>
      </Tooltip>
    </Box>
  );
}

function BottomToolTipContent({ completedTestCount, scheduledTestCount, incompleteTestCount }: MaintenanceProgressBarProps) {
  return (
    <Box className="flex box-border justify-between gap-1">
      {/* Tests Maintained This Week */}
      <Box className="flex flex-col w-full justify-center items-center py-1 px-2">
        <Typography variant="caption" color="blue.dark" fontWeight="bold" lineHeight={1.2}>
          Complete
        </Typography>
        <Typography variant="caption" lineHeight={1.2}>
          {completedTestCount}
        </Typography>
      </Box>

      <Divider orientation="vertical" variant="middle" flexItem />

      {/* Tests Scheduled for Maintenance This Week */}
      <Box className="flex flex-col w-full justify-center items-center py-1 px-2">
        <Typography variant="caption" color="blue.main" fontWeight="bold" lineHeight={1.2}>
          Scheduled
        </Typography>
        <Typography variant="caption" lineHeight={1.2}>
          {scheduledTestCount}
        </Typography>
      </Box>

      <Divider orientation="vertical" variant="middle" flexItem />

      {/* Tests Currently In Maintenance */}
      <Box className="flex flex-col w-full justify-center items-center py-1 px-2">
        <Typography noWrap variant="caption" color="gray.main" fontWeight="bold" lineHeight={1.2}>
          Tests In Maintenance
        </Typography>
        <Typography variant="caption" lineHeight={1.2}>
          {incompleteTestCount}
        </Typography>
      </Box>
    </Box>
  );
}

export function MaintenanceProgressBar({ incompleteTestCount, scheduledTestCount, completedTestCount }: MaintenanceProgressBarProps) {
  // If there are no tests in maintenance, return a placeholder box
  if (!incompleteTestCount) {
    return (
      <Box sx={{ bgcolor: 'blue.light' }} className="w-full h-7 flex items-center justify-center text-white text-xs font-bold">
        No Maintenance
      </Box>
    );
  }

  return (
    <Box className="flex flex-col gap-1">
      <BottomProgressBar completedTestCount={completedTestCount} scheduledTestCount={scheduledTestCount} incompleteTestCount={incompleteTestCount} />
    </Box>
  );
}
