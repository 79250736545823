import { useRef, useEffect } from 'react';
import { Core } from 'cytoscape';
import { FailureSignature, UseCytoscapeGraphProps } from '../types';
import {
  calculateNodeStates,
  updateNodeClasses,
  highlightSequence,
  handleBlockSelection,
  handleWorkflowSelection,
  navigateWorkflowSequence,
  handleSequenceBlockSelection,
} from '../helpers';
import { layout } from '../layout';

/**
 * Hook for managing Cytoscape graph interactions and state
 */
export const useCytoscapeGraph = (props: UseCytoscapeGraphProps) => {
  const {
    currentData,
    selectedWorkflow,
    setSelectedWorkflow,
    selectedBlock,
    setSelectedBlock,
    selectedSequenceIndex,
    setSelectedSequenceIndex,
    selectedAttempt,
    setSelectedAttempt,
    activeView,
    setActiveView,
  } = props;

  // Reference to the Cytoscape instance
  const cyRef = useRef<Core | null>(null);

  // Update node states whenever relevant data changes
  useEffect(() => {
    if (cyRef.current && currentData) {
      const nodeStates = calculateNodeStates(currentData, selectedAttempt);
      updateNodeClasses(cyRef.current, nodeStates, selectedWorkflow, selectedAttempt, selectedBlock, currentData);
    }
  }, [selectedWorkflow, selectedAttempt, selectedBlock, currentData]);

  // Add tooltip handling to Cytoscape initialization
  useEffect(() => {
    if (cyRef.current && currentData) {
      const cy = cyRef.current;

      // Add tooltip handling
      cy.on('mouseover', 'node', (event) => {
        const node = event.target;
        const tooltipText = node.data('tooltip');
        if (tooltipText) {
          // Store timeout ID in node data for cleanup
          const timeoutId = setTimeout(() => {
            const renderedNode = event.target.renderedPosition();
            const container = cy.container();

            if (container) {
              const tooltip = document.createElement('div');
              tooltip.className = 'cytoscape-tooltip';
              tooltip.textContent = tooltipText;
              tooltip.style.position = 'absolute';
              tooltip.style.left = `${renderedNode.x + container.offsetLeft}px`;
              tooltip.style.top = `${renderedNode.y + container.offsetTop - 40}px`;
              tooltip.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
              tooltip.style.color = 'white';
              tooltip.style.padding = '5px 10px';
              tooltip.style.borderRadius = '4px';
              tooltip.style.fontSize = '12px';
              tooltip.style.pointerEvents = 'none';
              tooltip.style.whiteSpace = 'pre-line';
              tooltip.style.zIndex = '10';

              container.appendChild(tooltip);

              node.data('tooltipElement', tooltip);
            }
          }, 500);

          // Store timeout ID for cleanup
          node.data('tooltipTimeout', timeoutId);
        }
      });

      cy.on('mouseout', 'node', (event) => {
        const node = event.target;
        // Clear timeout if it exists
        const timeoutId = node.data('tooltipTimeout');
        if (timeoutId) {
          clearTimeout(timeoutId);
          node.removeData('tooltipTimeout');
        }
        // Remove tooltip if it exists
        const tooltip = node.data('tooltipElement');
        if (tooltip) {
          tooltip.remove();
          node.removeData('tooltipElement');
        }
      });

      // Handle cleanup on node movement or zoom
      cy.on('viewport', () => {
        const tooltips = document.querySelectorAll('.cytoscape-tooltip');
        tooltips.forEach((tooltip) => tooltip.remove());
      });

      // Add node click handler
      cy.on('tap', 'node', (event) => {
        const node = event.target;
        const blockId = node.id();

        if (activeView === 'workflows' && !selectedWorkflow) {
          setActiveView('blocks');
        }

        // Always handle block selection first
        handleBlockSelection(
          cy,
          blockId,
          selectedBlock,
          setSelectedBlock,
          setSelectedWorkflow,
          selectedAttempt,
          setSelectedSequenceIndex,
          currentData,
          setActiveView,
          selectedWorkflow,
          true,
        );

        // If we're in workflows view or there's a selected workflow, handle workflow-specific logic
        if (selectedWorkflow) {
          const selectedWorkflowData = currentData?.filter((signature: FailureSignature) => signature.sequence.workflowId === selectedWorkflow);
          // Ensure we are using the selected attempt data, default to first attempt
          if (selectedWorkflowData) {
            const selectedAttemptData = selectedWorkflowData.find((signature) => signature.attemptIndex === selectedAttempt.index) ?? selectedWorkflowData[0];
            // process the selectedAttempt's sequence
            if (selectedAttemptData) {
              const fullSequenceItems = selectedAttemptData.sequence.items.sort((a, b) => a.position - b.position);
              // const fullSequenceBlockIds = fullSequenceItems.map((item) => item.blockId);
              const failingPosition = selectedAttemptData.failingPosition;
              const failingSequenceItems = fullSequenceItems.slice(0, failingPosition);
              const failingSequenceBlockIds = failingSequenceItems.map((item) => item.blockId);

              // Check if the blockId is in the sequence
              if (failingSequenceBlockIds.includes(blockId)) {
                const blockIndex = failingSequenceBlockIds.indexOf(blockId);
                setSelectedSequenceIndex(blockIndex);
                highlightSequence(cy, failingSequenceItems, false);
              }
            }
          }
        }
      });

      return () => {
        // Clean up any remaining tooltips
        const tooltips = document.querySelectorAll('.cytoscape-tooltip');
        tooltips.forEach((tooltip) => tooltip.remove());
        cy.removeAllListeners();
      };
    }
  }, [
    currentData,
    activeView,
    selectedWorkflow,
    selectedSequenceIndex,
    selectedBlock,
    selectedAttempt,
    setActiveView,
    setSelectedBlock,
    setSelectedSequenceIndex,
    setSelectedWorkflow,
    setSelectedAttempt,
  ]);

  /**
   * Refreshes the graph layout and updates node classes
   */
  const handleRefreshLayout = () => {
    if (!cyRef.current) return;

    cyRef.current.layout(layout).run();
    const nodeStates = calculateNodeStates(currentData, selectedAttempt);
    updateNodeClasses(cyRef.current, nodeStates, selectedWorkflow, selectedAttempt, selectedBlock, currentData);
  };

  return {
    cyRef,
    handleRefreshLayout,
    handleWorkflowSelection: (signature: FailureSignature) => {
      handleWorkflowSelection(cyRef.current, signature.sequence.workflowId, selectedWorkflow, selectedAttempt, setSelectedWorkflow, setSelectedBlock, setSelectedSequenceIndex, currentData);
    },
    navigateWorkflowSequence: (direction: 'prev' | 'next') =>
      navigateWorkflowSequence(cyRef.current, direction, selectedWorkflow, selectedAttempt, selectedSequenceIndex, currentData, setSelectedSequenceIndex),
    handleSequenceBlockSelection: (blockId: string, index: number) =>
      handleSequenceBlockSelection(cyRef.current, blockId, index, setSelectedSequenceIndex),
  };
};
