import { useCommunications } from '../../contexts/CommunicationsContext';
import { TooltipProvider, TooltipTrigger, Tooltip } from '@/components/ui/tooltip';
import { Button } from '@/components/ui/button';
import { TooltipContent } from '@radix-ui/react-tooltip';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { CheckCircle, Loader, Loader2 } from 'lucide-react';
import { SuiteDataForContextCloud } from '../../contexts/CommunicationsContextTypes';
import { calculateElapsedTime } from '../Cards/helpersInvestigation';
import { formatDate } from '@/components/MessageHQ/utils/date';
import { LoadingMessage } from '../LoadingMessage';

export function RecentSuitesList() {
  const { recentSuitesForCustomer, isLoading } = useCommunications();
  if (isLoading.suites) return <LoadingMessage resource="suites" />;
  if (!recentSuitesForCustomer?.length && !isLoading.suites) return <p>No recent suites found</p>;

  const sortedSuitesByLatest = recentSuitesForCustomer.sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

  return (
    <div className="grid grid-cols-1 gap-2 pt-2">
      {sortedSuitesByLatest.map((suite, index) => {
        const timestamp = Math.floor(new Date(suite.createdAt).getTime() / 1000).toString();
        const elapsedTime = calculateElapsedTime(timestamp);
        const formattedDate = formatDate(timestamp);

        const determineStatus = () => {
          if (suite.toInvestigate + suite.investigationMovedCount > 0) return 'Failures to Investigate';
          return 'Investigated';
        };

        const suiteUrl = `https://app.qawolf.com/${suite.teamSlug}/environments/${suite.environmentId}/runs/${suite.id}`;

        return (
          <TooltipProvider key={`${suite.id}-${index}`}>
            <Tooltip disableHoverableContent={false} delayDuration={0}>
              <DropdownMenu>
                <TooltipTrigger asChild>
                  <DropdownMenuTrigger asChild>
                    <Button variant="outline" className="justify-start text-gray-700 hover:text-gray-900 cursor-pointer w-full" asChild>
                      <span className="truncate block">
                        {determineStatus() === 'Failures to Investigate' ? (
                          <Loader2 className="w-4 h-4 mr-1 animate-spin" />
                        ) : (
                          <CheckCircle className="text-green-600 w-4 h-4 mr-1" />
                        )}
                        <span className="truncate block w-full">{suite.triggerName ? suite.triggerName : `Ad hoc by ${suite.creatorName}`}</span>
                        <span className="text-xs text-gray-500">{elapsedTime} ago</span>
                      </span>
                    </Button>
                  </DropdownMenuTrigger>
                </TooltipTrigger>
                <DropdownMenuContent className="w-48 z-[9999]" align="start">
                  <DropdownMenuItem className="cursor-pointer" onClick={() => window.open(suiteUrl, '_blank')}>
                    <Loader className="w-4 h-4 text-suite" />
                    See Suite
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>

              <TooltipContent side="left" align="start" style={{ width: '350px' }} className="bg-gray-600 border-none shadow-xl z-[9999] mr-2 p-3">
                <div className="w-full">
                  <RecentSuiteTooltipContent
                    suite={suite}
                    formattedDate={formattedDate}
                    elapsedTime={elapsedTime}
                    determineStatus={determineStatus}
                  />
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        );
      })}
    </div>
  );
}

function RecentSuiteTooltipContent({
  suite,
  formattedDate,
  elapsedTime,
  determineStatus,
}: {
  suite: SuiteDataForContextCloud;
  formattedDate: string;
  elapsedTime: string;
  determineStatus: () => string;
}) {
  const determineType = () => {
    if (suite.creatorName) return 'Manual';
    if (suite.triggerName?.includes('Deployment')) return 'Deployment';
    if (suite.triggerName?.includes('Daily')) return 'Daily';
    return 'Other';
  };
  const initialRunTimestamp = suite.initialRunCompletedAt ? Math.floor(new Date(suite.initialRunCompletedAt).getTime() / 1000).toString() : null;
  const formattedInitialRunDate = initialRunTimestamp ? formatDate(initialRunTimestamp) : 'N/A';
  return (
    <div className="space-y-2">
      {/* Header */}
      <div className="flex items-center justify-between gap-2">
        <span className="text-gray-300">{suite.triggerName ? suite.triggerName : `Ad hoc by ${suite.creatorName}`}</span>
        <span
          className={`text-xs px-2 py-0.5 rounded-full whitespace-nowrap flex-shrink-0 ${
            determineStatus() === 'Failures to Investigate' ? 'bg-red-500/10 text-red-400' : 'bg-green-500/10 text-green-400'
          }`}
        >
          {determineStatus()}
        </span>
      </div>

      {/* Main Content */}
      <MainContentContainer title="Type" value={determineType()} />
      {suite.triggerName && <MainContentContainer title="Trigger Name" value={suite.triggerName} />}
      {suite.environmentName && <MainContentContainer title="Environment" value={suite.environmentName} />}
      {suite.initialRunCompletedAt && <MainContentContainer title="Initial Run Completed At" value={formattedInitialRunDate} />}
      {suite.concurrency && <MainContentContainer title="Concurrency" value={suite.concurrency.toString()} />}

      {/* Pull Request related info */}
      {suite.pullRequestId && <MainContentContainer title="Pull Request ID" value={suite.pullRequestId} />}
      {suite.commitUrl && <MainContentContainer title="Commit URL" value={suite.commitUrl} />}
      {suite.commitMessage && <MainContentContainer title="Commit Message" value={suite.commitMessage} />}
      {suite.commitSha && <MainContentContainer title="Commit SHA" value={suite.commitSha} />}

      {/* Footer Info */}
      <div className="flex flex-col text-xs text-gray-300 pt-1 space-y-1">
        <span className="truncate">Created on {formattedDate}</span>
        <span className="truncate">{elapsedTime} ago</span>
      </div>
    </div>
  );
}

export function MainContentContainer({ title, value }: { title: string; value: string }) {
  return (
    <div className="text-white text-sm font-medium">
      <p className="text-gray-300 text-xs font-medium">{title}</p>
      <p className="break-all whitespace-pre-wrap overflow-hidden max-h-[300px]">{value}</p>
    </div>
  );
}
