import { MaterialReactTable, useMaterialReactTable, MRT_ShowHideColumnsButton, MRT_GlobalFilterTextField } from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

//Material UI Imports
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, lighten } from '@mui/material';

// Component and variable imports
import { useHandleSubmissionChange, handleDeleteSubmissions } from '../HiringUtils/helperFunctions';
import { unarchiveSingleSubmission } from '../HiringUtils/api';
import Toast from '../../Layout/Toast';
import CandidateDetailPanel from '../HiringCandidatePanel/CandidateDetailPanel';
import { DataForTable_Type } from '../Types/types';

import { Submission, RowData } from '../Types/types';
import { MRT_Row } from 'material-react-table';
import getHiringColumns from '../HiringUtils/columns';
import { ToastData } from '@/components/HQ/contexts/CommunicationsContextTypes';

function ArchivedTable({ currentSubmissions, allCandidates, allSubmissions }: DataForTable_Type) {
  const initialToast = {
    title: 'Your title here',
    message: 'Your message here',
    show: false,
    content: '',
    isSuccess: false,
    onDone: () => setToast(initialToast),
  };

  const [toast, setToast] = useState<ToastData | null>(null);
  const queryClient = useQueryClient();

  const [value, setValue] = useState<Record<number, Submission>>(() =>
    currentSubmissions.reduce<Record<number, Submission>>((acc, row) => {
      acc[row.id] = row;
      return acc;
    }, {} as Record<number, Submission>),
  );
  const handleSubmissionChange = useHandleSubmissionChange();

  useEffect(() => {
    setValue(
      currentSubmissions.reduce<Record<number, Submission>>((acc, row) => {
        acc[row.id] = row;
        return acc;
      }, {}),
    );
  }, [currentSubmissions]);
  const tableData = useMemo(() => Object.values(value), [value]);

  const handleUnArchiveSubmission = async (rows: MRT_Row<RowData>[]) => {
    const result = await unarchiveSingleSubmission({ id: rows[0].original.id });

    const { success } = result;

    if (!success) {
      console.log(result);
    }

    const title = success ? 'Success!' : 'Ruh-roh';
    const message = success ? `Submission(s) unarchived.` : 'Something went wrong! Unable to unarchive submission(s).';

    setToast({
      title: title,
      message: message,
      isSuccess: success,
      show: true,
      content: '',
      onDone: () => setToast(initialToast),
    });

    await queryClient.refetchQueries({ queryKey: ['combinedData'] });
    await table.resetRowSelection();
  };

  // Define our table columns
  const columns = getHiringColumns({
    tableData: [
      {
        currentSubmissions: currentSubmissions,
        allCandidates: allCandidates,
        allSubmissions: allSubmissions,
      },
    ],
    handleSubmissionChange,
    columnsToReturn: ['name', 'email', 'locationId', 'statusId', 'archivedDateId'],
    value: value,
    setValue: setValue,
  });

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 100,
      },
      columnPinning: {
        left: ['mrt-row-select', 'mrt-row-expand'],
        right: ['mrt-row-actions'],
      },
      sorting: [{ id: 'archivedDateId', desc: true }],
      showGlobalFilter: true,
    },
    enableColumnFilters: true,
    enableTopToolbar: true,
    enableTableFooter: true,
    autoResetPageIndex: false,
    enableRowSelection: true,
    enableExpanding: true,
    enableSorting: true,

    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }),
    }),

    renderDetailPanel: ({ row }) => (
      <CandidateDetailPanel
        row={row}
        currentSubmissions={currentSubmissions}
        allCandidates={allCandidates}
        allSubmissions={allSubmissions}
        showTab={true}
      />
    ),
    renderTopToolbar: ({ table }) => {
      return (
        <Box
          sx={(theme) => ({
            backgroundColor: lighten(theme.palette.background.default, 0.05),
            display: 'flex',
            gap: '0.5rem',
            p: '8px',
            justifyContent: 'space-between',
            borderBottom: '1px solid #D3D3D3',
          })}
        >
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            {/* import MRT sub-components */}
            {/* ==================HEADER SEARCH BAR================== */}
            <MRT_GlobalFilterTextField table={table} />

            {/* ===============HEADER HIDE COLS BUTTON=============== */}
            <MRT_ShowHideColumnsButton table={table} />

            {/* ===============DELETE CANDIDATES BUTTON=============== */}
            <button
              className="custom-button"
              disabled={table.getSelectedRowModel().rows.length === 0}
              onClick={() => {
                handleDeleteSubmissions(table.getSelectedRowModel().rows, table, setToast, queryClient);
              }}
            >
              <DeleteIcon
                style={{
                  color: !table.getIsSomeRowsSelected() ? 'rgb(235, 235, 228)' : 'gray',
                }}
              />
            </button>

            <button
              disabled={table.getSelectedRowModel().rows.length === 0}
              className="custom-button"
              onClick={() => {
                handleUnArchiveSubmission(table.getSelectedRowModel().rows);
              }}
            >
              <UnarchiveIcon
                style={{
                  color: !table.getIsSomeRowsSelected() ? 'rgb(235, 235, 228)' : 'gray',
                }}
              />
            </button>
          </Box>
        </Box>
      );
    },
  });

  return (
    <div>
      <MaterialReactTable table={table} />
      <Toast {...toast} show={toast !== null} onDone={() => setToast(initialToast)} />
    </div>
  );
}

export default ArchivedTable;
