// React imports
import React, { useState, useEffect } from "react";
import { DateTimePicker, DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from 'dayjs';
import { highlightText } from "./helperFunctions";
import { PickersActionBar, PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';

interface EditableCellProps {
  initialValue: string,
  onSave: (newValue: Date | string) => void,
  placeholder: string,
  type: string,
  options: string[],
  rows: number,
  globalFilter: string,
  onTable?: boolean,
}

interface CustomActionBarProps extends Omit<PickersActionBarProps, "onAccept" | "onClear" | "onSetToday"> {
  onCancel: () => void;  // Updated to match the expected type
}

const EditableCell = ({ initialValue, onSave, placeholder, type = "input", options = [], rows = 0, globalFilter, onTable }: EditableCellProps) => {

  const [dateValue, setDateValue] = useState<Dayjs>(dayjs());
  const [tempDateValue, setTempDateValue] = useState<Dayjs>(dayjs());
  const [textValue, setTextValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsEditing(false);
    setIsOpen(false);
    if (dayjs(initialValue).isValid()) {
      const parsedDate = dayjs(initialValue);
      setDateValue(parsedDate);
      setTempDateValue(parsedDate);
    }
    setTextValue(initialValue);
  }, [initialValue]);


  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default behavior
      setIsEditing(false);

      if (type === "datepicker" || type === "datetimepicker") {
        setDateValue(tempDateValue);

        // Convert Dayjs to Date if tempDateValue is a Dayjs object
        const dateValue: Date = tempDateValue.toDate();

        // Now pass the Date to onSave
        onSave(dateValue);  // This ensures that a Date is passed, not Dayjs
      } else {
        onSave(textValue);  // Pass string value to onSave
      }
    }
  };

  // Handle input change for text/select
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    setTextValue(e.target.value);
  };

  const handleTextBlur = () => {
    setIsEditing(false);
    onSave(textValue);
  };


  const handleClick = () => {
    setIsEditing(true);
    if (type === "datepicker" || type === "datetimepicker") {
      setTempDateValue(dateValue);
      setIsOpen(true);
    }
  };

  const handleDateChange = (newValue: Dayjs | null) => {
    if (newValue) {
      setTempDateValue(newValue); // Only set if it's a valid Dayjs object
    } else {
      setTempDateValue(dayjs()); // Fallback to a valid Dayjs value if null
    }
  };

  return (
    <div 
      onClick={handleClick} 
      style={{
        cursor: "pointer", 
        padding: '4px',
        border: onTable ? '' : '1px solid #ccc',
        borderRadius: onTable ? '' : '4px',
        backgroundColor: onTable ? '' : '#f9f9f9',
      }}
    >
      {isEditing ? (
        type === "select" ? (
          <select
            value={textValue}
            onChange={handleChange}
            onBlur={handleTextBlur}
            style={{
              width: "100%",
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "8px",
            }}
          >
            <option value="">{`Select option`}</option>
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        ) : type === "datepicker" ? (
          <div onClick={(e) => e.stopPropagation()}>
            <DatePicker
              sx={{ height: '56px' }}
              value={tempDateValue}
              onChange={handleDateChange}
              open={isOpen}
              onOpen={() => setIsOpen(true)}
              onClose={() => {
                setIsOpen(false);
                setTempDateValue(dateValue);
              }}
              slots={{
                actionBar: PickersActionBar,
              }}
              slotProps={{
                actionBar: {
                  actions: ["cancel", "accept"],
                  onCancel: (e: React.SyntheticEvent) => {
                    e.stopPropagation();
                    setIsOpen(false);
                    setIsEditing(false);
                  },
                } as CustomActionBarProps,
              }}
              onAccept={(finalValue: Dayjs | null) => {
                if (finalValue && finalValue.isValid()) {
                  setDateValue(finalValue);
                  onSave(finalValue.toDate());
                }
                setIsEditing(false);
                setIsOpen(false);
              }}
              closeOnSelect={false}
            />
          </div>
        ) : type === "datetimepicker" ? (
          <DateTimePicker
            value={tempDateValue}
            onChange={handleDateChange} // just track changes
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => {
              setIsOpen(false); // Ensure that it closes on cancel
              setTempDateValue(dateValue); // Optionally reset the date if cancel
            }}
            // Add OK/Cancel buttons:
            slots={{
              actionBar: PickersActionBar,
            }}
            slotProps={{
              actionBar: {
                actions: ["cancel", "accept"],
                onCancel: (e: React.SyntheticEvent) => {
                  e?.stopPropagation();
                  setIsOpen(false);
                  setIsEditing(false);
                },
              } as CustomActionBarProps,
            }}
            onAccept={(finalValue) => {

              if (finalValue) {
                setDateValue(finalValue);
                onSave(finalValue.toDate());
                setIsEditing(false);
              }
            }}
            closeOnSelect={false}
          />
        ) : type === "textarea" ? (

          <textarea
            value={textValue}
            onChange={handleChange}
            onBlur={handleTextBlur}
            placeholder={placeholder}
            style={{
              width: "100%",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
            rows={rows} />

        ) : (<input
          type="text"
          value={textValue}
          onChange={handleChange}
          onBlur={handleTextBlur}
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
          style={{
            width: "100%",
            border: "1px solid #ccc",
            borderRadius: "4px",
          }}
        />
        )
      ) : type === "textarea" ? (
        <textarea
          value={textValue}
          onChange={handleChange}
          onBlur={handleTextBlur}
          placeholder={placeholder}
          style={{
            width: "100%",
            border: "1px solid #ccc",
            borderRadius: "4px",
            backgroundColor: "transparent",
            resize: "none",
            outline: "none",
          }}
          rows={rows} />

      ) : (
        <span>{highlightText(textValue, globalFilter) || placeholder}</span>
      )}
    </div>
  );
};

export default EditableCell;
