import { useMemo, useCallback } from 'react';
import type { Customer, FilterState, SortDirection, Warning } from '../types/types';
interface UseFilteredCustomersProps {
    customers: Customer[];
    filters: FilterState;
    sortType: 'alphabetical' | 'magic' | 'weeks' | 'maintenance' | 'creation' | 'outlines';
    order: SortDirection;
    warningFilters: Warning[];
    selectAllWarningFilters: boolean;
}

export default function useFilteredCustomers({
    customers,
    sortType,
    order,
    warningFilters,
    filters,
    selectAllWarningFilters,
}: UseFilteredCustomersProps) {
    const calculateMagicScore = useCallback((customer: Customer): number => {
        // Sum up all warning weights
        return customer.warnings.reduce((score, warning) => {
            return score + warning.weight;
        }, 0);
    }, []);

    return useMemo(() => {
        const sorted = customers.filter((customer) => {
            const hasMaintenance = customer.maintenance.incompleteTestCount > 0;
            if (filters.excludeNoMaint && !hasMaintenance) return false;
    
            const isFullyUtilized = customer.creation.activeTests >= customer.creation.totalBudget;
            if (filters.excludeFullyUtilized && isFullyUtilized) return false;

            // If selectAllWarningFilters is true, return true for all customers
            if (selectAllWarningFilters) {
                return true;
            }

            const activeFiltersMatch = warningFilters.some((warning) => {
                const hasWarning = customer.warnings.some(
                    (w) => w.type === warning.type || (warning.type === 'milestoneBlocker' && (w.type === 'milestoneBlocker')),
                );
                return hasWarning;
            });

            return activeFiltersMatch;
        });

        if (sortType === 'alphabetical') {
            sorted.sort((a, b) => a.name.localeCompare(b.name));
        } else if (sortType === 'magic') {
            sorted.sort((a, b) => calculateMagicScore(b) - calculateMagicScore(a));
        } else if (sortType === 'weeks') {
            sorted.sort((a, b) => {
                const getWeeks = (customer: Customer) => (!customer.activeMilestone.status ? 0 : customer.activeMilestone.weeksRemaining);
                return getWeeks(a) - getWeeks(b);
            });
        } else if (sortType === 'maintenance') {
            const getWorkToBeDone = (customer: Customer) => customer.maintenance.incompleteTestCount + customer.maintenance.completedTestCount;
            sorted.sort((a, b) => getWorkToBeDone(a) - getWorkToBeDone(b));
        } else if (sortType === 'creation') {
            sorted.sort((a, b) => {
                const getWorkToBeDone = (customer: Customer) => customer.creation.totalBudget - customer.creation.activeTests;
                return getWorkToBeDone(a) - getWorkToBeDone(b);
            });
        } else if (sortType === 'outlines') {
            sorted.sort((a, b) => {
                const getOutlinesPercentage = (customer: Customer) => (customer.creation.activeTests / customer.creation.totalBudget) * 100;
                return getOutlinesPercentage(a) - getOutlinesPercentage(b);
            });
        }

        return order === 'asc' ? sorted : sorted.reverse();
    }, [customers, sortType, order, warningFilters, filters, calculateMagicScore]);
}
