import { useHQContext } from '../../contexts/HQContext';
import { useQaTeam } from '@/components/context/QaTeamContext';
import { Menu, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { classNames } from '../../../InvestigationBoard/helpers';
import { sendPostRequest } from '@/utils/network';
import ActiveShiftsDisplay from '../../../InvestigationBoard/ActiveShiftsDisplay';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

export function Shifts() {
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const { userShiftPrefs, activeShifts, user, investigations, setHqToastData, refetchAll, suiteAction } = useHQContext();
  const { selectedQaTeamIds, qaTeams } = useQaTeam();
  const enabledTeams = qaTeams.filter((x) => selectedQaTeamIds.includes(x.id));

  function getShiftButtonText(text: string) {
    if (!enabledTeams.length) {
      return 'End My Shift For All Teams';
    }

    if (text === 'End') {
      // For End button, only count teams user is on shift for
      const activeShiftTeams = userShiftPrefs.suiteConfig.teams || [];
      const teamCount = activeShiftTeams.length;

      if (teamCount === 1) {
        const team = enabledTeams.find((t) => t.qawId === activeShiftTeams[0]);
        return `End Shift For ${team?.teamName || 'Team'}`;
      }

      return `End Shift For ${teamCount} Teams`;
    }

    // For Start button, show selected teams count
    let teamText;
    if (enabledTeams.length === 1) {
      teamText = enabledTeams[0].teamName;
    } else {
      teamText = enabledTeams.length.toString();
    }
    return `${text} Shift For ${teamText}${teamText.length <= 2 ? ' Teams' : ''}`;
  }

  async function startShift(excludedTaskType: string) {
    try {
      setShowSpinner(true);
      // if messages or suites only selected, unsubscrbie from the other first
      if (excludedTaskType) {
        await sendPostRequest('/task-unsubscribe', {
          qawId: user.qawId,
          taskType: excludedTaskType,
        });
      }

      const teamIds = enabledTeams.filter((x) => x.active && x.qawId !== 'cky0ip87x002509jo3exj9s4z').map((x) => x.qawId);

      await sendPostRequest('/start-shift', {
        qawId: user.qawId,
        teamIds,
      });

      setHqToastData({
        title: 'Success!',
        message: 'Started Team Shift',
        isSuccess: true,
        content: null,
        show: true,
        onDone: () => setHqToastData(null),
      });
    } catch (e: unknown) {
      setShowSpinner(false);
      setHqToastData({
        title: 'Ruh-roh',
        message: `Something went wrong: ${e instanceof Error ? e.message : String(e)}`,
        isSuccess: false,
        content: null,
        show: true,
        onDone: () => setHqToastData(null),
      });
    } finally {
      setShowSpinner(false);
      await refetchAll();
    }
  }

  async function endShift() {
    try {
      setShowSpinner(true);

      const teamIds = enabledTeams.filter((x) => x.active).map((x) => x.qawId);

      await sendPostRequest('/end-shift', { qawId: user.qawId, teamIds });

      // unclaim all claimed triage tasks and messages
      const claimedTasks = [...investigations.ongoing.currentUserTasks, ...investigations.ongoing.currentUserConversations];
      claimedTasks.forEach((t) => {
        suiteAction(t, '/unclaim');
      });

      setShowSpinner(false);
      setHqToastData({
        title: 'Success!',
        message: 'Ended Team Shift',
        isSuccess: true,
        content: null,
        show: true,
        onDone: () => setHqToastData(null),
      });
    } catch (e: unknown) {
      setShowSpinner(false);
      setHqToastData({
        title: 'Ruh-roh',
        message: `Something went wrong: ${e instanceof Error ? e.message : String(e)}`,
        isSuccess: false,
        content: null,
        show: true,
        onDone: () => setHqToastData(null),
      });
    } finally {
      setShowSpinner(false);
      await refetchAll();
    }
  }

  return (
    <div className="flex flex-col ml-4 items-center min-w-[300px]">
      <div id="button-container" className="mt-2 mb-2 flex">
        {!userShiftPrefs?.suiteConfig?.teams?.length ? (
          <Menu as="div" className="relative inline-block text-left">
            <div className="flex">
              <button
                type="button"
                className="inline-flex items-center gap-x-2 rounded-l-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm h-10 hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                disabled={enabledTeams.every((x) => !x.active)}
                onClick={() => startShift('')}
              >
                <CheckCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                {getShiftButtonText('Start')}
              </button>
              <Menu.Button
                type="button"
                className="inline-flex items-center rounded-r-md border-l border-green-700 bg-green-600 px-2 py-2.5 text-sm font-semibold text-white shadow-sm h-10 hover:bg-green-500"
              >
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item key="messagesOnly">
                    {({ active }) => (
                      <button
                        type="button"
                        onClick={() => startShift('suite')}
                        className={classNames('w-full text-left', active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}
                      >
                        Messages Only
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item key="suitesOnly">
                    {({ active }) => (
                      <button
                        type="button"
                        onClick={() => startShift('message')}
                        className={classNames(
                          'overflow-visible w-full text-left',
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm',
                        )}
                      >
                        Suites Only
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        ) : (
          <div className="relative inline-block text-left">
            <button
              type="button"
              className="w-max inline-flex items-center gap-x-2 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm h-10 hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
              onClick={endShift}
            >
              <XCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              {getShiftButtonText('End')}
            </button>
          </div>
        )}
      </div>
      <div className="flex-1 overflow-hidden">
        {showSpinner ? (
          <div className="flex flex-col items-center justify-center h-full bg-purple-500">
            <img
              className="animate-spin"
              src="https://assets-global.website-files.com/6260298eca091b57c9cf188e/6260298eca091b8710cf18ea_logo.svg"
              alt="logo"
              width="40"
            />
            <h1 className="mt-2 text-white">Updating...</h1>
          </div>
        ) : (
          <ActiveShiftsDisplay shifts={activeShifts} teams={enabledTeams} user={user} />
        )}
      </div>
    </div>
  );
}
