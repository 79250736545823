import { Typography, Divider, Box, ListItem, ListItemText } from '@mui/material';
import { List } from 'reactstrap';
import { QaTeamCapacity, usePlanningContext } from '../../context/PlanningContext';

export function TeamCapacityPanel() {
  const { qaTeamCapacity, clientSummaries, totalCreationScheduledForCurrentWeek, totalMaintenanceScheduledForCurrentWeek, remainingCapacity } =
    usePlanningContext();
  const capacityData = qaTeamCapacity.data as QaTeamCapacity;
  const clientSummariesData = clientSummaries.data;
  const isCapacityLoading = qaTeamCapacity.isLoading || qaTeamCapacity.isFetching;
  const isClientSummariesLoading = clientSummaries.isLoading || clientSummaries.isFetching;

  const requiredMilestoneTests = clientSummariesData?.reduce((sum, row) => sum + Math.max(row.requiredVelocity || 0, 0), 0) ?? 0;

  return (
    <Box sx={{ px: 2 }}>
      <Box display="flex" justifyContent="space-between" mt={1}>
        <Typography variant="subtitle2">
          Sched Creation: {isClientSummariesLoading ? 'Loading...' : `${totalCreationScheduledForCurrentWeek} tests`}
        </Typography>
        <Typography variant="subtitle2">
          Sched Maint: {isClientSummariesLoading ? 'Loading...' : `${totalMaintenanceScheduledForCurrentWeek} tests`}
        </Typography>
        <Typography variant="subtitle2">
          Est Creation / Maint Capacity: {isCapacityLoading ? 'Loading...' : `${capacityData.estCapacity} tests`}
        </Typography>
        <Typography variant="subtitle2">Remaining Capacity: {isClientSummariesLoading ? 'Loading...' : `${remainingCapacity} tests`}</Typography>
        <Typography variant="subtitle2">
          Req'd for Milestones: {isClientSummariesLoading ? 'Loading...' : `${requiredMilestoneTests} tests`}
        </Typography>
        <Typography variant="subtitle2">
          Creation / Maint Completed: {isCapacityLoading ? 'Loading...' : `${capacityData.testsCreatedAndMaintainedThisWeek} tests`}
        </Typography>
      </Box>
      <Divider sx={{ my: 1 }} />
      <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between" sx={{ px: 2 }}>
        <Box>
          <List>
            <ListItem disableGutters disablePadding>
              <ListItemText primary="Total QAE Hours" secondary={isCapacityLoading ? 'Loading...' : capacityData.totalQaeHours} />
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ListItemText primary="OOO Hours" secondary={isCapacityLoading ? 'Loading...' : capacityData.oooHours} />
            </ListItem>
          </List>
        </Box>
        <Box>
          <List>
            <ListItem disableGutters disablePadding>
              <ListItemText primary="Avg. Investigation Hours" secondary={isCapacityLoading ? 'Loading...' : capacityData.avgInvestigationHours} />
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ListItemText primary="Avg. Meeting / Other Hours" secondary={isCapacityLoading ? 'Loading...' : capacityData.avgMeetingHours} />
            </ListItem>
          </List>
        </Box>
        <Box>
          <List>
            <ListItem disableGutters disablePadding>
              <ListItemText
                primary="Est. Creation / Maint Hours Available"
                secondary={isCapacityLoading ? 'Loading...' : capacityData.hoursAvailable}
              />
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ListItemText
                primary="Avg. Tests Created / Maintained Per Hour"
                secondary={isCapacityLoading ? 'Loading...' : capacityData.avgTestsCreatedOrMaintainedPerHour}
              />
            </ListItem>
          </List>
        </Box>
        <Box></Box>
      </Box>
      <Box sx={{ ml: 2, my: 2, maxWidth: '100ch' }}>
        <Typography variant="caption">
          <strong>Note:</strong> Average values are calculated based on the last 8 weeks of data.
        </Typography>
      </Box>
    </Box>
  );
}
