import { useState } from 'react';
import { READY_FOR_REVIEW_QUESTIONS } from './ReadyForReviewQuestions';

export function useChecklistState() {
  const [checkedQuestions, setCheckedQuestions] = useState<Record<string, boolean>>({});

  const allQuestionIds = READY_FOR_REVIEW_QUESTIONS.map((question) => question.question);
  const isChecklistComplete = allQuestionIds.every((id) => checkedQuestions[id]);
  const checklistCompletionProgress = (Object.values(checkedQuestions).filter(Boolean).length / allQuestionIds.length) * 100;

  const handleQuestionChange = (id: string) => {
    setCheckedQuestions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const resetChecklist = () => setCheckedQuestions({});

  return {
    checkedQuestions,
    checklistCompletionProgress,
    handleQuestionChange,
    isChecklistComplete,
    resetChecklist,
  };
}
