// React imports
import { useState, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';


// Material UI Imports
import { Box } from '@mui/material';

// Data imports
import EditableCell from '../HiringUtils/EditableCell';
import { getAllJobSources } from '../HiringUtils/api';
import { useHandleSubmissionChange, useHandleCandidateInfoChange } from '../HiringUtils/helperFunctions';
import MarkdownPreviewer from '../HiringCandidatePanel/NotesSection/MarkdownPreviewer';
import ChatGPTDecision from '../HiringCandidatePanel/NotesSection/ChatGPTDecision';
import { JobSource, Submission } from '../Types/types';
import { MRT_Row } from 'material-react-table';
import { FieldHandlers, FormState } from '../Types/FinalRoundDetailPanel/types';
import dayjs from 'dayjs';

const initialNotes = `### Candidate Notes:
- Location:  
- Previous Experience:  
- Referrer:  
- Interviewed Before:  
- Phone Presence:  
- Extra time given:  

##### Other Info:
- Rating:  

### [Question 1]()
-  

### [Question 2]()
-  

### [Question 3]()
-  
- [ ] Checked to see if arrays were empty  
`;


const FinalRoundDetailPanel = ({ row }: { row: MRT_Row<Submission> }) => {
  const { data: jobSources, isLoading, isFetching } = useQuery({
    queryKey: ['allJobSources'],
    queryFn: getAllJobSources,

  });

  // Initialize form state with existing candidate data
  const initialFormState: FormState = useMemo(() => ({
    firstName: row.original.candidate.firstName || '',
    lastName: row.original.candidate.lastName || '',
    email: row.original.candidate.email || '',
    location: row.original.candidate.location || '',
    refereeName: row.original.refereeName || '',
    jobSource: row.original.jobSourceName || '',
    instituteName: row.original.instituteName || '',
    takeHomeVersion: row.original.takeHomeVersion || '',
    finalRoundDate: row.original.finalRoundDate || '',
    level: row.original.level || '',
    interviewer: row.original.interviewer || '',
    status: row.original.status || '',
    stipendInfo: row.original.candidate.stipendInfo || '',
    offerExpirationDate: dayjs(row.original.candidate.offerExpirationDate).isValid()
      ? dayjs(row.original.candidate.offerExpirationDate).format("MM/DD/YYYY") : '',
    followUpDate: dayjs(row.original.candidate.followUpDate).isValid()
      ? dayjs(row.original.candidate.followUpDate).format("MM/DD/YYYY") : '',
    notes: row.original.notes || '',
  }), [row.original]);

  const [formState, setFormState] = useState(initialFormState);

  const handleCandidateInfoChange = useHandleCandidateInfoChange();
  const handleSubmissionChange = useHandleSubmissionChange();

  // Define form fields dynamically
  const formFields = useMemo(() => [
    { label: 'Job Source', name: 'jobSource', type: 'select', options: !isLoading && !isFetching ? jobSources.map((j: JobSource) => j.sourceName) : [] },
    { label: 'Referee Name', name: 'refereeName', type: 'input' },
    { label: 'Institute Name', name: 'instituteName', type: 'input' },
    { label: 'Interviewer', name: 'interviewer', type: 'select', options: ["Amanda", "Jonathan", "Laura"] },
    { label: 'Stipend Info', name: 'stipendInfo', type: 'input' },
    { label: 'Level', name: 'level', type: 'select', options: ['Passed', 'Close', 'Ran out of Time', 'Waste of Time'] },
    { label: 'Offer Expiration Date', name: 'offerExpirationDate', type: 'datepicker' },
    { label: 'Follow-up date', name: 'followUpDate', type: 'datepicker' },
  ], [jobSources]);

  // Handle change of data for specific fields 
  const fieldHandlers: FieldHandlers = {
    stipendInfo: (row, newStipendData) => {
      return handleCandidateInfoChange({
        id: row.original.candidateId,
        stipendInfo: newStipendData,
      },
      );
    },
    followUpDate: (row, newFollowUpDate) => {
      return handleCandidateInfoChange({
        id: row.original.candidateId,
        followUpDate: new Date(newFollowUpDate),
      });
    },
    offerExpirationDate: (row, newOfferExpirationDate) => {
      return handleCandidateInfoChange({
        id: row.original.candidateId,
        offerExpirationDate: new Date(newOfferExpirationDate),
      },
      );
    },
    jobSource: (row, jobSourceName) => {
      const jobSourceId = jobSources.findIndex((j: JobSource) => j.sourceName === jobSourceName) + 1;
      return handleSubmissionChange({
        submissionId: row.original.id,
        newInfo: jobSourceId,
      },
      );
    },
  };

  // Default handler for fields without specific handlers
  const defaultHandler = (row: MRT_Row<Submission>, newValue: string, fieldName: string) => {
    return handleSubmissionChange(
      {
        submissionId: row.original.id,
        newInfo: { [fieldName]: newValue },
      },
    );
  };

  // Handle saving the new info
  const handleSave = (row: MRT_Row<Submission>, newValue: string, fieldName: keyof FieldHandlers) => {
    let handler;

    let valueToSave = '';

    if (typeof newValue === 'object') {
      valueToSave = dayjs(newValue).format("MM/DD/YYYY");
    } else if (typeof newValue === 'string') {
      valueToSave = newValue;
    }

    // Update the local form state with a string value.
    setFormState((prevState) => ({
      ...prevState,
      [fieldName]: valueToSave,
    }));


    if (fieldHandlers[fieldName]) {
      handler = fieldHandlers[fieldName],
        handler(row, newValue);
    } else {
      handler = defaultHandler,
        handler(row, newValue, fieldName);
    }

  };

  return (
    <Box
      className="panelContainer"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        width: '100%',
        height: '100%',
        position: 'sticky',
        backgroundColor: 'rgb(17, 24, 39)',
        '@media (max-width: 1320px) or (max-height: 870px)': {
          width: '90%',
        },
      }}
    >
      <Box
        className="panelTopContainer"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          boxShadow: 6,
          margin: 0,
          borderRadius: 1,
          width: '35%',
          // height: '100%',
          overflowY: 'auto', // Added for scrollability
        }}
      >
        <section
          className="flex flex-wrap flex-col justify-center px-4 pt-2"
          style={{ width: '100%' }}
        >
          {formFields.map((field) => {

            if (field.type === "textarea") {
              return (
                <div className="flex flex-col inputs mb-4" key={field.name}>
                  <label className="font-semibold mb-1 text-white" htmlFor={field.name}>
                    {field.label}
                  </label>
                  <EditableCell
                    initialValue={formState[field.name] as string}
                    onSave={(newValue) => {
                      handleSave(row, newValue as string, field.name as keyof FieldHandlers);
                    }}
                    placeholder={formState[field.name] ? formState[field.name] as string : "—"}
                    type={field.type}
                    options={field.options || []}
                    rows={10}
                    globalFilter=''
                  />
                </div>
              );
            }

            return (
              <div className="flex flex-col inputs mb-4" key={field.name}>
                <label className="font-semibold mb-1 text-white" htmlFor={field.name}>
                  {field.label}
                </label>
                <EditableCell
                  initialValue={formState[field.name] as string}
                  onSave={(newValue) => {
                    handleSave(row, newValue as string, field.name as keyof FieldHandlers);
                  }}
                  placeholder={formState[field.name] ? formState[field.name] as string : "—"}
                  type={field.type}
                  options={field.options || []}
                  rows={0}
                  globalFilter=''
                />
              </div>
            );

          })}
        </section>
        <section
          className="flex flex-col p-4"
          style={{ width: '100%', height: '500px', maxHeight: '500px' }}
        >
          <ChatGPTDecision row={row.original} />
        </section>
      </Box>
      <Box className=" panelBottomContainer p-4 border-r border-gray-700 bg-gray-900 overflow-auto" sx={{ width: '100%' }}>
        <div>
          <MarkdownPreviewer notes={row.original.notes ? row.original.notes : initialNotes} row={row.original} finalRoundNotesWidth={"100%"} finalRoundNotesHeight={"980px"} />
        </div>
      </Box>
    </Box>
  );
};

export default FinalRoundDetailPanel;
