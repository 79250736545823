import React from 'react';

interface MessageProps {
  time: string | number;
  text: string;
  sender: string;
}

const Message: React.FC<MessageProps> = ({ time, text, sender }) => {
  const formattedText = text.split(/(On[\s\S]*?wrote:)/gm)[0];

  return (
    <div className="mb-4">
      <p>{sender}</p>
      <div
        className={`p-4 rounded-lg ${
          sender === 'You' ? 'bg-gray-700 self-end' : 'bg-gray-800'
        }`}
      >
        <div className="text-gray-300">
          <div dangerouslySetInnerHTML={{ __html: formattedText }} />
        </div>
        <p className="text-xs text-gray-500 mt-2">{time}</p>
      </div>
    </div>
  );
};

export default Message;
