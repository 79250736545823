import { FormControl, Select, MenuItem, SelectChangeEvent, Avatar, InputLabel } from '@mui/material';
import { usePlanningContext } from '../../context/PlanningContext';

export default function CsmSelector() {
  const { csms, selectedCsmQawId, setSelectedCsmQawId } = usePlanningContext();

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedCsmQawId(event.target.value ? event.target.value : null);
  };

  return (
    <FormControl sx={{ width: 200 }}>
      <InputLabel>CSM</InputLabel>
      <Select
        value={selectedCsmQawId || ''}
        onChange={handleChange}
        displayEmpty
        renderValue={(selected) => {
          if (!selected) return <em>Any CSM</em>;
          const selectedCsm = csms.data?.find((csm) => csm.qawId === selected);
          return (
            <div style={{ display: 'flex', alignItems: 'center', maxWidth: '100%' }}>
              <Avatar src={selectedCsm?.avatar48} sx={{ width: 24, height: 24, mr: 1, flexShrink: 0 }} />
              <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{selectedCsm?.name}</span>
            </div>
          );
        }}
        label="CSM"
      >
        {csms.data?.map((csm) => (
          <MenuItem key={csm.qawId} value={csm.qawId}>
            <Avatar src={csm.avatar48} sx={{ width: 24, height: 24, mr: 1 }} />
            {csm.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
