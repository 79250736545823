import React, { useMemo } from 'react';
import { generateCsvData, downloadCsv } from '../helpers';
import { FailureSignatureArray } from '../types';
import Button from './Button';

interface HeaderProps {
  customerName: string | null;
  suiteName: string | null;
  customerSlug: string | null;
  environmentId: string | null;
  suiteId: string;
  isLoading: boolean;
  isDeleting: boolean;
  currentData: FailureSignatureArray | null;
  handleDelete: () => void;
}

/**
 * Header component for the Failure Signature page
 * Displays customer and suite information, and provides actions for reprocessing and exporting
 */
const Header: React.FC<HeaderProps> = ({
  customerName,
  suiteName,
  customerSlug,
  environmentId,
  suiteId,
  isLoading,
  isDeleting,
  currentData,
  handleDelete,
}) => {
  /**
   * Generates and downloads CSV data for the current failure signature
   */
  const handleExport = () => {
    if (!currentData) return;

    const csvContent = generateCsvData(currentData);
    if (csvContent) {
      const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
      downloadCsv(csvContent, `Failure-Signature-Report-${customerName}-${suiteId}-${timestamp}.csv`);
    }
  };

  // Memoize the suite link to prevent unnecessary re-renders
  const suiteLink = useMemo(() => {
    if (!customerSlug || !environmentId || !suiteId) return null;

    return `https://app.qawolf.com/${customerSlug}/environments/${environmentId}/runs/${suiteId}`;
  }, [customerSlug, environmentId, suiteId]);

  // Refresh icon for the reprocess button
  const refreshIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 ${isDeleting ? 'animate-spin' : ''}`} viewBox="0 0 20 20" fill="currentColor">
      <path
        fillRule="evenodd"
        d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
        clipRule="evenodd"
      />
    </svg>
  );

  // Download icon for the export button
  const downloadIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
      <path
        fillRule="evenodd"
        d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
  );

  return (
    <div>
      <div className="flex justify-between items-center mb-5">
        <div className="flex flex-wrap items-center gap-2">
          <h1 className="text-3xl font-bold text-gray-200">Failure Signature Graph:</h1>
          {(customerName || suiteName) && (
            <div className="flex items-center gap-2 min-w-fit">
              {customerName && (
                <>
                  <span className="text-3xl font-bold text-gray-200">{customerName}</span>
                </>
              )}
              {suiteName && (
                <>
                  <span className="text-3xl font-bold text-gray-200">-</span>
                  <span className="text-3xl font-bold text-gray-200">{suiteName}</span>
                  {suiteLink && (
                    <a
                      href={suiteLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center text-blue-400 hover:text-blue-300 transition-colors"
                      title="Link to Suite"
                      aria-label={`Open ${suiteName} in QA Wolf`}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                        <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                      </svg>
                    </a>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        <div className="flex items-center gap-4">
          <Button
            onClick={handleDelete}
            disabled={isLoading || !currentData || isDeleting}
            variant="primary"
            isLoading={isDeleting}
            icon={refreshIcon}
          >
            {isDeleting ? 'Reprocessing...' : 'Reprocess Suite'}
          </Button>

          <Button onClick={handleExport} disabled={isLoading || !currentData} variant="success" icon={downloadIcon}>
            Export Report
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Header;
