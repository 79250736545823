import { TableBody, TableRow, TableCell, Skeleton } from '@mui/material';

export default function LoadingRows() {
  const rows = Array.from({ length: 15 }, (_, i) => i + 1);
  return (
    <TableBody>
      {rows.map((rowNum) => (
        <TableRow key={rowNum}>
          <TableCell width="15%">
            <Skeleton variant="rectangular" height={'32px'} className="py-3" />
          </TableCell>
          <TableCell width="15%">
            <Skeleton variant="rectangular" height={'32px'} className="py-3" />
          </TableCell>
          <TableCell width="10%">
            <Skeleton variant="rectangular" height={'32px'} className="py-3" />
          </TableCell>
          <TableCell width="20%">
            <Skeleton variant="rectangular" height={'32px'} className="py-3" />
          </TableCell>
          <TableCell width="20%">
            <Skeleton variant="rectangular" height={'32px'} className="py-3" />
          </TableCell>
          <TableCell width="20%">
            <Skeleton variant="rectangular" height={'32px'} className="py-3" />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
}
