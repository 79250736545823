import { Loader2 } from 'lucide-react';

export function LoadingMessage({ resource }: { resource: string }) {
  return (
    <div className="flex items-center justify-center py-4 text-gray-500 gap-2">
      <Loader2 className="w-4 h-4 animate-spin" />
      <span className="text-sm font-medium">Loading {resource}...</span>
    </div>
  );
}
