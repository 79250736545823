import { useMemo } from 'react';
import Plot from 'react-plotly.js';
import dayjs from 'dayjs';
import { formatCamelCaseToTitle } from './ChartHelpers';
import { stringToColor, hslStringToRgbString } from '../../../utils/colorUtils';

const FlakeTrendLineByTrigger = ({ suiteData }) => {
  // Sort suiteData by startTime
  const sortedSuiteData = useMemo(() => suiteData.sort((a, b) => dayjs(a.startTime).valueOf() - dayjs(b.startTime).valueOf()), [suiteData]);

  // Calculate the number of unique days
  const uniqueDays = useMemo(() => new Set(sortedSuiteData.map((suite) => dayjs(suite.startTime).format('YYYY-MM-DD'))).size, [sortedSuiteData]);

  // Group suites by trigger
  const suitesByTrigger = useMemo(() => {
    return sortedSuiteData.reduce((acc, suite) => {
      let triggerName = suite.triggerName || 'Manual';

      // Group PR suites together
      if (
        triggerName.toLowerCase().startsWith('pr deploy') ||
        triggerName.toLowerCase().startsWith('pr test') ||
        triggerName.toLowerCase().startsWith('pull request') ||
        triggerName.toLowerCase().startsWith('preview')
      ) {
        triggerName = 'PR Deployments';
      }

      if (!acc[triggerName]) {
        acc[triggerName] = [];
      }
      acc[triggerName].push(suite);
      return acc;
    }, {});
  }, [sortedSuiteData]);

  // Calculate flake percentage for each trigger
  const plotData = useMemo(
    () =>
      Object.entries(suitesByTrigger).map(([trigger, suites]) => {
        const dataPoints = suites
          .map((suite) => ({
            x: dayjs(suite.startTime).toISOString(),
            y: suite.flakePercent != null ? parseFloat(suite.flakePercent.toFixed(2)) : null,
          }))
          .filter((point) => point.y !== null);

        const color = hslStringToRgbString(stringToColor(trigger));

        return {
          x: dataPoints.map((point) => point.x),
          y: dataPoints.map((point) => point.y),
          type: 'scatter',
          mode: 'lines+markers',
          line: {
            shape: 'spline',
            smoothing: 1,
            color: color,
          },
          marker: {
            color: color,
          },
          name: formatCamelCaseToTitle(trigger),
        };
      }),
    [suitesByTrigger],
  );

  return (
    <Plot
      // @ts-ignore
      data={plotData}
      layout={{
        title: 'Flake Percentage Trend by Trigger',
        xaxis: {
          title: 'Date',
          type: 'date',
          automargin: true,
          tickformat: '%d-%m-%Y',
          nticks: uniqueDays,
        },
        yaxis: {
          title: 'Flake Percentage',
          automargin: true,
          rangemode: 'tozero',
        },
        hovermode: 'closest',
        legend: {
          orientation: 'h',
          y: -0.5,
          x: 0.5,
          xanchor: 'center',
        },
        margin: {
          l: 50,
          r: 50,
          b: 100,
          t: 50,
          pad: 4,
        },
      }}
      useResizeHandler={true}
      style={{ width: '100%', height: '100%' }}
    />
  );
};

export default FlakeTrendLineByTrigger;
