import { useState } from 'react';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { ChevronDown, ChevronUp, ChevronLeft, ChevronRight, Clock, Layers, AlertCircle, Loader2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { TeamSettings } from './TeamSettings';
import type { RealInvestigationTask, RealUser } from '../types/investigation';
import { InvestigationCard } from './Cards/InvestigationCard';
import { useHQContext } from '../contexts/HQContext';
import { UserAggregator } from './UserAggregator';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { ConversationCard } from './Cards/ConversationCard';
import { Conversation } from '@/components/HQ/data/types';
import { SelectedItem } from './HQMainPage';
import { LoadingMessage } from './LoadingMessage';

// import { useCommunications } from '@/components/context/CommunicationsContext';
import { getOldestTaskAge } from './Cards/helpersInvestigation';
import { useCommunications } from '../contexts/CommunicationsContext';
interface TaskListProps {
  activeTab: 'ongoing' | 'completed';
  setActiveTab: (tab: 'ongoing' | 'completed') => void;
  users: RealUser[];
  handleSelectConversation: (id: number | null) => void;
  selectedItem: SelectedItem;
  setSelectedItem: (item: SelectedItem) => void;
  isExpanded: boolean;
  setIsExpanded: (expanded: boolean) => void;
}

export function TaskList({
  activeTab,
  setActiveTab,
  users,
  handleSelectConversation,
  selectedItem,
  setSelectedItem,
  isExpanded,
  setIsExpanded,
}: TaskListProps) {
  const [isOtherTasksExpanded, setIsOtherTasksExpanded] = useState(false);
  const { investigations } = useHQContext();
  const { currentUserTasks, otherUserTasks, unclaimedTasks, currentUserConversations, otherUserConversations, unclaimedConversations } =
    investigations.ongoing;
  // const completedTasks = investigations.completed;
  const { completedConversations } = investigations;
  const { isLoading } = useCommunications();

  // const sortedAndFilteredInvestigations = useMemo(() => {
  //   const { currentUserTasks, otherUserTasks, unclaimedTasks, completed } = filterTasks(investigations, user, selectedQaTeamIds, qaTeams);

  //   return {
  //     ongoing: { currentUserTasks, otherUserTasks, unclaimedTasks },
  //     completed: completed.sort((a, b) => {
  //       const bTime = b.completedAt || b.createdAt;
  //       const aTime = a.completedAt || a.createdAt;
  //       return new Date(bTime).getTime() - new Date(aTime).getTime();
  //     }),
  //   };
  // }, [investigations, user, selectedQaTeamIds, qaTeams]);

  // Calculate the number of tasks for each tab
  const yourTasksLength = currentUserConversations.length + currentUserTasks.length;
  const otherTasksLength = otherUserTasks.length + otherUserConversations.length;
  const unclaimedTasksLength = unclaimedTasks.length + unclaimedConversations.length;
  const ongoingTasksLength = yourTasksLength + otherTasksLength + unclaimedTasksLength;

  /**
   * /**
   * TODO: Add toggles for specific task types
   */
  // useEffect(() => {
  //   // if there are teams active in local storage but not in the state, update the state
  //   const localActiveTeams = JSON.parse(localStorage.getItem('teams'))?.filter((team) => team.active);

  //   if (!enabledTeams.length && localActiveTeams?.length) setEnabledTeams(localActiveTeams);

  //   // update localStorage with filter settings
  //   localStorage.setItem(
  //     'filterSettings',
  //     JSON.stringify({ showUserTasksOnly, showUserTodosOnly, showGenericTasks, showTriageTasks, showMessageTasks, showPromotionTasks }),
  //   );
  // }, [showUserTasksOnly, showUserTodosOnly, showMessageTasks, showGenericTasks, showTriageTasks, showPromotionTasks]);

  return (
    <div className="overflow-x-hidden">
      <div className="flex items-center justify-between p-2 border-b border-border">
        <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value as 'ongoing' | 'completed')} className="flex-1">
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="ongoing">
              Ongoing
              <span className="ml-2 text-xs bg-primary text-primary-foreground rounded-full px-2 py-1">{ongoingTasksLength}</span>
            </TabsTrigger>
            <TabsTrigger value="completed">Completed</TabsTrigger>
          </TabsList>
        </Tabs>
        <div className="flex items-center gap-2">
          <TeamSettings />
          <Button variant="ghost" size="icon" onClick={() => setIsExpanded(!isExpanded)} className="h-8 w-8">
            {isExpanded ? <ChevronLeft className="h-4 w-4" /> : <ChevronRight className="h-4 w-4" />}
          </Button>
        </div>
      </div>
      <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value as 'ongoing' | 'completed')} className="flex-1 flex flex-col">
        <TabsContent value="ongoing" className="flex-1 p-0" hidden={activeTab !== 'ongoing'}>
          <ScrollArea className="h-[calc(100vh-64px)]">
            <div>
              {/* Claimed message tasks
              {messageTasks.filter((task) => task.claimed && task.claimedBy === 'current-user-id').map(renderMessageTask)} */}
              <div className="bg-purple-100 px-4 py-2 text-sm font-medium text-muted-foreground text-purple-700">Your Tasks ({yourTasksLength})</div>
              {/* Current user's tasks */}
              {isLoading.messages && <LoadingMessage resource="conversations" />}
              {!isLoading.messages &&
                currentUserConversations.map((conversation) => (
                  <ConversationCard
                    key={conversation.id}
                    conversation={conversation}
                    handleSelectConversation={handleSelectConversation}
                    selectedItem={selectedItem}
                  />
                ))}
              {currentUserTasks.map((investigationTask) => (
                <InvestigationCard
                  key={investigationTask.id}
                  investigationTask={investigationTask}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                />
              ))}

              {/* Separator and summary for other users' tasks */}
              <div className="bg-purple-100 px-4 py-1 text-sm font-medium text-purple-700">Other's Tasks ({otherTasksLength})</div>
              {otherTasksLength > 0 && (
                <div className="bg-muted py-2 px-3">
                  <div className="flex justify-between items-center text-xs text-muted-foreground mb-1">
                    <div className="flex items-center space-x-3">
                      <div className="flex items-center">
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger>
                              <div className="flex items-center">
                                <Layers className="w-3 h-3 mr-1" />
                                <span>{otherTasksLength}</span>
                              </div>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>Tasks claimed by others</p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </div>
                      <div className="flex items-center">
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger>
                              <div className="flex items-center">
                                <AlertCircle className="w-3 h-3 mr-1" />
                                <span>
                                  {otherUserTasks.reduce((acc, task) => {
                                    if (task.type === 'suiteInvestigation') {
                                      return acc + task.suite.investigationMetrics.workflowsToInvestigateCount;
                                    }
                                    return acc;
                                  }, 0)}
                                </span>
                              </div>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>Total failures to investigate</p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <Clock className="w-3 h-3 mr-1" />
                      <span>{getOldestTaskAge(otherUserTasks)}</span>
                    </div>
                  </div>
                  <div className="flex justify-between items-center">
                    <UserAggregator users={users as RealUser[]} tasks={otherUserTasks as RealInvestigationTask[]} />
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => setIsOtherTasksExpanded(!isOtherTasksExpanded)}
                      className="h-6 text-xs px-2 py-0"
                    >
                      {isOtherTasksExpanded ? <ChevronUp className="h-3 w-3" /> : <ChevronDown className="h-3 w-3" />}
                    </Button>
                  </div>
                </div>
              )}

              {/* Expandable section for other users' tasks */}

              {isOtherTasksExpanded && (
                <>
                  {isLoading.messages && <LoadingMessage resource="conversations" />}
                  {!isLoading.messages &&
                    otherUserConversations.length > 0 &&
                    otherUserConversations.map((conversation) => (
                      <ConversationCard
                        key={conversation.id}
                        conversation={conversation}
                        handleSelectConversation={handleSelectConversation}
                        selectedItem={selectedItem}
                      />
                    ))}
                  {otherUserTasks.map((investigationTask) => (
                    <InvestigationCard
                      key={investigationTask.id}
                      investigationTask={investigationTask}
                      selectedItem={selectedItem}
                      setSelectedItem={setSelectedItem}
                    />
                  ))}
                </>
              )}

              {/* {isOtherTasksExpanded && messageTasks.filter((task) => task.claimed && task.claimedBy !== 'current-user-id').map(renderMessageTask)} */}

              {/* Message Tasks
              {messageTasks.filter((task) => !task.claimed).map(renderMessageTask)} */}

              {/* Unclaimed tasks */}
              <div className="bg-purple-100 px-4 py-1 text-sm font-medium text-purple-700">Unclaimed Tasks ({unclaimedTasksLength})</div>
              {isLoading.messages && (
                <div className="flex items-center justify-center py-4 text-gray-500 gap-2">
                  <Loader2 className="w-4 h-4 animate-spin" />
                  <span className="text-sm font-medium">Loading conversations...</span>
                </div>
              )}
              {!isLoading.messages &&
                unclaimedConversations.map((conversation) => (
                  <ConversationCard
                    key={conversation.id}
                    conversation={conversation}
                    handleSelectConversation={handleSelectConversation}
                    selectedItem={selectedItem}
                  />
                ))}
              {unclaimedTasks.map((investigationTask) => (
                <InvestigationCard
                  key={investigationTask.id}
                  investigationTask={investigationTask}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                />
              ))}
            </div>
          </ScrollArea>
        </TabsContent>
        <TabsContent value="completed" className="flex-1 p-0" hidden={activeTab !== 'completed'}>
          <ScrollArea className="h-[calc(100vh-64px)]">
            <div>
              {isLoading.messages && <LoadingMessage resource="conversations" />}
              {!isLoading.messages &&
                completedConversations.map((conversation: Conversation) => (
                  <ConversationCard
                    key={conversation.id}
                    conversation={conversation}
                    handleSelectConversation={handleSelectConversation}
                    selectedItem={selectedItem}
                  />
                ))}
              {/* {completedTasks.map((investigationTask) => (
                <InvestigationCard
                  key={investigationTask.id}
                  investigationTask={investigationTask}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                />
              ))} */}
            </div>
          </ScrollArea>
        </TabsContent>
      </Tabs>
    </div>
  );
}
