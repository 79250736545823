'use client';

import { useState /** useEffect, */ } from 'react';
import { TaskList } from './TaskList';
import { InvestigationView } from './InvestigationView';
// import type { RealInvestigationTask, WorkflowStatus } from '../types/investigation';
import { HQContextProvider, useHQContext } from '../contexts/HQContext';
import { CommunicationsProvider, useCommunications } from '@/components/HQ/contexts/CommunicationsContext';
import { ConversationDetails } from './ConversationDetails';
import { ConversationResources } from '@/components/HQ/components/ConversationResources';
import Toast from '@/components/Layout/Toast';
import WolfLoader from '@/components/WolfLoader/WolfLoader';

export interface SelectedItem {
  id: string | number | null;
  type: 'conversation' | 'investigation' | null;
}

function HQMainPage() {
  const { /**updateInvestigation */ allUsers, isLoading, hasError, errors, hqToastData, setHqToastData } = useHQContext();
  // const { updateWorkflowStatus } = useHQContext();
  const [activeTab, setActiveTab] = useState<'ongoing' | 'completed'>('ongoing');
  const [selectedItem, setSelectedItem] = useState<SelectedItem>({ id: null, type: null });
  const [isExpanded, setIsExpanded] = useState(false);
  // Conversation handling
  const { setSelectedConversationId, setConversationsLoading, getConversation, selectedConversationId, toast, setToast } = useCommunications();

  const handleSelectConversation = (conversationId: number | null) => {
    setConversationsLoading(true);
    requestAnimationFrame(() => {
      setSelectedConversationId(conversationId);

      // Get the conversation data
      const conversation = getConversation(Number(conversationId));
      if (conversation) {
        setConversationsLoading(false);
      }

      // Handle tab change if needed
      if (conversation) {
        setActiveTab(conversation.completed ? 'completed' : 'ongoing');
      }
    });
    setSelectedItem({ id: conversationId, type: 'conversation' });
  };

  // // Ensure all investigations have an issues array
  // const updatedInvestigations = useMemo(
  //   () =>
  //     investigations.ongoing.currentUserTasks.map((inv) => ({
  //       ...inv,
  //       issues: [],
  //     })),
  //   [investigations],
  // );

  // useEffect(() => {
  //   if (selectedInvestigationId) {
  //     const investigation = updatedInvestigations.find((inv) => inv.id === selectedInvestigationId);
  //     if (investigation) {
  //       investigation.issues.forEach((issue) => {
  //         issue.workflows.forEach((workflow) => {
  //           updateWorkflowStatus(workflow.id, workflow.status as WorkflowStatus, workflow);
  //         });
  //       });
  //     }
  //   }
  // }, [selectedInvestigationId, updatedInvestigations, updateWorkflowStatus]);

  // const handleCompleteInvestigation = (updatedInvestigation: RealInvestigationTask) => {
  //   updateInvestigation(updatedInvestigation);
  //   setSelectedInvestigationId(null);
  //   setActiveTab('completed');
  // };

  if (isLoading) return <WolfLoader />;
  if (hasError)
    return (
      <span>
        Error:{' '}
        {Object.values(errors)
          .filter((error) => error !== null)
          .join(', ')}
      </span>
    );

  return (
    <div className="flex flex-col h-screen overflow-x-hidden">
      {toast && (
        <Toast
          title={toast?.title ?? ''}
          message={toast?.message ?? ''}
          show={toast !== null}
          onDone={() => setToast(null)}
          isSuccess={toast?.isSuccess}
          content={toast?.content || <></>}
        />
      )}
      <div className="flex flex-1">
        <div className={`flex-shrink-0 w-80 ${isExpanded ? 'w-[500px]' : 'w-80'}`}>
          <TaskList
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            users={allUsers}
            handleSelectConversation={handleSelectConversation}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
          />
        </div>
        <div className="flex-1">
          {selectedItem.type === 'conversation' && selectedConversationId && (
            <div className="flex flex-1 h-full overflow-hidden">
              <div className="flex-1 h-screen overflow-auto bg-qaw-logo bg-center bg-no-repeat bg-blue-50 min-w-[300px]">
                <ConversationDetails conversationId={selectedConversationId} />
              </div>
              <div className={`flex-shrink-0 ${isExpanded ? 'w-[500px]' : 'w-80'} overflow-auto h-screen border-l`}>
                <ConversationResources conversationId={selectedConversationId} handleSelectConversation={handleSelectConversation} />
              </div>
            </div>
          )}
          {selectedItem.id !== null && selectedItem.type === 'investigation' && (
            <InvestigationView
              selectedItem={selectedItem}
              // onComplete={handleCompleteInvestigation}
              // investigations={updatedInvestigations}
            />
          )}
        </div>
      </div>
      <Toast {...hqToastData} show={hqToastData !== null} onDone={() => setHqToastData(null)} />
    </div>
  );
}

export function HQMain() {
  return (
    <CommunicationsProvider>
      <HQContextProvider>
        <HQMainPage />
      </HQContextProvider>
    </CommunicationsProvider>
  );
}
