import { ReactNode, useState } from 'react';
import { useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { flattenTaskData } from '../../../Tasks/helpers';
import TasksTable from '../../../shared/TasksTable';
import Toast from '../../../Layout/Toast';
import { defaultTableState, usePlanningContext } from '../../../context/PlanningContext';
import { QAWTask } from '@/types';
import { Box } from '@mui/material';

type ToastData = {
  title: string;
  message: string;
  isSuccess: boolean;
  content: ReactNode | null;
};

export function PlanningTasksTable({ tasksQuery, queryKey }: { tasksQuery: UseQueryResult<QAWTask[]>; queryKey: string[] }) {
  const [toastData, setToastData] = useState<ToastData | null>(null);
  const { tableState, setTableState } = usePlanningContext();
  const queryClient = useQueryClient();

  if (tasksQuery.isError)
    return (
      <div className="rounded-xl border border-gray-200 bg-white mx-2 mb-4">
        <h1>{'Error loading client tasks...'}</h1>
        <p>{tasksQuery.error?.message}</p>
      </div>
    );

  return (
    <Box sx={{ height: '100%', overflowY: 'auto' }}>
      <Toast
        title={toastData?.title ?? ''}
        message={toastData?.message ?? ''}
        show={toastData !== null}
        onDone={() => setToastData(null)}
        isSuccess={toastData?.isSuccess}
        content={<></>}
      />
      <TasksTable
        data={flattenTaskData(tasksQuery.data ?? [], !!tableState.groupCRandMRs)}
        initialInstanceState={{
          columnVisibility: {
            customer: false,
            name: true,
            workflowStatus: false,
            type: false,
            childCount: false,
          },
        }}
        setToastData={setToastData}
        showSkeletons={tasksQuery.isPlaceholderData && tasksQuery.isFetching}
        tableState={tableState}
        setTableState={setTableState}
        saveViewButton={undefined}
        clearSelectedView={undefined}
        selectedView={undefined}
        defaultTableState={defaultTableState}
        queryClient={queryClient}
        queryKey={queryKey}
      />
    </Box>
  );
}
