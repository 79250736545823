import { useQuery } from '@tanstack/react-query';
import { sendGetRequest } from '../../utils/network';
import { HomeIcon } from '@heroicons/react/24/outline';
import { useState, Fragment } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { TrashIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import { isSavedViewUpdated } from './helpers';
import { adjustDateBasedOnCurrentColumnFilterType } from '../shared/ColumnComponents/helpersForDueDates';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SavedViews({
  selectedView,
  setSelectedView,
  setTableState,
  deleteView,
  updateView,
  setToastData,
  defaultTableState,
  tableState,
}) {
  const userQawId = localStorage.getItem('userId');
  const [contextMenu, setContextMenu] = useState(null);
  const [idForSavedViewMenu, setIdForSavedViewMenu] = useState(null);

  const {
    data: savedTableViews,
    isPending,
    isPlaceholderData,
    isError,
    error,
  } = useQuery({
    queryKey: ['savedTableViews'],
    queryFn: async () => {
      const views = (await sendGetRequest(`/table-views/${userQawId}?tableName=taskTable`)).data;
      // console.dir(views, { depth: null, colors: true });
      return views;
    },
    placeholderData: [],
  });

  if (isPending || isPlaceholderData) return <div>Loading...</div>;
  if (isError) return <span>Error: {error.message}</span>;

  const handleViewSwitch = (view, defaultTableState) => {
    let viewStateCopy = { ...view.state };

    // If the view has a currentColumnFilterType, adjust the columnFilters
    if (viewStateCopy?.currentColumnFilterType) {
      let filterTypes = Object.keys(viewStateCopy.currentColumnFilterType);
      if (filterTypes.includes('dueDate')) {
        viewStateCopy = adjustDateBasedOnCurrentColumnFilterType(viewStateCopy);
      }
    }

    // Set selected view for UI to update
    setSelectedView(view.id);
    // Set table state based on the selected view
    setTableState(() => ({
      ...defaultTableState,
      ...viewStateCopy,
    }));
  };

  const handleContextMenu = (event, viewId) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null,
    );
    setIdForSavedViewMenu(viewId);
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleDelete = () => {
    if (idForSavedViewMenu === 0) {
      setToastData({
        title: 'Error',
        message: "The Home view can't be deleted. 🙃",
        isSuccess: false,
      });
    } else {
      handleClose();
      deleteView.mutate(idForSavedViewMenu, {
        onSuccess: () => {
          if (idForSavedViewMenu === selectedView) {
            setIdForSavedViewMenu(null);
            handleViewSwitch(savedTableViews[0], defaultTableState);
          }
        },
      });
    }
  };

  const handleUpdate = () => {
    if (idForSavedViewMenu === 0) {
      setToastData({
        title: 'Error',
        message: "The Home view can't be updated. 🙃",
        isSuccess: false,
      });
    } else {
      updateView.mutate(idForSavedViewMenu);
    }
    handleClose();
  };

  return (
    <div className="w-4/5">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          onChange={(e) => setSelectedView(e.target.value)}
          value={selectedView}
        >
          {savedTableViews?.map((view) => (
            <option key={view.id}>{view.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex flex-wrap space-x-2" aria-label="Tabs">
          {savedTableViews?.map((view) => (
            <Fragment key={view.id}>
              <a
                onContextMenu={(event) => (view.name !== 'Home' ? handleContextMenu(event, view.id) : null)}
                style={{ cursor: view.name !== 'Home' ? 'context-menu' : 'pointer' }}
                className={classNames(
                  view.id === selectedView ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700',
                  'rounded-md px-3 py-2 text-sm font-medium cursor-pointer',
                )}
                onClick={() => handleViewSwitch(view, defaultTableState)}
                aria-current={view.id === selectedView ? 'page' : undefined}
              >
                {view.name === 'Home' ? <HomeIcon className="h-5 w-5" /> : view.name}
              </a>
              {view.name !== 'Home' && (
                <Menu
                  open={contextMenu !== null}
                  onClose={handleClose}
                  anchorReference="anchorPosition"
                  anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
                >
                  {/* Delete menu item */}
                  <MenuItem onClick={handleDelete}>
                    <TrashIcon className="h-5 w-5 pt-1 mr-1" /> Delete
                  </MenuItem>

                  {/* Update menu item */}
                  <MenuItem
                    onClick={handleUpdate}
                    disabled={
                      idForSavedViewMenu !== selectedView
                        ? true
                        : !isSavedViewUpdated(savedTableViews.find((view) => view.id === idForSavedViewMenu)?.state, tableState, defaultTableState)
                    }
                  >
                    <ArrowPathIcon className="h-5 w-5 pt-1 mr-1" /> Update
                  </MenuItem>
                </Menu>
              )}
            </Fragment>
          ))}
        </nav>
      </div>
    </div>
  );
}
