import { useMutation, useQuery, useQueries } from '@tanstack/react-query';
import { sendPostRequest, sendGetRequest } from '../../utils/network';
import { getOpenTasks } from './investigationActions';

export function useInvestigationBoardQueries(user, filterTasks) {
  const tasksQuery = useQuery({
    queryKey: ['openTasks'],
    queryFn: getOpenTasks,
    placeholderData: [],
    select: filterTasks,
    refetchInterval: 60 * 1000,
    refetchOnWindowFocus: true,
  });

  const [userShiftPrefsQuery, activeShiftsQuery] = useQueries({
    queries: [
      {
        queryKey: ['userShiftPrefs', user?.qawId],
        queryFn: async () => {
          const { data } = await sendPostRequest('/user-prefs', {
            qawId: user.qawId,
            page: '/triage',
          });
          return data;
        },
        enabled: !!user?.qawId,
      },
      {
        queryKey: ['activeShifts'],
        queryFn: async () => {
          const { data } = await sendGetRequest('/active-shifts');
          return data;
        },
      },
    ],
  });

  return {
    tasksQuery,
    userShiftPrefsQuery,
    activeShiftsQuery,
  };
}

export function useTaskMutation(refetch, setDisabledTasks) {
  return useMutation({
    mutationKey: ['taskMutation'],
    mutationFn: ({ endpoint, task }) => {
      return sendPostRequest(endpoint, { task, taskId: task?.id });
    },
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      console.log(error);
    },
    onSettled: (data, error, variables) => {
      setDisabledTasks((prev) => ({ ...prev, [variables.task?.id]: false }));
    },
  });
}
