import React from 'react';
import { ButtonProps } from '../types';

/**
 * A reusable button component with different variants
 */
const Button: React.FC<ButtonProps> = ({ onClick, disabled = false, className = '', children, variant = 'primary', isLoading = false, icon }) => {
  // Base classes
  const baseClasses = 'px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-opacity-50 flex items-center space-x-2';

  // Variant-specific classes
  const variantClasses = {
    primary: 'bg-blue-600 hover:bg-blue-700 text-white focus:ring-blue-500',
    secondary: 'bg-gray-600 hover:bg-gray-700 text-white focus:ring-gray-500',
    success: 'bg-green-600 hover:bg-green-700 text-white focus:ring-green-500',
    danger: 'bg-red-600 hover:bg-red-700 text-white focus:ring-red-500',
  };

  // Disabled classes
  const disabledClasses = 'opacity-50 cursor-not-allowed';

  // Combine classes
  const buttonClasses = `
    ${baseClasses} 
    ${variantClasses[variant]} 
    ${disabled ? disabledClasses : ''} 
    ${className}
  `;

  return (
    <button onClick={onClick} disabled={disabled || isLoading} className={buttonClasses} aria-busy={isLoading}>
      {isLoading && (
        <svg className="animate-spin h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}
      {icon && !isLoading && <span>{icon}</span>}
      <span>{children}</span>
    </button>
  );
};

export default Button;
