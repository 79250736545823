import React from 'react';
import { OperationSummaryItem } from '../typesForSuiteNetworkReport';
import { XMarkIcon } from '@heroicons/react/20/solid';

interface OperationsSummaryRowsProps {
  operations: OperationSummaryItem[];
  expandedRowIndex: number | null;
  toggleRow: (index: number) => void;
  showPassingAttempts: boolean;
}

export default function OperationsSummaryRows({ operations, expandedRowIndex, toggleRow, showPassingAttempts }: OperationsSummaryRowsProps) {
  return (
    <>
      {operations.map((operation, index) => {
        const hasCriticalFailure = operation.workflowsCallingOperation.some((workflow) =>
          workflow.attempts.some((attempt) => attempt.causedAnAttemptToFailFailedTrace || attempt.causedAnAttemptToFailOver30Seconds),
        );

        return (
          <React.Fragment key={index}>
            <tr className={`hover:bg-gray-100 cursor-pointer ${hasCriticalFailure ? 'bg-red-300' : ''}`} onClick={() => toggleRow(index)}>
              <td className="px-2 py-1 border-b border-gray-200 text-left">
                <span className="inline-flex items-center">
                  {operation.operationName.length > 82 ? `${operation.operationName.substring(0, 82)}...` : operation.operationName}
                  {operation.workflowsCallingOperation.some((workflow) => workflow.attempts.some((attempt) => attempt.attemptStatus !== 'pass')) && (
                    <XMarkIcon className="h-5 w-5 text-red-500 ml-1" />
                  )}
                </span>
              </td>
              <td className="px-2 py-1 border-b border-gray-200 text-left">{operation.totalCallsForOperation}</td>
              <td className="px-2 py-1 border-b border-gray-200 text-left">{operation.totalWorkflowsCallingOperation}</td>
              <td className="px-2 py-1 border-b border-gray-200 text-left">{operation.totalReAttempts}</td>
              <td className="px-2 py-1 border-b border-gray-200 text-left">{operation.totalFailedAttempts}</td>
              <td className="px-2 py-1 border-b border-gray-200 text-left">{operation.totalFailedCalls}</td>
              <td className="px-2 py-1 border-b border-gray-200 text-left">{operation.totalCallsLongerThan2}</td>
              <td className="px-2 py-1 border-b border-gray-200 text-left">{operation.totalCallsLongerThan30}</td>
            </tr>
            {expandedRowIndex === index && (
              <tr>
                <td colSpan={8} className="px-2 py-1 border-b border-gray-200">
                  <div className="bg-gray-100 p-2">
                    {operation.workflowsCallingOperation
                      .filter((workflow) => workflow.attempts.some((attempt) => showPassingAttempts || attempt.attemptStatus !== 'pass'))
                      .map((workflow, workflowIndex) => {
                        const hasCriticalFailure = workflow.attempts.some(
                          (attempt) => attempt.causedAnAttemptToFailFailedTrace || attempt.causedAnAttemptToFailOver30Seconds,
                        );

                        return (
                          <div key={workflowIndex} className="mb-5" style={{ backgroundColor: hasCriticalFailure ? 'pink' : 'inherit' }}>
                            <p className="font-bold">
                              Workflow Name: <i>{workflow.workflowName}</i>
                            </p>
                            <ul>
                              {workflow.attempts
                                .filter((attempt) => showPassingAttempts || attempt.attemptStatus !== 'pass')
                                .map((attempt, attemptIndex) => (
                                  <li key={attemptIndex} className="ml-4">
                                    <p>Attempt ID: {attempt.attemptId}</p>
                                    <p>
                                      Attempt URL:{' '}
                                      <a
                                        href={attempt.attemptUrl.toString()}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-500 hover:underline"
                                      >
                                        {attempt.attemptUrl.toString()}
                                      </a>
                                    </p>
                                    <p>Attempt Number: {attempt.attemptNumber}</p>
                                    <p
                                      style={{
                                        color: attempt.attemptStatus === 'pass' ? 'green' : attempt.attemptStatus === 'fail' ? 'red' : 'inherit',
                                      }}
                                    >
                                      Attempt Status: {attempt.attemptStatus}
                                    </p>
                                    <p style={{ color: attempt.causedAnAttemptToFailFailedTrace ? 'red' : 'inherit' }}>
                                      Failed operation Caused An Attempt To Fail: {attempt.causedAnAttemptToFailFailedTrace ? 'Yes' : 'No'}
                                    </p>
                                    <p style={{ color: attempt.causedAnAttemptToFailFailedTrace ? 'red' : 'inherit' }}>
                                      Over 30 Seconds operation Caused An Attempt To Fail: {attempt.causedAnAttemptToFailOver30Seconds ? 'Yes' : 'No'}
                                    </p>
                                    <hr className="mb-3" />
                                  </li>
                                ))}
                            </ul>
                          </div>
                        );
                      })}
                  </div>
                </td>
              </tr>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
}
