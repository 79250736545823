import { useMutation, useQuery } from '@tanstack/react-query';
import { sendPostRequest } from '@/utils/network';
import type { RealInvestigationTask, RealUser } from '../types/investigation';
import { StoredQaTeam } from '@/components/context/PlanningContext';
import { getActiveShifts, getOpenTasks, getUserShiftPrefs } from '@/components/InvestigationBoard/investigationActions';
import { Conversation } from '@/components/HQ/data/types';

export function useTasksQuery(
  user: RealUser,
  filterTasks: (
    tasks: RealInvestigationTask[],
    user: RealUser,
    selectedQaTeamIds: number[],
    qaTeams: StoredQaTeam[],
  ) => {
    ongoing: {
      currentUserTasks: RealInvestigationTask[];
      otherUserTasks: RealInvestigationTask[];
      unclaimedTasks: RealInvestigationTask[];
    };
    completed: RealInvestigationTask[];
    hasUserClaimedMaxTasks: boolean;
  },
  selectedQaTeamIds: number[],
  qaTeams: StoredQaTeam[],
) {
  return useQuery({
    queryKey: ['openTasks'],
    queryFn: getOpenTasks,
    placeholderData: [],
    select: (tasks) => filterTasks(tasks, user, selectedQaTeamIds, qaTeams),
    refetchInterval: 60 * 1000,
    refetchOnWindowFocus: true,
  });
}

export function useUserShiftPrefs(user: RealUser) {
  return useQuery({
    queryKey: ['userShiftPrefs', user?.qawId],
    queryFn: () => getUserShiftPrefs(user),
    enabled: !!user?.qawId,
    refetchInterval: 5 * 60 * 1000, // 5 minutes
  });
}

export function useActiveShifts() {
  return useQuery({
    queryKey: ['activeShifts'],
    queryFn: getActiveShifts,
    refetchInterval: 2 * 60 * 1000, // 2 minutes
  });
}

export function useTaskMutation(refetch: () => void) {
  return useMutation({
    mutationKey: ['taskHQMutation'],
    mutationFn: ({ endpoint, task }: { endpoint: string; task: RealInvestigationTask | Conversation }) => {
      return sendPostRequest(endpoint, { task, taskId: task?.id });
    },
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      console.log(error);
    },
  });
}
