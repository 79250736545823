import { useCommunications } from '../../contexts/CommunicationsContext';
import { CustomerAsk } from '../../contexts/CommunicationsContextTypes';
import { Loader2, CheckCircle, MessageSquare, ExternalLink } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { calculateElapsedTime } from '../Cards/helpersInvestigation';
import { formatDate } from '../../utils/date';
import { Button } from '@/components/ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { generateMessageUrl } from '../../utils/slackUrlGenerator';
import { MainContentContainer } from './RecentSuitesResources';
import { LoadingMessage } from '../LoadingMessage';

// Inner component that only handles the changing content
export function CustomerAsksList({ handleSelectConversation }: { handleSelectConversation: (conversationId: number | null) => void }) {
  const { recentCustomerAsksForCustomer, isLoading } = useCommunications();
  if (isLoading.customerAsks) return <LoadingMessage resource="customerAsks" />;
  if (!recentCustomerAsksForCustomer?.length && !isLoading.customerAsks) return <p>No customer asks found</p>;

  const asksSortedByLatestAndUnresolved = recentCustomerAsksForCustomer.sort((a, b) => {
    // First compare resolved status
    if (a.resolved !== b.resolved) {
      return a.resolved ? 1 : -1; // Unresolved first
    }
    // Then sort by date within each group (latest first)
    const dateA = new Date(a.createdAt).getTime();
    const dateB = new Date(b.createdAt).getTime();
    return dateB - dateA; // Latest dates first
  });

  const { getConversationForAssociatedCustomerAsk } = useCommunications();

  return (
    <div className="grid grid-cols-1 gap-2 pt-2">
      {asksSortedByLatestAndUnresolved.map((customerAsk) => {
        const handleClick = (conversationId: number | null) => {
          handleSelectConversation(conversationId);
        };
        const conversation = getConversationForAssociatedCustomerAsk(customerAsk.id);

        const timestamp = Math.floor(new Date(customerAsk.createdAt).getTime() / 1000).toString();
        const elapsedTime = calculateElapsedTime(timestamp);
        const formattedDate = formatDate(timestamp);

        return (
          <TooltipProvider key={customerAsk.id}>
            <Tooltip disableHoverableContent={false} delayDuration={0}>
              <DropdownMenu>
                <TooltipTrigger asChild>
                  <DropdownMenuTrigger asChild>
                    <Button variant="outline" className="justify-start text-gray-700 hover:text-gray-900 cursor-pointer w-full" asChild>
                      <span className="truncate block">
                        {customerAsk.resolved ? (
                          <CheckCircle className="text-green-600 w-4 h-4 mr-2" />
                        ) : (
                          <Loader2 className="w-4 h-4 mr-1 animate-spin" />
                        )}
                        <span className="truncate block w-full">{customerAsk.reasoning}</span>
                        <span className="text-xs text-gray-500">{elapsedTime} ago</span>
                      </span>
                    </Button>
                  </DropdownMenuTrigger>
                </TooltipTrigger>
                <DropdownMenuContent className="w-48 z-[9999]" align="start">
                  <DropdownMenuItem className="cursor-pointer" onClick={() => window.open(generateMessageUrl(customerAsk.message), '_blank')}>
                    <MessageSquare className="w-4 h-4 mr-2" />
                    See in Slack
                  </DropdownMenuItem>
                  {conversation && (
                    <DropdownMenuItem className="cursor-pointer" onClick={() => handleClick(conversation.id)}>
                      <ExternalLink className="w-4 h-4 mr-2" />
                      See in HQ
                    </DropdownMenuItem>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
              <TooltipContent side="left" align="start" style={{ width: '380px' }} className="bg-gray-600 border-none shadow-xl z-[9999] mr-2 p-3">
                <div className="w-full">
                  <CustomerAskTooltipContent customerAsk={customerAsk} formattedDate={formattedDate} elapsedTime={elapsedTime} />
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        );
      })}
    </div>
  );
}

export function CustomerAskTooltipContent({
  customerAsk,
  formattedDate,
  elapsedTime,
}: {
  customerAsk: CustomerAsk;
  formattedDate: string;
  elapsedTime: string;
}) {
  const typeMapping = {
    information: 'Information',
    run: 'Run',
    maintenance: 'Maintenance',
    bugRevalidation: 'Bug Revalidation',
    testCoverage: 'Test Coverage',
    platform: 'Platform',
    directive: 'Directive',
    other: 'Other',
  };
  return (
    <div className="space-y-2">
      {/* Header */}
      <div className="flex items-center justify-between">
        <span className="text-gray-300 text-xs truncate">Customer Ask #{customerAsk.id}</span>
        <span
          className={`text-xs px-2 py-0.5 rounded-full whitespace-nowrap flex-shrink-0 ${
            customerAsk.resolved ? 'bg-green-500/10 text-green-400' : 'bg-yellow-500/10 text-yellow-400'
          }`}
        >
          {customerAsk.resolved ? 'Resolved' : 'In-Progress'}
        </span>
      </div>

      {/* Main Content */}
      <div className="text-white text-sm font-medium">
        <p className="whitespace-pre-wrap overflow-hidden">{customerAsk.reasoning}</p>
      </div>
      <div className="flex items-center justify-between text-xs text-gray-300 pt-1">
        <MainContentContainer title="Type" value={typeMapping[customerAsk.type || 'other']} />
        {customerAsk.taskQawId && <MainContentContainer title="Task ID" value={customerAsk.taskQawId} />}
      </div>

      {/* Footer Info */}
      <div className="flex flex-col text-xs text-gray-300 pt-1 space-y-1">
        <span className="truncate">Created on {formattedDate}</span>
        <span className="truncate">{elapsedTime} ago</span>
      </div>
      <div className="flex items-center justify-between text-xs text-gray-300 pt-1">
        <span className="truncate">
          {customerAsk.user ? `${customerAsk.resolved ? 'Resolved' : 'Claimed'} by ${customerAsk.user.name}` : 'Unclaimed'}
        </span>
      </div>
    </div>
  );
}
