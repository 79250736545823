import React, { useEffect, useState } from 'react';
import { useCommunications } from '../../HQ/contexts/CommunicationsContext';
import { MessageFeed } from './MessageFeed';
import { Badge } from '@/components/ui/badge';
import { CheckCircle, Circle } from 'lucide-react';
import WolfLoader from '@/components/WolfLoader/WolfLoader';
import { AnimatePresence, motion } from 'framer-motion';
interface ConversationDetailsProps {
  conversationId: number | null;
}

const CUSTOMER_LOGO = 'https://api.dicebear.com/6.x/initials/svg?seed=AC'; // Placeholder for Acme Corp logo

export const ConversationDetails: React.FC<ConversationDetailsProps> = ({ conversationId }) => {
  const [isLocalLoading, setIsLocalLoading] = useState(true);
  const { getConversation, getCustomerById, conversationsLoading } = useCommunications();

  useEffect(() => {
    setIsLocalLoading(true);
    const timer = setTimeout(() => {
      setIsLocalLoading(false);
    }, 50);
    return () => clearTimeout(timer);
  }, [conversationId]);

  const conversation = getConversation(Number(conversationId));
  const customer = getCustomerById(conversation?.customerId || 0);

  if (!conversation) {
    return <div style={{ minWidth: '340px' }} className="flex-1 flex items-center justify-center mt-4"></div>;
  }

  // Only show loader during initial load or when explicitly loading
  if ((isLocalLoading && !conversation) || conversationsLoading) {
    return (
      <div className="flex-1 flex items-center justify-center">
        <WolfLoader />
      </div>
    );
  }

  // TODO: Comment back in when feature is ready
  // const [pinnedThread, setPinnedThread] = React.useState<string | null>(null);
  // const handlePinThread = React.useCallback((threadId: string | null) => {
  //   setPinnedThread((prevPinnedThread) => (prevPinnedThread === threadId ? null : threadId));
  // }, []);

  // const handleAddRelevantItem = () => {
  //   console.log('handleAddRelevantItem');
  // };

  // const togglePinThread = (threadId: string) => {
  //   handlePinThread(threadId === pinnedThread ? null : threadId);
  // };

  return (
    <div className="flex-1 flex flex-col h-full">
      {/* Fixed header */}
      <div className="flex-shrink-0">
        <AnimatePresence>
          <motion.div
            key="conversation-details"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{
              type: 'spring',
              stiffness: 300,
              damping: 20,
              mass: 1,
            }}
          >
            <div className="bg-white shadow-sm py-3">
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <img
                    src={customer?.logo || CUSTOMER_LOGO}
                    alt={customer?.officialName || 'Customer Logo'}
                    width={40}
                    height={40}
                    className="rounded-full mr-3"
                  />
                  <h2 className="text-2xl font-bold text-gray-800">{customer?.officialName || 'Customer Name'}</h2>
                </div>
                <Badge variant={conversation.completed ? 'secondary' : 'outline'} className="text-sm px-2 py-1">
                  {conversation.completed ? <CheckCircle className="w-4 h-4 mr-1" /> : <Circle className="w-4 h-4 mr-1" />}
                  {conversation.completed ? 'Completed' : 'In Progress'}
                </Badge>
              </div>
              <p className="text-sm text-gray-600">{conversation.summary}</p>
              <div className="flex flex-wrap gap-2">
                {/* {conversation.threads.map((thread: Thread) => (
              // TODO: Comment back in when feature is ready
              <TooltipProvider key={thread.id}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div
                      className={`flex items-center space-x-1 px-2 py-1 rounded-full cursor-pointer transition-all duration-200 ${
                        pinnedThread === thread.id ? 'bg-gray-200 text-gray-800' : 'bg-gray-100 text-gray-600'
                      }`}
                      onClick={() => togglePinThread(thread.id)}
                      style={{ borderLeft: `4px solid ${thread.color}` }}
                    >
                      <span className="text-sm font-medium">{thread.id}</span>
                      <Button
                        variant="ghost"
                        size="sm"
                        className="p-0 h-auto text-gray-500 hover:text-gray-700"
                        onClick={(e) => {
                          e.stopPropagation();
                          togglePinThread(thread.id);
                        }}
                      >
                        <Pin className={`w-3 h-3 ${pinnedThread === thread.id ? 'fill-current text-gray-800' : ''}`} />
                      </Button>
                      {thread.completed && <CheckCircle className="w-3 h-3 text-green-500" />}
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{thread.completed ? 'Completed' : 'In Progress'}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ))} */}
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>

      {/* Scrollable message feed */}
      <div className="flex-1 overflow-hidden">
        <MessageFeed conversation={conversation} />
      </div>
    </div>
  );
};
