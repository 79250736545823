import { useMemo, useCallback } from "react";
//Material UI Imports
import { Box, Checkbox } from '@mui/material';

import { statusMap } from "./StatusConstants";

import dayjs from "dayjs";

import { highlightText } from "./helperFunctions";
import { MRT_Row, MRT_ColumnDef, MRT_SortingFn } from "material-react-table";

import EditableCell from "./EditableCell";
import { debounce } from "lodash";


import { CellProps, RowData, UseHandleSubmissionChange, CandidateSubmissionStatus, UseCandidateInfoChange, HandleSubmissionChangeData, Submission, DataForTable_Type } from "../Types/types";

interface GetHiringColumnsProps {
    tableData: DataForTable_Type[] // Array of RowData
    handleSubmissionChange: UseHandleSubmissionChange,
    handleCandidateInfoChange?: UseCandidateInfoChange,
    columnsToReturn?: string[],
    value: Record<number, Submission> | Submission[],
    setValue: (newState: Record<number, Submission> | ((prevState: Record<number, Submission>) => Record<number, Submission>)) => void;

}

interface FinalRoundDateCellValueProps {
    submission: Submission,
    finalRoundDate: Date
}

export default function getHiringColumns({
    tableData,
    handleSubmissionChange,
    handleCandidateInfoChange,
    columnsToReturn = [],
    value,
}: GetHiringColumnsProps) {

    const debouncedHandleChange = useCallback(
        debounce((submissionData: HandleSubmissionChangeData) => {
            handleSubmissionChange(submissionData);
        }) as unknown as (submissionData: HandleSubmissionChangeData) => void,
        [handleSubmissionChange],
    );




    return useMemo(() => {
        const hiringColumns: MRT_ColumnDef<RowData>[] = [

            // ============================ CANDIDATE NAME COLUMN ============================
            {
                accessorFn: (row: RowData) => {
                    const fullName = `${row.candidate.firstName} ${row.candidate.lastName}`;

                    return `${fullName}`.trim();
                },
                id: 'name',
                header: 'Candidate Name',
                enableGlobalFilter: true,
                enableClickToCopy: true,
                Cell: ({ cell, table }: CellProps) => {
                    const nameString = cell.getValue();
                    const globalFilter = table.getState().globalFilter;
                    const submissionCount = cell.row.original.candidate.submissionCount ? `(${cell.row.original.candidate.submissionCount})` : '';

                    return (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <span>{highlightText(`${nameString} ${submissionCount}`, globalFilter)}</span>
                        </Box>
                    );

                },
            },
            // ============================ CANDIDATE EMAIL COLUMN ============================
            {
                accessorFn: (row) => row?.candidate.email ?? '',
                id: 'email',
                header: 'Candidate Email',
                enableClickToCopy: true,
                size: 100,
                Cell: ({ cell, table }) => {
                    const email = cell.getValue<string>();
                    const globalFilter = table.getState().globalFilter;

                    return (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <span>{highlightText(email, globalFilter)}</span>
                        </Box>
                    );

                },
            },
            // ============================ JOB SOURCE COLUMN ============================
            {
                accessorFn: (row) => row?.jobSourceName ?? '',

                id: 'jobSource', //id is still required when using accessorFn instead of accessorKey
                header: 'Job Source',
                size: 100,
                Cell: ({ cell, table }) => {
                    const jobSourceString = cell.getValue<string>();
                    const globalFilter = table.getState().globalFilter;

                    return (

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <span>{highlightText(jobSourceString, globalFilter)}</span>
                        </Box>
                    );
                },
            },
            // ============================ DATE SUBMITTED COLUMN ============================
            {
                accessorFn: (row: RowData) => row.dateSubmitted ? dayjs(row.dateSubmitted) : null,
                id: 'dateSubmitted',
                header: 'Date Submitted',
                filterVariant: 'date-range',

                // Use a custom sorting function or one of MRT's built-ins (e.g. 'datetime')
                sortingFn: ((rowA: MRT_Row<RowData>, rowB: MRT_Row<RowData>, columnId: string): number => {
                    const dateA = rowA.getValue(columnId) ? (dayjs(rowA.getValue(columnId))?.valueOf() ?? 0) : 0;
                    const dateB = rowB.getValue(columnId) ? (dayjs(rowB.getValue(columnId))?.valueOf() ?? 0) : 0;
                    return dateA - dateB;
                }) as MRT_SortingFn<RowData>,

                // Render the highlight
                Cell: ({ cell, table }: CellProps) => {
                    // The raw cell value is a Dayjs object (or null)
                    const dayjsVal = cell.getValue() as dayjs.Dayjs | null;
                    const dateString = dayjsVal ? dayjsVal.format('MM/DD/YYYY') : '';

                    // Grab the user’s typed search from the global filter
                    const globalFilter = table.getState().globalFilter;
                    return (
                        <span>
                            {highlightText(String(dateString), globalFilter)}
                        </span>
                    );
                },
            },
            // ============================ CANDIDATE LOCATION COLUMN ============================
            {
                accessorFn: (row: RowData) => row.candidate.location ?? '',
                id: 'locationId',
                header: 'Location',
                size: 100,
                Cell: ({ cell, table }: CellProps) => {
                    const globalFilter = table.getState().globalFilter;

                    const location = cell.getValue<string>();
                    return (

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <span>{highlightText(location, globalFilter)}</span>
                        </Box>
                    );
                },
            },
            // ============================ CANDIDATE STATUS COLUMN ============================
            {
                accessorFn: (row: RowData) => {
                    const status = statusMap.find((s) => s.value === row.status);
                    return status?.label;
                },
                id: 'statusId',
                header: 'Status',
                size: 100,

                Cell: ({ cell }) => {
                    const submissionId = cell.row.original.id;
                    const status = cell.row.original.status;
                    const matchedStatus = statusMap.find((s) => s.value === status);

                    return (
                        <>
                            <select
                                id="status"
                                name="status"
                                style={{
                                    backgroundColor: matchedStatus?.color || 'blue',
                                    color: matchedStatus?.textColor || 'white',
                                    textAlign: 'center',
                                    borderRadius: '20px',
                                    padding: '5px 24px 5px 18px',
                                    width: '145px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    fontSize: '12px',
                                }}
                                value={status}
                                onChange={(e) => handleSubmissionChange(
                                    {
                                        submissionId: submissionId,
                                        newInfo: { status: e.target.value as CandidateSubmissionStatus },
                                    })
                                }
                            >
                                {statusMap.map((status) => (
                                    <option value={status.value} key={status.value}>
                                        {status.label}
                                    </option>
                                ))}
                            </select>
                        </>
                    );
                },
            },
            // // ========================= FINAL ROUND DATE =========================
            {
                accessorFn: (row: RowData) => ({
                    submission: row,
                    finalRoundDate: row.finalRoundDate,
                }),
                id: 'finalRoundDate',
                header: 'Final Round Date',
                Cell: ({ cell, table }: CellProps) => {
                    const { submission, finalRoundDate } = cell.getValue() as FinalRoundDateCellValueProps;
                    const globalFilter = table.getState().globalFilter;
                    const row = cell.row;


                    const initialValue = finalRoundDate ? dayjs(finalRoundDate).format('MMM DD, YYYY h:mm A') : "Select a date";

                    // Handler to save the updated date
                    const handleSaveDate = async (newValue: Date) => {
                        if (handleCandidateInfoChange) {
                            const convertedDate = dayjs(newValue).add(12, 'month').toDate();
                            handleCandidateInfoChange({
                                candidate: {
                                    id: submission.candidateId,
                                    newInfo: { finalRoundCooldown: convertedDate },
                                },
                                row,
                            });
                        }

                        if (handleSubmissionChange) {
                            handleSubmissionChange({
                                submissionId: submission.id,
                                newInfo: { finalRoundDate: newValue },
                            });
                        }
                    };
                    const handleSave = (newValue: Date | string) => {
                        if (newValue instanceof Date) {
                            handleSaveDate(newValue);
                        }
                    };



                    return (
                        <EditableCell
                            initialValue={initialValue}
                            onSave={handleSave}
                            placeholder="Select a date"
                            type="datetimepicker"
                            globalFilter={globalFilter}
                            rows={0}
                            options={[]}
                            onTable={true}
                        />
                    );
                },
                sortingFn: (a, b) => {
                    const dateA = a.original.finalRoundDate ? new Date(a.original.finalRoundDate) : new Date(0);
                    const dateB = b.original.finalRoundDate ? new Date(b.original.finalRoundDate) : new Date(0);
                    return dateA.getTime() - dateB.getTime();
                },
            },
            // ========================= FINAL ROUND SANDBOX =========================
            {
                header: 'Sandbox',
                id: 'removedFromSandbox',
                accessorKey: 'removedFromSandbox',
                size: 50,
                Cell: ({ cell, table }: CellProps) => {
                    const globalFilter = table.getState().globalFilter;
                    const row = cell.row;

                    const handleSaveSandbox = (newValue: string) => {
                        handleSubmissionChange({
                            submissionId: row.original.id,
                            newInfo: { sandbox: newValue },
                        });
                    };


                    const handleSave = (newValue: Date | string) => {
                        if (typeof newValue === 'string') {
                            handleSaveSandbox(newValue);
                        }
                    };

                    return (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Checkbox
                                checked={value[row.original.id].removedFromSandbox}
                                onChange={(e) => {
                                    const checked = e.target.checked;
                                    debouncedHandleChange({
                                        submissionId: row.original.id,
                                        newInfo: { removedFromSandbox: checked },
                                    });
                                }}
                            />

                            {
                                !value[row.original.id].removedFromSandbox &&


                                <EditableCell
                                    initialValue={value[row.original.id].sandbox ?? ""}
                                    onSave={handleSave}
                                    placeholder="#"
                                    globalFilter={globalFilter}
                                    type='text'
                                    rows={0}
                                    options={[]}
                                    onTable={true}
                                />
                            }

                        </Box>
                    );
                },
            },
            // ========================== FINAL ROUND TEAM ==========================
            {
                header: 'Final Round Team',
                id: 'finalRoundTeam',
                accessorKey: 'finalRoundTeam',
                Cell: ({ cell, table }) => {
                    const globalFilter = table.getState().globalFilter;
                    const cellData = cell.getValue() as string;
                    const row = cell.row;

                    return (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {cellData && (
                                <a
                                    href={`https://app.qawolf.com/${cellData}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                        color: '#6366f1',
                                    }}
                                >

                                    {highlightText(`${row.original.candidate.firstName}'s Superday`, globalFilter)}
                                </a>
                            )}
                        </Box>
                    );
                },
            },
            // ========================== FOLLOW UP DATE ==========================
            {
                accessorKey: 'followUpDate',
                id: 'followUpDate',
                header: 'Follow-up Date',
                sortingFn: (rowA, rowB) => {
                    const dateA = rowA.original.candidate.followUpDate ? new Date(rowA.original.candidate.followUpDate) : new Date(0);
                    const dateB = rowB.original.candidate.followUpDate ? new Date(rowB.original.candidate.followUpDate) : new Date(0);


                    // Handle missing dates first
                    if (!dateA && !dateB) return 0;
                    if (!dateA) return -1; // Put empty/missing dates first
                    if (!dateB) return 1;
                    // Convert both to timestamps and subtract for ascending order
                    const timeA = dayjs(dateA).valueOf();
                    const timeB = dayjs(dateB).valueOf();

                    return timeA - timeB;
                },
                Cell: ({ cell }) => {
                    const followUpDate = cell.getValue() as string; // raw value from accessorKey

                    // Check if valid
                    if (!followUpDate || isNaN(new Date(followUpDate).getTime())) {
                        return <p>N/A</p>;
                    }

                    // Format with dayjs
                    const formattedDate = dayjs(followUpDate).format('MM/DD/YYYY hh:mm A');
                    return <p>{formattedDate}</p>;
                },
            },
            // ========================== OFFER EXP DATE ==========================
            {
                accessorKey: 'offerExpirationDate',
                id: 'offerExpirationDate',
                header: 'Offer Expiration Date',
                sortingFn: (rowA, rowB) => {
                    const dateA = rowA.original.candidate.offerExpirationDate ? new Date(rowA.original.candidate.offerExpirationDate) : new Date(0);
                    const dateB = rowB.original.candidate.offerExpirationDate ? new Date(rowB.original.candidate.offerExpirationDate) : new Date(0);


                    // Handle missing dates first
                    if (!dateA && !dateB) return 0;
                    if (!dateA) return -1; // Put empty/missing dates first
                    if (!dateB) return 1;
                    // Convert both to timestamps and subtract for ascending order
                    const timeA = dayjs(dateA).valueOf();
                    const timeB = dayjs(dateB).valueOf();

                    return timeA - timeB;
                },
                Cell: ({ cell }) => {
                    const offerExpirationDate = cell.getValue() as string; // raw value from accessorKey

                    // Check if valid
                    if (!offerExpirationDate || isNaN(new Date(offerExpirationDate).getTime())) {
                        return <p>N/A</p>;
                    }

                    // Format with dayjs
                    const formattedDate = dayjs(offerExpirationDate).format('MM/DD/YYYY hh:mm A');
                    return <p>{formattedDate}</p>;
                },
            },
            // ========================== ARCHIVED DATE ==========================
            {
                accessorKey: 'archivedDate',
                id: 'archivedDateId',
                header: 'Archived Date',
                sortingFn: (rowA, rowB) => {
                    const dateA = rowA.original.archivedDate ? new Date(rowA.original.archivedDate) : new Date(0);
                    const dateB = rowB.original.archivedDate ? new Date(rowB.original.archivedDate) : new Date(0);


                    // Handle missing dates first
                    if (!dateA && !dateB) return 0;
                    if (!dateA) return -1; // Put empty/missing dates first
                    if (!dateB) return 1;
                    // Convert both to timestamps and subtract for ascending order
                    const timeA = dayjs(dateA).valueOf();
                    const timeB = dayjs(dateB).valueOf();

                    return timeA - timeB;
                },
                Cell: ({ cell }) => {
                    const archivedDate = cell.getValue() as string; // raw value from accessorKey

                    // Check if valid
                    if (!archivedDate || isNaN(new Date(archivedDate).getTime())) {
                        return <p>No Date Available</p>;
                    }

                    // Format with dayjs
                    const formattedDate = dayjs(archivedDate).format('MM/DD/YYYY hh:mm A');
                    return <p>{formattedDate}</p>;
                },
            },
        ];
        // Filter columns based on columnsToReturn
        if (columnsToReturn.length > 0) {
            return hiringColumns.filter((column) => columnsToReturn.includes(column.id as string));
        }

        // If no columns are specified, return all columns
        return hiringColumns;
    }, [tableData, columnsToReturn]);

}
