import { FormControl, InputLabel, Select, MenuItem, ListItemText, Checkbox, ListItemAvatar, Avatar } from '@mui/material';
import { usePlanningContext } from '../../context/PlanningContext';

import { plural } from '../../InvestigationBoard/helpers';
import { ChangeEvent, MouseEvent } from 'react';

export function QaTeamSelector() {
  const { selectedQaTeamIds, setSelectedQaTeamIds, allTeamsSelected, setAllTeamsSelected, qaTeams } = usePlanningContext();

  const getSelectLabel = () => {
    if (selectedQaTeamIds.length === 0) return '';
    if (selectedQaTeamIds.length === 1) {
      return qaTeams.find((team) => team.id === selectedQaTeamIds[0])?.teamName;
    }
    const firstTeam = qaTeams.find((team) => team.id === selectedQaTeamIds[0])?.teamName;
    return `${firstTeam} + ${selectedQaTeamIds.length - 1} other${plural(selectedQaTeamIds.length - 1)}`;
  };

  /**
   * Handle checkbox click within `<Checkbox />` component
   */
  const handleTeamCheckboxClick = (e: MouseEvent<HTMLButtonElement>, teamId: number) => {
    e.stopPropagation();

    // if this is the last selected team and user is trying to uncheck it, do nothing
    if (selectedQaTeamIds.length === 1 && selectedQaTeamIds.includes(teamId)) {
      return;
    }

    // toggle the team id in the selection
    const newSelection = selectedQaTeamIds.includes(teamId) ? selectedQaTeamIds.filter((id) => id !== teamId) : [...selectedQaTeamIds, teamId];

    setSelectedQaTeamIds(newSelection);
    setAllTeamsSelected(newSelection.length === qaTeams.length);
    localStorage.setItem('selectedQaTeamIds', JSON.stringify(newSelection));
  };

  /**
   * Handle click on `<MenuItem />` (but not `<Checkbox />`)
   */
  const handleTeamItemClick = (teamId: number) => {
    // if this team is the only selected team, do nothing
    if (selectedQaTeamIds.length === 1 && selectedQaTeamIds.includes(teamId)) {
      return;
    }

    // replace selection with just this team
    const newSelection = [teamId];
    setSelectedQaTeamIds(newSelection);
    setAllTeamsSelected(false);
    localStorage.setItem('selectedQaTeamIds', JSON.stringify(newSelection));
  };

  const handleAllTeamsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAllTeamsSelected(e.target.checked);

    // if all teams are selected, set the selected qa team ids to all qa team ids, otherwise set the selected qa team ids to the first qa team id
    const newSelection = e.target.checked ? qaTeams.map((team) => team.id) : [qaTeams[0].id];
    setSelectedQaTeamIds(newSelection);
    localStorage.setItem('selectedQaTeamIds', JSON.stringify(newSelection));
  };

  return (
    <FormControl sx={{ minWidth: 200 }}>
      <InputLabel>QA Team</InputLabel>
      <Select multiple value={selectedQaTeamIds} label="QA Team" renderValue={getSelectLabel}>
        <MenuItem>
          <ListItemText primary="All QA Teams" />
          <Checkbox checked={allTeamsSelected} onChange={handleAllTeamsChange} />
        </MenuItem>
        {qaTeams.map((team) => (
          <MenuItem
            key={team.id}
            value={team.id}
            onClick={() => handleTeamItemClick(team.id)}
            // disable the entire menuitem if this is the only selected team
            disabled={selectedQaTeamIds.length === 1 && selectedQaTeamIds.includes(team.id)}
          >
            <ListItemAvatar>
              <Avatar src={team.imageUrl} />
            </ListItemAvatar>
            <ListItemText primary={team.teamName} secondary={team.name} />
            <Checkbox
              checked={selectedQaTeamIds.includes(team.id)}
              onClick={(e) => handleTeamCheckboxClick(e, team.id)}
              // disable checkbox if this is the only selected team
              disabled={selectedQaTeamIds.length === 1 && selectedQaTeamIds.includes(team.id)}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
