import { RealInvestigationTask, RealUser } from '../types/investigation';
// import _ from 'lodash';
// import { getTriageStats } from '../../../InvestigationBoard/helpers';
import { StoredQaTeam } from '@/components/context/PlanningContext';

/**
 * Takes in the array of all tasks and returns an object with an array of tasks for each columln.
 * @param {import('../../types').QAWTask[]} tasks
 * @returns {{ tasks: {
 *   toDoTasks: import('../../types').QAWTask[],
 *   inProgressTasks: import('../../types').QAWTask[],
 * }, hasUserClaimedMaxTasks: boolean }} The sorted tasks object.
 */
export function filterTasks(tasks: RealInvestigationTask[], user: RealUser, selectedQaTeamIds: number[], qaTeams: StoredQaTeam[]) {
    const maxClaimedTriageTasks = 5;
    const taskTypeOrder = {
        "message": 0,
        "triage": 2,
        // "bugVerification": 2,
        "projectPlan": 3,
        "none": 4,
        "promotion": 1,
        "suiteInvestigation": 2,
    };

    const visibleLeadsIds = selectedQaTeamIds.length ? qaTeams.filter((x) => selectedQaTeamIds.includes(x.id)).map((y) => y.qawId) : ['ckyj0a6ar000x09mfefnp9irj']; // allows seeing Test Client when deselecting all teams

    // ensure tasks are sorted by type & filter out tasks for unselected teams
    const filteredTasks = tasks
        .sort((a, b) => {
            const aSubType = (a.subType || a.type) as keyof typeof taskTypeOrder;
            const bSubType = (b.subType || b.type) as keyof typeof taskTypeOrder;
            if (taskTypeOrder[aSubType] < taskTypeOrder[bSubType]) return -1;
            if (taskTypeOrder[aSubType] > taskTypeOrder[bSubType]) return 1;
            return 0;
        })
        .filter((x) => x.type === 'suiteInvestigation')
        .filter((x) => visibleLeadsIds.includes(x.qaLead.id));

    const inProgressTasks = filteredTasks.filter((inv) => inv.status === 'toDo' || inv.status === 'inProgress');
    const completed = filteredTasks.filter((inv) => inv.status === 'done');

    const sortByPriority = (a: RealInvestigationTask, b: RealInvestigationTask) => {
        const priorityOrder = { urgent: 0, high: 1, medium: 2, low: 3, unprioritized: 4 };
        return priorityOrder[a.priority] - priorityOrder[b.priority];
    };

    const currentUserTasks = inProgressTasks.filter((inv) => inv.assignedTo && inv.assignedTo.id === user.qawId).sort(sortByPriority);
    const otherUserTasks = inProgressTasks.filter((inv) => inv.assignedTo.id && inv.assignedTo.id !== user.qawId).sort(sortByPriority);
    const unclaimedTasks = inProgressTasks.filter((inv) => inv.assignedTo.id === undefined).sort(sortByPriority);

    /**
     * TODO: Add button for primary team only, and include this filter
     */
    // // Adjust tasks based on primaryTeamOnly state
    // if (primaryTeamOnly) {
    //     filteredTasks = filteredTasks.filter((task) => !task.primaryQaTeam || task.qaLead.id === task.primaryQaTeam.lead.qawId);
    // }

    /** 
     * TODO: Implement functino for triage stats
     */
    // // generate triage stats
    // let { stats, groupsSummary } = getTriageStats(
    //     selectedQaTeamIds,
    //     tasks.filter((x) => x.type === 'suiteInvestigation'),
    //     todoTasks.filter((x) => x.type === 'suiteInvestigation'),
    //     inProgressTasks.filter((x) => x.type === 'suiteInvestigation'),
    //     tasks.filter((x) => x.subType === 'message'), // message tasks
    // );

    // const triageStats = stats;
    // const teamTriageSummaries = groupsSummary;

    /**
     * TODO: Add toggles for specific task types
     */
    // // filter tasks based on selected view toggles
    // const todoTasksFiltered = todoTasks.filter((x) => {
    //     if (x.type === 'suiteInvestigation') return showTriageTasks;
    //     if (x.type === 'promotion') return showPromotionTasks;
    //     if (x.subType === 'message') return showMessageTasks;
    //     if (x.subType === 'bugVerification') return showGenericTasks;
    //     if (x.subType === 'projectPlan') return showGenericTasks;
    //     if (x.subType === 'none') return showGenericTasks;
    //     return true;
    // });

    // // filter tasks based on selected view toggles
    // const inProgressTasksFiltered = inProgressTasks.filter((x) => {
    //     if (x.type === 'suiteInvestigation') return showTriageTasks;
    //     if (x.type === 'promotion') return showPromotionTasks;
    //     if (x.subType === 'message') return showMessageTasks;
    //     if (x.subType === 'bugVerification') return showGenericTasks;
    //     if (x.subType === 'projectPlan') return showGenericTasks;
    //     if (x.subType === 'none') return showGenericTasks;
    //     return true;
    // });

    const hasUserClaimedMaxTasks =
        inProgressTasks.filter((x) => x.type === 'suiteInvestigation' && x.assignedTo.id === user.qawId).length >= maxClaimedTriageTasks;

    return {
        ongoing: { currentUserTasks, otherUserTasks, unclaimedTasks },
        completed: completed.sort((a, b) => {
            const bTime = b.completedAt || b.createdAt;
            const aTime = a.completedAt || a.createdAt;
            return new Date(bTime).getTime() - new Date(aTime).getTime();
        }),
        hasUserClaimedMaxTasks,
        /**
         * triageStats,
         * teamTriageSummaries,
         */
    };
}
