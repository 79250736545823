import axios, { AxiosResponse } from 'axios';

type RequestConfig = {
  method: string;
  maxBodyLength: number;
  url: string;
  headers: {
    'Content-Type': string;
    Authorization?: string;
  };
  data: string | object;
};

// eslint-disable-next-line no-undef
const baseUrl = `${process.env.REACT_APP_URL}/apis/task-wolf`;

export async function sendPostRequest(url: string, jsonData: Record<string, any> = {}, contentType = 'application/json'): Promise<AxiosResponse<any, any>> {
  const targetUrl = `${baseUrl}${url}`;
  const stringifiedData = JSON.stringify(jsonData);
  let config: RequestConfig;

  if (contentType === 'multipart/form-data') {
    config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: targetUrl,
      headers: {
        'Content-Type': contentType,
      },
      data: jsonData,
    };
  } else {
    config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: targetUrl,
      headers: {
        'Content-Type': contentType,
      },
      data: stringifiedData,
    };
  }


  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `bearer ${token}`;
  }

  return (await axios.request(config)) as AxiosResponse<any, any>;
}

export async function sendGetRequest(url: string): Promise<AxiosResponse<any, any>> {
  const targetUrl = `${baseUrl}${url}`;
  const stringiedData = JSON.stringify({});

  const config: RequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: targetUrl,
    headers: {
      'Content-Type': 'application/json',
    },
    data: stringiedData,
  };

  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `bearer ${token}`;
  }

  return (await axios.request(config)) as AxiosResponse<any, any>;
}

export async function sendPutRequest(url: string, jsonData: Record<string, any> = {}): Promise<AxiosResponse<any, any>> {
  const targetUrl = `${baseUrl}${url}`;
  const stringifiedData = JSON.stringify(jsonData);

  const config: RequestConfig = {
    method: 'put',
    maxBodyLength: Infinity,
    url: targetUrl,
    headers: {
      'Content-Type': 'application/json',
    },
    data: stringifiedData,
  };

  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `bearer ${token}`;
  }

  return (await axios.request(config)) as AxiosResponse<any, any>;
}
