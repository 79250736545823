import React, { useState, ChangeEvent } from 'react';

import emailTemplates from './emailTemplate';
import '../../../../css/hiring.css';
import { sendCandidateEmail } from '../../HiringUtils/api';
import { Editor, EditorState, CompositeDecorator, ContentBlock, ContentState, CharacterMetadata } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import ReactDOMServer from 'react-dom/server';
import Toast from '../../../Layout/Toast';
import { MessageInputData, LinkProps, EmailTemplate, HandleSendingEmail } from '../../Types/EmailSection/types.js';
import { ToastData } from '@/components/HQ/contexts/CommunicationsContextTypes';

const MessageInput = ({ candidate, singleMessageHeaders }: MessageInputData) => {
  const initialToast = {
    title: 'Your title here',
    message: 'Your message here',
    show: false,
    content: '',
    isSuccess: false,
    onDone: () => setToastData(initialToast),
  };

  const [isOpen, setIsOpen] = useState(false); // To manage dropdown visibility
  const [isDisabled, setIsDisabled] = useState(false);
  const [toastData, setToastData] = useState<ToastData | null>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Set the search term
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  // Filter through the email templates based on the search term
  const filteredItems = emailTemplates.filter((template) => template.name.toLowerCase().includes(searchTerm.toLowerCase()));

  // Define the Link Decorator
  const findLinkEntities = (contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState): void => {
    contentBlock.findEntityRanges((character: CharacterMetadata) => {
      const entityKey = character.getEntity();
      return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
    }, callback);
  };

  const Link: React.FC<LinkProps> = ({ contentState, entityKey, children }: LinkProps) => {
    const { url } = contentState.getEntity(entityKey).getData();
    return (
      <a href={url} style={{ color: 'teal', textDecoration: 'underline' }}>
        {children}
      </a>
    );
  };

  // Create a CompositeDecorator
  const linkDecorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);

  // Handle template selection from buttons or dropdown
  const handleTemplateSelection = (templateObj: EmailTemplate) => {
    const templateContent = templateObj.content({ firstName: candidate.firstName });
    const htmlString = ReactDOMServer.renderToString(templateContent);
    const blocksFromHTML = stateFromHTML(htmlString);

    // Apply the CompositeDecorator to highlight links
    const editorState = EditorState.createWithContent(blocksFromHTML, linkDecorator);

    setEditorState(editorState);
    setIsOpen(false); // Close the dropdown after selection
    setSearchTerm(''); // Clear the search term after selection
  };

  const handleSendingEmail = async ({ candidate, singleMessageHeaders }: HandleSendingEmail) => {
    try {
      // Disable the button
      setIsDisabled(true);

      // Clears the input box
      setEditorState(() => EditorState.createEmpty());

      const state = editorState.getCurrentContent();
      const currentEditorState = stateToHTML(state);
      await sendCandidateEmail({ currentEditorState, candidate, singleMessageHeaders });

      setToastData({
        title: 'Success!',
        message: `Email sent to candidate.`,
        isSuccess: true,
        show: true,
        content: '',
        onDone: () => setToastData(initialToast),
      });

      // Re-enable the button
      setIsDisabled(false);
    } catch (error) {
      console.log('Error sending candidate email:', error);
      setToastData({
        title: 'Ruh-roh',
        message: 'Something went wrong! Unable to send email.',
        isSuccess: false,
        show: true,
        content: '',
        onDone: () => setToastData(initialToast),
      });

      // Re-enable the button
      setIsDisabled(false);
    }
  };

  return (
    <div className="template-container flex items-center p-4 border-t border-gray-700 bg-gray-900 h-[25%]">
      <div className="template-buttons flex flex-col items-center justify-center space-y-2.5 w-[33%] h-full">
        {/* ========================= Buttons ========================= */}
        <button
          className="reject-button bg-gray-700 text-white rounded-md shadow-md hover:bg-gray-600 focus:outline-none w-2/3 p-0.75"
          onClick={() => {
            const template = emailTemplates.find((item) => item.name === 'Reject Take-home');
            if (template) {
              handleTemplateSelection(template);
            }
          }}
        >
          Reject Take Home
        </button>
        <button
          className="final-round-button bg-gray-700 text-white rounded-md shadow-md hover:bg-gray-600 focus:outline-none w-2/3 p-0.75"
          onClick={() => {
            const template = emailTemplates.find((item) => item.name === 'Schedule Final Round');
            if (template) {
              handleTemplateSelection(template);
            }
          }}
        >
          Schedule Final Round
        </button>

        {/* ========================== Custom Dropdown ========================== */}
        <div className=" relative inline-block w-2/3 ">
          <button
            onClick={toggleDropdown}
            className="template-dropdown-button bg-white text-gray-700 rounded-md shadow-md hover:bg-gray-200 focus:outline-none w-full p-0.75"
          >
            Choose a template
          </button>

          {isOpen && (
            <div className="template-select absolute bottom-full mb-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10 text-gray-700">
              <input
                type="text"
                placeholder="Search templates..."
                value={searchTerm}
                onChange={handleSearch}
                style={{
                  padding: '20px 10px',
                  width: '100%',
                  height: '32px',
                  fontSize: '14px',
                  border: '1px solid #ccc',
                  borderRadius: '4px 4px 0 0',
                }}
              />
              {searchTerm ? (
                <ul className="py-1 max-h-48 overflow-y-auto divide-y divide-solid divide-gray-200">
                  {filteredItems.map((item, index) => (
                    <li key={index}>
                      <button
                        onClick={() => handleTemplateSelection(item)}
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200 w-full text-left"
                      >
                        {item.name}
                      </button>
                    </li>
                  ))}
                </ul>
              ) : (
                <ul className="py-1 max-h-48 overflow-y-auto divide-y divide-solid divide-gray-200">
                  {emailTemplates.map((template, index) => (
                    <li key={index}>
                      <button
                        onClick={() => handleTemplateSelection(template)}
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200 w-full text-left"
                      >
                        {template.name}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </div>
      </div>

      {/* ======================= Email Textarea ======================= */}
      <Editor editorState={editorState} onChange={setEditorState} placeholder={'Type your message here...'} />
      <button
        onClick={() => {
          handleSendingEmail({
            candidate,
            singleMessageHeaders,
          });
        }}
        className="ml-4 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
        style={
          isDisabled
            ? {
                backgroundColor: 'grey',
                cursor: 'not-allowed',
              }
            : undefined
        }
        disabled={isDisabled}
      >
        Send
      </button>
      <Toast {...toastData} show={toastData !== null} onDone={() => setToastData(null)} content="" />
    </div>
  );
};

export default MessageInput;
