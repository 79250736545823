import {
  Modal,
  Paper,
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Chip,
  Autocomplete,
  Checkbox,
  SelectChangeEvent,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { sendTSPostRequest, sendTSPutRequest } from '../../utils/tanstackNetwork';
import WolfLoader from '../WolfLoader/WolfLoader';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import dayjs from 'dayjs';
import QAWTask from '@/types';
import { QaTeam, User } from '../Planning/Summary/types';

const initialBlockerReason = `**Blocked Because:**\n\n**What We Need to Get Unblocked:**\n\n**Date of Last Follow Up:** ${dayjs().format(
  'MM/DD/YYYY',
)}\n\n[Example Context Link](https://qawolfhq.slack.com/archives/C03800JCCEA/p1739375842689789)`;

type ChildTask = QAWTask & { parentTask: QAWTask & { team: NonNullable<QAWTask['team']> } };

type BlockerRequestBody = {
  blockedChildTasks: ChildTask[];
  title: string;
  description: string;
  nextFollowUpDate: string;
  parentTask: ChildTask['parentTask'];
};

type FilterValues = {
  customers: string[];
  statuses: { label: string; value: string; color: string; textColor: string }[];
  priorities: { label: string; value: string; color: string; textColor: string }[];
  users: User[];
  teams: QaTeam[];
};

function BlockedReasonModal({
  showBlockedReasonModal,
  setShowBlockedReasonModal,
  originalBlockedTask,
  setOriginalBlockedTask,
  setToastData,
}: {
  showBlockedReasonModal: boolean;
  setShowBlockedReasonModal: (showBlockedReasonModal: boolean) => void;
  originalBlockedTask: ChildTask | null;
  setOriginalBlockedTask: (originalBlockedTask: ChildTask | null) => void;
  setToastData: (toastData: { title: string; message: string; isSuccess: boolean }) => void;
}) {
  // State for error handling
  const [titleError, setTitleError] = useState(false);
  const [reasonError, setReasonError] = useState(false);
  const [followUpError, setFollowUpError] = useState(false);

  // State for mutation/API request data
  const [blockerTitle, setBlockerTitle] = useState('');
  const [blockerReason, setBlockerReason] = useState(initialBlockerReason);
  const [nextFollowUpDate, setNextFollowUpDate] = useState(dayjs().add(1, 'week').format('YYYY-MM-DD'));
  const [selectedTaskIds, setSelectedTaskIds] = useState<string[]>([]);
  const [selectedExistingBlocker, setSelectedExistingBlocker] = useState<ChildTask['blocker'] | null>(null);

  // State for disabling name input when the CR is fully blocked (all tasks are selected)
  const [isFullyBlocked, setIsFullyBlocked] = useState(false);

  // Set the selected task ids to the original blocked task id
  useEffect(() => {
    if (originalBlockedTask) {
      setSelectedTaskIds([originalBlockedTask.id]);
    }
  }, [originalBlockedTask]);

  // Resets all state vars
  const resetState = () => {
    // Reset show modal state
    setShowBlockedReasonModal(false);

    // Reset error state
    setTitleError(false);
    setReasonError(false);
    setFollowUpError(false);

    // Reset data state
    setBlockerTitle('');
    setBlockerReason(initialBlockerReason);
    setNextFollowUpDate(dayjs().add(1, 'week').format('YYYY-MM-DD'));
    setBlockerTitle('');
    setOriginalBlockedTask(null);
    setSelectedTaskIds([]);
    setSelectedExistingBlocker(null);
    setIsFullyBlocked(false);
  };

  // Get queryClient to mutate cached task data
  const queryClient = useQueryClient();

  // Get the statuses from the filter values (used for displaying current task statuses)
  const filterValues = queryClient.getQueryData(['filterValues']) as FilterValues;
  const statuses = filterValues?.statuses || [];

  // Get the child tasks for the parent issue
  const issueChildTasks = originalBlockedTask?.parentTask?.childTasks ?? [];

  // Get existing customer blockers
  /**@type {import('../../types').QAWTask[]} */
  const allTasks: QAWTask[] = queryClient.getQueryData(['allTasks', originalBlockedTask?.team?.id]) || queryClient.getQueryData(['allTasks']) || [];
  const customerTasks = allTasks.filter((t) => t.team?.id === originalBlockedTask?.team?.id);
  const parentTaskType = originalBlockedTask?.type === 'testMaintenance' ? 'testMaintenance' : 'testCoverageRequest';
  const allActiveBlockers = customerTasks
    .filter((t) => t.type === parentTaskType && t.childTasks?.some((t) => t.status === 'blocked' && !!t.blocker))
    .flatMap((t) => t.childTasks?.filter((t) => t.blocker).map((t) => t.blocker)) as NonNullable<ChildTask['blocker']>[];

  const blockerIds = new Set();
  const customerBlockers: NonNullable<ChildTask['blocker']>[] = [];

  for (const blocker of allActiveBlockers.filter((b) => !!b)) {
    if (!blockerIds.has(blocker.id)) {
      customerBlockers.push(blocker);
    }
    blockerIds.add(blocker.id);
  }

  // Mutation to create a blocker
  const createBlockerMutation = useMutation({
    mutationFn: async (reqBody: BlockerRequestBody) => await sendTSPostRequest(`/task-blockers`, reqBody),
    onSuccess: () => {
      resetState();
      setToastData({
        title: 'Blocker Created Successfully 🎉',
        message: 'Task statuses have been updated',
        isSuccess: true,
      });
    },
    onError: () => {
      setToastData({
        title: 'Updating tasks failed',
        message: 'Sorry, there was an problem! Please reach out to the Dragons team',
        isSuccess: false,
      });
    },
    onSettled: () => {
      queryClient.refetchQueries({ queryKey: ['allTasks'] });
    },
  });

  // Mutation to add tasks to an existing blocked CR
  const addToBlockerMutation = useMutation({
    mutationFn: async (reqBody: BlockerRequestBody) => await sendTSPutRequest(`/task-blockers/${selectedExistingBlocker?.id}`, reqBody),
    onSuccess: () => {
      resetState();
      setToastData({
        title: 'Blocker Updated Successfully 🎉',
        message: 'Task statuses have been updated',
        isSuccess: true,
      });
    },
    onError: () => {
      setToastData({
        title: 'Updating tasks failed',
        message: 'Sorry, there was an problem! Please reach out to the Dragons team',
        isSuccess: false,
      });
    },
    onSettled: () => {
      queryClient.refetchQueries({ queryKey: ['allTasks'] });
    },
  });

  const handleSelectBlockedTask = (e: SelectChangeEvent<string[]>) => {
    // Handle case where e.target.value is a string rather than array
    const valueArray = Array.isArray(e.target.value) ? e.target.value : [e.target.value];
    const filteredSelectValues = valueArray.filter((taskId: string) => taskId !== 'select-all-tasks');
    const hasSelectedAllTasks = filteredSelectValues.length === issueChildTasks.length;

    // Handle select all
    if (valueArray.includes('select-all-tasks') && !hasSelectedAllTasks) {
      setIsFullyBlocked(true);
      return setSelectedTaskIds(issueChildTasks.map((t) => t.id));
    }

    // Handle unselect all
    if (valueArray.includes('select-all-tasks') && hasSelectedAllTasks) {
      setIsFullyBlocked(false);
      return setSelectedTaskIds([originalBlockedTask?.id as string]);
    }

    // Handle individual selection/deselection
    setIsFullyBlocked(hasSelectedAllTasks);
    return setSelectedTaskIds(filteredSelectValues);
  };

  const handleClose = () => {
    // Reset the blocked task status back to it's original value
    queryClient.setQueryData(['allTasks'], (oldData: QAWTask[]) => {
      if (!oldData) return oldData;
      const remappedData = oldData.map((task) => {
        // Find the parent task
        if (task.id === originalBlockedTask?.parentTask?.id && task.childTasks) {
          // Find the child task and reset its status
          const newChildTasks = task.childTasks.map((childTask) => {
            if (childTask.id === originalBlockedTask?.id) {
              return { ...childTask, status: originalBlockedTask.status };
            }
            return childTask;
          });

          return { ...task, status: originalBlockedTask.status, childTasks: newChildTasks };
        }
        return task;
      });
      return remappedData;
    });

    // Reset the data and modal state
    return resetState();
  };

  const handleSubmit = () => {
    // Get the blocked child tasks based on the blocked task type
    const blockedChildTasks = issueChildTasks.filter((t) => selectedTaskIds.includes(t.id)) as ChildTask[];

    // Handle creating a new blocker or updating the original if fully blocked
    if (!selectedExistingBlocker && originalBlockedTask) {
      // Validate the form data
      if (!blockerTitle.length || !blockerReason || blockerReason === initialBlockerReason || !nextFollowUpDate) {
        setTitleError(!blockerTitle.length);
        setReasonError(!blockerReason.length || blockerReason === initialBlockerReason);
        setFollowUpError(!nextFollowUpDate.length);
        return;
      }

      return createBlockerMutation.mutate({
        blockedChildTasks,
        title: blockerTitle,
        description: blockerReason,
        nextFollowUpDate: nextFollowUpDate,
        parentTask: originalBlockedTask.parentTask,
      });
    }

    // Handle adding tasks to an existing blocked CR or the original
    if (selectedExistingBlocker && originalBlockedTask) {
      return addToBlockerMutation.mutate({
        blockedChildTasks,
        title: selectedExistingBlocker.title,
        description: selectedExistingBlocker.description,
        nextFollowUpDate: selectedExistingBlocker.nextFollowUpDate || dayjs().add(1, 'week').format('YYYY-MM-DD'),
        parentTask: originalBlockedTask?.parentTask,
      });
    }
  };

  // Show loading modal while the API request is being made TODO: Update these
  const loadingMessage = 'Updating Task Statuses...';
  const isLoading = createBlockerMutation.isPending || addToBlockerMutation.isPending;

  return isLoading ? (
    <LoadingModal open={true} handleClose={handleClose} message={loadingMessage} />
  ) : (
    <Modal open={showBlockedReasonModal} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          p: 4,
          width: '50%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h6" sx={{ mt: -1 }}>
          {selectedExistingBlocker
            ? `Add Tasks to Existing Blocker`
            : originalBlockedTask?.type !== 'outline'
            ? `Create New Blocker`
            : `Block this CR/Outline`}
        </Typography>
        {!selectedExistingBlocker && <Typography variant="caption">Please provide additional details before setting tasks to blocked.</Typography>}
        {
          <Autocomplete
            freeSolo
            options={customerBlockers.map((b) => ({ ...b, label: b.title }))}
            value={selectedExistingBlocker ?? ''}
            onChange={(_event, newValue: ChildTask['blocker'] | string | null) => {
              setSelectedExistingBlocker(newValue as ChildTask['blocker'] | null);
              setTitleError(false);
              setReasonError(false);
              setFollowUpError(false);
            }}
            isOptionEqualToValue={(option, value) => {
              if (typeof option === 'string' || typeof value === 'string') return false;
              return option?.id === value?.id;
            }}
            renderOption={(props, option) => {
              if (typeof option === 'string') return null;
              return (
                <li {...props} key={`${option?.title}-${option?.id}`}>
                  {option?.title}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                value={blockerTitle}
                label="Create blocker title or Select existing blocker"
                placeholder={'e.g. "Missing user data"'}
                fullWidth
                required
                error={titleError}
                onChange={(e) => {
                  setTitleError(false);
                  setBlockerTitle(e.target.value);
                  setSelectedExistingBlocker(null);
                }}
                InputLabelProps={{ shrink: true }}
                sx={{ mt: 2 }}
              />
            )}
          />
        }

        <MDEditor
          value={!selectedExistingBlocker ? blockerReason : selectedExistingBlocker.description}
          onChange={(val) => {
            setReasonError(false);
            setBlockerReason(val || '');
          }}
          preview={selectedExistingBlocker ? 'preview' : 'edit'}
          previewOptions={{
            rehypePlugins: [[rehypeSanitize]],
            components: {
              // Makes links open in new tab and allow Slack app communication
              a: ({ children, ...props }) => (
                <a {...props} target="_blank" rel="opener">
                  {children}
                </a>
              ),
            },
          }}
          height={200}
          data-color-mode="light"
          textareaProps={{
            placeholder: 'Enter detailed reason for blocked status',
            disabled: selectedExistingBlocker !== null,
          }}
          style={{
            marginTop: 10,
            boxSizing: 'border-box',
            outline: reasonError ? '1px solid red' : 'none',
          }}
          hideToolbar={!!selectedExistingBlocker}
        />
        <TextField
          label="Next Follow-up Date"
          type="date"
          fullWidth
          required
          disabled={!!selectedExistingBlocker}
          error={followUpError}
          value={!selectedExistingBlocker ? nextFollowUpDate : ''}
          onChange={(e) => {
            setFollowUpError(false);
            setNextFollowUpDate(e.target.value);
          }}
          inputProps={{
            min: dayjs().format('YYYY-MM-DD'),
          }}
          sx={{ mt: 2 }}
          InputLabelProps={{ shrink: true }}
        />
        {parentTaskType === 'testMaintenance' && (
          <FormControl sx={{ mt: 2 }}>
            <InputLabel id="additional-blocked-workflow-tasks-label" disableAnimation shrink sx={{ backgroundColor: '#fff', ml: -1, pl: 1, pr: 1 }}>
              Select workflow creation tasks affected by this blocker
            </InputLabel>
            <Select
              multiple
              labelId="additional-blocked-workflow-tasks-label"
              value={selectedTaskIds}
              onChange={(event: SelectChangeEvent<string[]>) => handleSelectBlockedTask(event)}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((taskId) => (
                    <Chip key={taskId} label={issueChildTasks.find((t) => t.id === taskId)?.workflow?.name || ''} />
                  ))}
                </Box>
              )}
            >
              <MenuItem value="select-all-tasks">
                <Checkbox checked={selectedTaskIds.length === issueChildTasks.length} />
                {isFullyBlocked ? 'Unselect all' : 'Select all'}
              </MenuItem>
              {issueChildTasks.map((task) => {
                const statusValues = statuses?.find((s) => s.value === task.status) || { color: '', textColor: '', label: task.status };
                return (
                  <MenuItem key={task.id} value={task.id} disabled={task.id === originalBlockedTask?.id}>
                    <Checkbox checked={selectedTaskIds.includes(task.id)} />
                    {task.workflow?.name || ''}
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                      <Chip label={statusValues.label} sx={{ backgroundColor: statusValues.color, color: statusValues.textColor }} />
                    </Box>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
        {parentTaskType === 'testCoverageRequest' && (
          <FormControl sx={{ mt: 2 }}>
            <InputLabel id="additional-blocked-workflow-tasks-label" disableAnimation shrink sx={{ backgroundColor: '#fff', ml: -1, pl: 1, pr: 1 }}>
              Select workflow creation tasks affected by this blocker
            </InputLabel>
            <Select
              multiple
              labelId="additional-blocked-workflow-tasks-label"
              value={selectedTaskIds}
              onChange={handleSelectBlockedTask}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((taskId) => (
                    <Chip key={taskId} label={issueChildTasks.find((t) => t.id === taskId)?.workflow?.name || 'Outline Task'} />
                  ))}
                </Box>
              )}
            >
              <MenuItem value="select-all-tasks">
                <Checkbox checked={selectedTaskIds.length === issueChildTasks.length} />
                {isFullyBlocked ? 'Unselect all' : 'Select all'}
              </MenuItem>
              {issueChildTasks.map((task) => {
                const statusValues = statuses?.find((s) => s.value === task.status) || { color: '', textColor: '', label: task.status };
                return (
                  <MenuItem key={task.id} value={task.id} disabled={task.id === originalBlockedTask?.id}>
                    <Checkbox checked={selectedTaskIds.includes(task.id)} />
                    {task.workflow?.name || 'Outline Task'}
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                      <Chip label={statusValues.label} sx={{ backgroundColor: statusValues.color, color: statusValues.textColor }} />
                    </Box>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}

        <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mt: 2 }}>
          {selectedExistingBlocker ? 'Add tasks to existing blocker' : 'Create Blocker and Update Tasks'}
        </Button>
      </Paper>
    </Modal>
  );
}

function LoadingModal({ open, handleClose, message }: { open: boolean; handleClose: () => void; message: string }) {
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          p: 4,
          width: '50%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h6" sx={{ mt: -1 }}>
          {message}
        </Typography>
        <WolfLoader customStyles={{ height: '100%' }} />
      </Paper>
    </Modal>
  );
}

export default BlockedReasonModal;
