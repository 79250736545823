import { ElementDefinition, Core, NodeSingular } from 'cytoscape';
import {
  BlockSequenceItem,
  BlockDictionaryEntry,
  FailureSignatureArray,
  FailureSignature,
  NodeState,
  BlockReference,
  Block,
} from './types';

export const transformToGraphElements = (failureSignatureData: FailureSignatureArray, selectedAttempt: { index: number; id: string | null }): ElementDefinition[] => {
  if (!failureSignatureData || failureSignatureData.length === 0) {
    console.warn('No Failure Signature Data found');
    return [];
  }

  const graphElements: ElementDefinition[] = [];
  const nodeIds = new Set<string>();
  const edgeIds = new Set<string>();
  const blockVisitCounts = new Map<string, number>();

  // Filter for the selected attempt
  const selectedAttemptData = failureSignatureData.filter(
    (signature: FailureSignature) => signature.attemptIndex === selectedAttempt.index,
  );

  if (selectedAttemptData.length === 0) {
    console.warn('No data found for selected attempt:', selectedAttempt);
    return [];
  }

  // First pass: count block visits
  selectedAttemptData.forEach((signature: FailureSignature) => {
    if (!signature.sequence?.items) {
      console.warn('No Block Sequence found in signature:', signature);
      return;
    }

    signature.sequence.items.forEach((item: BlockSequenceItem) => {
      if (!item.blockId) {
        console.warn('Invalid block ID found in sequence');
        return;
      }
      const blockId = item.blockId.toString();
      blockVisitCounts.set(blockId, (blockVisitCounts.get(blockId) || 0) + 1);
    });
  });

  // Second pass: create nodes and edges
  selectedAttemptData.forEach((signature: FailureSignature) => {
    if (!signature.sequence?.items) return;

    // Sort items by position to ensure correct sequence order
    const sortedItems = [...signature.sequence.items].sort((a, b) => a.position - b.position);

    sortedItems.forEach((item: BlockSequenceItem, index: number) => {
      if (!item.blockId) {
        console.warn('Invalid block ID found in sequence');
        return;
      }

      const blockId = item.blockId.toString();
      const blockName = item.block.name;

      // Create node if it doesn't exist
      if (!nodeIds.has(blockId)) {
        graphElements.push({
          data: {
            id: blockId,
            label: blockName,
            visitCount: blockVisitCounts.get(blockId) || 1,
          },
        });
        nodeIds.add(blockId);
      }

      // Create edge from previous block if not first block
      if (index > 0) {
        const prevBlockId = sortedItems[index - 1].blockId.toString();
        // Skip creating edge if source and target are the same block
        if (prevBlockId === blockId) {
          return;
        }
        const edgeId = `${prevBlockId}-${blockId}`;

        if (!edgeIds.has(edgeId)) {
          graphElements.push({
            data: {
              id: edgeId,
              source: prevBlockId,
              target: blockId,
            },
          });
          edgeIds.add(edgeId);
        }
      }
    });
  });

  console.log('Generated graph elements:', graphElements);
  return graphElements;
};

export const getAllBlockIds = (currentData: FailureSignatureArray | null): Set<string> => {
  const allBlockIds = new Set<string>();

  if (!currentData?.length) {
    return allBlockIds;
  }

  // Use a single pass through the data
  currentData.forEach((signature: FailureSignature) => {
    // Skip invalid signatures
    if (!signature?.sequence?.items?.length) {
      return;
    }

    // Process all items in the sequence
    signature.sequence.items.forEach((item: BlockSequenceItem) => {
      // Skip invalid items
      if (!item?.blockId) {
        return;
      }

      // Convert to string and add to set
      allBlockIds.add(item.blockId.toString());
    });
  });

  return allBlockIds;
};

// export const createBlockIdToNameMap = (blockIds: Set<string>, buildingBlocks: BuildingBlock[]): Map<string, string> => {
//   const blockIdToName = new Map<string, string>();
//   if (!blockIds || !buildingBlocks) return blockIdToName;

//   Array.from(blockIds).forEach((blockId) => {
//     if (!blockId) return;

//     const matchingBlock = buildingBlocks.find((block) => block?.name && blockId.toLowerCase().includes(block.name.toLowerCase()));
//     if (matchingBlock?.name) {
//       blockIdToName.set(blockId, matchingBlock.name);
//     }
//   });
//   return blockIdToName;
// };

export const getBuildingBlocks = (
  currentData: FailureSignatureArray | null,
  blockIds: Set<string> | null,
): Block[] => {
  // Early return for invalid inputs
  if (!currentData?.length || !blockIds?.size) {
    return [];
  }

  // Get dictionary from first signature
  const firstSignature = currentData[0];
  if (!firstSignature?.dictionary?.entries?.length) {
    console.warn('No dictionary entries found in failure signature data');
    return [];
  }

  const { dictionary } = firstSignature;
  const { entries } = dictionary;

  // Filter and map in a single pass for better performance
  return entries
    .filter((entry: BlockDictionaryEntry) => {
      // Skip invalid entries
      if (!entry?.blockId || !entry?.block) {
        return false;
      }
      return blockIds.has(entry.blockId.toString());
    })
    .map((entry: BlockDictionaryEntry) => entry.block);
};

export const calculateNodeStates = (currentData: FailureSignatureArray | null, selectedAttempt: { index: number; id: string | null }): Map<string, NodeState> => {
  const states = new Map<string, NodeState>();

  if (!currentData?.length) {
    return states;
  }

  const selectedAttemptData = currentData.filter(
    (signature: FailureSignature) => signature.attemptIndex === selectedAttempt.index,
  );

  // Process each signature
  selectedAttemptData.forEach((signature: FailureSignature) => {
    // Skip invalid signatures
    if (!signature?.sequence?.items?.length) {
      console.warn('Invalid signature found: missing sequence items');
      return;
    }

    // Sort items by position to ensure correct sequence order
    const sequenceItems = [...signature.sequence.items].sort((a, b) => a.position - b.position);

    // Process each block in the sequence
    sequenceItems.forEach((item: BlockSequenceItem, index: number) => {
      // Skip invalid items
      if (!item?.blockId) {
        console.warn('Invalid sequence item found: missing blockId');
        return;
      }

      const blockId = item.blockId.toString();
      const currentState = states.get(blockId) || { success: 0, failure: 0 };

      // Check if this block is the failing block
      const isFailingBlock = signature.failingPosition === index &&
        signature.failingBlockId?.toString() === blockId;

      // Update state counts
      if (isFailingBlock) {
        currentState.failure++;
      } else {
        currentState.success++;
      }

      // Store updated state
      states.set(blockId, currentState);
    });
  });

  return states;
};

export const updateNodeClasses = (
  cy: Core | null,
  nodeStates: Map<string, NodeState>,
  selectedWorkflow: string | null,
  selectedAttempt: { index: number; id: string | null },
  selectedBlock: string | null,
  currentData: FailureSignatureArray | null,
): void => {
  // Early return for invalid cytoscape instance
  if (!cy) {
    console.warn('Invalid cytoscape instance provided');
    return;
  }

  // Early return for invalid node states
  if (!nodeStates?.size) {
    console.warn('No node states provided');
    return;
  }

  // Process each node
  cy.nodes().forEach((node) => {
    const nodeId = node.id();
    const state = nodeStates.get(nodeId);

    // Skip nodes without state
    if (!state) {
      console.warn(`No state found for node: ${nodeId}`);
      return;
    }

    // Reset node classes
    node.removeClass('success mixed failure dimmed highlighted');

    // Update tooltip with state information
    const tooltipText = `Success: ${state.success}\nFailure: ${state.failure}`;
    node.data('tooltip', tooltipText);

    // Handle workflow-specific view
    if (selectedWorkflow) {
      const signature = currentData?.find(
        (sig: FailureSignature) =>
          sig.sequence.workflowId === selectedWorkflow &&
          sig.attemptIndex === selectedAttempt?.index,
      );

      if (signature?.sequence?.items?.length) {
        const sequenceItems = [...signature.sequence.items].sort((a, b) => a.position - b.position);
        const isInSequence = sequenceItems.some(
          (item: BlockSequenceItem) => item.blockId.toString() === nodeId,
        );

        if (isInSequence) {
          // Node is in the selected workflow sequence
          const isFailingBlock = signature.failingBlockId?.toString() === nodeId;
          node.addClass(isFailingBlock ? 'failure' : 'success');
        } else if (nodeId === selectedBlock) {
          // Selected block not in sequence
          updateNodeStateClass(node, state);
          node.addClass('highlighted');
        } else {
          // Node not in sequence and not selected
          node.addClass('dimmed');
        }
      }
    } else if (nodeId === selectedBlock) {
      // Block selection view
      updateNodeStateClass(node, state);
      node.addClass('highlighted');

      // Dim other nodes
      cy.nodes().forEach((otherNode) => {
        if (otherNode.id() !== nodeId) {
          otherNode.addClass('dimmed');
        }
      });
    } else if (!selectedBlock && !selectedWorkflow) {
      // Global view
      updateNodeStateClass(node, state);
    }
  });
};

// Helper function to update node state class
const updateNodeStateClass = (node: NodeSingular, state: NodeState): void => {
  if (state.failure === 0) {
    node.addClass('success');
  } else if (state.success === 0) {
    node.addClass('failure');
  } else {
    node.addClass('mixed');
  }
};

export const highlightSequence = (
  cy: Core | null,
  failingSequence: BlockSequenceItem[],
  shouldZoom = true,
): void => {
  // Early return for invalid inputs
  if (!cy) {
    console.warn('Invalid cytoscape instance provided');
    return;
  }

  if (!failingSequence?.length) {
    console.warn('No sequence items provided');
    return;
  }

  // Reset all nodes and edges to default state
  cy.nodes().removeClass('success mixed failure dimmed');
  cy.edges().removeClass('path-highlighted');

  // Sort sequence items by position to ensure correct order
  const sortedSequence = [...failingSequence].sort((a, b) => a.position - b.position);

  // Highlight nodes and edges in sequence
  sortedSequence.forEach((sequenceItem: BlockSequenceItem, position: number) => {
    // Skip invalid items
    if (!sequenceItem?.blockId) {
      console.warn('Invalid sequence item found: missing blockId');
      return;
    }

    const blockId = sequenceItem.blockId.toString();
    const node = cy.$(`#${blockId}`);
    const isLastNode = position === sortedSequence.length - 1;

    // Skip if node not found
    if (!node.length) {
      console.warn(`Node not found for block ID: ${blockId}`);
      return;
    }

    // Highlight edges between sequential nodes
    if (position < sortedSequence.length - 1) {
      const nextItem = sortedSequence[position + 1];
      if (!nextItem?.blockId) {
        console.warn('Invalid next sequence item found: missing blockId');
        return;
      }

      const nextBlockId = nextItem.blockId.toString();

      // Skip if source and target are the same block
      if (nextBlockId === blockId) {
        return;
      }

      // Color based on workflow attempt status for last node in failing sequence
      const status = isLastNode ? 'failure' : 'success';
      node.addClass(status);

      // Find and highlight the edge
      const edge = cy.edges(`[source = "${blockId}"][target = "${nextBlockId}"]`);
      if (edge.length) {
        edge.addClass('path-highlighted');
      } else {
        console.warn(`Edge not found between blocks: ${blockId} -> ${nextBlockId}`);
      }
    }
  });

  // Dim nodes not in sequence
  cy.nodes().forEach((node) => {
    const nodeId = node.id();
    if (!sortedSequence.some((item) => item.blockId.toString() === nodeId)) {
      node.addClass('dimmed');
    }
  });

  // Only zoom if requested and there are elements to zoom to
  if (shouldZoom) {
    const sequenceElements = cy.elements('node.success, node.failure, .path-highlighted');
    if (sequenceElements.length > 0) {
      cy.animate({
        fit: {
          eles: sequenceElements,
          padding: 50,
        },
        duration: 500,
      });
    } else {
      console.warn('No sequence elements found to zoom to');
    }
  }
};

export const centerOnNode = (cy: Core | null, blockId: string) => {
  if (!cy) return;
  const node = cy.$(`#${blockId}`);
  if (node.length) {
    cy.animate({
      center: {
        eles: node,
      },
      zoom: 2,
      duration: 500,
    });
  }
};

export const handleBlockSelection = (
  cy: Core | null,
  blockId: string,
  selectedBlock: string | null,
  setSelectedBlock: (blockId: string | null) => void,
  setSelectedWorkflow: (workflowId: string | null) => void,
  selectedAttempt: { index: number; id: string | null },
  setSelectedSequenceIndex: (index: number) => void,
  currentData?: FailureSignatureArray | null,
  setActiveView?: (view: 'workflows' | 'blocks') => void,
  selectedWorkflow: string | null = null,
  fromGraphClick = false,
): void => {
  // Early return for invalid cytoscape instance
  if (!cy) {
    console.warn('Invalid cytoscape instance provided');
    return;
  }

  // Early return for invalid block ID
  if (!blockId) {
    console.warn('Invalid block ID provided');
    return;
  }

  // Auto-switch to blocks view when a block is selected from graph
  if (fromGraphClick) {
    setActiveView?.('blocks');
    // Scroll the block into view in the side panel
    setTimeout(() => {
      const blockElement = document.querySelector(`[data-block-id="${blockId}"]`);
      if (!blockElement) {
        console.warn(`Block element not found for ID: ${blockId}`);
        return;
      }
      blockElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 100); // Small delay to ensure view switch has completed
  }

  // Toggle selection if clicking the same block
  if (blockId === selectedBlock) {
    setSelectedBlock(null);
    // Reset node states while preserving workflow sequence
    if (currentData?.length) {
      const nodeStates = calculateNodeStates(currentData, selectedAttempt);
      updateNodeClasses(cy, nodeStates, selectedWorkflow, selectedAttempt, null, currentData);

      if (selectedWorkflow) {
        const workflow = currentData.find(
          (signature: FailureSignature) =>
            signature.sequence.workflowId === selectedWorkflow &&
            signature.attemptIndex === selectedAttempt?.index,
        );

        if (workflow?.sequence?.items?.length) {
          highlightSequenceEdges(cy, workflow.sequence.items);
        }
      }
    }
    return;
  }

  // Select the new block
  setSelectedBlock(blockId);
  const node = cy.$(`#${blockId}`);

  // Find and center on the node
  if (node.length) {
    cy.animate({
      center: {
        eles: node,
      },
      zoom: 2,
      duration: 500,
    });
  } else {
    console.warn(`Node not found for block ID: ${blockId}`);
  }

  // Update node states while preserving workflow highlighting if it exists
  if (currentData?.length) {
    const nodeStates = calculateNodeStates(currentData, selectedAttempt);

    // If there's a selected workflow, keep its highlighting
    if (selectedWorkflow) {
      const workflow = currentData.find(
        (signature: FailureSignature) =>
          signature.sequence.workflowId === selectedWorkflow &&
          signature.attemptIndex === selectedAttempt?.index,
      );

      if (workflow?.sequence?.items?.length) {
        updateNodeClasses(cy, nodeStates, selectedWorkflow, selectedAttempt, blockId, currentData);
        // Re-highlight the sequence edges
        highlightSequenceEdges(cy, workflow.sequence.items);
      } else {
        console.warn('Selected workflow has no sequence items');
        updateNodeClasses(cy, nodeStates, null, selectedAttempt, blockId, currentData);
      }
    } else {
      updateNodeClasses(cy, nodeStates, null, selectedAttempt, blockId, currentData);
    }
  }
};

// Helper function to highlight sequence edges without affecting node states or zoom
export const highlightSequenceEdges = (
  cy: Core | null,
  sequenceItems: BlockSequenceItem[],
): void => {
  // Early return for invalid cytoscape instance
  if (!cy) {
    console.warn('Invalid cytoscape instance provided');
    return;
  }

  // Early return for invalid sequence items
  if (!sequenceItems?.length) {
    console.warn('No sequence items provided');
    return;
  }

  // Reset all edge highlights
  cy.edges().removeClass('path-highlighted');

  // Create a copy and sort items by position to ensure correct sequence order
  const sortedItems = [...sequenceItems].sort((a, b) => a.position - b.position);

  // Process each pair of consecutive blocks
  sortedItems.forEach((currentItem, index) => {
    // Skip invalid items
    if (!currentItem?.blockId) {
      console.warn('Invalid sequence item found: missing blockId');
      return;
    }

    // Skip if not the last item
    if (index >= sortedItems.length - 1) {
      return;
    }

    const nextItem = sortedItems[index + 1];
    if (!nextItem?.blockId) {
      console.warn('Invalid next sequence item found: missing blockId');
      return;
    }

    const currentBlockId = currentItem.blockId.toString();
    const nextBlockId = nextItem.blockId.toString();

    // Skip if source and target are the same block
    if (currentBlockId === nextBlockId) {
      return;
    }

    // Find and highlight the edge
    const edge = cy.edges(`[source = "${currentBlockId}"][target = "${nextBlockId}"]`);
    if (edge.length) {
      edge.addClass('path-highlighted');
    } else {
      console.warn(`Edge not found between blocks: ${currentBlockId} -> ${nextBlockId}`);
    }
  });
};

export const getBlockReferences = (
  blockId: string,
  currentData: FailureSignatureArray | null,
): BlockReference => {
  // Early return for invalid inputs
  if (!blockId) {
    console.warn('Invalid block ID provided');
    return { passed: [], failed: [] };
  }

  if (!currentData?.length) {
    console.warn('No failure signature data provided');
    return { passed: [], failed: [] };
  }

  const references: BlockReference = {
    passed: [],
    failed: [],
  };

  // Process each signature
  currentData.forEach((signature: FailureSignature) => {
    // Skip invalid signatures
    if (!signature?.sequence?.items?.length) {
      console.warn('Invalid signature found: missing sequence items');
      return;
    }

    // Check if block is in sequence
    const isInSequence = signature.sequence.items.some((item: BlockSequenceItem) => item.blockId.toString() === blockId);

    if (isInSequence) {
      // Validate failing block ID
      const failingBlockId = signature.failingBlockId?.toString();
      if (!failingBlockId) {
        console.warn('Invalid signature found: missing failing block ID');
        return;
      }

      // Categorize signature based on failure status
      if (failingBlockId === blockId) {
        references.failed.push(signature);
      } else {
        references.passed.push(signature);
      }
    }
  });

  return references;
};

export const handleWorkflowSelection = (
  cy: Core | null,
  workflowId: string | null,
  selectedWorkflow: string | null,
  selectedAttempt: { index: number; id: string | null },
  setSelectedWorkflow: (workflowId: string | null) => void,
  setSelectedBlock: (blockId: string | null) => void,
  setSelectedSequenceIndex: (index: number) => void,
  currentData: FailureSignatureArray | null,
): void => {
  // Early return for invalid cytoscape instance
  if (!cy) {
    console.warn('Invalid cytoscape instance provided');
    return;
  }

  // Early return for invalid data
  if (!currentData?.length) {
    console.warn('No failure signature data provided');
    return;
  }

  // Early return for invalid attempt
  if (selectedAttempt?.index === undefined) {
    console.warn('Invalid attempt index provided');
    return;
  }

  if (workflowId === selectedWorkflow) {
    // Reset all states
    setSelectedWorkflow(null);
    setSelectedBlock(null);
    setSelectedSequenceIndex(0);

    // Reset all highlights
    cy.elements().removeClass('highlighted path-highlighted success failure mixed dimmed');

    // Reset node states to their default view
    if (currentData) {
      const nodeStates = calculateNodeStates(currentData, selectedAttempt);
      updateNodeClasses(cy, nodeStates, null, selectedAttempt, null, currentData);
    }

    return;
  }

  const selectedWorkflowData = currentData.find(
    (signature: FailureSignature) =>
      signature.sequence.workflowId === workflowId &&
      signature.attemptIndex === selectedAttempt.index,
  );

  // Handle new workflow selection
  if (selectedWorkflowData?.sequence?.items?.length) {
    // Update states
    setSelectedWorkflow(selectedWorkflowData.sequence.workflowId);
    setSelectedBlock(null);
    setSelectedSequenceIndex(0);

    // Reset all previous highlights
    cy.elements().removeClass('highlighted path-highlighted success failure mixed dimmed');

    // Sort items by position and get failing sequence
    const fullSequenceItems = [...selectedWorkflowData.sequence.items].sort(
      (a, b) => a.position - b.position,
    );
    const failingPosition = selectedWorkflowData.failingPosition;
    const failingSequenceItems = fullSequenceItems.slice(0, failingPosition);

    // Highlight the sequence and zoom to fit
    highlightSequence(cy, failingSequenceItems, true);
  } else {
    console.warn('Selected workflow has no sequence items');
    // Reset states if no valid sequence found
    setSelectedWorkflow(null);
    setSelectedBlock(null);
    setSelectedSequenceIndex(0);
  }
};

export const navigateWorkflowSequence = (
  cy: Core | null,
  direction: 'prev' | 'next',
  selectedWorkflow: string | null,
  selectedAttempt: { index: number; id: string | null },
  selectedSequenceIndex: number,
  currentData: FailureSignatureArray | null,
  setSelectedSequenceIndex: (index: number) => void,
): void => {
  // Early return for invalid cytoscape instance
  if (!cy) {
    console.warn('Invalid cytoscape instance provided');
    return;
  }

  // Early return for invalid workflow selection
  if (!selectedWorkflow) {
    console.warn('No workflow selected');
    return;
  }

  // Early return for invalid data
  if (!currentData?.length) {
    console.warn('No failure signature data provided');
    return;
  }

  // Early return for invalid attempt
  if (selectedAttempt?.index === undefined) {
    console.warn('Invalid attempt index provided');
    return;
  }

  // Find the selected workflow data
  const signature = currentData.find(
    (sig: FailureSignature) =>
      sig.sequence.workflowId === selectedWorkflow &&
      sig.attemptIndex === selectedAttempt.index,
  );

  // Early return if no matching signature found
  if (!signature) {
    console.warn('No matching signature found for selected workflow and attempt');
    return;
  }

  // Early return if no sequence items
  if (!signature.sequence?.items?.length) {
    console.warn('Selected workflow has no sequence items');
    return;
  }

  // Create a copy and sort items by position
  const sortedItems = [...signature.sequence.items].sort((a, b) => a.position - b.position);

  // Calculate new index based on direction
  const newIndex = direction === 'next'
    ? Math.min(selectedSequenceIndex + 1, sortedItems.length - 1)
    : Math.max(selectedSequenceIndex - 1, 0);

  // Get the target block ID
  const targetItem = sortedItems[newIndex];
  if (!targetItem?.blockId) {
    console.warn('Invalid sequence item found at target index');
    return;
  }

  // Update sequence index and center on the target node
  setSelectedSequenceIndex(newIndex);
  centerOnNode(cy, targetItem.blockId.toString());
};

export const handleSequenceBlockSelection = (
  cy: Core | null,
  blockId: string,
  index: number,
  setSelectedSequenceIndex: (index: number) => void,
): void => {
  // Early return for invalid cytoscape instance
  if (!cy) {
    console.warn('Invalid cytoscape instance provided');
    return;
  }

  // Early return for invalid block ID
  if (!blockId) {
    console.warn('Invalid block ID provided');
    return;
  }

  // Early return for invalid index
  if (index < 0) {
    console.warn('Invalid sequence index provided:', index);
    return;
  }

  // Verify the block exists in the graph
  const node = cy.$(`#${blockId}`);
  if (!node.length) {
    console.warn(`Node not found for block ID: ${blockId}`);
    return;
  }

  // Update sequence index and center on the target node
  setSelectedSequenceIndex(index);
  centerOnNode(cy, blockId);
};

interface BlockStats {
  total: number;
  failures: number;
  flaky: boolean;
  failureRate: number;
  workflowIds: Set<string>;
}

export const generateCsvData = (currentData: FailureSignatureArray | null): string => {
  // Early return for invalid data
  if (!currentData?.length) {
    console.warn('No failure signature data provided');
    return '';
  }

  const blockStats = new Map<string, BlockStats>();

  // Gather statistics for each block
  currentData.forEach((signature: FailureSignature) => {
    // Skip invalid signatures
    if (!signature?.sequence?.items?.length) {
      console.warn('Invalid signature found: missing sequence items');
      return;
    }

    signature.sequence.items.forEach((item: BlockSequenceItem) => {
      // Skip invalid items
      if (!item?.blockId) {
        console.warn('Invalid sequence item found: missing blockId');
        return;
      }

      const blockId = item.blockId.toString();
      const stats = blockStats.get(blockId) || {
        total: 0,
        failures: 0,
        flaky: false,
        failureRate: 0,
        workflowIds: new Set<string>(),
      };

      // Update statistics
      stats.total++;
      stats.workflowIds.add(signature.sequence.workflowId);

      // Count as failure if this is the failing block
      if (signature.failingBlockId?.toString() === blockId) {
        stats.failures++;
      }

      // Calculate failure rate and flaky status
      stats.failureRate = (stats.failures / stats.total) * 100;
      stats.flaky = stats.failures > 0 && stats.failures < stats.total && stats.failureRate !== 100;

      blockStats.set(blockId, stats);
    });
  });

  // Early return if no valid block stats
  if (!blockStats.size) {
    console.warn('No valid block statistics found');
    return '';
  }

  // Convert to array and sort by failure rate
  const sortedBlocks = Array.from(blockStats.entries())
    .map(([blockId, stats]) => ({
      blockId,
      ...stats,
      flaky: stats.failures > 0 && stats.failures < stats.total && stats.failureRate !== 100,
    }))
    .sort((a, b) => b.failureRate - a.failureRate)
    .map(({ blockId, ...stats }) => [blockId, stats] as [string, BlockStats]);

  // Define CSV headers and row format
  const headers = ['Block ID', 'Total Uses', 'Failures', 'Failure Rate', 'Is Flaky', 'Workflow IDs'];
  const rows = sortedBlocks.map(([blockId, stats]) => {
    // Escape special characters in block ID and workflow IDs
    const escapedBlockId = blockId.replace(/"/g, '""');
    const escapedWorkflowIds = Array.from(stats.workflowIds)
      .map((id) => id.replace(/"/g, '""'))
      .join(';');

    return [
      `"${escapedBlockId}"`,
      stats.total.toString(),
      stats.failures.toString(),
      `${stats.failureRate.toFixed(2)}%`,
      stats.flaky ? 'Yes' : 'No',
      `"${escapedWorkflowIds}"`,
    ].join(',');
  });

  return [headers.join(','), ...rows].join('\n');
};

export const downloadCsv = (csvContent: string, fileName: string) => {
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

