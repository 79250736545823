// Import React dependencies.
import { useState, useCallback, useRef, useEffect } from 'react';

// Import the Slate editor factory.
import { createEditor, Editor } from 'slate';

// Import the Slate components and React plugin.
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';

import { Descendant, Transforms } from 'slate';

import { Button } from '@/components/ui/button';
import { useCommunications } from '@/components/HQ/contexts/CommunicationsContext';
import { Conversation, Message } from '@/components/HQ/data/types';
import { SlackAuthButton } from '@/components/HQ/components/SlackAuthButton';

const initialValue = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

type SlateEditorProps = {
  conversation: Conversation;
};

export const SlateEditor = ({ conversation }: SlateEditorProps) => {
  const { sendSlackMessageMutation, getCustomerById, setToast } = useCommunications();
  const editorRef = useRef<HTMLDivElement>(null);
  const [editor] = useState(() => withReact(createEditor()));
  const [value, setValue] = useState<Descendant[]>(initialValue as Descendant[]);
  const currentUser = JSON.parse(localStorage.getItem('user') || '{}');
  const mostRecentMessage = conversation.messages[0];
  const currentCustomer = getCustomerById(mostRecentMessage.customerId);

  // Function to focus the editor and set the cursor at the beginning
  const focusEditor = useCallback(() => {
    if (!editor.children.length) {
      const defaultNode: Descendant = { type: 'paragraph', children: [{ text: '' }] };
      Transforms.insertNodes(editor, defaultNode);
    }

    // Force focus on the editor
    ReactEditor.focus(editor);

    // Move cursor to end of content
    Transforms.select(editor, Editor.end(editor, []));
  }, [editor]);

  // Add useEffect to handle initial focus
  useEffect(() => {
    // Small delay to ensure DOM is ready
    const timeoutId = setTimeout(() => {
      focusEditor();
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [focusEditor]);

  const handleSubmitMessage = () => {
    if (currentCustomer && conversation) {
      const messageText = editor.children
        .map((child: Descendant) => {
          if ('children' in child && Array.isArray(child.children)) {
            return child.children[0]?.text || '';
          }
          return '';
        })
        .join('\n');
      const outboundMessage: Message = {
        id: mostRecentMessage.id + 1,
        channelId: mostRecentMessage.channelId,
        channel: mostRecentMessage?.channel,
        messageContent: messageText,
        thread: mostRecentMessage.thread,
        user: currentUser?.name,
        platform: 'slack',
        platformChannelId: mostRecentMessage.platformChannelId,
        customerId: currentCustomer?.id,
        avatar: currentUser?.avatar48,
        channelName: mostRecentMessage?.channelName,
        sender: 'internal',
        timestamp: (new Date().getTime() / 1000).toFixed(6),
        secure: currentUser?.secure,
        customerAsks: [],
        primaryQaTeam: mostRecentMessage?.primaryQaTeam,
        onlineQaTeam: mostRecentMessage?.onlineQaTeam,
        customerQawId: mostRecentMessage?.customerQawId,
      };

      if (messageText === '') {
        setToast({
          title: 'Empty message',
          message: 'You must write a message before sending',
          show: true,
          isSuccess: false,
          onDone: () => {
            setToast(null);
          },
          content: <></>,
        });
        return;
      }

      // Send the message to the server
      sendSlackMessageMutation(outboundMessage, {
        onError: (error: Error & { response?: { status: number } }) => {
          if (error?.response?.status === 403) {
            setToast({
              title: 'Authorization required',
              message: 'You must authorize us to send messages to Slack on your behalf.',
              show: true,
              isSuccess: false,
              onDone: () => {
                setToast(null);
              },
              content: <SlackAuthButton />,
            });
          } else {
            setToast({
              title: 'Error',
              message: error?.message || 'An error occurred',
              show: true,
              isSuccess: false,
              onDone: () => {
                setToast(null);
              },
              content: <></>,
            });
          }
        },
      });

      // Clear the editor
      editor.children = [
        {
          type: 'paragraph',
          children: [{ text: '' }],
        },
      ];
      focusEditor();
    }
  };

  return (
    <div className="flex items-center gap-4 w-full">
      <div ref={editorRef} className="flex-1 min-w-0 max-w-full" onClick={focusEditor}>
        <Slate editor={editor} initialValue={value} onChange={(newValue) => setValue(newValue)}>
          <Editable
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                handleSubmitMessage();
              }
            }}
            className="p-3 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent overflow-hidden overflow-wrap-break-word w-full"
            placeholder="Howl your message here..."
            style={{ overflowWrap: 'break-word', wordWrap: 'break-word', wordBreak: 'break-word' }}
          />
        </Slate>
      </div>
      <Button onClick={handleSubmitMessage} className="flex-none bg-blue-500 hover:bg-blue-600 text-white h-10 px-6">
        Send
      </Button>
    </div>
  );
};
