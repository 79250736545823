import { sendPostRequest } from '../../utils/network';

/**
 * Creates a new task
 * @param {import('../../types').QAWTask} taskData
 * @returns {Promise<{data: object, success: boolean, message: string}>}
 */
export default async function createNewTask(taskData) {
  try {
    const options = {
      team: {
        id: taskData.customer.qawId,
        name: taskData.customer.officialName,
      },
      taskDetails: {
        type: 'generic',
        assignedTo: { qawId: taskData.assignee.qawId },
        user: taskData.assignee,
        dueAt: taskData.dueDate,
        name: taskData.taskName,
      },
    };

    await sendPostRequest('/create-generic-task', options);

    return {
      data: options,
      success: true,
      message: 'Task Created Successfully',
    };
  } catch (error) {
    throw new Error('Failed to create new generic task', error);
  }
}
