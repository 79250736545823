import dayjs from "dayjs";
import { MilestoneProgress, Milestone } from "../../types";
import { getRequiredVelocity } from "../../ClientMilestones/utils";

interface MilestoneWithSubMilestones extends Milestone {
    subMilestones: MilestoneProgress[];
}

/**
 * Calculates metrics for a sub-milestone within a larger milestone.
 * This includes:
 * - Number of tests added in this sub-milestone period
 * - Whether this is the current or a future week
 * - Required velocity (tests per week) needed to reach the milestone target
 */
export default function getSubMilestoneMetrics(milestone: MilestoneWithSubMilestones, subMilestoneIndex: number) {
    // Get high level milestone details
    const subMilestones = milestone.subMilestones;
    const targetTestCount = milestone.targetTestCount;
    const indexOfFullOutlineTargetWeek = subMilestones.findIndex((subMilestone) => subMilestone.targetOutlinedTests >= milestone.targetTestCount);
    const isFirstFullOutlineTargetWeek = indexOfFullOutlineTargetWeek === subMilestoneIndex;

    // Get the previous submilestone
    const previousSubMilestone = subMilestones[subMilestoneIndex - 1];

    // Get the last submilestone
    const lastSubMilestone = subMilestones.at(-1);

    // Get the submilestone at the given index
    const subMilestone = subMilestones[subMilestoneIndex] ?? lastSubMilestone; // If the submilestone at the given index is not found, use the last submilestone

    // Derive the initial active test count for the submilestone
    const initialSubMilestoneTestCount = previousSubMilestone ? previousSubMilestone.actualTests : milestone.initialTestCount ?? 0;
    const testsAdded = subMilestone.actualTests - (initialSubMilestoneTestCount);

    // Determine if the submilestone is current or future
    const isCurrentWeek = dayjs(subMilestone.dueDate).isSame(dayjs(), 'week');
    const isFutureWeek = !isCurrentWeek && dayjs(subMilestone.dueDate).isAfter(dayjs(), 'week');

    // Calculate weeks remaining in the milestone
    const weeksRemaining = subMilestones.length - subMilestoneIndex;

    // Calculate the required velocity for this week, using the previous week's actual tests as a baseline
    const requiredVelocity = Math.round(getRequiredVelocity(targetTestCount, initialSubMilestoneTestCount, weeksRemaining));

    // Derive the initial outlined test count for the submilestone
    const initialMilestoneOutlinedTestCount = (milestone.initialDraftCount ?? 0) + (milestone.initialTestCount ?? 0);
    const initialSubmilestoneOutlinedTestCount = previousSubMilestone ? previousSubMilestone.draftTests + previousSubMilestone.actualTests : initialMilestoneOutlinedTestCount;
    const actualOutlinedTests = subMilestone.draftTests + subMilestone.actualTests;
    const outlinedTestsAdded = actualOutlinedTests - initialSubmilestoneOutlinedTestCount;

    // Calculate the number of weeks remaining to fully outline the milestone (default to 1 if there is less than 1 week remaining until the expected fully outlined week)
    const outlineWeeksRemaining = Math.max(indexOfFullOutlineTargetWeek - subMilestoneIndex + 1, 1);

    // Calculate the required velocity for the number of weeks to fully outline the milestone
    const requiredOutlineVelocity = Math.round(getRequiredVelocity(milestone.targetTestCount, initialSubmilestoneOutlinedTestCount, outlineWeeksRemaining));

    return {
        testsAdded,
        outlinedTestsAdded,
        isCurrentWeek,
        isFutureWeek,
        requiredVelocity,
        requiredOutlineVelocity,
        actualOutlinedTests,
        isFirstFullOutlineTargetWeek,
    };
}
