import { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

export type StoredQaTeam = {
  id: number;
  name: string;
  qawId: string;
  email: string;
  teamName: string;
  packId: number;
  imageUrl: string;
  active: boolean;
  selectedInSettings: boolean;
};

type QaTeamContextType = {
  selectedQaTeamIds: number[];
  setSelectedQaTeamIds: Dispatch<SetStateAction<number[]>>;
  allTeamsSelected: boolean;
  setAllTeamsSelected: Dispatch<SetStateAction<boolean>>;
  qaTeams: StoredQaTeam[];
};

const QaTeamContext = createContext<QaTeamContextType | undefined>(undefined);

export function QaTeamProvider({ children }: { children: ReactNode }) {
  // Get teams and selected teams from localStorage
  const qaTeams: StoredQaTeam[] = JSON.parse(localStorage.getItem('teams') || '[]');
  const storedSelectedQaTeamIds = JSON.parse(localStorage.getItem('selectedQaTeamIds') || '[]');

  // Set states
  const [selectedQaTeamIds, setSelectedQaTeamIds] = useState<number[]>(
    storedSelectedQaTeamIds.length > 0 ? storedSelectedQaTeamIds : [qaTeams[0]?.id],
  );
  const [allTeamsSelected, setAllTeamsSelected] = useState(storedSelectedQaTeamIds.length === qaTeams.length);

  return (
    <QaTeamContext.Provider
      value={{
        selectedQaTeamIds,
        setSelectedQaTeamIds,
        allTeamsSelected,
        setAllTeamsSelected,
        qaTeams,
      }}
    >
      {children}
    </QaTeamContext.Provider>
  );
}

export function useQaTeam() {
  const context = useContext(QaTeamContext);
  if (context === undefined) {
    throw new Error('useQaTeam must be used within a QaTeamProvider');
  }
  return context;
}
