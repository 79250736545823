import { Message } from '@/components/HQ/data/types';
import { AlertCircle, CheckCircle } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from '@/components/ui/button';
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip';
import { useCommunications } from '@/components/HQ/contexts/CommunicationsContext';
import { useHQContext } from '@/components/HQ/contexts/HQContext';
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';

export function SystemMessageCard({ message }: { message: Message }) {
  const { customerAskAction, customerAskLoading } = useCommunications();
  const { user } = useHQContext();

  const handleClaim = (askId: number, action: 'claim' | 'unclaim' | 'resolve' | 'false-positive') => {
    customerAskAction(action, askId, user.id);
  };

  const sortedCustomerAsks = message.customerAsks?.sort((a, b) => a.id - b.id);
  const numberOfCustomerAsks = sortedCustomerAsks?.length ?? 0;
  return (
    <TooltipProvider skipDelayDuration={2000}>
      <motion.div
        initial={{ opacity: 0, scale: 0, y: 20 }}
        animate={{ opacity: 1, scale: 1, y: 0 }}
        transition={{
          duration: 0.3,
          ease: 'easeOut',
        }}
        style={{
          originX: 0,
          originY: 1,
        }}
        className="flex items-center justify-center mb-3 overflow-x-hidden"
        id={`customer-ask-${message.id}`}
      >
        <div className="w-[80%] max-w-2xl bg-gray-200 border border-gray-300 shadow-sm text-gray-900 px-4 py-2.5 flex items-center flex-col rounded-[20px]">
          <span className="text-sm font-medium flex items-center">
            <Tooltip>
              <TooltipTrigger asChild>
                <motion.div
                  initial={{ opacity: 0, scale: 0, y: 20 }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                    y: 0,
                    rotate: [0, -8, 8, -5, 5, 0], // increased rotation angles
                  }}
                  transition={{
                    duration: 0.3,
                    ease: 'easeOut',
                    rotate: {
                      duration: 1.5, // faster duration
                      repeat: Infinity,
                      repeatType: 'reverse',
                      ease: 'easeInOut',
                    },
                  }}
                >
                  <AlertCircle className="w-4 h-4 mr-2 qawolf-blue-text " />
                </motion.div>
              </TooltipTrigger>
              <span className="font-medium">
                Found potential customer ask<span className="text-gray-600 text-sm">{numberOfCustomerAsks === 1 ? '' : 's'}</span>
              </span>
              <TooltipContent side="top" align="start" className="bg-gray-600 border-none shadow-xl">
                <div className="flex flex-col gap-2 p-1">
                  <div className="flex items-center gap-2 border-b border-gray-700/50 pb-2">
                    <AlertCircle className="w-4 h-4 text-purple-400" />
                    <p className="text-gray-200 font-medium">Message Details</p>
                  </div>
                  <div className="flex flex-col gap-1.5">
                    <div className="flex items-center gap-2">
                      <span className="text-gray-400 text-xs">Owner:</span>
                      <span className="text-gray-200 text-sm font-medium">{message.user}</span>
                    </div>
                    <div className="flex flex-col gap-1">
                      <span className="text-gray-400 text-xs">Content:</span>
                      <p className="text-gray-200 text-sm max-w-[300px] line-clamp-3">{message.messageContent}</p>
                    </div>
                  </div>
                </div>
              </TooltipContent>
            </Tooltip>
          </span>
          <div className="flex flex-col gap-3 w-full mt-2">
            {sortedCustomerAsks?.map((ask) => (
              <div
                key={ask.id}
                className="w-full flex-col bg-white/50 backdrop-blur-sm border border-gray-300 rounded-xl p-3 shadow-sm hover:shadow-md transition-all duration-200"
              >
                <div className="flex justify-between items-start mb-3">
                  <p className="text-sm markdown-content text-left w-full">{ask.reasoning}</p>
                </div>
                <div className="flex items-center justify-center gap-2 flex-wrap">
                  {!ask.user ? (
                    ask.resolved ? (
                      <div className="flex items-center gap-2 flex-col">
                        <span className="text-gray-400 text-xs">Resolved by System</span>
                        <Button
                          onClick={() => handleClaim(ask.id, 'false-positive')}
                          variant="lessDestructive"
                          size="sm"
                          className="h-6 text-xs px-2 py-0 hover:opacity-90 transition-opacity"
                          disabled={customerAskLoading[`false-positive-${ask.id}`]}
                        >
                          <span>{customerAskLoading[`false-positive-${ask.id}`] ? 'Marking as GPT Error...' : 'Mark as GPT Error'}</span>
                        </Button>
                      </div>
                    ) : (
                      <>
                        <Button
                          onClick={() => handleClaim(ask.id, 'claim')}
                          variant="outline"
                          size="sm"
                          className="h-6 text-xs px-2 py-0 hover:bg-gray-100 transition-colors"
                          disabled={customerAskLoading[`claim-${ask.id}`]}
                        >
                          <span>{customerAskLoading[`claim-${ask.id}`] ? 'Claiming...' : 'Claim'}</span>
                        </Button>
                      </>
                    )
                  ) : (
                    <>
                      <AnimatePresence mode="wait">
                        <Tooltip>
                          <TooltipTrigger>
                            <motion.div
                              key="claimed-avatar"
                              initial={{ scale: 0, opacity: 0 }}
                              animate={{ scale: 1, opacity: 1 }}
                              exit={{ scale: 0, opacity: 0 }}
                              transition={{
                                type: 'spring',
                                stiffness: 400,
                                damping: 15,
                                mass: 0.8,
                              }}
                              className="flex-none"
                            >
                              {ask?.user && (
                                <div className="flex gap-1 items-center">
                                  {ask.resolved && <CheckCircle className="w-3 h-3 text-green-600" />}
                                  <Avatar className="h-6 w-6 flex items-center">
                                    <AvatarImage src={ask?.user?.avatar48 || ''} alt={ask?.user?.name || 'User avatar'} className="rounded-full" />
                                    <AvatarFallback>{ask?.user?.name?.[0] || '?'}</AvatarFallback>
                                  </Avatar>
                                </div>
                              )}
                            </motion.div>
                          </TooltipTrigger>
                          <TooltipContent>
                            <span>
                              <span className="font-semibold">{ask?.user?.name}</span> {ask.resolved ? 'resolved' : 'claimed'} this ask
                            </span>
                          </TooltipContent>
                        </Tooltip>
                      </AnimatePresence>
                      {!ask.resolved && (
                        <Button
                          onClick={() => handleClaim(ask.id, 'unclaim')}
                          variant="outline"
                          size="sm"
                          className="h-6 text-xs px-2 py-0 hover:bg-gray-100 transition-colors"
                          disabled={customerAskLoading[`unclaim-${ask.id}`]}
                        >
                          <span>{customerAskLoading[`unclaim-${ask.id}`] ? 'Un-claiming...' : 'Unclaim'}</span>
                        </Button>
                      )}
                    </>
                  )}
                  {!ask.resolved && (
                    <>
                      <Button
                        onClick={() => handleClaim(ask.id, 'resolve')}
                        variant="done"
                        size="sm"
                        className="h-6 text-xs px-2 py-0 hover:opacity-90 transition-opacity"
                        disabled={customerAskLoading[`resolve-${ask.id}`]}
                      >
                        <span>{customerAskLoading[`resolve-${ask.id}`] ? 'Resolving...' : 'Mark as Resolved'}</span>
                      </Button>
                      <Button
                        onClick={() => handleClaim(ask.id, 'false-positive')}
                        variant="lessDestructive"
                        size="sm"
                        className="h-6 text-xs px-2 py-0 hover:opacity-90 transition-opacity"
                        disabled={customerAskLoading[`false-positive-${ask.id}`]}
                      >
                        <span>{customerAskLoading[`false-positive-${ask.id}`] ? 'Marking as False Positive...' : 'Mark as False Positive'}</span>
                      </Button>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </motion.div>
    </TooltipProvider>
  );
}
