import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { Clock, Layers } from 'lucide-react';
import { getTaskInformation } from './helpersInvestigation';
import type { RealInvestigationTask } from '../../types/investigation';
import { BellAlertIcon, HandRaisedIcon, BoltIcon, BoltSlashIcon, ClockIcon } from '@heroicons/react/24/outline';
import { Badge } from '@/components/ui/badge';
import { useHQContext } from '../../contexts/HQContext';
import InvestigationActionMenu from '../InvestigationActionMenu';
import { getOldestTaskAge } from './helpersInvestigation';
import { SelectedItem } from '../HQMainPage';
interface InvestigationCardProps {
  investigationTask: RealInvestigationTask;
  selectedItem: SelectedItem;
  setSelectedItem: (item: SelectedItem) => void;
}

export function InvestigationCard({ investigationTask, selectedItem, setSelectedItem }: InvestigationCardProps) {
  const { claimInvestigation, hasUserClaimedMaxTasks, setSelectedTaskInformation, user, allUsers: users } = useHQContext();
  const taskInformation = getTaskInformation(investigationTask, user, users);

  // const queuedWorkflows = investigationTask.workflows.filter((wf) => wf.status === 'queued').length;
  // const runningWorkflows = investigationTask.workflows.filter((wf) => wf.status === 'running').length;
  // const queuedAndRunning = investigationTask.workflows.filter((wf) => wf.status === 'queued' || wf.status === 'running').length;
  // const queuedAndRunningPercentage = Math.round((queuedAndRunning / investigationTask.workflows.length) * 100);

  const deployTypeIcon = taskInformation.isDeployRun ? (
    <BellAlertIcon className="w-4 h-4 text-red-500 mr-1" title="Deploy Trigger" />
  ) : taskInformation.isManualRun ? (
    <HandRaisedIcon className="w-4 h-4 text-yellow-500 mr-1" title={`Manual run deployed by ${investigationTask.suite.trigger?.manuallyCreatedBy}`} />
  ) : taskInformation.isTimedRun ? (
    <ClockIcon className="w-4 h-4 text-gray-900 mr-1" title="Timer Trigger" />
  ) : null;

  const slaIcon = investigationTask.team.slaReady ? (
    <BoltIcon
      className="w-4 h-4 text-green-500 mr-1"
      title={`SLA Ready${!taskInformation.nested ? `\n\nSort Info:\n\n${investigationTask.sortingScore.reasoning.join(`\n`)}` : ''}`}
    />
  ) : (
    <BoltSlashIcon
      className="w-4 h-4 text-red-500 mr-1"
      title={`Not SLA Ready${!taskInformation.nested ? `\n\nSort Info:\n\n${investigationTask.sortingScore.reasoning.join(`\n`)}` : ''}`}
    />
  );

  const renderPriorityBadge = (priority: string) => {
    switch (priority) {
      case 'urgent':
        return (
          <Badge variant="default" className="text-xs px-1 py-0">
            Urgent
          </Badge>
        );
      case 'high':
        return (
          <Badge variant="destructive" className="text-xs px-1 py-0">
            High
          </Badge>
        );
      default:
        return null; // No badge for default priority
    }
  };

  const handleClaim = (investigation: RealInvestigationTask) => {
    // If user has claimed max tasks, do not claim
    if (hasUserClaimedMaxTasks) {
      return;
    }

    // Claim Investigation
    claimInvestigation(investigation);

    // Open Suite Investigation View
    setSelectedItem({ id: investigation.id, type: 'investigation' });
    setSelectedTaskInformation(taskInformation);
  };

  const handleInvestigationTaskSelection = () => {
    setSelectedTaskInformation(taskInformation);
    setSelectedItem({ id: investigationTask.id, type: 'investigation' });
  };

  return (
    <div
      key={investigationTask.id}
      className={`w-full text-left p-3 transition-colors rounded-md ${
        investigationTask.sortingScore.isUrgentTeam === 1 ||
        investigationTask.sortingScore.isUrgentTrigger === 1 ||
        investigationTask.priority === 'urgent'
          ? `border-2 ${taskInformation.borderColor}`
          : `border ${taskInformation.borderColor}`
      } ${
        selectedItem.type === 'investigation' && selectedItem.id === investigationTask.id
          ? 'bg-accent text-accent-foreground'
          : investigationTask.assignedTo.id && investigationTask.assignedTo.id !== user.qawId
          ? taskInformation.backgroundColor + ' text-foreground hover:bg-accent hover:text-accent-foreground'
          : taskInformation.backgroundColor + ' text-foreground hover:bg-accent hover:text-accent-foreground'
      }`}
      onClick={handleInvestigationTaskSelection}
    >
      <div className="flex justify-between items-start mb-1">
        <div className="flex items-center">
          <h3 className="font-semibold text-sm mr-2"> {investigationTask.team.name} </h3>
          {renderPriorityBadge(investigationTask.priority)}
        </div>
        <div className="flex items-center text-xs">
          <span>{investigationTask.suite.environment.name} </span>
        </div>
      </div>
      <div className="flex justify-between items-center text-xs mb-1">
        <div className="flex items-center">
          <span className="text-muted-foreground"> {investigationTask.suite.trigger.name} </span>
          {slaIcon}
          {deployTypeIcon}
        </div>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger className="flex items-center">
              <div className="w-16 h-2 bg-gray-200 rounded-full overflow-hidden mr-2">
                <div className="h-full bg-indigo-600" style={{ width: `${taskInformation.investigationProgress}%` }} />
              </div>
              <span> {taskInformation.investigationProgress} % </span>
            </TooltipTrigger>
            <TooltipContent>
              <p>
                {taskInformation.alreadyReviewedCount} / {taskInformation.originalFailures} investigated
              </p>
              <p>{taskInformation.failuresTitle}</p>
              {/* {queuedWorkflows > 0 && <p>{queuedWorkflows} queued</p>}
                {runningWorkflows > 0 && <p>{runningWorkflows} running</p>} */}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <div className="flex justify-between items-center text-xs">
        <div className="flex items-center">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <div className={`flex items-center ${taskInformation.nested ? 'text-red-500' : ''}`}>
                  <Clock className="w-3 h-3 mr-1" />
                  <span>{taskInformation.thisSuiteAge} </span>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>Suite Age: {taskInformation.thisSuiteAge} </p>
                {taskInformation.nested && <p>Oldest Failure: {getOldestTaskAge(investigationTask.childTasks || [])} </p>}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          {taskInformation.nested && investigationTask.status !== 'done' && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <div className="flex items-center text-red-500 ml-2">
                    <Layers className="w-3 h-3 mr-1" />
                    <span>History </span>
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <p>{investigationTask.childTasks?.length} older suites</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
        {investigationTask.status === 'done' ? (
          <span>Completed by: {investigationTask.assignedTo?.name} </span>
        ) : investigationTask.assignedTo?.name ? (
          <div className="flex items-center">
            <div className="relative">
              <Avatar className="w-4 h-4 mr-1">
                <AvatarImage src={investigationTask.assignedTo.avatarUrl || '/placeholder.svg'} alt={investigationTask.assignedTo.name} />
                <AvatarFallback>{investigationTask.assignedTo.name.charAt(0)} </AvatarFallback>
              </Avatar>
              {/* <span
                className={`absolute bottom-0 right-0 w-1.5 h-1.5 rounded-full border border-background
                    ${
                      investigationTask.assignee.onlineStatus === 'red'
                        ? 'bg-red-500'
                        : investigationTask.assignee.onlineStatus === 'yellow'
                        ? 'bg-yellow-500'
                        : 'bg-green-500'
                    }
                    ${investigationTask.assignee.onlineStatus === 'red' ? 'animate-ping' : ''}`}
              /> */}
            </div>
            <span>
              {' '}
              {investigationTask.assignedTo.id === user.qawId ? (
                <InvestigationActionMenu parentComponent="investigationCard" investigationTask={investigationTask} />
              ) : (
                investigationTask.assignedTo.name
              )}{' '}
            </span>
          </div>
        ) : (
          <Button
            variant="outline"
            disabled={hasUserClaimedMaxTasks}
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              handleClaim(investigationTask);
            }}
            className="h-6 text-xs px-2 py-0"
          >
            Claim
          </Button>
        )}
      </div>
    </div>
  );
}
