import { MouseEvent as ReactMouseEvent, SyntheticEvent } from 'react';

import { MRT_TableInstance } from 'material-react-table';

import { Box, Tab, Tabs } from '@mui/material';
import { Close, Home } from '@mui/icons-material';

import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';

import { sendTSGetRequest, sendTSPostRequest } from '@/utils/tanstackNetwork';

import { StoredUser } from '@/components/HowlUtilization/types';
import { PlanningHubCustomerTableView } from './types';
import { ClientSummaryTableRow } from '../../types';
import { usePlanningContext, DEFAULT_SAVED_VIEW_ID } from '@/components/context/PlanningContext';
import ApproveAllButton from '../../ApproveAllButton';

/**
 * Renders the selected view tabs and the approve all button at the top of the table
 */
export default function TopToolbarCustomActions({ table }: { table: MRT_TableInstance<ClientSummaryTableRow> }) {
  // --- State and Setup ---
  const queryClient = useQueryClient();
  const { showApproveAllButton, selectedSavedViewId, setSelectedSavedViewId } = usePlanningContext();
  const user: StoredUser = JSON.parse(localStorage.getItem('user') ?? '{}');

  // --- API Mutations and Queries ---
  // Mutation for deleting a saved view
  const deleteViewMutation = useMutation({
    mutationKey: ['deletePlanningHubCustomerTableView'],
    mutationFn: (viewId: number) => sendTSPostRequest(`/delete-table-view`, { viewId }),
  });

  // Query to fetch all saved table views
  const savedTableViews = useQuery<PlanningHubCustomerTableView[]>({
    queryKey: ['planningHubCustomerTableViews'],
    queryFn: () => sendTSGetRequest(`/table-views/${user.qawId}?tableName=planningHubCustomerTable`),
    // Placeholder data while loading
    placeholderData: [],
    // Filter out the default home view in the response
    select: (data) => data.filter((x) => x.id > 0),
  });

  // --- Event Handlers ---
  // Handle tab change - loads the selected view's state
  const handleChangeTab = (_event: SyntheticEvent, savedViewId: number) => {
    // Restore the default state if the home view is selected
    if (savedViewId === DEFAULT_SAVED_VIEW_ID) {
      table.setState(table.initialState);
    }

    // Update local and meta state with new view id
    setSelectedSavedViewId(savedViewId);

    // Load the selected view's table state
    const selectedView = savedTableViews.data?.find((view) => view.id === savedViewId);
    if (selectedView) table.setState((prev) => ({ ...prev, ...selectedView.state }));
  };

  // Handle view deletion
  const handleDeleteView = (e: ReactMouseEvent<SVGSVGElement>, viewId: number) => {
    // Prevent event bubbling
    e.stopPropagation();

    deleteViewMutation.mutate(viewId, {
      onSuccess: (_, viewId) => {
        // Remove deleted view from cache
        queryClient.setQueryData(['planningHubCustomerTableViews'], (prev: PlanningHubCustomerTableView[]) =>
          prev.filter((view) => view.id !== viewId),
        );

        // Reset to home view and update meta state
        setSelectedSavedViewId(DEFAULT_SAVED_VIEW_ID);
      },
    });
  };

  return (
    <Box>
      <Tabs
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons={"auto"}
        value={selectedSavedViewId}
        onChange={handleChangeTab}
      >
        {/* Home tab - always present */}
        <Tab icon={<Home />} disableRipple value={-1} />

        {/* Saved view tabs */}
        {savedTableViews.data?.map((view) => (
          <Tab
            sx={{ minHeight: 'unset' }}
            key={view.id}
            disableRipple
            disabled={deleteViewMutation.variables === view.id && deleteViewMutation.isPending}
            value={view.id}
            label={view.name}
            icon={<Close fontSize="small" onClick={(e) => handleDeleteView(e, view.id)} />}
            iconPosition="end"
          />
        ))}
      </Tabs>

      {/* Button to approve all project plans */}
      {showApproveAllButton && <ApproveAllButton />}
    </Box>
  );
}
