import { getTeamInfo } from '../../utils/helpers';
import { sendGetRequest, sendPostRequest } from '../../utils/network';

/**
 * Retrieve team details asynchronously.
 * @returns {Promise<import('../../types').TeamDetails[]>} Promise that resolves to an object with teamName as keys and TeamDetails as values.
 */
export async function getCutomerData() {
  // refresh team data
  let teamsResonse = await sendGetRequest(`/get-teams`);
  const currTeams = getTeamInfo();

  // save teams
  const formatedTeams = teamsResonse?.data?.map((team) => {
    const currTeam = currTeams.find((t) => t.qawId === team.lead.qawId);

    return {
      id: team.id,
      name: team.lead.name,
      qawId: team.lead.qawId,
      email: team.lead.email,
      teamName: team.name,
      packId: team.pack.lead.teamId,
      imageUrl: team.imageUrl,
      active: currTeam ? currTeam.active : true,
      selectedInSettings: currTeam ? currTeam.selectedInSettings : false,
    };
  });
  if (formatedTeams) localStorage.setItem('teams', JSON.stringify(formatedTeams));

  let response = await sendGetRequest(`/tasks-by-team`);

  const clientArray = Object.values(response.data);

  return clientArray;
}

/**
 * Updates a task.
 * @param {object} data - The data to update the task with.
 * @returns {Promise<import('../../types').QAWTask[]>} - A promise that resolves to the updated task or an error message.
 */
export async function updateTask(data) {
  try {
    let result = await sendPostRequest(`/update-task`, data);
    return result.data;
  } catch (error) {
    // @ts-ignore
    return { error: 'Failed to fetch new tasks info. Please refresh the page.' };
  }
}

export async function multiUpdateTasks(data) {
  try {
    let result = await sendPostRequest(`/update-tasks`, data);

    return result.data;
  } catch (error) {
    // @ts-ignore
    return { error: 'Failed to fetch new tasks info. Please refresh the page.' };
  }
}
