import { useRef, useEffect, useState } from "react";
import { Table, TableHead, TableCell, TableBody, TableRow, TableContainer, Paper, Typography, List, ListItem } from "@mui/material";

const ChatGPTDecision = ({ row }) => {
    const [containerHeight, setContainerHeight] = useState(0);
    const containerRef = useRef(null);

    // Calculate the height of the decision box
    useEffect(() => {
        if (containerRef.current) {
            const height = containerRef.current.clientHeight; // Grab the height dynamically
            setContainerHeight(parseInt(height, 10));
        }
    }, []);

    if (!row.aiNotes) {
        return (
            <TableContainer className="gptDecisionTable" component={Paper} sx={{ height: '100%', width: '100%' }} ref={containerRef}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2} align="center">
                                <Typography style={{ fontWeight: 'bold' }}>
                                    ChatGPT's Suggestion on {row.candidate.firstName}'s Submission
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <Typography className="p-4 italic text-[#333]">
                            No AI Notes to display
                        </Typography>
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }


    // Format ChatGPT's suggestion on the candidate
    const formatJSON = (inputString) => {
        const formatted = {};

        // Parse the string as JSON
        const parsedData = JSON.parse(inputString);

        Object.entries(parsedData).forEach(([key, value]) => {
            formatted[key] = value;
        });

        return formatted;
    };

    const formattedData = formatJSON(row.aiNotes);


    return (
        <TableContainer className="gptDecisionTable" component={Paper} sx={{ height: '100%', width: '100%' }} ref={containerRef}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2} align="center">
                            <Typography style={{ fontWeight: 'bold' }}>
                                ChatGPT's Suggestion on {row.candidate.firstName}'s Submission
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell style={{ width: '50px' }}>
                            <Typography variant="body1">Decision</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body2" align="left">{formattedData?.gptJsonResponse ? formattedData.gptJsonResponse.decision : "N/A"}</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ padding: `calc((${containerHeight}px / 3) / 2) 16px` }}>
                            <Typography variant="body1">Reason</Typography>
                        </TableCell>
                        <TableCell sx={{ padding: `calc((${containerHeight}px / 3) / 2) 16px` }}>
                            <Typography variant="body2" align="left">{formattedData?.gptJsonResponse ? formattedData.gptJsonResponse.reason : "N/A"}</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography variant="body1">Rating</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body2" align="left">{formattedData?.gptJsonResponse ? formattedData.gptJsonResponse.rating : "N/A"}</Typography>
                        </TableCell>
                    </TableRow>


                    {formattedData.workflowResponses && formattedData.workflowResponses.map((item, idx) => (
                        <TableRow key={idx}>
                            <TableCell>
                                <Typography variant="body1">Question {idx + 1}</Typography>
                            </TableCell>
                            <TableCell>
                                <List>
                                    <ListItem>
                                        <Typography variant="body2" align="left"><strong>Response: </strong>{formattedData.workflowResponses ? formattedData.workflowResponses[idx].response : "N/A"}</Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography variant="body2" align="left"><strong>Reason: </strong>{formattedData.workflowResponses ? formattedData.workflowResponses[idx].reason : "N/A"}</Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography variant="body2" align="left"><strong>Score: </strong>{formattedData.workflowResponses ? formattedData.workflowResponses[idx].score : "N/A"}</Typography>
                                    </ListItem>
                                </List>
                            </TableCell>
                        </TableRow>
                    ))}

                    <TableRow>
                        <TableCell sx={{ padding: `calc((${containerHeight}px / 3) / 2) 16px` }}>
                            <Typography variant="body1">Feedback for Candidate:</Typography>
                        </TableCell>
                        <TableCell>
                            <List>
                                {formattedData?.candidateFeedback?.map((feedbackItem, idx) => (
                                    <ListItem key={idx}>{feedbackItem}</ListItem>
                                ))}
                            </List>

                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ChatGPTDecision;
