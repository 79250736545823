import React from 'react';
import Button from './Button';
import { SearchFormProps } from '../types';

/**
 * SearchForm component for entering and submitting suite IDs
 */
const SearchForm: React.FC<SearchFormProps> = ({ inputValue, setInputValue, handleSubmit }) => {
  // Create a handler for the button click that doesn't expect parameters
  const handleButtonClick = () => {
    // The form will handle the actual submission
  };

  return (
    <form onSubmit={handleSubmit} className="flex items-center" aria-label="Suite ID search form">
      <div className="flex-shrink-0 flex items-center">
        <label htmlFor="suite-id-input" className="sr-only">
          Enter Suite ID
        </label>
        <input
          id="suite-id-input"
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Enter Suite ID"
          size={32}
          className="px-4 py-2 rounded bg-gray-700 text-gray-200 border border-gray-600 focus:outline-none focus:border-blue-500 text-center"
          aria-label="Suite ID"
        />
        <Button onClick={handleButtonClick} variant="primary" className="ml-2">
          Load Suite
        </Button>
      </div>
    </form>
  );
};

export default SearchForm;
