import { useQueries } from '@tanstack/react-query';
import { PieChart, Pie, Cell, Legend, Tooltip, ResponsiveContainer } from 'recharts';
import { sendGetRequest } from '../../utils/network';
import ExportCustomerArrButton from './utils/ExportCustomerARR';
import ExportQaeCostButton from './utils/ExportQaeCost';
import WolfLoader from '../WolfLoader/WolfLoader';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Tabs from '../UtilityComponents/TabComponents/tabs';
import ExecutiveOverviewBoard from './ExecutiveOverviewBoard';
import OrgTimeTrackingView from './views/OrgTimeTrackingView';
import CustomerLogsTable from './tables/CustomerLogsTable';
import dayjs from 'dayjs';
import { processTeamViewData, processCustomerViewData, processPackViewData, calculateMetrics, handleTimeTrackingLoaded } from './utils/helpers';
import { ANNUAL_QAE_BENEFITS_COST, HOSTING_COST_PERCENTAGE, DEFAULT_DAYS_RANGE, WORKFLOW_STATUS_COLORS, DEFAULT_TABS } from './utils/constants';
import { CircularProgress } from '@mui/material';

export default function ExecutiveDashboard() {
  // get and parse search params
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsMap = Object.fromEntries([...searchParams]);

  // Set tabs based on search params
  const [tabs, setTabs] = useState(() =>
    paramsMap.activeTab ? DEFAULT_TABS.map((tab) => ({ ...tab, current: tab.type === paramsMap.activeTab })) : DEFAULT_TABS,
  );

  // Add state for time tracking loading
  const [isTimeTrackingLoading, setIsTimeTrackingLoading] = useState(true);

  // static filters for all teams and last 30 days using day.js
  const staticFilters = {
    entity: {
      data: {
        name: 'All Teams',
      },
    },
    gte: dayjs().subtract(DEFAULT_DAYS_RANGE, 'day').format('YYYY-MM-DD'),
    lte: dayjs().format('YYYY-MM-DD'),
  };

  // handle init and update to search params
  useEffect(() => {
    searchParams.set('activeTab', tabs.find((tab) => tab.current).type);
    setSearchParams(searchParams);
  }, [searchParams, tabs]);

  // Query user data from Tanstack network
  const results = useQueries({
    queries: [
      {
        queryKey: ['getAllQAWUsers'],
        queryFn: () => sendGetRequest('/list-all-qaw-users'),
      },
      {
        queryKey: ['getCustomerData'],
        queryFn: () => sendGetRequest('/tasks-by-team'),
      },
      {
        queryKey: ['getCustomerLogs'],
        queryFn: () => sendGetRequest('/logs/get-customerActivity'),
      },
    ],
  });

  const isLoading = results.some((result) => result.isLoading);
  const isError = results.some((result) => result.isError);

  if (isLoading) return <WolfLoader />;
  if (isError) return <div>Error fetching data</div>;

  const qawUserData = Array.isArray(results[0].data) ? results[0].data : [];
  const allCustomersData = Object.values(results[1].data.data);
  const logs = results[2].data.data;

  const customerLogs = logs.map((log) => {
    const customer = allCustomersData.find((customer) => customer.id === log.customerId);
    return { ...log, customerName: customer.name };
  });

  const allQaEngineeringEEs = qawUserData.filter((user) => (user.isQAE || user.isManager || user.isLead) && user.teamId !== 34);
  const managersOnly = allQaEngineeringEEs.filter((user) => user.isManager);

  // Get one lead per team
  const leadsOnly = Object.values(
    allQaEngineeringEEs
      .filter((user) => user.isLead)
      .reduce((acc, lead) => {
        if (!acc[lead.team.id]) {
          acc[lead.team.id] = lead;
        }
        return acc;
      }, {}),
  );

  const qaEngineersOnly = allQaEngineeringEEs.filter((user) => user.isQAE && user.team);

  const totalArr = allCustomersData.reduce((acc, customer) => acc + customer.arr, 0);
  const annualHostingCosts = totalArr * HOSTING_COST_PERCENTAGE;

  const metrics = calculateMetrics(allCustomersData, totalArr, allQaEngineeringEEs, annualHostingCosts, ANNUAL_QAE_BENEFITS_COST);

  const workflowStatusData = [
    { name: 'Active', value: metrics.totalTestsActive - metrics.totalTestsBugged - metrics.totalTestsMaintenance },
    { name: 'Maintenance', value: metrics.totalTestsMaintenance - metrics.totalBlockedTestsMaintenance },
    { name: 'Blocked Maint', value: metrics.totalBlockedTestsMaintenance },
    { name: 'Bugged', value: metrics.totalTestsBugged },
  ];

  // Process table data
  tabs.find((tab) => tab.type === 'Pack View').tableData = processPackViewData(
    leadsOnly,
    qaEngineersOnly,
    managersOnly,
    allQaEngineeringEEs,
    allCustomersData,
    annualHostingCosts,
    ANNUAL_QAE_BENEFITS_COST,
  );

  tabs.find((tab) => tab.type === 'Team View').tableData = processTeamViewData(
    leadsOnly,
    qaEngineersOnly,
    managersOnly,
    allQaEngineeringEEs,
    allCustomersData,
    annualHostingCosts,
    ANNUAL_QAE_BENEFITS_COST,
  );

  tabs.find((tab) => tab.type === 'Customer View').tableData = processCustomerViewData(allCustomersData, leadsOnly);

  return (
    <div className="min-h-full p-4">
      <div className="pb-2">
        <div className="flex flex-auto flex-row">
          <dl className="flex flex-auto order-1 w-2/3 flex-row gap-5 flex-wrap">
            <div key="totalArr" title="Total sum of all Customer's ARR" className="relative overflow-hidden rounded-lg bg-gray-50 shadow sm:p-6">
              <ExportCustomerArrButton customerData={allCustomersData} />
              <dt className="truncate text-sm font-medium text-gray-500">Total ARR</dt>
              <dd className="mt-1 text-lg font-semibold tracking-tight text-gray-900">
                {totalArr.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}
              </dd>
            </div>
            <div
              key="totalCost"
              title="Total sum of Hosting Costs and all Salaries (plus benefits) of QAEs, Leads, Managers, and the Director of Engineering"
              className="relative overflow-hidden rounded-lg bg-gray-50 shadow sm:p-6"
            >
              <ExportQaeCostButton qaEngineerData={allQaEngineeringEEs} qaBenefitsAmount={ANNUAL_QAE_BENEFITS_COST} />
              <dt className="truncate text-sm font-medium text-gray-500">Total QA Eng. Cost</dt>
              <dd className="mt-1 text-lg font-semibold tracking-tight text-gray-900">
                {metrics.totalCost.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}
              </dd>
            </div>
            <div key="totalHostingCost" title="Annual Hosting Costs" className="relative rounded-lg bg-gray-50 shadow sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">Annual Hosting Costs</dt>
              <dd className="mt-1 text-lg font-semibold tracking-tight text-gray-900">
                {annualHostingCosts.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}
              </dd>
            </div>
            <div
              key="totalMarginDollar"
              title="Difference between Total ARR and Total Costs"
              className="relative rounded-lg bg-gray-50 shadow sm:p-6"
            >
              <dt className="truncate text-sm font-medium text-gray-500">Total Margin $</dt>
              <dd className="mt-1 text-lg font-semibold tracking-tight text-gray-900">
                {metrics.totalMargin.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}
              </dd>
            </div>
            <div
              key="totalMarginPercent"
              title="Percentage difference between Total ARR and Total Costs"
              className="relative rounded-lg bg-gray-50 shadow sm:p-6"
            >
              <dt className="truncate text-sm font-medium text-gray-500">Total Margin %</dt>
              <dd className="mt-1 text-lg font-semibold tracking-tight text-gray-900">{Math.round((metrics.totalMargin / totalArr) * 100)}%</dd>
            </div>
            <div
              key="totalTestsUnderContract"
              title="Amount of Tests Contracted with all Customers"
              className="relative rounded-lg bg-gray-50 shadow sm:p-6"
            >
              <dt className="truncate text-sm font-medium text-gray-500">Tests Under Contract:</dt>
              <dd className="mt-1 text-lg font-semibold tracking-tight text-gray-900">{metrics.totalTestsUnderContract.toLocaleString()}</dd>
            </div>
            <div
              key="totalTestsActive"
              title="Amount of Total Tests that have been implemented, includes Bugged and Maintenance"
              className="relative rounded-lg bg-gray-50 shadow sm:p-6"
            >
              <dt className="truncate text-sm font-medium text-gray-500">Active Tests</dt>
              <dd className="mt-1 text-lg font-semibold tracking-tight text-gray-900">{metrics.totalTestsActive.toLocaleString()}</dd>
            </div>
            <div
              key="totalTestsActivePercent"
              title="Percentage of Total Tests that have been implemented, includes Bugged and Maintenance"
              className="relative rounded-lg bg-gray-50 shadow sm:p-6"
            >
              <dt className="truncate text-sm font-medium text-gray-500">Active Tests %</dt>
              <dd className="mt-1 text-lg font-semibold tracking-tight text-gray-900">
                {Math.round((metrics.totalTestsActive / metrics.totalTestsUnderContract) * 100)}%
              </dd>
            </div>
            <div
              key="averageActiveTestsPerQaeWithManagers"
              title="Total Active Tests divided by amount of Engineers (Including Leads and Managers)"
              className="relative rounded-lg bg-gray-50 shadow sm:p-6 w-70"
            >
              <dt className="truncate text-sm font-medium text-gray-500">Avg. Active Tests per QAE (Incl. Managers)</dt>
              <dd className="mt-1 text-lg font-semibold tracking-tight text-gray-900">
                {Math.round(metrics.totalTestsActive / allQaEngineeringEEs.length)}
              </dd>
            </div>
            <div
              key="averageActiveTestsPerQae"
              title="Total Active Tests divided by amount of Engineers (Including Leads, Excluding Managers)"
              className="relative rounded-lg bg-gray-50 shadow sm:p-6 w-70"
            >
              <dt className="truncate text-sm font-medium text-gray-500">Avg. Active Tests per QAE (Excl. Managers)</dt>
              <dd className="mt-1 text-lg font-semibold tracking-tight text-gray-900">
                {Math.round(metrics.totalTestsActive / qaEngineersOnly.length)}
              </dd>
            </div>
          </dl>
          <div className="order-2 w-7/12 h-auto">
            <ResponsiveContainer height={400}>
              <PieChart>
                <Pie
                  data={workflowStatusData}
                  labelLine={false}
                  fontSize={14}
                  label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(1)}%`}
                  outerRadius={150}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {workflowStatusData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={WORKFLOW_STATUS_COLORS[index % WORKFLOW_STATUS_COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="shadow-md ring-1 ring-gray-200 rounded-lg my-4 flex flex-col gap-y-2">
          <h1 className="flex-none text-center text-2xl font-bold p-2">
            Org Time Tracking (Last 30 Days)
            {isTimeTrackingLoading && (
              <CircularProgress
                size={20}
                sx={{
                  ml: 1,
                  verticalAlign: 'middle',
                  display: 'inline-block',
                }}
              />
            )}
          </h1>
          <div>
            <OrgTimeTrackingView filters={staticFilters} onDataLoaded={() => handleTimeTrackingLoaded(setIsTimeTrackingLoading)} />
          </div>
        </div>
        <div className="shadow-md ring-1 ring-gray-200 rounded-lg my-4 flex flex-col gap-y-2">
          <h1 className="flex-none text-center text-2xl font-bold p-4">Customer Overview Breakdown</h1>
          <div className="flex-1">
            <Tabs tabs={tabs} setTabs={setTabs} activeTab={tabs.find((tab) => tab.current)} />
            <ExecutiveOverviewBoard activeTab={tabs.find((tab) => tab.current)} tableData={tabs.find((tab) => tab.current).tableData} />
          </div>
        </div>
        <div className="shadow-md ring-1 ring-gray-200 rounded-lg my-4 flex flex-col gap-y-2">
          <h1 className="flex-none text-center text-2xl font-bold p-4">Customer Activity Logs</h1>
          <div className="flex-1">
            <CustomerLogsTable customerLogs={customerLogs} />
          </div>
        </div>
      </div>
    </div>
  );
}
