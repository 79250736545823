import { useState, useEffect } from 'react';
import { TaskMutationData, User } from './useSubmitReadyForReview';

type ReadyForReviewTaskData = {
  row: {
    original: TaskMutationData;
  };
  data: {
    status: string;
  };
};

type WorkflowDisplayData = {
  workflowAssignedTo: User;
  workflowName: string;
  workflowTeamName: string;
  workflowEnvName: string;
  workflowParentIssueName: string;
};

export function useWorkflowDisplay() {
  const [readyForReviewTaskData, setReadyForReviewTaskData] = useState<ReadyForReviewTaskData>();
  const [workflowDisplayData, setWorkflowDisplayData] = useState<WorkflowDisplayData | Record<string, never>>({});

  const resetWorkflowDisplay = () => setWorkflowDisplayData({});

  useEffect(() => {
    if (!readyForReviewTaskData) return;

    const data = readyForReviewTaskData.row.original;
    const workflowData = {
      workflowAssignedTo: { ...data.assignedTo },
      workflowName: data.workflow.name,
      workflowTeamName: data.team.name,
      workflowEnvName: data.workflow.envName,
      workflowParentIssueName: data.parentIssue.name,
    };
    setWorkflowDisplayData(workflowData);
  }, [readyForReviewTaskData]);

  return {
    readyForReviewTaskData,
    resetWorkflowDisplay,
    setReadyForReviewTaskData,
    workflowDisplayData,
  };
}
