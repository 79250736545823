import { Fragment, useState } from 'react';
import { Switch, FormControlLabel, Button, Tooltip, Typography, Box, Skeleton, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BuildOutlined, AddCircleOutline } from '@mui/icons-material';
import { incompleteStatuses, usePlanningContext } from '../../context/PlanningContext';
import { TestHeatMap } from '../Summary/types';

// Define the task categories from shortest to longest duration
const categories = ['Under a day', '1-2 days', '3-5 days', '1 week', '2 weeks', '> 2 weeks'].reverse();

// Map the age filter values to the category
const categoryFilterValueMap: {
  [key: string]: [string | undefined, string | undefined];
} = {
  'Under a day': [undefined, '1'],
  '1-2 days': ['1', '2'],
  '3-5 days': ['3', '5'],
  '1 week': ['6', '7'],
  '2 weeks': ['8', '14'],
  '> 2 weeks': ['14', undefined],
};

const getSize = (value: number) => {
  const sizes = [6, 9, 12, 15, 18, 21, 24, 27];
  const thresholds = [1, 5, 10, 20, 35, 50, 65, 75];
  for (let i = 0; i < thresholds.length; i++) {
    if (value <= thresholds[i]) {
      return sizes[i];
    }
  }
  return sizes[sizes.length - 1];
};

const getColor = (categoryIndex: number, isMaintenanceTask: boolean) => {
  const blueShades = [
    '#2563eb', // bright blue
    '#3b82f6', // medium blue
    '#60a5fa', // light blue
    '#93c5fd', // lighter blue
    '#bfdbfe', // very light blue
    '#dbeafe', // palest blue
  ];
  const greenShades = [
    '#16a34a', // bright green
    '#22c55e', // medium green
    '#4ade80', // light green
    '#86efac', // lighter green
    '#bbf7d0', // very light green
    '#dcfce7', // palest green
  ];
  return isMaintenanceTask ? blueShades[categoryIndex] : greenShades[categoryIndex];
};

const TaskDot = styled('div')(() => ({
  borderRadius: '50%',
  position: 'absolute',
  transition: 'all 0.3s ease',
  cursor: 'pointer',
  '&:hover': {
    animation: 'vibrate 0.6s linear infinite',
  },
  '@keyframes vibrate': {
    '0%': { transform: 'translate(0)' },
    '20%': { transform: 'translate(-1px, 1px)' },
    '40%': { transform: 'translate(-1px, -1px)' },
    '60%': { transform: 'translate(1px, 1px)' },
    '80%': { transform: 'translate(1px, -1px)' },
    '100%': { transform: 'translate(0)' },
  },
}));

// TODO: HIDE ZERO MAINTENANCE
// TODO: SORT BY MOST MAINT
// TODO: Reduce Whitespace
// TODO: Add individual heat maps to planning drawers

export default function TaskHeatmap() {
  const { clientSummaries, setOpenPlanningDrawerId, setSelectedView, setTableState, setSelectedTab } = usePlanningContext();
  const data = clientSummaries.data?.map((cs) => cs.testHeatMap) as TestHeatMap[];
  const [showBlockers, setShowBlockers] = useState(true);
  const [showMaintenance, setShowMaintenance] = useState(true);
  const [showCreation, setShowCreation] = useState(true);

  const handleDotClick = (customerId: string, type: 'maintenance' | 'creation', category: string) => {
    // Get the appropriate drawer view
    const drawerView = type === 'maintenance' ? 'testMaintenance' : 'testCoverageRequest';

    // Get the appropriate type filter value
    const typeFilterValue = type === 'maintenance' ? 'testMaintenance' : 'testCreation';

    setSelectedView(drawerView);
    setSelectedTab(0);
    setTableState((prev) => {
      return {
        ...prev,
        groupCRandMRs: false,
        includeChildTasks: {
          status: false,
          dueDate: false,
        },
        columnFilters: [
          { id: 'status', value: incompleteStatuses },
          { id: 'type', value: [typeFilterValue] },
          { id: 'age', value: categoryFilterValueMap[category] },
        ],
      };
    });
    setOpenPlanningDrawerId(customerId);
  };

  return (
    <Box sx={{ px: 2 }}>
      {clientSummaries.isFetching ? (
        <Skeleton variant="rectangular" height={410} width="100%" />
      ) : (
        <Box sx={{ height: 410 }}>
          <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ flex: 1, display: 'flex', overflow: 'hidden' }}>
              {/* Test Age Labels  */}
              <Box sx={{ display: 'flex', mt: 2 }}>
                <Box>
                  {categories.map((category) => (
                    <Box key={category} sx={{ height: 32, mb: 1, display: 'flex', alignItems: 'center' }}>
                      <Typography variant="caption" sx={{ whiteSpace: 'nowrap', mr: 2 }}>
                        {category}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
              <Divider orientation="vertical" flexItem />

              <Box sx={{ flex: 1, overflow: 'auto' }}>
                <Box sx={{ minWidth: 'max-content' }}>
                  {/* Chart Data  */}
                  <Box sx={{ display: 'flex', mb: 2 }}>
                    {data.map((customer) => (
                      <Box key={customer.name} sx={{ flex: 1, textAlign: 'center' }} />
                    ))}
                  </Box>
                  {categories.map((category, categoryIndex) => (
                    <Box key={category} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      {data.map((customer) => (
                        <Box
                          key={`${customer.name}-${category}`}
                          sx={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                            height: 32,
                            minWidth: '60px',
                          }}
                        >
                          <Tooltip
                            title={
                              <Fragment>
                                <Typography variant="body2">
                                  <strong>{customer.name}</strong>
                                </Typography>
                                <Typography variant="body2">{category}</Typography>
                                <Typography variant="body2">
                                  Maintenance Tests: {customer.maintenanceTests.actionable[categoryIndex]} (Blocked:{' '}
                                  {customer.maintenanceTests.blocked[categoryIndex]})
                                </Typography>
                                <Typography variant="body2">
                                  Creation Tests: {customer.creationTests.actionable[categoryIndex]} (Blocked:{' '}
                                  {customer.creationTests.blocked[categoryIndex]})
                                </Typography>
                              </Fragment>
                            }
                          >
                            <Box
                              sx={{
                                position: 'relative',
                                width: 28,
                                height: 28,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              {[
                                {
                                  type: 'maintenance',
                                  tasks: {
                                    actionable: customer.maintenanceTests.actionable[categoryIndex],
                                    blocked: customer.maintenanceTests.blocked[categoryIndex],
                                  },
                                },
                                {
                                  type: 'creation',
                                  tasks: {
                                    actionable: customer.creationTests.actionable[categoryIndex],
                                    blocked: customer.creationTests.blocked[categoryIndex],
                                  },
                                },
                              ]
                                .filter((item) => item.tasks.actionable > 0 || item.tasks.blocked > 0)
                                .filter((item) => (item.type === 'maintenance' && showMaintenance) || (item.type === 'creation' && showCreation))
                                .sort((a, b) => b.tasks.actionable - a.tasks.actionable)
                                .map((item, index, arr) => {
                                  const blockedSize = getSize(item.tasks.blocked);
                                  const actionableSize = item.tasks.actionable > 0 ? getSize(item.tasks.actionable) : 0;
                                  const size = Math.max(blockedSize, actionableSize);
                                  const isMaintenanceTask = item.type === 'maintenance';
                                  const offset = arr.length === 2 ? (index === 0 ? -size / 4 : size / 4) : 0;
                                  const blockedRatio = item.tasks.blocked / (item.tasks.actionable || item.tasks.blocked);
                                  const minOutlineWidth = 2;
                                  const maxOutlineWidth = Math.floor(size * 0.4);
                                  const showBlockedAsMain = item.tasks.blocked > 0 && item.tasks.actionable === 0 && showBlockers;
                                  const blockedOutlineWidth =
                                    item.tasks.blocked > 0 && showBlockers && !showBlockedAsMain
                                      ? Math.max(minOutlineWidth, Math.min(maxOutlineWidth, Math.floor(size * 0.5 * blockedRatio)))
                                      : 0;
                                  return (
                                    <TaskDot
                                      key={item.type}
                                      onClick={() => handleDotClick(customer.qawId, item.type as 'maintenance' | 'creation', category)}
                                      sx={(theme) => ({
                                        width: showBlockedAsMain ? blockedSize : item.tasks.actionable > 0 ? actionableSize : 0,
                                        height: showBlockedAsMain ? blockedSize : item.tasks.actionable > 0 ? actionableSize : 0,
                                        minWidth: showBlockedAsMain ? blockedSize : item.tasks.actionable > 0 ? actionableSize : 0,
                                        minHeight: showBlockedAsMain ? blockedSize : item.tasks.actionable > 0 ? actionableSize : 0,
                                        position: 'absolute',
                                        left: `calc(50% - ${size / 2}px + ${offset}px)`,
                                        top: `calc(50% - ${size / 2}px)`,
                                        zIndex: index === 0 ? 1 : 2,
                                        bgcolor: showBlockedAsMain
                                          ? isMaintenanceTask
                                            ? '#9333ea'
                                            : theme.palette.error.main
                                          : item.tasks.actionable > 0
                                          ? getColor(categoryIndex, isMaintenanceTask)
                                          : 'transparent',
                                        boxShadow: [
                                          !showBlockedAsMain && item.tasks.actionable > 0 && `0 0 0 1px ${theme.palette.divider}`,
                                          !showBlockedAsMain &&
                                            item.tasks.blocked > 0 &&
                                            showBlockers &&
                                            `0 0 0 ${blockedOutlineWidth}px ${isMaintenanceTask ? '#9333ea' : theme.palette.error.main}`,
                                        ]
                                          .filter(Boolean)
                                          .join(', '),
                                      })}
                                    />
                                  );
                                })}
                            </Box>
                          </Tooltip>
                        </Box>
                      ))}
                    </Box>
                  ))}

                  <Divider />

                  {/* Customer Labels  */}
                  <Box sx={{ display: 'flex', mt: 3, mb: 3 }}>
                    {data.map((customer) => (
                      <Box
                        key={customer.name}
                        sx={{
                          flex: 1,
                          textAlign: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                          minWidth: '60px',
                        }}
                      >
                        <Tooltip title={customer.name}>
                          <Typography
                            variant="caption"
                            sx={{
                              fontWeight: 'medium',
                              transform: 'rotate(-45deg)',
                              transformOrigin: 'center',
                              display: 'inline-block',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: '120px',
                            }}
                          >
                            {customer.name}
                          </Typography>
                        </Tooltip>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>

            <Divider sx={{ mt: 1, mb: 1 }} />

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              {/* Chart Legend */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="subtitle1">Creation</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box sx={{ borderRadius: '50%', width: 15, height: 15, bgcolor: '#16a34a' }} />
                    <Typography variant="caption">Unblocked</Typography>
                    <Box sx={{ borderRadius: '50%', width: 15, height: 15, bgcolor: '#f43f5e' }} />
                    <Typography variant="caption">Blocked</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="subtitle1">Maintenance</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box sx={{ borderRadius: '50%', width: 15, height: 15, bgcolor: '#2563eb' }} />
                    <Typography variant="caption">Unblocked</Typography>
                    <Box sx={{ borderRadius: '50%', width: 15, height: 15, bgcolor: '#9333ea' }} />
                    <Typography variant="caption">Blocked</Typography>
                  </Box>
                </Box>
              </Box>

              {/* Chart Controls */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showBlockers}
                      onChange={(e) => setShowBlockers(e.target.checked)}
                      color="error"
                      disabled={clientSummaries.isFetching}
                    />
                  }
                  label="Show Blocked Tests"
                />
                <Button
                  variant={showMaintenance ? 'contained' : 'outlined'}
                  size="small"
                  onClick={() => setShowMaintenance(!showMaintenance)}
                  startIcon={<BuildOutlined />}
                  disabled={clientSummaries.isFetching}
                  sx={{
                    bgcolor: showMaintenance ? '#2563eb' : 'transparent',
                    color: showMaintenance ? 'white' : '#2563eb',
                    borderColor: '#2563eb',
                    '&:hover': {
                      bgcolor: showMaintenance ? '#1d4ed8' : 'rgba(37, 99, 235, 0.04)',
                      borderColor: '#2563eb',
                    },
                  }}
                >
                  Maintenance
                </Button>
                <Button
                  variant={showCreation ? 'contained' : 'outlined'}
                  size="small"
                  onClick={() => setShowCreation(!showCreation)}
                  startIcon={<AddCircleOutline />}
                  disabled={clientSummaries.isFetching}
                  sx={{
                    bgcolor: showCreation ? '#16a34a' : 'transparent',
                    color: showCreation ? 'white' : '#16a34a',
                    borderColor: '#16a34a',
                    '&:hover': {
                      bgcolor: showCreation ? '#15803d' : 'rgba(22, 163, 74, 0.04)',
                      borderColor: '#16a34a',
                    },
                  }}
                >
                  Creation
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
