import { motion } from 'framer-motion';
import { Fragment, MouseEvent } from 'react';
import { Button } from '@/components/ui/button';
import { Conversation } from '../data/types';
import { useCommunications } from '@/components/HQ/contexts/CommunicationsContext';
import { QAWTask } from '@/types';
import { ArrowPathIcon, ArrowUturnLeftIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { Transition } from '@headlessui/react';
import { Menu } from '@headlessui/react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

export function ClaimAndUnclaimButton({ conversation }: { conversation: Conversation }) {
  return (
    <div className="flex items-center justify-evenly">
      <motion.div animate={{ opacity: [0, 1] }} transition={{ duration: 0.2 }}>
        {conversation.claimed ? <ConversationMenu conversation={conversation} /> : <ClaimButton conversation={conversation} />}
      </motion.div>
    </div>
  );
}

export function ClaimButton({ conversation }: { conversation: Conversation }) {
  const { suiteAction } = useCommunications();

  const { isPending } = useCommunications();
  const determineIsPending = (conversation: Conversation, action: string) => {
    return conversation.messages.some((message) => {
      const messageTask = message.messageTasks?.find((task) => task.type === 'message');
      if (messageTask && isPending[String(messageTask.id)]) {
        return isPending[String(messageTask.id)][action];
      }
      return false;
    });
  };

  const handleClaimClick = (e: MouseEvent<HTMLButtonElement>, conversation: Conversation) => {
    e.stopPropagation();
    if (!conversation.messages.length) return;
    for (const message of conversation.messages) {
      const messageTask = message.messageTasks?.find((task) => task.type === 'message');
      if (messageTask && !messageTask.assignedTo?.id && messageTask.status !== 'done' && messageTask.status !== 'ignore') {
        suiteAction(messageTask as unknown as QAWTask, '/claim');
      }
    }
  };

  return (
    <Button
      disabled={determineIsPending(conversation, 'claim')}
      onClick={(e) => handleClaimClick(e, conversation)}
      variant="outline"
      size="sm"
      className="h-6 text-xs px-2 py-0"
    >
      {determineIsPending(conversation, '/claim') ? <span>Claiming</span> : <span>Claim</span>}
    </Button>
  );
}

function ConversationMenu({ conversation }: { conversation: Conversation }) {
  const { suiteAction } = useCommunications();

  const { isPending } = useCommunications();
  const determineIsPending = (conversation: Conversation, action: string) => {
    return conversation.messages.some((message) => {
      const messageTask = message.messageTasks?.find((task) => task.type === 'message');
      if (messageTask && isPending[String(messageTask.id)]) {
        return isPending[String(messageTask.id)][action];
      }
      return false;
    });
  };

  const handleButtonClick = (e: MouseEvent<HTMLButtonElement>, conversation: Conversation, action: string) => {
    e.stopPropagation();
    e.preventDefault();

    if (action === '/claim') {
      if (!conversation.messages.length) return;
      for (const message of conversation.messages) {
        const messageTask = message.messageTasks?.find((task) => task.type === 'message');
        if (messageTask && !messageTask.assignedTo?.id && messageTask.status !== 'done' && messageTask.status !== 'ignore') {
          suiteAction(messageTask as unknown as QAWTask, action);
        }
      }
    } else if (action === '/unclaim' || action === '/done') {
      if (!conversation.messages.length) return;
      for (const message of conversation.messages) {
        const messageTask = message.messageTasks?.find((task) => task.type === 'message');
        if (messageTask && messageTask.status !== 'done' && messageTask.status !== 'ignore') {
          suiteAction(messageTask as unknown as QAWTask, action);
        }
      }
    }
  };

  const conversationCustomerAsks = conversation.messages.flatMap((message) => message.customerAsks);
  const numberOfUnresolvedCustomerAsks = conversationCustomerAsks.filter((ask) => !ask?.resolved).length;
  const conversationHasUnresolvedCustomerAsks = numberOfUnresolvedCustomerAsks > 0;

  return (
    <Menu
      as="div"
      className="menu-button-container flex items-center relative rounded-md py-0.5 px-2 text-xs font-medium ring-1 ring-inset bg-gray-50 text-gray-700 ring-gray-600/20 hover:ring-2 hover:bg-gray-100"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
        <EllipsisHorizontalIcon className="h-3 w-3" aria-hidden="true" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 bottom-full mb-1 w-32 origin-bottom-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                className={`w-full flex px-3 py-1 text-sm leading-6 text-indigo-600 ${active ? 'bg-gray-100' : ''}`}
                onClick={(e) => handleButtonClick(e, conversation, '/unclaim')}
                disabled={determineIsPending(conversation, '/unclaim')}
              >
                <ArrowUturnLeftIcon className="h-5 w-5 pt-1 mr-1" /> Unclaim
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                className={`w-full flex px-3 py-1 text-sm leading-6 cursor-pointer ${
                  conversationHasUnresolvedCustomerAsks ? 'text-gray-400' : 'text-green-600'
                } ${active ? 'bg-gray-100' : ''}`}
                onClick={(e) => handleButtonClick(e, conversation, '/done')}
                disabled={determineIsPending(conversation, '/done') || conversationHasUnresolvedCustomerAsks}
              >
                <ArrowPathIcon className={`h-5 w-5 pt-1 mr-1 ${conversationHasUnresolvedCustomerAsks ? 'opacity-40' : ''}`} />
                {conversationHasUnresolvedCustomerAsks ? (
                  <TooltipProvider delayDuration={1500}>
                    <Tooltip>
                      <TooltipTrigger className="inline-block">
                        <span className="cursor-pointer">Done</span>
                      </TooltipTrigger>
                      <TooltipContent side="top" align="end">
                        <span className="text-gray-600 text-sm">
                          Conversation has <span className="text-gray-900 font-bold">{numberOfUnresolvedCustomerAsks}</span> unresolved customer ask
                          {numberOfUnresolvedCustomerAsks === 1 ? '' : 's'}.
                        </span>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                ) : (
                  <span>Done</span>
                )}
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
