import { FileDownload } from '@mui/icons-material';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import timezonePlugin from 'dayjs/plugin/timezone';
import utcPlugin from 'dayjs/plugin/utc';

dayjs.extend(timezonePlugin);
dayjs.extend(utcPlugin);

export default function ExportSuitesDetailsToCsvButton({ filteredSuiteData, customerNameOrId }) {
  // Define the headers
  const headers = [
    'ID',
    'Trigger Name',
    'Start Time (PT)',
    'Initial Run Completed At (PT)',
    'Last Updated At Time (PT)',
    'Run Time (Minutes)',
    'Investigation Time (Minutes)',
    'Total Time (Minutes)',
    'Superseded Total Time (Minutes)',
    'CI/CD Overhead',
    'Bugged Workflows (New)',
    'Flake Percent',
    'Success Percent',
    'Workflows Run',
    'Concurrency',
    'Status',
  ];

  const downloadCsv = () => {
    let csvContent = headers.join(',') + '\n';

    // Helper function to escape double quotes and wrap in double quotes
    const escapeForCsv = (value) => {
      if (value === null || value === undefined) return '';
      const escapedValue = value.toString().replace(/"/g, '""');
      return `"${escapedValue}"`;
    };

    // Helper function to determine meaning of null concurrency
    // This is because unlimited concurrency is currently set as a null field on the frontend
    // We want to differentiate between "null" being unlimited, and "null" being unknown
    // This was merged on Feb 24, 2025, so that's how we'll determine the difference now that we're tracking moving forward
    const determineConcurrency = (concurrency, startTime) => {
      if (concurrency === null && dayjs(startTime).isSameOrBefore(dayjs('2025-02-25'))) {
        return 'N/A';
      } else if (concurrency === null && dayjs(startTime).isAfter(dayjs('2025-02-25'))) {
        return 'Unlimited';
      } else {
        return concurrency;
      }
    };

    // Helper function to determine suite status
    const getSuiteStatus = (suite) =>
      suite.isCompleted
        ? 'Completed'
        : suite.isSuperseded
        ? 'Superseded'
        : suite.isMovedToNewerSuite
        ? 'Moved to Newer Suite'
        : suite.isCanceled
        ? 'Canceled'
        : 'Incomplete';

    // Process each suite to generate the CSV rows
    filteredSuiteData.forEach((suite) => {
      const id = escapeForCsv(suite.id);
      const triggerName = escapeForCsv(suite.triggerName);
      const startTime = escapeForCsv(suite.startTimeString);
      const initialRunCompletedAt = escapeForCsv(
        dayjs
          .tz(suite.startTime, 'America/Los_Angeles')
          .add(suite.runTimeInMinutes, 'minute')
          .tz('America/Los_Angeles')
          .format('MMMM D, YYYY, h:mm A'),
      );
      const lastUpdatedAtTime = escapeForCsv(
        dayjs
          .tz(suite.startTime, 'America/Los_Angeles')
          .add(suite.totalTimeInMinutes, 'minute')
          .tz('America/Los_Angeles')
          .format('MMMM D, YYYY, h:mm A'),
      );
      const runTime = escapeForCsv(suite.runTimeInMinutes);
      const triageTime = escapeForCsv(suite.triageTimeInMinutes);
      const totalTime = escapeForCsv(suite.totalTimeInMinutes);
      const supersededTotalTime = escapeForCsv(suite.supersededTotalTimeInMinutes);
      const ciCdOverhead = escapeForCsv(suite.ciCdOverhead);
      const numberOfRunsTriagedAsBugged = escapeForCsv(suite.numberOfRunsTriagedAsBug);
      const flakePercent = escapeForCsv(suite.flakePercent);
      const successPercent = escapeForCsv(suite.successPercent);
      const runs = escapeForCsv(suite.numberOfRuns);
      const concurrency = escapeForCsv(determineConcurrency(suite.suiteRunConcurrency, suite.startTime));
      const status = escapeForCsv(getSuiteStatus(suite));

      // Create a row string
      const row = [
        id,
        triggerName,
        startTime,
        initialRunCompletedAt,
        lastUpdatedAtTime,
        runTime,
        triageTime,
        totalTime,
        supersededTotalTime,
        ciCdOverhead,
        numberOfRunsTriagedAsBugged,
        flakePercent,
        successPercent,
        runs,
        concurrency,
        status,
      ].join(',');

      csvContent += row + '\n';
    });

    // Trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${customerNameOrId} Suites Runs Details.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <Button
      variant="contained"
      sx={{
        backgroundColor: '#2f2fc1',
        '&:hover': {
          backgroundColor: '#1e1ea1',
        },
        marginLeft: '10px',
        padding: '6px 12px',
        fontSize: '0.8rem',
        minWidth: '160px',
      }}
      startIcon={<FileDownload />}
      onClick={downloadCsv}
    >
      Runs Details CSV
    </Button>
  );
}
