import { useState, useEffect } from 'react';

/**
 * Hook for managing the active view (workflows or blocks) and search functionality
 */
export const useActiveView = (selectedWorkflow: string | null, selectedBlock: string | null) => {
  const [activeView, setActiveView] = useState<'workflows' | 'blocks'>('workflows');
  const [workflowSearch, setWorkflowSearch] = useState('');
  const [blockSearch, setBlockSearch] = useState('');

  /**
   * Effect to handle scrolling when activeView changes
   * Scrolls to the selected workflow or block when the view changes
   */
  useEffect(() => {
    if (selectedWorkflow && activeView === 'workflows') {
      const element = document.querySelector(`[data-workflow-id="${selectedWorkflow}"]`);
      element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else if (selectedBlock && activeView === 'blocks') {
      const element = document.querySelector(`[data-block-id="${selectedBlock}"]`);
      element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [activeView, selectedWorkflow, selectedBlock]);

  return {
    activeView,
    setActiveView,
    workflowSearch,
    setWorkflowSearch,
    blockSearch,
    setBlockSearch,
  };
};
