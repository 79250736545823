import { Modal, Paper } from '@mui/material'; // TODO: replace with Wolf UI
import { ReadyForReviewChecklist } from './ReadyForReviewChecklist';
import { type ReadyForReviewChecklistHookReturn } from './useReadyForReviewChecklist';

type ReadyForReviewChecklistModalProps = Pick<
  ReadyForReviewChecklistHookReturn,
  | 'checkedQuestions'
  | 'checklistCompletionProgress'
  | 'closeReadyForReviewChecklistModal'
  | 'handleQuestionChange'
  | 'isChecklistComplete'
  | 'showReadyForReviewChecklistModal'
  | 'submitTaskReadyForReview'
  | 'workflowDisplayData'
>;

export function ReadyForReviewChecklistModal({
  checkedQuestions,
  checklistCompletionProgress,
  closeReadyForReviewChecklistModal,
  handleQuestionChange,
  isChecklistComplete,
  showReadyForReviewChecklistModal,
  submitTaskReadyForReview,
  workflowDisplayData,
}: ReadyForReviewChecklistModalProps) {
  return (
    <Modal open={showReadyForReviewChecklistModal} onClose={closeReadyForReviewChecklistModal}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '65%',
          maxHeight: '48rem',
          maxWidth: 'min(48rem,65%)',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        }}
      >
        <ReadyForReviewChecklist
          {...{
            checkedQuestions,
            checklistCompletionProgress,
            handleQuestionChange,
            isChecklistComplete,
            submitTaskReadyForReview,
            workflowDisplayData,
          }}
        />
      </Paper>
    </Modal>
  );
}
