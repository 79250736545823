import { useMutation } from '@tanstack/react-query';
import { sendPostRequest } from '../../../utils/network';
import { useState } from 'react';
import { WOLF_LOGO } from '../../../constants';

/**
 *
 * @returns {import('react').JSX.Element}
 */
export default function FacebookCodeConverter() {
  const [token, setToken] = useState('');
  const [success, setSuccess] = useState(null);
  const [copied, setCopied] = useState(false);

  const codeMutation = useMutation({
    mutationFn:
      /**
       *
       * @param {string} code
       * @returns {Promise}
       */
      (code) => {
        return sendPostRequest(`/oauth/code/facebook`, { code });
      },
    onSuccess: (data) => {
      const token = data.data?.access_token;
      setToken(token ? token : '');
      setSuccess(true);
    },
    onError: (error) => {
      reportError('Failed to fetch new auth.');
      setSuccess(false);
      reportError(error);
    },
  });

  function convertCode() {
    const urlParams = new URLSearchParams(window.location.search);
    let code = urlParams.get('code');
    codeMutation.mutate(code);
  }

  function copyToken() {
    navigator.clipboard.writeText(token);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2500);
  }

  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <div className="max-w-sm">
        <div className="mb-4 text-xl font-bold flex items-end gap-2">
          <img src={WOLF_LOGO} alt="wolf_logo" className="w-8 h-8 rounded-full" />
          <span>Use this page to:</span>
        </div>
        <ul className="list-disc list-inside mb-4">
          <li>Exchange Facebook auth code for access token</li>
          <li>Receive access token for use with 3rd party APIs</li>
        </ul>
        <button
          type="button"
          className="rounded-md w-full bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          onClick={() => {
            convertCode();
          }}
        >
          Convert Code on Server
        </button>
        <div className="mt-4 flex items-center justify-between">
          <a href="/facebook/auth" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
            Restart the Auth Flow
          </a>
          <span className="text-sm italic">
            {success === null ? 'No token exchange yet' : success ? 'Token exchange successful' : 'Token exchange failed'}
          </span>
        </div>
        <div className="mt-4 flex">
          {token && (
            <div className="flex flex-col gap-2">
              <span className="font-sm italic">Access Token:</span>
              <div className="flex items-center gap-2">
                <div className="font-sm overflow-x-auto whitespace-nowrap max-w-full" style={{ maxWidth: '320px' }}>
                  {token}
                </div>
                <button className="text-sm text-blue-600 hover:underline" disabled={copied} onClick={copyToken}>
                  {copied ? 'Copied' : 'Copy'}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
