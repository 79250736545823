import { RealUser, RealInvestigationTask } from '../types/investigation';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import _ from 'lodash';

export function UserAggregator({ users, tasks, maxDisplay = 4 }: { users: RealUser[]; tasks: RealInvestigationTask[]; maxDisplay?: number }) {
  /**
   * TODO: Implement online statuses
   */
  // const sortedUsers = [...users].sort((a, b) => {
  //   const order = { red: 0, yellow: 1, green: 2 };
  //   return order[a.onlineStatus] - order[b.onlineStatus];
  // });

  const tasksByUser = _.groupBy(tasks, (task) => task.assignedTo?.id);
  const filteredUsers = users.filter((user) => tasksByUser[user.qawId]?.length);
  const sortedUsers = filteredUsers.sort((a, b) => (tasksByUser[b.qawId]?.length || 0) - (tasksByUser[a.qawId]?.length || 0));

  const displayedUsers = sortedUsers.slice(0, maxDisplay);
  const remainingUsers = sortedUsers?.length - maxDisplay;

  return (
    <div className="flex items-center">
      {displayedUsers.map((user) => (
        <TooltipProvider key={user.id}>
          <Tooltip>
            <TooltipTrigger>
              <div className="relative -ml-2 first:ml-0">
                <Avatar
                  /**
                   * TODO: Implement online statuses
                   */
                  //   className={`w-6 h-6 border-2 border-background overflow-visible
                  // ${user.onlineStatus === 'red' ? 'bg-red-200 animate-[pulse_2s_ease-in-out_infinite]' : ''}`}
                  className={`w-6 h-6 border-2 border-background overflow-visible rounded-full`}
                >
                  <AvatarImage src={user.avatar48} alt={user.name} className="rounded-full object-cover" />
                  <AvatarFallback className="rounded-full">{user.name.charAt(0)}</AvatarFallback>
                  <span
                    /**
                     * TODO: Implement online statuses
                     */
                    // className={`absolute bottom-0 right-0 w-2 h-2 rounded-full border border-background
                    // ${user.onlineStatus === 'red' ? 'bg-red-500' : user.onlineStatus === 'yellow' ? 'bg-yellow-500' : 'bg-green-500'}`}
                    className={`absolute bottom-0 right-0 w-2 h-2 rounded-full border border-background bg-green-500`}
                  />
                </Avatar>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <p>{`${user.name}\n ${tasksByUser[user.qawId]?.length} tasks claimed`}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ))}
      {remainingUsers > 0 && <span className="text-xs text-muted-foreground ml-1">+{remainingUsers} more</span>}
    </div>
  );
}
