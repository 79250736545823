import { FileDownload } from '@mui/icons-material';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import ExcelJS from 'exceljs';

export default function ExportSuitesTableToXLSXButton({ filteredSuiteData, customerNameOrId }) {
  const headers = ['Workflow Name'];

  // Sort suites by start time
  const sortedSuiteData = [...filteredSuiteData].sort((a, b) => dayjs(a.startTime).diff(dayjs(b.startTime)));

  // Add date headers for each suite
  sortedSuiteData.forEach((suite) => {
    headers.push(`${suite.startTimeString} (PT)`);
  });

  // Add headers for Workflow Flakiness and Suite Health Effect
  headers.push('Workflow Flakiness', 'Suite Health Effect');

  const downloadXlsx = async () => {
    const workbook = new ExcelJS.Workbook();
    const usedSheetNames = new Set();

    // Helper function to sanitize trigger names for worksheet names
    const sanitizeSheetName = (name) => {
      let sanitized = name.replace(/[*?:\\/[\]]/g, '|').substring(0, 31);

      // Special handling for European Time Zone triggers
      if (sanitized.startsWith('CI_Weekly_(European_Time_Zone)_')) {
        const dayNumber = sanitized.match(/\d+/)[0];
        const day = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][parseInt(dayNumber) - 1];
        sanitized = `EU_TZ_${day}`;
      }

      // Ensure unique sheet names
      let uniqueSanitized = sanitized;
      let counter = 1;
      while (usedSheetNames.has(uniqueSanitized)) {
        uniqueSanitized = `${sanitized.substring(0, 28)}_${counter}`.substring(0, 31);
        counter++;
      }
      usedSheetNames.add(uniqueSanitized);

      return uniqueSanitized;
    };

    // Group suites by trigger
    const suitesByTrigger = filteredSuiteData.reduce((acc, suite) => {
      const triggerName =
        suite.triggerName.toLowerCase().startsWith('pr deploy') ||
        suite.triggerName?.toLowerCase().startsWith('pr test') ||
        suite.triggerName?.toLowerCase().startsWith('pull request') ||
        suite.triggerName?.toLowerCase().startsWith('preview')
          ? 'PR Deployments'
          : suite.triggerName || 'Manual';

      if (!acc[triggerName]) {
        acc[triggerName] = [];
      }
      acc[triggerName].push(suite);
      return acc;
    }, {});

    // Create a sheet for each trigger
    Object.entries(suitesByTrigger).forEach(([triggerName, triggerSuites]) => {
      const sanitizedTriggerName = sanitizeSheetName(triggerName);
      const worksheet = workbook.addWorksheet(sanitizedTriggerName);

      // Sort suites by start time
      const sortedSuiteData = [...triggerSuites].sort((a, b) => dayjs(a.startTime).diff(dayjs(b.startTime)));

      // Add headers
      const headers = ['Workflow Name'];
      sortedSuiteData.forEach((suite) => {
        headers.push(`${suite.startTimeString} (PT)`);
      });
      headers.push('Workflow Flakiness', 'Suite Health Effect');
      worksheet.addRow(headers);

      // Create a map to track the status of workflows across all suites
      const workflowStatusMap = {};
      const workflowSuiteSlowdownMap = {};
      const workflowFlakinessMap = {};

      const suiteSlowdownWeightage = {
        'Passed on First Attempt': 0,
        'Passed on Second Attempt': 30,
        'Passed on Third Attempt': 80,
        'Passed on QAE Retry': 130,
        'Passed on QAE Change': 0,
        'Previously Bugged': 0,
        'Newly Bugged': 0,
        Maintenance: 0,
        'Do Not Investigate': 0,
        Superseded: 90,
        Canceled: 0,
        Failed: 0,
      };

      const workflowFlakiness = {
        'Passed on First Attempt': 0,
        'Passed on Second Attempt': 1,
        'Passed on Third Attempt': 1,
        'Passed on QAE Retry': 1,
        'Passed on QAE Change': 0,
        'Previously Bugged': 0,
        'Newly Bugged': 0,
        Maintenance: 0,
        'Do Not Investigate': 0,
        Superseded: 0,
        Canceled: 0,
        Failed: 0,
      };

      sortedSuiteData.forEach((suite, suiteIndex) => {
        Object.keys(suite.runsByLastAttemptStatus).forEach((status) => {
          suite.runsByLastAttemptStatus[status].forEach((workflow) => {
            const workflowName = workflow.name.split(/:(.+)/)[1];
            if (!workflowStatusMap[workflowName]) {
              workflowStatusMap[workflowName] = new Array(sortedSuiteData.length + 3).fill('');
              workflowStatusMap[workflowName][0] = workflowName;
              workflowSuiteSlowdownMap[workflowName] = { totalWeight: 0, count: 0 };
              workflowFlakinessMap[workflowName] = { totalFlakiness: 0, count: 0 };
            }
            workflowStatusMap[workflowName][suiteIndex + 1] = statusToHeaderMap(status);

            workflowSuiteSlowdownMap[workflowName].totalWeight += suiteSlowdownWeightage[statusToHeaderMap(status)] || 0;
            workflowSuiteSlowdownMap[workflowName].count += 1;

            workflowFlakinessMap[workflowName].totalFlakiness += workflowFlakiness[statusToHeaderMap(status)] || 0;
            workflowFlakinessMap[workflowName].count += 1;
          });
        });
      });

      const workflowRows = Object.entries(workflowStatusMap).map(([workflowName, row]) => {
        const flakiness = workflowFlakinessMap[workflowName].totalFlakiness / workflowFlakinessMap[workflowName].count;
        const suiteHealthEffect = workflowSuiteSlowdownMap[workflowName].totalWeight / workflowSuiteSlowdownMap[workflowName].count;

        row[row.length - 2] = flakiness;
        row[row.length - 1] = suiteHealthEffect;

        return { workflowName, row, flakiness };
      });

      workflowRows.sort((a, b) => b.flakiness - a.flakiness);

      // Add rows to the worksheet
      workflowRows.forEach(({ row }) => {
        worksheet.addRow(row);
      });

      // Apply styles to the worksheet
      applyWorksheetStyles(worksheet, sortedSuiteData);
    });

    // Generate XLSX file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${customerNameOrId} Suites Table Data.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // Helper function to apply styles to a worksheet
  const applyWorksheetStyles = (worksheet, sortedSuiteData) => {
    worksheet.eachRow((row, rowNumber) => {
      row.eachCell((cell, colNumber) => {
        // Default cell style
        cell.style = {
          font: { name: 'Arial', size: 12 },
          alignment: { vertical: 'middle', horizontal: 'center', wrapText: true },
          border: {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          },
        };

        // Header row style
        if (rowNumber === 1) {
          cell.style = {
            ...cell.style,
            font: { bold: true, color: { argb: 'FF000000' }, name: 'Arial', size: 12 },
            fill: {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFF5F5F5' },
            },
          };
        }

        // First column style
        if (colNumber === 1 && rowNumber > 1) {
          cell.style = {
            ...cell.style,
            font: { bold: true, color: { argb: 'FF000000' }, name: 'Arial', size: 12 },
            fill: {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFF5F5F5' },
            },
          };
        }

        // Make status cells square-ish
        if (rowNumber > 1 && colNumber > 1 && colNumber <= sortedSuiteData.length + 1) {
          const status = cell.value;
          cell.style.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: getStatusColor(status) },
          };
        }

        // Apply Workflow Flakiness formatting
        if (colNumber === worksheet.columnCount - 1 && rowNumber > 1) {
          cell.numFmt = '0.00'; // Format as number with 2 decimal places
          const flakiness = cell.value;
          cell.style.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: getFlakinessColor(flakiness) },
          };
          cell.style.font.color = { argb: 'FFFFFFFF' }; // White text
        }

        // Apply Suite Health Effect formatting
        if (colNumber === worksheet.columnCount && rowNumber > 1) {
          cell.numFmt = '0'; // Format as integer
          const suiteHealthEffect = cell.value;
          cell.style.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: getSlowdownEffectColor(suiteHealthEffect) },
          };
          cell.style.font.color = { argb: 'FFFFFFFF' }; // White text
        }
      });
    });

    // Set column widths
    worksheet.columns = [
      { width: 30 }, // Workflow name column
      ...Array(sortedSuiteData.length).fill({ width: 15 }), // Status columns
      { width: 20 }, // Workflow Flakiness column
      { width: 20 }, // Suite Health Effect column
    ];

    // Set row height
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber > 1) {
        row.height = 60; // Adjust this value as needed
      }
    });

    // Freeze first row and column
    worksheet.views = [{ state: 'frozen', xSplit: 1, ySplit: 1, topLeftCell: 'B2', activeCell: 'B2' }];
  };

  // Map status keys to the corresponding header
  const statusToHeaderMap = (statusKey) => {
    switch (statusKey) {
      case 'passedFirst':
        return 'Passed on First Attempt';
      case 'passedSecond':
        return 'Passed on Second Attempt';
      case 'passedThird':
        return 'Passed on Third Attempt';
      case 'qaeFlake':
        return 'Passed on QAE Retry';
      case 'qaeTriage':
        return 'Passed on QAE Change';
      case 'autoTriaged':
        return 'Previously Bugged';
      case 'newlyBugged':
        return 'Newly Bugged';
      case 'maintenance':
        return 'Maintenance';
      case 'doNotInvestigate':
        return 'Do Not Investigate';
      case 'canceled':
        return 'Canceled';
      case 'failed':
        return 'Failed';
      case 'supersededRuns':
        return 'Superseded';
      default:
        return '';
    }
  };

  // Function to get color based on status
  const getStatusColor = (status) => {
    const statusColorMap = {
      'Passed on First Attempt': 'FFccffcc',
      'Previously Bugged': 'FFffcccc',
      'Passed on Second Attempt': 'FFffff99',
      'Passed on Third Attempt': 'FFffd699',
      'Passed on QAE Change': 'FF99ccff',
      'Passed on QAE Retry': 'FFffdb4d',
      'Newly Bugged': 'FFff6666',
      Maintenance: 'FFe6ccff',
      'Do Not Investigate': 'FFd9d9d9',
      Superseded: 'FFc0c0c0',
      Canceled: 'FFf2f2f2',
      Failed: 'FFcccccc',
    };
    return statusColorMap[status] || 'FFffffff';
  };

  // Function to get color for Workflow Flakiness
  const getFlakinessColor = (flakiness) => {
    if (flakiness <= 0.2) return '4CAF50'; // Dark green
    if (flakiness <= 0.5) return 'FFA000'; // Dark amber
    if (flakiness <= 0.8) return 'F57C00'; // Dark orange
    return 'D32F2F'; // Dark red
  };

  // Function to get color for Suite Health Effect
  const getSlowdownEffectColor = (effect) => {
    if (effect <= 20) return '4CAF50'; // Dark green
    if (effect <= 50) return 'FFA000'; // Dark amber
    if (effect <= 80) return 'F57C00'; // Dark orange
    return 'D32F2F'; // Dark red
  };

  return (
    <Button
      variant="contained"
      sx={{
        backgroundColor: '#2f2fc1',
        '&:hover': {
          backgroundColor: '#1e1ea1',
        },
        padding: '6px 12px',
        fontSize: '0.8rem',
        minWidth: '160px',
      }}
      startIcon={<FileDownload />}
      onClick={downloadXlsx}
    >
      Runs Table XLSX
    </Button>
  );
}
