import { useQueryClient } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { useHQContext } from '../../contexts/HQContext';
import { useState } from 'react';
import { sendPostRequest } from '@/utils/network';
import axios from 'axios';

export function AddSuiteInvestigationTask() {
  const { setHqToastData } = useHQContext();
  const [newTaskSuiteId, setNewTaskSuiteId] = useState('');

  const queryClient = useQueryClient();

  const addSuiteMutation = useMutation({
    mutationFn: (data: { suiteId: string; claim: boolean }) => {
      return sendPostRequest('/add-investigation-task', data);
    },
    onSuccess: () => {
      setHqToastData({
        title: 'Success',
        message: 'Suite added',
        isSuccess: true,
        content: null,
        show: true,
        onDone: () => setHqToastData(null),
      });
    },
    onError: (error: unknown) => {
      let message = 'Failed to add suite';

      if (axios.isAxiosError(error)) {
        message = error.response?.data?.message || message;
      } else if (error instanceof Error) {
        message = error.message || message;
      }

      setHqToastData({
        title: 'Error',
        message,
        isSuccess: false,
        content: null,
        show: true,
        onDone: () => setHqToastData(null),
      });

      // Optionally rethrow with the same message
      throw new Error(message);
    },
    onSettled: () => {
      setNewTaskSuiteId('');
      queryClient.invalidateQueries({ queryKey: ['openTasks'] });
    },
  });

  return (
    <div>
      <div className="mt-2">
        <p className="text-sm text-gray-500">
          Enter the Suite ID to add a new Investigation Task. The Suite ID is the ID that follows `/runs/` in the suite URL.
        </p>
        <p className="text-sm text-gray-500 mt-2">
          You will need to use the "check" button in the menu to refresh failure counts and close this task.
        </p>
        <input
          type="text"
          value={newTaskSuiteId}
          onChange={(e) => setNewTaskSuiteId(e.target.value)}
          className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
          placeholder={'suiteId'}
        />
      </div>

      <div className="mt-4 flex justify-between">
        <button
          type="button"
          className="inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
          onClick={() => {
            setNewTaskSuiteId('');
          }}
        >
          Cancel
        </button>
        <div>
          <button
            type="button"
            className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() => addSuiteMutation.mutate({ suiteId: newTaskSuiteId, claim: false })}
            disabled={!newTaskSuiteId}
          >
            Add
          </button>
          <button
            type="button"
            className="inline-flex justify-center rounded-md border border-transparent bg-green-100 ml-2 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() => addSuiteMutation.mutate({ suiteId: newTaskSuiteId, claim: true })}
            disabled={!newTaskSuiteId}
          >
            Add & Claim
          </button>
        </div>
      </div>
    </div>
  );
}
