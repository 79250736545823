import { useState } from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { QaTeamSelector } from './Settings/QATeamSelector';
import { AddSuiteInvestigationTask } from './Settings/AddSuiteInvestigationTask';
import { Shifts } from './Settings/Shifts';
// import { TeamConfiguration } from './TeamConfiguration';

export function TeamSettings() {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <>
      <IconButton size="small" onClick={handleOpen} sx={{ padding: 0, marginLeft: '10px' }}>
        <SettingsIcon sx={{ fontSize: 20 }} />
      </IconButton>

      <Dialog
        open={isOpen}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            position: 'fixed',
            top: 0,
            m: 0,
            borderRadius: '0 0 16px 16px',
            maxHeight: '90vh',
          },
        }}
      >
        <DialogTitle>Team Settings</DialogTitle>
        <DialogContent>
          <div style={{ paddingTop: '16px', paddingBottom: '16px' }}>
            {/* <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>Team Configuration</AccordionSummary>
              <AccordionDetails>
                <TeamConfiguration />
              </AccordionDetails>
            </Accordion> */}

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>Team Selection</AccordionSummary>
              <AccordionDetails>
                <div className="flex justify-between items-start w-full gap-4">
                  <div className="w-1/2">
                    <QaTeamSelector />
                  </div>
                  <div className="w-1/2">
                    <Shifts />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>Add Suite Investigation Task</AccordionSummary>
              <AccordionDetails>
                <AddSuiteInvestigationTask />
              </AccordionDetails>
            </Accordion>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
