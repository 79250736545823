import { useCommunications } from '../../contexts/CommunicationsContext';
import { BugDataForContextCloud } from '../../contexts/CommunicationsContextTypes';
import { TooltipProvider, TooltipTrigger, Tooltip } from '@/components/ui/tooltip';
import { Button } from '@/components/ui/button';
import { TooltipContent } from '@radix-ui/react-tooltip';
import { calculateElapsedTime } from '../Cards/helpersInvestigation';
import { formatDate } from '@/components/MessageHQ/utils/date';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { Bug as BugIcon, Loader } from 'lucide-react';
import { LoadingMessage } from '../LoadingMessage';
import { MainContentContainer } from './RecentSuitesResources';

export function RecentBugsList() {
  const { recentBugsForCustomer, isLoading } = useCommunications();
  if (isLoading.bugs) return <LoadingMessage resource="bugs" />;
  if (!recentBugsForCustomer?.length && !isLoading.bugs) return <p>No recent bugs found</p>;

  const sortedBugsByLatestAndOpenFirst = recentBugsForCustomer?.sort((a, b) => {
    if (a.status === 'resolved' && b.status === 'inProgress') return 1;
    if (a.status === 'inProgress' && b.status === 'resolved') return -1;
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

  return (
    <div className="grid grid-cols-1 gap-2 pt-2">
      {sortedBugsByLatestAndOpenFirst.map((bug) => {
        const timestamp = Math.floor(new Date(bug.createdAt).getTime() / 1000).toString();
        const elapsedTime = calculateElapsedTime(timestamp);
        const formattedDate = formatDate(timestamp);
        const bugStatusClass = bug.status === 'resolved' ? 'bg-bug-status-closed' : 'bg-bug-status-open';
        const bugUrl = `https://app.qawolf.com/${bug.teamSlug}/bug-reports/${bug.id}`;
        const suiteUrl = `https://app.qawolf.com/${bug.teamSlug}/environments/${bug.environmentId}/runs/${bug.suiteId}`;

        return (
          <TooltipProvider key={bug.id}>
            <Tooltip disableHoverableContent={false} delayDuration={0}>
              <DropdownMenu>
                <TooltipTrigger asChild>
                  <DropdownMenuTrigger asChild>
                    <Button variant="outline" className="justify-start text-gray-700 hover:text-gray-900 cursor-pointer w-full" asChild>
                      <span className="truncate block">
                        <div className={`w-2 h-2 rounded-full ${bugStatusClass}`} />
                        <span className="truncate block w-full">{bug.name}</span>
                        <span className="text-xs text-gray-500">{elapsedTime} ago</span>
                      </span>
                    </Button>
                  </DropdownMenuTrigger>
                </TooltipTrigger>
                <DropdownMenuContent className="w-48 z-[9999]" align="start">
                  <DropdownMenuItem className="cursor-pointer" onClick={() => window.open(bugUrl, '_blank')}>
                    <BugIcon className="w-4 h-4 text-bug-status-open" />
                    See Report
                  </DropdownMenuItem>
                  <DropdownMenuItem className="cursor-pointer" onClick={() => window.open(suiteUrl, '_blank')}>
                    <Loader className="w-4 h-4 text-suite" />
                    See Suite
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>

              <TooltipContent side="left" align="start" style={{ width: '350px' }} className="bg-gray-600 border-none shadow-xl z-[9999] mr-2 p-3">
                <div className="w-full">
                  <RecentBugTooltipContent bug={bug} formattedDate={formattedDate} elapsedTime={elapsedTime} />
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        );
      })}
    </div>
  );
}

function RecentBugTooltipContent({ bug, formattedDate, elapsedTime }: { bug: BugDataForContextCloud; formattedDate: string; elapsedTime: string }) {
  const descriptionFormatted = bug.description;

  // Bug status mapping for classnames
  const bugStatusClass = bug.status === 'resolved' ? 'bg-bug-status-closed' : 'bg-bug-status-open';
  const bugStatusText = bug.status === 'resolved' ? 'Closed' : 'Open';

  return (
    <div className="space-y-2">
      {/* Header */}
      <div className="flex items-center justify-between gap-2">
        <span className="text-gray-300">{bug.name}</span>
        <span className={`text-xs px-2 py-0.5 rounded-full whitespace-nowrap flex-shrink-0 ${bugStatusClass}`}>{bugStatusText}</span>
      </div>

      {/* Main Content */}
      <div className="text-white text-sm font-medium">
        <p className="text-gray-300 text-xs font-medium">Description</p>
        <p className="break-all whitespace-pre-wrap overflow-hidden max-h-[300px]">
          {descriptionFormatted.replace(/(https?:\/\/[^\s]+)/g, (url: string) => url.slice(0, 30) + '...')}
        </p>
      </div>
      {bug.environmentName && <MainContentContainer title="Environment" value={bug.environmentName} />}
      {bug.triggerName && <MainContentContainer title="Trigger" value={bug.triggerName} />}

      {/* Footer Info */}
      <div className="flex flex-col text-xs text-gray-300 pt-1 space-y-1">
        <span className="truncate">Created on {formattedDate}</span>
        <span className="truncate">{elapsedTime} ago</span>
      </div>
    </div>
  );
}
