import _ from 'lodash';
import dayjs from 'dayjs';

import { useMemo } from 'react';
import { useQueries } from '@tanstack/react-query';
import { Text } from 'recharts';

import { sendGetRequest } from '../../utils/network';
import { taskTypeMap } from './types.ts';

export const chartColors = Array(75)
  .fill(null)
  .map(() => generateColor());

export function generateColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const storageMap = {
  qae: { investigation: 'investigationChartVisibility', creation: 'creationChartVisibility' },
  team: { investigation: 'teamInvestigationChartVisibility', creation: 'teamCreationChartVisibility' },
  pack: { investigation: 'packInvestigationChartVisibility', creation: 'packCreationChartVisibility' },
};

export const dataTypeMap = {
  qae: 'qaeTasksByWeekAndType',
  team: 'teamTasksByWeekAndType',
  pack: 'packTasksByWeekAndType',
};

/**
 *
 * @param {import('./types.js').TeamInsightsFilter} filters
 * @returns {Promise<import('./types.js').TeamCreationOutlineMaintenanceTasksInsights>} creationInsights
 */
export async function getCreationOutlineMaintenanceInsights(filters) {
  const { entity, types } = filters;

  // build URL with params and query string
  let url = `/task-insights/${entity.type}/${entity?.data.id || entity?.data.name}`;
  if (types.length) {
    url += '?';
    types.forEach((type) => {
      url += `types[]=${type}&`;
    });
    url = url.slice(0, -1);
  }

  url += `&gte=${encodeURIComponent(filters.gte)}&lte=${encodeURIComponent(filters.lte)}`;

  // GET task insights data with built URL
  const taskInsights = await sendGetRequest(url);

  return taskInsights.data;
}

/**
 * Gets investigation insights by filter
 * @param {import('./types.js').QAEInsightsFilter | import('./types.js').TeamInsightsFilter | import('./types.js').PackInsightsFilter} filters
 * @returns {Promise<import('./types.js').InvestigationData>}
 */
export async function getInvestigationInsights(filters) {
  const { entity, investigationOptions } = filters;

  // build URL with params and query string
  // @ts-ignore - linter hates me but this is safe
  let url = `/investigation-insights/${entity.type}/${entity?.data.id || entity?.data.name}`;
  url += `?gte=${encodeURIComponent(filters.gte)}&lte=${encodeURIComponent(filters.lte)}&includeRuns=true`;

  // add investigation options to URL search params
  if (investigationOptions && Object.values(investigationOptions).some(Boolean)) {
    url += '&';
    const validOptions = _.pickBy(investigationOptions, Boolean);
    for (const option of Object.keys(validOptions)) {
      url += `${option}=${validOptions[option]}&`;
    }
    url = url.slice(0, -1);
  }

  // GET investigation data with built URL
  const investigationInsights = await sendGetRequest(url);

  return investigationInsights.data;
}

/**
 * Gets investigation insights aggregated with tracked time by filter
 * @param {import ('./types.js').QAEInsightsFilter | import('./types.js').TeamInsightsFilter | import('./types.js').PackInsightsFilter} filters
 * @returns {Promise<import('./types.js').InvestigationAggregateResponse>}
 */
export async function getGeneralInvestigationInsights(filters) {
  const { entity } = filters;
  // @ts-ignore - linter hates me but this is safe
  let url = `/general-insights/investigation/${entity.type}/${entity?.data.id || entity?.data.name}`;
  url += `?gte=${encodeURIComponent(filters.gte)}&lte=${encodeURIComponent(filters.lte)}`;

  const generalInvestigationInsightsResponse = await sendGetRequest(url);

  /** @type {import('./types.js').InvestigationAggregateResponse} */
  return generalInvestigationInsightsResponse.data;
}

/**
 *
 * @param {import('./types.js').QAEInsightsFilter | import('./types.js').TeamInsightsFilter | import('./types.js').PackInsightsFilter} filters
 * @returns {Promise<import('./types.js').TaskSnapshotAggregateResponse>}
 */
export async function getGeneralTaskSnapshotInsights(filters) {
  const { entity } = filters;
  // @ts-ignore - linter hates me but this is safe
  let url = `/general-insights/creation/${entity.type}/${entity?.data.id || entity?.data.name}`;
  url += `?gte=${encodeURIComponent(filters.gte)}&lte=${encodeURIComponent(filters.lte)}`;

  url += `&types=${filters.types.join('&types=')}`;

  const generalTaskSnapshotInsightsResponse = await sendGetRequest(url);

  /** @type {import('./types.js').TaskSnapshotAggregateResponse} */
  return generalTaskSnapshotInsightsResponse.data;
}

/**
 * Type guard for investigation aggregate response (checks if instance of PackInvestigationAggregateResponse)
 * @param {import('./types.js').InvestigationAggregateResponse | import('./types.js').TaskSnapshotAggregateResponse} response
 * @returns {boolean}
 */
export const isPackAggregateResponse = (response) => {
  return response && 'pack' in response && 'packTeams' in response;
};

/**
 * Type guard for investigation aggregate response (checks if instance of TeamInvestigationAggregateResponse)
 * @param {import('./types.js').InvestigationAggregateResponse | import('./types.js').TaskSnapshotAggregateResponse} response
 * @returns {boolean}
 */
export const isTeamAggregateResponse = (response) => {
  return response && 'team' in response;
};

/**
 * Type guard for investigation aggregate response (checks if instance of QAEInvestigationAggregateResponse)
 * @param {import('./types.js').InvestigationAggregateResponse | import('./types.js').TaskSnapshotAggregateResponse} response
 * @returns {boolean}
 */
export const isQAEAggregateResponse = (response) => {
  return response && 'qae' in response;
};

/**
 * @param {object[]} teams
 * @param {import('./types.js').QAEInsightsFilter | import('./types.js').TeamInsightsFilter | import('./types.js').PackInsightsFilter} filters
 * @returns {import("@tanstack/react-query").UseQueryResult<import("./types.js").TimeTrackingTeamResponse>[]}
 */
export function useTimeTrackingInsights(teams, filters) {
  const queries = useQueries({
    queries: useMemo(
      () =>
        teams.map((team) => ({
          queryKey: ['timeTrackingInsights', team.teamName, filters.gte, filters.lte],
          queryFn: async () => {
            const teamUrl = `/time-tracking-insights/team/${team.teamName}?gte=${encodeURIComponent(filters.gte)}&lte=${encodeURIComponent(
              filters.lte,
            )}`;
            const response = await sendGetRequest(teamUrl);
            return response.data;
          },
          staleTime: 5 * 60 * 1000,
          cacheTime: 15 * 60 * 1000,
          refetchOnWindowFocus: false,
          retry: 2,
        })),
      [teams, filters.gte, filters.lte],
    ),
  });

  return queries;
}

/**
 *
 * @param {object} data
 * @returns {import('./types.js').SumObject}
 */
export function sumAllCreationOutliningMaintenanceTasks(data) {
  const dataByType = _.groupBy(data, 'type');

  const sumsByType = /** @type {import('./types.js').SumsByType} */ ({});

  for (const [type, typeData] of Object.entries(dataByType)) {
    if (!sumsByType[type]) {
      sumsByType[type] = {
        sumTasks: _.sumBy(typeData, (obj) => parseFloat(obj['total_tasks'])),
        sumTests: _.sumBy(typeData, (obj) => parseFloat(obj['total_steps'])),
      };
    } else {
      sumsByType[type].sumTasks += _.sumBy(typeData, (obj) => parseFloat(obj['total_tasks']));
      sumsByType[type].sumTests += _.sumBy(typeData, (obj) => parseFloat(obj['total_steps']));
    }
  }

  const sumTasks = _.sumBy(data, (obj) => parseFloat(obj['total_tasks']));
  const sumTests = _.sumBy(data, (obj) => parseFloat(obj['total_steps']));

  const sortedSumsByType = Object.keys(sumsByType)
    .sort((a, b) => b.localeCompare(a))
    .reduce((acc, key) => {
      acc[key] = sumsByType[key];
      return acc;
    }, /** @type {import('./types.js').SumsByType} */ ({}));

  return { sumTasks, sumTests, sumsByType: sortedSumsByType };
}

/**
 *
 * @param {*} dataArr
 * @returns {number}
 */
export function computeDataMedian(dataArr) {
  const sortedArr = dataArr.sort((a, b) => a - b);
  const mid = Math.floor(sortedArr.length / 2);
  return sortedArr.length % 2 === 0 ? (sortedArr[mid - 1] + sortedArr[mid]) / 2 : sortedArr[mid];
}

/**
 *
 * @param {number[]} numbers
 * @returns {number}
 */
export const computeMedian = (numbers) => {
  if (!numbers.length) return 0;

  const sortedNumbers = numbers.slice().sort((a, b) => a - b);
  const mid = Math.floor(sortedNumbers.length / 2);

  return sortedNumbers.length % 2 !== 0 ? sortedNumbers[mid] : (sortedNumbers[mid - 1] + sortedNumbers[mid]) / 2;
};

/**
 * Calculates the average and median number of investigation tasks per week for a team.
 * @param {*} data
 * @param {import('./types.js').User[]} teamMembers
 * @returns {{[key: string]: { mean: number, median: number }}} weeklyAveragesAndMedians
 */
export function computeAverageAndMedianInvestigationTasksPerWeek(data, teamMembers) {
  const weeklyAveragesAndMedians = /**@type {{[key: string]: { mean: number, median: number }}}*/ ({});
  for (const week of Object.keys(data)) {
    const weeklyInvestigation = data[week];
    const investigationTasksPerUser = _.countBy(weeklyInvestigation, 'taskClaimedBy');
    const userWithInvestigationThisWeek = Object.keys(investigationTasksPerUser);
    for (const member of teamMembers) {
      if (!userWithInvestigationThisWeek.includes(member.email)) {
        investigationTasksPerUser[member.email] = 0;
      }
    }
    const allCounts = Object.values(investigationTasksPerUser);

    const mean = Math.round(_.mean(allCounts) * 100) / 100;
    const median = Math.round(computeMedian(allCounts) * 100) / 100;
    weeklyAveragesAndMedians[week] = { mean, median };
  }

  return weeklyAveragesAndMedians;
}

/**
 * Calculates the average and median number of assisted investigation tasks per week for a team.
 * @param {*} data
 * @param {import('./types.js').User[]} teamMembers
 * @returns {{[key: string]: { mean: number, median: number }}} weeklyAveragesAndMedians
 */
export function computeAverageAndMedianAssistedInvestigationTasksPerWeek(data, teamMembers) {
  const weeklyAveragesAndMedians = /**@type {{[key: string]: { mean: number, median: number }}}*/ ({});
  const teamMembersEmails = teamMembers.map((member) => member.email);

  // iterate through the week keys in the data
  for (const week of Object.keys(data)) {
    const weeklyInvestigation = data[week];
    // get investigation tasks assisted per user for the week (user is in any index of weeklyInvestigation[idx].supportedBy array)
    const investigationTasksPerUser = _.countBy(_.flatMap(weeklyInvestigation, (t) => t.supportedBy));
    const allCounts = _(investigationTasksPerUser)
      .pickBy((v, k) => teamMembersEmails.includes(k))
      .values()
      .value();

    const mean = Math.round(_.mean(allCounts) * 100) / 100;
    const median = Math.round(computeMedian(allCounts) * 100) / 100;
    weeklyAveragesAndMedians[week] = { mean, median };
  }

  return weeklyAveragesAndMedians;
}

/**
 * Groups tasks by the week of the year.
 * @param {import('./types.js').InvestigationTask[]} data
 * @returns {{[key: string]: import('./types.js').InvestigationTask[]}} groupedData
 */
export function groupInvestigationTasksByWeek(data) {
  // group all data by week
  const groupedData = _.groupBy(data, (task) => {
    const startTime = dayjs(task.startTime);
    const week = startTime.week();
    const year = startTime.year();
    return `${week} ${year}`;
  });

  return groupedData;
}

export function groupAssistedInvestigationTasksByWeek(data) {
  const groupedData = _.groupBy(data, (task) => {
    const startTime = dayjs(task.startTime);
    const week = startTime.week();
    const year = startTime.year();
    return `${week} ${year}`;
  });

  return groupedData;
}

/**
 * Aggregates extra computed investigation data for each week.
 * @param {object} data
 * @param {{[key: string]: import('./types.js').InvestigationTask[]}} data.primaryData
 * @param {{[key: string]: import('./types.js').InvestigationTask[]}} [data.assistedData]
 * @param {boolean} [isTeam=false]
 * @returns {{[key: string]: import('./types.js').WeeklyInvestigationAggregates}} groupedDataAggregates
 */
export function computeInvestigationAggregatesByWeek(data, isTeam = false) {
  const groupedDataAggregates = /** @type {{[key: string]: import('./types.js').WeeklyInvestigationAggregates}} */ ({});
  const { primaryData, assistedData } = data;

  for (const weekOfYear of Object.keys(primaryData)) {
    const weeklySuitesWithQAEActivity = primaryData[weekOfYear].filter(hasQAEActivity);
    const weeklyPrimarySuiteIds = new Set(weeklySuitesWithQAEActivity.map((suite) => suite.id));

    const weeklyAssistedSuitesWithQAEActivity = (assistedData?.[weekOfYear] || []).filter(
      (suite) => !weeklyPrimarySuiteIds.has(suite.id) && hasQAEActivity(suite),
    );

    const allSuites = [...weeklySuitesWithQAEActivity, ...weeklyAssistedSuitesWithQAEActivity];

    groupedDataAggregates[weekOfYear] = {
      sumFailures: calculateSum(allSuites, sumFailures),
      sumTests: calculateSum(allSuites, sumTests),
      sumInvestigatedAsBug: calculateSum(allSuites, 'numberOfRunsTriagedAsBug'),
      sumInvestigatedAsMaint: calculateSum(allSuites, 'numberOfRunsTriagedAsMaintenance'),
      sumPassedOnFlake: calculateSum(allSuites, 'numberOfRunsPassingOnQaeFlake'),
      sumPassedOnFix: calculateSum(allSuites, 'numberOfRunsPassingOnQaeFix'),
      avgMinsUntilInvestigationStart: calculateMean(allSuites, 'minutesUntilTriageStarted'),
      avgMinsUntilFirstQaeAttempt: calculateMean(allSuites, 'minutesUntilFirstQaeAttempt'),
      avgInvestigationTime: calculateMean(allSuites, 'triageTimeInMinutes'),
    };

    if (isTeam) {
      const uniqueQAEEmails = _.uniq(allSuites.map((suite) => suite.taskClaimedBy));
      const numUniqueQAEs = uniqueQAEEmails.length || 1; // Avoid division by zero

      groupedDataAggregates[weekOfYear] = {
        ...groupedDataAggregates[weekOfYear],
        ...calculateTeamAveragesPerQAE(allSuites, numUniqueQAEs),
      };
    }
  }

  return groupedDataAggregates;
}

/**
 * Checks if a suite has QAE activity.
 * @param {object} suite - The suite to check.
 * @returns {boolean} True if the suite has QAE activity, otherwise false.
 */
function hasQAEActivity(suite) {
  return (
    suite.numberOfRunsPassingOnQaeFlake ||
    suite.numberOfRunsPassingOnQaeFix ||
    suite.numberOfRunsTriagedAsDoNotInvestigate ||
    suite.numberOfRunsTriagedAsBug ||
    suite.numberOfRunsTriagedAsMaintenance
  );
}

/**
 * Calculates the sum of failures for a suite.
 * @param {object} suite - The suite to calculate the sum for.
 * @returns {number} The sum of failures.
 */
function sumFailures(suite) {
  return (
    suite.numberOfRunsPassingOnQaeFlake +
    suite.numberOfRunsPassingOnQaeFix +
    suite.numberOfRunsTriagedAsDoNotInvestigate +
    suite.numberOfRunsTriagedAsBug +
    suite.numberOfRunsTriagedAsMaintenance
  );
}

/**
 * Calculates the sum of tests for a suite.
 * @param {object} suite - The suite to calculate the sum for.
 * @returns {number} The sum of tests.
 */
function sumTests(suite) {
  return _.sumBy(suite.runs, (r) => _.sumBy(r.workflowTests, (wft) => wft.length));
}

/**
 * Calculates the sum of a specific key for a list of suites.
 * @param {object[]} suites - The list of suites.
 * @param {string|((value: any) => number)} key - The key or function to sum by.
 * @returns {number} The sum of the specified key.
 */
function calculateSum(suites, key) {
  return _.sumBy(suites, key);
}

/**
 * Calculates the mean of a specific key for a list of suites.
 * @param {object[]} suites - The list of suites.
 * @param {string} key - The key to calculate the mean for.
 * @returns {number} The mean of the specified key.
 */
function calculateMean(suites, key) {
  return Math.round(_.meanBy(suites, key) * 100) / 100;
}

/**
 * Calculates team averages per QAE.
 * @param {object[]} suites - The list of suites.
 * @param {number} numUniqueQAEs - The number of unique QAEs.
 * @returns {object} The team averages per QAE.
 */
function calculateTeamAveragesPerQAE(suites, numUniqueQAEs) {
  return {
    avgFailuresPerQAE: Math.round((sumFailures(suites) / numUniqueQAEs) * 100) / 100,
    avgTestsPerQAE: Math.round((sumTests(suites) / numUniqueQAEs) * 100) / 100,
    avgInvestigationAsBugPerQAE: Math.round((calculateSum(suites, 'numberOfRunsTriagedAsBug') / numUniqueQAEs) * 100) / 100,
    avgInvestigationAsMaintPerQAE: Math.round((calculateSum(suites, 'numberOfRunsTriagedAsMaintenance') / numUniqueQAEs) * 100) / 100,
    avgPassedOnFlakePerQAE: Math.round((calculateSum(suites, 'numberOfRunsPassingOnQaeFlake') / numUniqueQAEs) * 100) / 100,
    avgPassedOnFixPerQAE: Math.round((calculateSum(suites, 'numberOfRunsPassingOnQaeFix') / numUniqueQAEs) * 100) / 100,
  };
}

export const buildQAEChartDataFromInsights = (insights) => {
  const chartData = [];

  for (const week of Object.keys(insights)) {
    const weekObj = {
      name: week,
    };

    for (const typeGroup of Object.keys(taskTypeMap)) {
      const typeGroupData = insights[week].filter((x) => x.type === typeGroup) || [];
      const sumTasks = _.sumBy(typeGroupData, (obj) => parseFloat(obj['total_tasks']));
      const sumTests = _.sumBy(typeGroupData, (obj) => parseFloat(obj['total_steps']));
      weekObj[`${taskTypeMap[typeGroup]} - ${typeGroup === 'testCreation' ? 'Workflows' : 'Tasks'}`] = sumTasks || 0;
      weekObj[`${taskTypeMap[typeGroup]} - Tests`] = sumTests || 0;
    }

    chartData.push(weekObj);
  }

  return chartData;
};

export const buildTeamChartDataFromInsights = (insights) => {
  const chartData = [];

  for (const week of Object.keys(insights)) {
    const weekObj = {
      name: week,
    };

    for (const typeGroup of Object.keys(taskTypeMap)) {
      const typeGroupData = insights[week].filter((x) => x.type === typeGroup) || [];
      const sumTasks = _.sumBy(typeGroupData, (obj) => parseFloat(obj['total_tasks']));
      const sumTests = _.sumBy(typeGroupData, (obj) => parseFloat(obj['total_steps']));
      weekObj[`Team ${taskTypeMap[typeGroup]} - ${typeGroup === 'testCreation' ? 'Workflows' : 'Tasks'}`] = sumTasks || 0;
      weekObj[`Team ${taskTypeMap[typeGroup]} - Tests`] = sumTests || 0;
    }

    chartData.push(weekObj);
  }

  return chartData;
};

export const buildPackChartDataFromInsights = (insights) => {
  const chartData = [];

  for (const week of Object.keys(insights)) {
    const weekObj = {
      name: week,
    };

    for (const typeGroup of Object.keys(taskTypeMap)) {
      const typeGroupData = insights[week].filter((x) => x.type === typeGroup) || [];
      const sumTasks = _.sumBy(typeGroupData, (obj) => parseFloat(obj['total_tasks']));
      const sumTests = _.sumBy(typeGroupData, (obj) => parseFloat(obj['total_steps']));
      weekObj[`Pack ${taskTypeMap[typeGroup]} - ${typeGroup === 'testCreation' ? 'Workflows' : 'Tasks'}`] = sumTasks || 0;
      weekObj[`Pack ${taskTypeMap[typeGroup]} - Tests`] = sumTests || 0;
    }

    chartData.push(weekObj);
  }

  return chartData;
};

export const getDisplayName = (name) => {
  const words = _.split(name, ' ');
  if (words.length > 1) {
    return `${words[0]} ${words[1][0]}.`;
  }

  return name;
};

export const handleChangeTeamOnPack = (teamName, setFilters) => {
  setFilters((prevFilters) => ({
    ...prevFilters,
    team: {
      ...prevFilters.team,
      entity: {
        type: 'team',
        data: {
          name: teamName,
        },
      },
    },
  }));
};

/**
 * Renders a custom tick for radar chart to offset text
 * @param {object} props
 * @param {object} props.payload
 * @param {number} props.x
 * @param {number} props.y
 * @param {number} props.cx
 * @param {number} props.cy
 * @returns {import('react').JSX.Element}
 */
export function renderPolarAngleAxis({ payload, x, y, cx, cy, ...rest }) {
  let newY = y + (y - cy) / 6;
  let newX = x + (x - cx) / 6;
  return (
    <Text {...rest} verticalAnchor="middle" y={newY} x={newX}>
      {payload.value}
    </Text>
  );
}

/**
 * Computes a percentile of a given array
 * @param {number[]} array
 * @param {number} percentile
 * @returns {number}
 */
export function computePercentile(array, percentile) {
  // sort provided values array
  const sortedValues = _.sortBy(array);

  // find exact index of percentile
  const index = (percentile / 100) * (sortedValues.length - 1);

  // find lower and upper index (since index may not be a round number)
  const lowerIndex = Math.floor(index);
  const upperIndex = Math.ceil(index);

  // get lower and upper values
  const lowerValue = sortedValues[lowerIndex];
  const upperValue = sortedValues[upperIndex];

  // compute percentile by linearly interpolating between lower and upper values using the decimal part of the index
  return Math.round((lowerValue + (upperValue - lowerValue) * (index - lowerIndex)) * 100) / 100;
}

/**
 * Protects the team filters from stale state from margin/general comp tab
 * @param {import('./types.js').TeamInsightsFilter} filters
 * @returns {import('./types.js').TeamInsightsFilter} filters
 */
export function protectFilters(filters) {
  let teamFilterName = filters.entity.data.name;
  teamFilterName =
    teamFilterName === 'all' || !teamFilterName ? 'Hammerheads' : teamFilterName.includes(',') ? teamFilterName.split(',')[0] : teamFilterName;

  filters.entity.data.name = teamFilterName;

  return filters;
}

/**
 * Filters QAEs based on the search term.
 * @param {string} searchTerm - The current search term entered by the user.
 * @param {import('./types.js').User[]} members - List of team members to filter.
 * @returns {import('./types.js').User[]} - The filtered list of QAEs.
 */
export function filterQAEsBySearchTerm(searchTerm, members) {
  // If no search term, return all members
  if (searchTerm === '') {
    return members;
  }

  // Filter members by name matching the search term
  return members.filter((member) => member.name.toLowerCase().includes(searchTerm.toLowerCase()));
}
