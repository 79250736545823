import { Menu, Transition } from '@headlessui/react';
import {
  ArrowPathRoundedSquareIcon,
  ClockIcon,
  CommandLineIcon,
  EllipsisHorizontalIcon,
  InboxArrowDownIcon,
  ReceiptPercentIcon,
  ReceiptRefundIcon,
  RocketLaunchIcon,
  XMarkIcon,
  UserGroupIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';

import { parseISO, differenceInMinutes } from 'date-fns';
import { Fragment } from 'react';
import { getTeamInfo } from '../../../utils/helpers';
import { classNames, getBackgroundColor, getElapsed } from '../helpers';
import moment from 'moment/moment';

let teamLeadsFromCache = getTeamInfo();

/**
 * Renders a BugCard component.
 * @param {object} props - The component props.
 * @param {import('../../../types').QAWTask} props.task - The information of the bug card.
 * @param {Function} props.toggleClaimed - The function to toggle the claimed status of the bug card.
 * @param {object} props.user - The current user object.
 * @param {boolean} [props.nested=false] - Indicates if the bug card is nested.
 * @param {Function} props.suiteAction - The function to perform an action on the bug card.
 * @returns {import('react').JSX.Element} The BugCard component.
 */
export function BugCard({ task, toggleClaimed, user, nested = false, suiteAction }) {
  const taskNotes = JSON.parse(task?.notes);
  let moment_time = moment(taskNotes.latest.completedAt).add(-8, 'hour').format('YYYY-MM-DDTHH:mm:ss');

  let parsedInfo = {
    id: task.id,
    title: `${task.team.name}`,
    bugReportName: `${taskNotes.bugReport.name}`,
    type: task.type,
    subType: 'bug',
    thisSuiteAge: getElapsed(parseISO(moment_time)),
    assignedToId: task?.assignedTo?.id, // TODO(Eric)
    assignedToName: task?.assignedTo?.name || task?.assignedTo?.email,
    assignedToAvatar: task.assignedTo?.avatarUrl,
    priority: task.priority,
    isCurrentUser: user.email === task?.assignedTo.email,
    ownerId: task.qaLead.id,

    recentBugRunUrl: `https://app.qawolf.com/runs/${taskNotes.latest.runId}/attempt/${taskNotes.latest.runAttemptId}`,
    failingLineMessage: `Original Failing Line: ${taskNotes.baselineLog.failingLine}. Current Failing Line: ${taskNotes.recentLog.failingLine}.`,
    originalMessage: taskNotes.baselineLog?.errorMessage?.split('====')[0] || 'No Error Message',
    latestMessage: taskNotes.recentLog?.errorMessage?.split('====')[0] || 'No Error Message',
    revalidateReason: taskNotes.reason,
  };

  let unclaimButton;
  if (parsedInfo.assignedToName && user.qawId === parsedInfo.assignedToId) {
    unclaimButton = (
      <>
        <button
          className="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-red-700 bg-blue-50 ring-green-600/20 hover:ring-2 hover:bg-red-100"
          onClick={() => suiteAction(parsedInfo, '/done')}
          title="Update the baseline for the bug."
        >
          Still a Bug
        </button>
        <button
          className="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-green-700 bg-blue-50 ring-green-600/20 hover:ring-2 hover:bg-green-100"
          onClick={() => suiteAction(parsedInfo, '/ignore')}
          title="No longer a bug, changes happened to the WF to capture this in QAW."
        >
          Resolved
        </button>
        <button
          className="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-blue-700 bg-blue-50 ring-blue-600/20"
          onClick={() => toggleClaimed(parsedInfo, false)}
        >
          Unclaim
        </button>
      </>
    );
  }

  let middleOption = (
    <Menu.Item>
      {({ active }) => (
        <button
          type="button"
          className={classNames(active ? 'bg-gray-100' : '', 'flex px-3 py-1 text-sm leading-6 text-yellow-900')}
          onClick={() => suiteAction(parsedInfo, '/urgent')}
        >
          <RocketLaunchIcon className="h-5 w-5 pt-1 mr-1" /> Urgent
        </button>
      )}
    </Menu.Item>
  );
  if (parsedInfo.assignedToName && user.qawId !== parsedInfo.assignedToId) {
    middleOption = (
      <Menu.Item>
        {({ active }) => (
          <button
            type="button"
            className={classNames(active ? 'bg-gray-100' : '', 'flex px-3 py-1 text-sm leading-6 text-yellow-900')}
            onClick={() => suiteAction(parsedInfo, '/claim')}
          >
            <InboxArrowDownIcon className="h-5 w-5 pt-1 mr-1" /> Claim
          </button>
        )}
      </Menu.Item>
    );
  }

  let timeSinceClaimed;
  let numberOfHandoffs;

  let overAnHourTriage = false;
  if (!nested) {
    let now = new Date();
    numberOfHandoffs = task.handovers;

    // How long has this particular task has been claimed for? Has it been over an hour?
    if (task.lastAssignedAt) {
      let isoTime = parseISO(task.lastAssignedAt);
      let diffInMinutes = differenceInMinutes(now, isoTime);
      overAnHourTriage = diffInMinutes > 60;
      timeSinceClaimed = getElapsed(isoTime);
    }
  }

  // Update lead info
  let leadInfo;
  try {
    leadInfo = teamLeadsFromCache.filter((x) => x.qawId === parsedInfo.ownerId)[0];
  } catch {
    leadInfo = {
      imageUrl: '',
      teamName: 'Unknown',
      name: 'Unknown',
    };
  }

  return (
    <div className="rounded-xl border border-gray-200 bg-white">
      <div className={classNames('rounded-t-xl flex items-center gap-x-4 border-b border-gray-900/5 p-4', getBackgroundColor(parsedInfo))}>
        <div className="w-full grid grid-cols-3">
          <div className="overflow-hidden flex justify-start text-sm font-medium col-span-1">
            <img
              className="h-6 w-6 rounded-full mr-2"
              src={leadInfo?.imageUrl}
              alt={`${leadInfo?.teamName} Team - ${leadInfo?.name}`}
              title={`${leadInfo?.teamName} Team - ${leadInfo?.name}`}
            />
            <p className="truncate flex ext-gray-900 max-w-2/5" title={`${parsedInfo.title} | ID ${parsedInfo.id}`}>
              {parsedInfo.title}
            </p>

            <p className="truncate max-w-1/5 ml-1 text-gray-500" title={parsedInfo.bugReportName}>
              {parsedInfo.bugReportName}
            </p>
          </div>

          <div className="flex justify-end col-span-2 xl:gap-1 2xl:gap-2">
            {parsedInfo.assignedToName ? (
              <div className="flex justify-right xl:gap-1 2xl:gap-2">
                <img
                  className="h-6 w-6 rounded-full"
                  src={parsedInfo.assignedToAvatar}
                  alt={parsedInfo.assignedToName}
                  title={parsedInfo.assignedToName}
                />
                <h3 className={classNames('truncate text-sm', parsedInfo.assginedToIsGuess ? 'italic text-gray-700' : 'font-medium text-gray-900')}>
                  {parsedInfo.assignedToName}
                </h3>
              </div>
            ) : (
              <>
                <button
                  className="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-blue-700 bg-blue-50 ring-blue-600/20 hover:ring-2 hover:bg-blue-100"
                  onClick={() => toggleClaimed(parsedInfo, true, parsedInfo.recentBugRunUrl)}
                >
                  Claim
                </button>
              </>
            )}
            {unclaimButton}
            <a
              href={parsedInfo.recentBugRunUrl}
              target="_blank"
              rel="noreferrer"
              className="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset bg-gray-50 text-gray-700 ring-gray-600/20 hover:ring-2 hover:bg-gray-100"
            >
              See
            </a>
            <Menu
              as="div"
              className="relative rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset bg-gray-50 text-gray-700 ring-gray-600/20 hover:ring-2 hover:bg-gray-100"
            >
              <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                <EllipsisHorizontalIcon className="h-4 w-4" aria-hidden="true" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-0.5 w-24 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                  {middleOption}
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="button"
                        className={classNames(active ? 'bg-gray-100' : '', 'flex px-3 py-1 text-sm leading-6 text-red-600')}
                        onClick={() => suiteAction(parsedInfo, '/ignore')}
                      >
                        <XMarkIcon className="h-5 w-5 pt-1 mr-1" /> Ignore
                      </button>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
      <dl className="-my-3 divide-y divide-gray-100 p-4 text-sm leading-6">
        <div className="py-2">
          <dt className="flex justify-between gap-x-3 text-gray-500 -ml-1">
            <div className="w-2/3">
              <div className="flex w-full">
                <div className="w-1/10">
                  <CommandLineIcon className="mt-0.5 w-5 h-5 text-red-500" />
                </div>
                <p className="truncate ml-2 text-gray-500" title={parsedInfo.failingLineMessage}>
                  {parsedInfo.failingLineMessage}
                </p>
              </div>

              <div className="flex w-full pt-2">
                <div className="w-1/10">
                  <ReceiptRefundIcon className="mt-0.5 w-5 h-5 text-red-500" title="Original Error" />
                </div>
                <p className="truncate ml-2 text-gray-500" title={parsedInfo.originalMessage}>
                  {parsedInfo.originalMessage}
                </p>
              </div>

              <div className="flex pt-2 overflow-hidden">
                <div className="w-1/10">
                  <ReceiptPercentIcon className="mt-0.5 w-5 h-5 text-amber-500" title="Current Error" />
                </div>
                <p className="w-9/10 truncate ml-2 text-gray-500" title={parsedInfo.latestMessage}>
                  {parsedInfo.latestMessage}
                </p>
              </div>
            </div>

            <div className="flex text-gray-500">
              <ClockIcon className={classNames('w-5 h-5  mt-0.5 mr-1', 'text-gray-500')} title={`Run Completed: ${parsedInfo.thisSuiteAge}`} />
              <p title={`Message recieved: ${parsedInfo.thisSuiteAge}`}>{parsedInfo.thisSuiteAge}</p>
              {timeSinceClaimed && (
                <div className={classNames('flex ml-4', overAnHourTriage ? 'text-yellow-500' : 'text-gray-500')}>
                  <ArrowPathRoundedSquareIcon
                    className={classNames('w-5 h-5  mt-0.5 mr-1', overAnHourTriage ? 'text-yellow-500' : 'text-gray-500')}
                    title={`Time since claimed: ${timeSinceClaimed}`}
                  />
                  <p title={`Time since claimed: ${timeSinceClaimed}`}>{timeSinceClaimed}</p>
                </div>
              )}
              {numberOfHandoffs > 1 && (
                <div className={classNames('flex ml-4 text-yellow-500')}>
                  {numberOfHandoffs < 3 ? (
                    <UsersIcon
                      className={classNames('w-5 h-5  mt-0.5 mr-1 text-yellow-500')}
                      title={`This task was claimed and unclaimed ${numberOfHandoffs} times.`}
                    />
                  ) : (
                    <UserGroupIcon
                      className={classNames('w-5 h-5  mt-0.5 mr-1 text-yellow-500')}
                      title={`This task was claimed and unclaimed ${numberOfHandoffs} times.`}
                    />
                  )}
                  <p title={`This task was claimed and unclaimed ${numberOfHandoffs} times.`}>{numberOfHandoffs}</p>
                </div>
              )}
            </div>
          </dt>
        </div>
      </dl>
    </div>
  );
}
