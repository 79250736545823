import { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Skeleton, Box } from '@mui/material';

import WeeklyFailureForecastChart from '../ProjectedSuiteFailureChart/Chart';
import ScheduleTimelines from './ScheduleTimelines';

import { type PollJobResponse, sendTSPollRequest, sendTSPostRequest } from '@/utils/tanstackNetwork';
import { usePlanningContext } from '@/components/context/PlanningContext';

import ScheduleBreakdowns from './ScheduleBreakdowns';
import ForecastBreakdowns from './ForecastBreakdowns';

import type { SuiteFailureData } from '../ProjectedSuiteFailureChart/types';
import type { InvestigationSchedule } from './investigationSchedulingTypes';

export default function InvestigationScheduling() {
  const { selectedQaTeamIds, selectedCsmQawId, filterBy, selectedWeek } = usePlanningContext();
  const [forecastChartLeftPadding, setForecastChartLeftPadding] = useState<string>('0px');
  const [selectedSchedule, setSelectedSchedule] = useState<{ schedule: InvestigationSchedule; index: number } | null>(null);

  // fetch suite failure predictions
  const suiteFailurePredictions = useQuery<SuiteFailureData[]>({
    queryKey: ['projectedSuiteFailureChart', selectedQaTeamIds, selectedCsmQawId, filterBy, selectedWeek],
    queryFn: async () => {
      const data = await sendTSPostRequest('/client-summaries/suite-failure-predictions', {
        qaTeamIds: selectedQaTeamIds,
        selectedCsmQawId: filterBy === 'csm' ? selectedCsmQawId : null,
        startDate: selectedWeek.toDate().toLocaleDateString('en-CA'),
      });

      const filteredData = data.filter((customer: SuiteFailureData) => {
        const forecastData = customer.forecast.forecastData;

        customer.forecast.forecastData = forecastData.filter((hour) => new Date(hour.timestamp).getTime() >= selectedWeek.toDate().getTime());

        return customer.forecast.forecastData.length > 0 && customer.forecast.validationData.length > 0;
      });

      return filteredData;
    },
  });

  // trigger schedule generation job processing
  const triggerScheduleJobMutation = useMutation<
    { message: string; queueName: 'worker-queue' | null; jobId: string | null; data?: InvestigationSchedule },
    Error,
    { teamId: number; startDate: string; triggerNewJob?: boolean; probe?: boolean }
  >({
    mutationKey: ['triggerJob', 'investigationScheduleJob', selectedQaTeamIds],
    mutationFn: ({ teamId, startDate, triggerNewJob, probe }: { teamId: number; startDate: string; triggerNewJob?: boolean; probe?: boolean }) =>
      sendTSPostRequest(`/investigation-scheduling/generate`, {
        teamId,
        startDate,
        triggerNewJob,
        probe,
      }),
    onSuccess: (data) => {
      // if the schedule is already confirmed, this data will be fetched from the database and normalized for the UI.
      // in this case, we don't want to allow the user to explore more schedules or to re-generate schedule options.
      // this is now the schedule, it is confirmed and the user should only be able to view it. Any edits must happen in Google Calendar.
      if (data.data && !data.message.includes('Probe complete. No confirmed schedule for this team and week.')) {
        const schedule = data.data;
        setSelectedSchedule({ schedule, index: -1 });
      } else if (data.message === 'Probe complete. No confirmed schedule for this team and week.') {
        // if the probe is complete and there is no pre-existing schedule in the database
        // we need to basically reset the state of the schedule AND this query.
        setSelectedSchedule(null);
        triggerScheduleJobMutation.reset();
      }
    },
  });

  // poll for job status
  const pollScheduleJobQuery = useQuery<PollJobResponse<InvestigationSchedule[]>>({
    queryKey: ['pollJob', 'investigationScheduleJob', selectedQaTeamIds, triggerScheduleJobMutation.data?.jobId],
    queryFn: () => {
      if (!triggerScheduleJobMutation.data?.jobId) throw new Error('No job ID available');
      return sendTSPollRequest(triggerScheduleJobMutation.data.jobId);
    },
    // only poll for the job if the trigger mutation has succeeded and indicates that there was no pre-existing schedule in the database.
    enabled:
      triggerScheduleJobMutation.isSuccess && triggerScheduleJobMutation.data.jobId !== undefined && triggerScheduleJobMutation.data.jobId !== null,
    refetchInterval: (query) => {
      // if the job is not completed, poll every 5 seconds
      const data = query.state.data;

      if (data?.success === true) {
        return ['completed', 'failed'].includes(data.jobStatus) ? false : 5000;
      }
    },
  });

  // mutate if referred from Slack
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const referrer = searchParams.get('referrer');

    if (referrer && referrer === 'slack' && selectedQaTeamIds.length === 1) {
      triggerScheduleJobMutation.mutate(
        {
          teamId: selectedQaTeamIds[0],
          startDate: selectedWeek.toDate().toLocaleDateString('en-CA'),
          triggerNewJob: false,
        },
        {
          onSuccess: () => {
            // remove the referrer param from the URL
            const url = new URL(window.location.href);
            url.searchParams.delete('referrer');
            window.history.replaceState({}, '', url.toString());
          },
        },
      );
    } else if (selectedQaTeamIds.length === 1) {
      // trigger a probe to check for a pre-existing schedule
      triggerScheduleJobMutation.mutate({
        teamId: selectedQaTeamIds[0],
        startDate: selectedWeek.toDate().toLocaleDateString('en-CA'),
        triggerNewJob: false,
        probe: true,
      });
    }
  }, [selectedQaTeamIds, selectedWeek]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
      <Box sx={{ border: '1px solid', borderColor: 'divider', borderRadius: 2 }}>
        <ScheduleTimelines
          triggerMutation={triggerScheduleJobMutation}
          pollQuery={pollScheduleJobQuery}
          setForecastChartLeftPadding={setForecastChartLeftPadding}
          selectedSchedule={selectedSchedule}
          setSelectedSchedule={setSelectedSchedule}
        />
      </Box>
      <Box
        sx={{
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1,
        }}
      >
        <div style={{ paddingLeft: forecastChartLeftPadding }}>
          {suiteFailurePredictions.isFetched ? (
            <WeeklyFailureForecastChart data={suiteFailurePredictions.data as SuiteFailureData[]} selectedDay={7} />
          ) : (
            <Skeleton variant="rounded" animation="wave" height={400} />
          )}
        </div>
        <Box sx={{ display: 'flex', gap: 2, px: 1 }}>
          <Box sx={{ flex: '0 0 auto' }}>
            <ForecastBreakdowns forecastData={suiteFailurePredictions.data as SuiteFailureData[]} />
          </Box>
          <Box sx={{ flex: 1, minWidth: 0, overflow: 'hidden' }}>
            <ScheduleBreakdowns selectedSchedule={selectedSchedule} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
