import dayjs from 'dayjs';
import { MilestoneProgress } from '../../types';


type SeverityColor = 'gray.main' | 'green.main' | 'red.main' | 'yellow.main' | 'green.dark';

type SubmilestoneColorsResult = {
    velocityColor: SeverityColor;
    targetTestsColor: SeverityColor;
    overallColor: SeverityColor;
}

type SubmilestoneStringsResult = {
    weekString: string;
    dateString: string;
    velocityString: string;
    targetString: string;
}

/**
 * Gets formatted strings for displaying submilestone information
 */
export function getSubmilestoneStrings(data: MilestoneProgress, actualTests: number, targetTests: number, testsAdded: number, requiredVelocity: number, isFuture: boolean): SubmilestoneStringsResult {
    return {
        weekString: `Week ${data.weekNumber}`,
        dateString: `${dayjs(data.dueDate).startOf('week').format('MMM D')} - ${dayjs(data.dueDate).format('MMM D')}`,
        velocityString: isFuture ? '--' : `${testsAdded} / ${Math.max(requiredVelocity, 0)}`,
        targetString: `${actualTests} / ${targetTests}`,
    };
}

/**
 * Determines the severity colors for velocity, total tests, and overall status
 */
export function getSubmilestoneColors(isFuture: boolean, isProjectedFullyOutlined: boolean, velocityDelta: number, targetDelta: number): SubmilestoneColorsResult {
    // Base text color for future weeks
    if (isFuture) {
        return {
            velocityColor: 'gray.main',
            targetTestsColor: 'gray.main',
            overallColor: isProjectedFullyOutlined ? 'green.dark' : 'gray.main',
        };
    }


    const velocityColor = velocityDelta <= 0 ? 'green.main' : 'red.main';
    const targetTestsColor = targetDelta <= 0 ? 'green.main' : 'red.main';

    // Determine overall severity
    const overallColor =
        velocityDelta <= 0 && targetDelta <= 0
            ? 'green.main'
            : velocityDelta > 0 && targetDelta > 0
                ? 'red.main'
                : 'yellow.main';

    return {
        velocityColor,
        targetTestsColor,
        overallColor,
    };
} 
