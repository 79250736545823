import { Box, Typography, Paper } from '@mui/material';
import dayjs from 'dayjs';
import { getSubmilestoneStyles } from './utils';
import { MilestoneProgress } from '../types';

export default function SubMilestone({
  subMilestone,
  testsAdded,
  outlinedTestsAdded,
  requiredVelocity,
  requiredOutlineVelocity,
}: {
  subMilestone: MilestoneProgress;
  testsAdded: number;
  outlinedTestsAdded: number;
  requiredVelocity: number;
  requiredOutlineVelocity: number;
}) {
  const weekStr = dayjs(subMilestone.dueDate).startOf('week').format('M/D');
  const isCurrWeek = dayjs(subMilestone.dueDate).isSame(dayjs(), 'week');
  const isFutureWeek = dayjs(subMilestone.dueDate).isAfter(dayjs(), 'week');
  const weekType = isCurrWeek ? 'present' : isFutureWeek ? 'future' : 'past';

  const hasMetTotalActiveGoal = subMilestone.targetTests <= subMilestone.actualTests;
  const hasMetCreationVelocityRequirement = requiredVelocity <= testsAdded;

  const totalOutlinedTests = subMilestone.draftTests + subMilestone.actualTests;
  const hasMetTotalOutlinedGoal = subMilestone.targetOutlinedTests <= totalOutlinedTests;
  const hasMetOutlineVelocityRequirement = requiredOutlineVelocity <= outlinedTestsAdded;

  const { testsBgColor: totalActiveBgColor, weekFontWt, weekFontClr, rowBgColor } = getSubmilestoneStyles(hasMetTotalActiveGoal, weekType);
  const { testsBgColor: testsAddedBgColor } = getSubmilestoneStyles(hasMetCreationVelocityRequirement, weekType);
  const { testsBgColor: totalOutlinedBgColor } = getSubmilestoneStyles(hasMetTotalOutlinedGoal, weekType);
  const { testsBgColor: outlinedAddedBgColor } = getSubmilestoneStyles(hasMetOutlineVelocityRequirement, weekType);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        p: '.5em',
        borderRadius: '5px',
        justifyContent: 'space-evenly',
        bgcolor: rowBgColor,
      }}
    >
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
        <Typography lineHeight={1.1} fontWeight={weekFontWt} color={weekFontClr}>
          {`Week ${subMilestone.weekNumber}`}
        </Typography>
        <Typography variant="caption" lineHeight={1} color={weekFontClr}>
          {`${weekStr} - ${dayjs(subMilestone.dueDate).format('M/D')}`}
        </Typography>
      </Box>
      <Box sx={{ width: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center', mx: 0.5 }}>
        <Paper
          sx={{
            bgcolor: outlinedAddedBgColor,
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '5px',
          }}
        >
          <Typography color="white" fontSize={14} fontWeight="bold">
            {!isFutureWeek && `${outlinedTestsAdded} / ${Math.max(requiredOutlineVelocity, 0)}`}
          </Typography>
        </Paper>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'right', mx: 0.5 }}>
        <Paper
          sx={{
            bgcolor: totalOutlinedBgColor,
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '5px',
          }}
        >
          <Typography color="white" fontSize={14} fontWeight="bold">
            {`${totalOutlinedTests} / ${subMilestone.targetOutlinedTests}`}
          </Typography>
        </Paper>
      </Box>
      <Box sx={{ width: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center', mx: 0.5 }}>
        <Paper
          sx={{
            bgcolor: testsAddedBgColor,
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '5px',
          }}
        >
          <Typography color="white" fontSize={14} fontWeight="bold">
            {!isFutureWeek && `${testsAdded} / ${Math.max(requiredVelocity, 0)}`}
          </Typography>
        </Paper>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'right', mx: 0.5 }}>
        <Paper
          sx={{
            bgcolor: totalActiveBgColor,
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '5px',
          }}
        >
          <Typography color="white" fontSize={14} fontWeight="bold">
            {`${subMilestone.actualTests} / ${subMilestone.targetTests}`}
          </Typography>
        </Paper>
      </Box>
    </Box>
  );
}
