import { MessageCircleQuestion, Bug, Loader } from 'lucide-react';
import { ScrollArea } from '@/components/ui/scroll-area';
import { CustomerAsksList } from './ResourceCards/CustomerAskResources';
import { RecentBugsList } from './ResourceCards/RecentBugsResources';
import { RecentSuitesList } from './ResourceCards/RecentSuitesResources';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@/components/ui/accordion';

import React from 'react';

export function ConversationResources({
  conversationId,
  handleSelectConversation,
}: {
  conversationId: number | null;
  handleSelectConversation: (conversationId: number | null) => void;
}) {
  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <ScrollArea className="flex-1">
        <div className="p-4 space-y-6">
          <div className="flex items-center justify-between">{/* <AddItemModal onAddItem={handleAddRelevantItem} /> */}</div>
          {/* TODO: Add related conversations once we have vectorized messages */}
          {/* <ResourceSection
              title="Related Conversations"
              icon={<MessageSquare className="w-4 h-4" />}
              resources={conversation.internalResources.map((r) => ({ ...r, id: String(r.id) })).filter((r) => r.type === 'conversation')}
            /> */}
          <ResourceSection
            title="Recent Bugs (last 7 days)"
            icon={<Bug className="w-4 h-4 text-bug-status-open" />}
            ListComponent={<RecentBugsList />}
            conversationId={conversationId}
          />
          <ResourceSection
            title="Recent Suites (last 7 days)"
            icon={<Loader className="w-4 h-4 text-suite" />}
            ListComponent={<RecentSuitesList />}
            conversationId={conversationId}
          />
          <ResourceSection
            title="Customer Asks (last 7 days)"
            icon={<MessageCircleQuestion className="w-4 h-4 text-blue-500" />}
            ListComponent={<CustomerAsksList handleSelectConversation={handleSelectConversation} />}
            conversationId={conversationId}
          />
          {/* TODO: Add related runs and workflows*/}
          {/* <ResourceSection
            title="Related Runs"
            icon={<Play className="w-4 h-4" />}
            resources={conversation?.internalResources.map((r) => ({ ...r, id: String(r.id) })).filter((r) => r.type === 'run') || []}
          /> */}
          {/* <ResourceSection
            title="Related Workflows"
            icon={<Loader className="w-4 h-4" />}
            resources={conversation?.internalResources.map((r) => ({ ...r, id: String(r.id) })).filter((r) => r.type === 'workflow') || []}
          /> */}
        </div>
      </ScrollArea>
    </div>
  );
}

// Main component with stable structure
export function ResourceSection({
  ...props
}: {
  title: string | undefined;
  icon: React.ReactNode | undefined;
  ListComponent: React.ReactNode | undefined;
  conversationId: number | null | undefined;
}) {
  return (
    <div>
      <Accordion type="single" collapsible className="-mt-6 border-0">
        <AccordionItem className="border-0" value="resources">
          <AccordionTrigger className="text-sm font-semibold">
            <div className="flex items-center">
              {props.icon}
              <span className="ml-2">{props.title}</span>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            {props.ListComponent}
            {/* <CustomerAsksList {...props} /> */}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
}
