import { FailureSignatureArray, Block } from '../types';
import { getAllBlockIds, getBuildingBlocks } from '../helpers';

/**
 * Gets building blocks data from the failure signature data
 * Filters blocks based on those used in the runs
 */
export const getBlocksData = (currentData: FailureSignatureArray | null) => {
  if (!currentData) {
    return [];
  }

  const allBlockIds = getAllBlockIds(currentData || []);
  const blockData: Block[] = getBuildingBlocks(currentData, allBlockIds);
  return blockData;
};
