import React, { useState, useEffect } from 'react';

import { AxiosError } from 'axios';
import { UseMutationResult, UseQueryResult, useMutation } from '@tanstack/react-query';
import { usePlanningContext } from '@/components/context/PlanningContext';

import { Typography, Button, Box, Skeleton, Snackbar, Alert, Tooltip } from '@mui/material';

import ConfirmScheduleModal from './ConfirmScheduleModal';
import TimelineChart from './TimelineChart';

import { sendTSPostRequest, type PollJobResponse } from '@/utils/tanstackNetwork';

import { InvestigationSchedule } from './investigationSchedulingTypes';

interface ToastGroupProps {
  errorToast: { open: boolean; message: string | undefined };
  successToast: { open: boolean; message: string | undefined };
  setErrorToast: (toast: { open: boolean; message: string | undefined }) => void;
  setSuccessToast: (toast: { open: boolean; message: string | undefined }) => void;
}

const ToastGroup: React.FC<ToastGroupProps> = ({ errorToast, successToast, setErrorToast, setSuccessToast }) => {
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={errorToast.open}
        autoHideDuration={6000}
        onClose={() => setErrorToast({ open: false, message: undefined })}
      >
        <Alert onClose={() => setErrorToast({ open: false, message: undefined })} severity="error" variant="filled" sx={{ width: '100%' }}>
          {errorToast.message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={successToast.open}
        autoHideDuration={6000}
        onClose={() => setSuccessToast({ open: false, message: undefined })}
      >
        <Alert onClose={() => setSuccessToast({ open: false, message: undefined })} severity="success" variant="filled" sx={{ width: '100%' }}>
          {successToast.message}
        </Alert>
      </Snackbar>
    </>
  );
};

interface ScheduleTimelinesProps {
  triggerMutation: UseMutationResult<
    { message: string; queueName: 'worker-queue' | null; jobId: string | null; data?: InvestigationSchedule },
    Error,
    { teamId: number; startDate: string; triggerNewJob?: boolean; probe?: boolean }
  >;
  pollQuery: UseQueryResult<PollJobResponse<InvestigationSchedule[]>>;
  setForecastChartLeftPadding: React.Dispatch<React.SetStateAction<string>>;
  selectedSchedule: { schedule: InvestigationSchedule; index: number } | null;
  setSelectedSchedule: React.Dispatch<React.SetStateAction<{ schedule: InvestigationSchedule; index: number } | null>>;
}
const ScheduleTimelines: React.FC<ScheduleTimelinesProps> = ({
  triggerMutation,
  pollQuery,
  setForecastChartLeftPadding,
  selectedSchedule,
  setSelectedSchedule,
}) => {
  const isMutationTriggered = triggerMutation.isPending || pollQuery.isLoading || pollQuery.isFetching;
  const isMutationSuccess = triggerMutation.isSuccess && triggerMutation.data?.jobId !== undefined;
  const { selectedQaTeamIds, selectedWeek } = usePlanningContext();
  const [scheduleTimeBreakdown, setScheduleTimeBreakdown] = useState<{
    investigation: number;
    externalMeetings: number;
    ooo: number;
    free: number;
  } | null>(null);
  const [isScheduleConfirmed, setIsScheduleConfirmed] = useState<boolean>(selectedSchedule?.index === -1);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [hasClickedGenerate, setHasClickedGenerate] = useState<boolean>(isMutationTriggered || isMutationSuccess);
  const [isShowingArtificialLoading, setIsShowingArtificialLoading] = useState<boolean>(false);
  const [errorToast, setErrorToast] = useState<{ open: boolean; message: string | undefined }>({ open: false, message: undefined });
  const [successToast, setSuccessToast] = useState<{ open: boolean; message: string | undefined }>({ open: false, message: undefined });

  useEffect(() => {
    // initialize clicked state if mutation has already been "triggered" (referred from Slack)
    setHasClickedGenerate(isMutationTriggered || isMutationSuccess);
    // force schedule confirmation state if the probe is complete and there is a pre-existing schedule in the DB (index is -1);
    setIsScheduleConfirmed(selectedSchedule?.index === -1);
  }, [isMutationTriggered, isMutationSuccess, selectedSchedule]);

  const confirmScheduleMutation = useMutation({
    mutationFn: (schedule: InvestigationSchedule) =>
      sendTSPostRequest('/investigation-scheduling/confirm', {
        schedule: schedule,
        teamId: selectedQaTeamIds[0],
      }),
    onSuccess: () => {
      setIsScheduleConfirmed(true);
      setIsConfirmModalOpen(false);
      setSuccessToast({ open: true, message: 'Schedule confirmed successfully' });
    },
    onError: (error: AxiosError<{ error: string; message: string }>) => {
      setErrorToast({ open: true, message: error.response?.data.message || error.message || undefined });
    },
  });

  const handleConfirmClick = () => {
    if (selectedSchedule) {
      setIsConfirmModalOpen(true);
    }
  };

  const handleConfirmSchedule = () => {
    if (selectedSchedule) {
      confirmScheduleMutation.mutate(selectedSchedule.schedule);
    }
  };

  // handle initial generate click
  const handleGenerateClick = () => {
    // if user has selected multiple QA teams, show error toast
    if (selectedQaTeamIds.length > 1) {
      setErrorToast({ open: true, message: 'Please select a single QA Team to generate a schedule.' });
      return;
    }

    // set clicked state
    setHasClickedGenerate(true);

    // if data is already loaded, show an artificial loading time
    if (!pollQuery.isLoading && !pollQuery.isFetching) {
      setIsShowingArtificialLoading(true);
      setTimeout(() => {
        setIsShowingArtificialLoading(false);
      }, 250);
    }

    // trigger the trigger mutation
    triggerMutation.mutate({
      teamId: selectedQaTeamIds[0],
      startDate: selectedWeek.toDate().toLocaleDateString('en-CA'),
      triggerNewJob: false,
    });
  };

  // handle re-generate click
  const handleRegenerateClick = () => {
    // if user has selected multiple QA teams, show error toast
    if (selectedQaTeamIds.length > 1) {
      setErrorToast({ open: true, message: 'Please select a single QA Team to generate a schedule.' });
      return;
    }

    // set clicked state
    setSelectedSchedule(null);
    setHasClickedGenerate(true);

    // re-trigger the trigger mutation
    triggerMutation.mutate({
      teamId: selectedQaTeamIds[0],
      startDate: selectedWeek.toDate().toLocaleDateString('en-CA'),
      triggerNewJob: true,
    });

    // show artificial loading while waiting for initial response
    setIsShowingArtificialLoading(true);
    setTimeout(() => {
      setIsShowingArtificialLoading(false);
    }, 250);
  };

  // handle selection
  const handleSelection = (schedule: InvestigationSchedule, index: number) => {
    const scheduleTimeBreakdown = schedule.qaTeam.members.reduce(
      (acc, member) => {
        acc.investigation += member.timeBreakdown.investigation;
        acc.externalMeetings += member.timeBreakdown.externalMeeting;
        acc.ooo += member.timeBreakdown.ooo;
        acc.free += member.timeBreakdown.free;
        return acc;
      },
      {
        investigation: 0,
        externalMeetings: 0,
        ooo: 0,
        free: 0,
      },
    );
    setSelectedSchedule({ schedule, index });
    setScheduleTimeBreakdown(scheduleTimeBreakdown);
  };

  // handle clear selection
  const handleClearSelection = () => {
    setSelectedSchedule(null);
    setForecastChartLeftPadding('0px');
  };

  // show skeleton while loading or during artificial delay
  const isJobInProgress = pollQuery.data?.success && !['completed', 'failed'].includes(pollQuery.data.jobStatus);
  const isLoading = isMutationTriggered || isShowingArtificialLoading || isJobInProgress;

  if (isLoading) {
    const message = triggerMutation.variables?.probe === true ? 'Scanning for pre-confirmed schedule...' : 'Generating schedule options...';
    return (
      <Box sx={{ g: 1, p: 2 }}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {message} {typeof pollQuery.data?.jobProgress === 'number' && `${pollQuery.data.jobProgress}%`}
        </Typography>
        <Box sx={{ display: 'flex', gap: 3, mb: 2, justifyContent: 'start' }}>
          <Skeleton variant="rounded" width={100} height={36} />
          <Skeleton variant="rounded" width={100} height={36} />
          <Skeleton variant="rounded" width={100} height={36} />
          {selectedSchedule !== null && (
            <>
              <Skeleton variant="rounded" width={100} height={36} />
              <Skeleton variant="rounded" width={100} height={36} />
            </>
          )}
        </Box>
        {selectedSchedule !== null && <Skeleton variant="rounded" width="100%" height={400} />}
      </Box>
    );
  }

  // show generate button if user hasn't clicked generate yet
  if (!hasClickedGenerate) {
    const today = new Date().getTime();
    const weekStart = selectedWeek.toDate().getTime();
    const disableGenerate = today > weekStart;
    return (
      <Box sx={{ g: 1, p: 2 }}>
        <ToastGroup errorToast={errorToast} successToast={successToast} setErrorToast={setErrorToast} setSuccessToast={setSuccessToast} />
        <Tooltip title={disableGenerate ? 'Cannot generate schedule for past weeks' : ''} arrow>
          <span>
            {' '}
            {/* wrapper needed because Tooltip doesn't work directly on disabled elements */}
            <Button variant="contained" onClick={handleGenerateClick} disabled={disableGenerate}>
              Generate Schedule Options
            </Button>
          </span>
        </Tooltip>
      </Box>
    );
  }

  if (pollQuery.data && (!pollQuery.data.success || pollQuery.data.jobStatus === 'failed')) {
    return (
      <Box sx={{ g: 1, p: 2 }}>
        <ToastGroup errorToast={errorToast} successToast={successToast} setErrorToast={setErrorToast} setSuccessToast={setSuccessToast} />
        <Typography variant="body1" color="error" sx={{ mb: 2 }}>
          Failed to generate schedule options. Please try again.
        </Typography>
        <Button variant="contained" onClick={handleRegenerateClick}>
          Try Again
        </Button>
      </Box>
    );
  }

  return (
    <div className="min-h-full px-4">
      <ToastGroup errorToast={errorToast} successToast={successToast} setErrorToast={setErrorToast} setSuccessToast={setSuccessToast} />
      <Box sx={{ g: 1, p: 2 }}>
        {(!isScheduleConfirmed || selectedSchedule === null) && (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
              <Typography variant="body1">Select a schedule option below or</Typography>
              <Button
                variant="text"
                size="small"
                onClick={handleRegenerateClick}
                sx={{
                  minWidth: 'auto',
                  p: 0,
                  textTransform: 'none',
                  fontFamily: 'inherit',
                  fontSize: 'inherit',
                  fontWeight: 'inherit',
                }}
              >
                re-generate schedule options:
              </Button>
            </Box>
            <Box sx={{ display: 'flex', gap: 3, mb: 2, justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', gap: 3 }}>
                {pollQuery.data &&
                  pollQuery.data.data?.map((schedule, index) => (
                    <Button
                      variant={selectedSchedule && selectedSchedule.index === index ? 'contained' : 'outlined'}
                      onClick={() => handleSelection(schedule, index)}
                      key={index}
                    >
                      Option {index + 1}
                    </Button>
                  ))}
                {selectedSchedule !== null && (
                  <>
                    <Button variant="outlined" onClick={handleClearSelection}>
                      Clear
                    </Button>
                    <Button variant="outlined" onClick={handleConfirmClick}>
                      Confirm
                    </Button>
                  </>
                )}
              </Box>
              {selectedSchedule && (
                <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                  <Typography variant="body2" color="text.secondary">
                    Investigation: {scheduleTimeBreakdown?.investigation.toFixed(2)}h
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    External meetings: {scheduleTimeBreakdown?.externalMeetings.toFixed(2)}h
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Out of office: {scheduleTimeBreakdown?.ooo.toFixed(2)}h
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Available: {scheduleTimeBreakdown?.free.toFixed(2)}h
                  </Typography>
                </Box>
              )}
            </Box>
          </>
        )}
        {selectedSchedule && (
          <ConfirmScheduleModal
            open={isConfirmModalOpen}
            onClose={() => setIsConfirmModalOpen(false)}
            onConfirm={handleConfirmSchedule}
            schedule={selectedSchedule.schedule}
            isConfirming={confirmScheduleMutation.isPending}
          />
        )}
        {isScheduleConfirmed && selectedSchedule !== null && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" color="success.main">
              {selectedSchedule.index === -1
                ? 'Schedule was previously confirmed and synced to Google Calendar. This is a read-only view.'
                : 'Schedule confirmed and synced to Google Calendar. This is now a read-only view.'}
            </Typography>
          </Box>
        )}
        {selectedSchedule && (
          <TimelineChart
            schedule={selectedSchedule.schedule}
            index={selectedSchedule.index}
            isScheduleConfirmed={isScheduleConfirmed}
            setForecastChartLeftPadding={setForecastChartLeftPadding}
          />
        )}
      </Box>
    </div>
  );
};

export default ScheduleTimelines;
