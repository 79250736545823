import { TooltipContent, Tooltip, TooltipTrigger } from '@/components/ui/tooltip';
import { Clock, MessageCircle } from 'lucide-react';
import { SiSlack } from '@icons-pack/react-simple-icons';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import { SiDiscord } from '@icons-pack/react-simple-icons';
import { TooltipProvider } from '@/components/ui/tooltip';
import { Conversation } from '@/components/HQ/data/types';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { useCommunications } from '@/components/HQ/contexts/CommunicationsContext';
import { formatDate } from '@/components/MessageHQ/utils/date';
import { ConversationStatus } from '@/components/HQ/components/ConversationStatus';
import { ClaimAndUnclaimButton } from '@/components/HQ/components/ConversationMenu';
import { Badge } from '@/components/ui/badge';
import { SelectedItem } from '../HQMainPage';
import { calculateElapsedTime } from './helpersInvestigation';
import { useState, useEffect, MouseEvent } from 'react';

interface ConversationCardProps {
  conversation: Conversation;
  handleSelectConversation: (id: number | null) => void;
  selectedItem: SelectedItem;
}

export function ConversationCard({ conversation, handleSelectConversation, selectedItem }: ConversationCardProps) {
  // const earliestMessage = conversation.messages[conversation.messages.length - 1];
  const latestMessage = conversation.messages[0];
  const { customers } = useCommunications();
  const currentCustomer = customers.find((c) => c.id === conversation.customerId);

  const [elapsedTime, setElapsedTime] = useState(calculateElapsedTime(latestMessage.timestamp));
  const [currentAskIndex, setCurrentAskIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setElapsedTime(calculateElapsedTime(latestMessage.timestamp));
    }, 60000); // Updates every minute

    return () => clearInterval(interval);
  }, [latestMessage.timestamp]);

  // Gets the state of the conversation and returns the appropriate badge
  const getStateBadge = (conversation: Conversation) => {
    const scrollToCustomerAsks = (e: MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      const messagesWithCustomerAsks = conversation.messages.filter((message) => message.customerAsks && message.customerAsks?.length > 0);

      if (messagesWithCustomerAsks.length > 0) {
        // Get the next message index, cycling back to 0 if we reach the end
        const nextIndex = (currentAskIndex + 1) % messagesWithCustomerAsks.length;
        setCurrentAskIndex(nextIndex);

        const elementId = `customer-ask-${messagesWithCustomerAsks[nextIndex].id}`;
        const element = document.getElementById(elementId);

        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });

          element.classList.add('highlight-pulse');
          setTimeout(() => {
            element.classList.remove('highlight-pulse');
          }, 2000);
        }
      }
    };

    if (!conversation.completed) {
      // Messages sorted from newest to oldest
      const lastMessage = conversation.messages[0];
      const lastMessageAssociatedTask = lastMessage.messageTasks?.find((task) => task.type === 'message');
      const conversationCustomerAsks = conversation.messages.flatMap((message) => message.customerAsks);
      const numberOfUnresolvedCustomerAsks = conversationCustomerAsks.filter((ask) => !ask?.resolved).length;
      const conversationHasUnresolvedCustomerAsks = numberOfUnresolvedCustomerAsks > 0;

      if (
        (lastMessage.sender === 'external' || lastMessage.sender === 'unknown') &&
        lastMessageAssociatedTask &&
        lastMessageAssociatedTask?.status !== 'done'
      ) {
        return <Badge variant="destructive">Requires Reply</Badge>;
      } else if (conversationHasUnresolvedCustomerAsks) {
        return (
          <Badge variant="secondary" className="cursor-pointer" onClick={(e) => scrollToCustomerAsks(e)}>
            Requires Action
          </Badge>
        );
      }
    }
    return null;
  };

  return (
    <div
      key={latestMessage.id}
      className={`w-full text-left p-3 transition-colors ${
        selectedItem.type === 'conversation' && selectedItem.id === conversation.id
          ? 'bg-muted text-foreground hover:bg-accent hover:text-accent-foreground'
          : 'bg-background text-foreground hover:bg-accent hover:text-accent-foreground'
      }`}
      onClick={(e) => {
        // Check if the click originated from the menu or its children
        const isMenuClick = (e.target as HTMLElement).closest('.menu-button-container');
        if (isMenuClick) {
          return;
        }
        handleSelectConversation(conversation.id);
      }}
    >
      <div className="flex justify-between items-start mb-1">
        <div className="flex items-center gap-2 w-full justify-between">
          <div className="flex items-center">
            <div className="relative">
              <Avatar className="w-4 h-4 mr-1">
                <AvatarImage
                  src={`${latestMessage.avatar || 'https://api.dicebear.com/9.x/avataaars/svg?seed=' + latestMessage.user}`}
                  alt={latestMessage.user}
                />
                <AvatarFallback>{latestMessage.user.charAt(0)}</AvatarFallback>
              </Avatar>
            </div>
            <span className="text-sm">{latestMessage.user}</span>
          </div>
          <div className="flex justify-end">{getStateBadge(conversation)}</div>
        </div>
      </div>
      <div className="flex justify-between items-center mb-1">
        <div className="flex items-center">
          {conversation?.platform === 'slack' && <SiSlack className="w-3 h-3 mr-1" />}
          {conversation?.platform === 'teams' && <MicrosoftIcon sx={{ width: '14px', height: '14px', marginRight: '4px' }} />}
          {conversation?.platform === 'discord' && <SiDiscord className="w-3 h-3 mr-1" />}
          <span className="text-xs">{currentCustomer?.officialName}</span>
        </div>
      </div>
      <div className="flex justify-between items-center text-xs">
        <div className="flex items-center gap-2">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="flex items-center flex-shrink-0">
                <Clock className="w-3 h-3 mr-1 justify-start" />
                <span className="text-xs flex-shrink-0">{elapsedTime}</span>
              </TooltipTrigger>
              <TooltipContent>
                <p>{`Received on ${formatDate(latestMessage.timestamp)} by ${latestMessage.user}`}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="flex items-center flex-shrink-0">
                <MessageCircle className="w-3 h-3 mr-1" />
              </TooltipTrigger>
              <TooltipContent>
                <p className="max-w-[200px]">{latestMessage.messageContent}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <div className="flex items-center">
          <ConversationStatus conversation={conversation} />
          {!conversation.completed && <ClaimAndUnclaimButton conversation={conversation} />}
        </div>
      </div>
    </div>
  );
}
