import { useState, useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { PollJobResponse, sendTSPollRequest, sendTSGetRequest } from '@/utils/tanstackNetwork';
import { FailureSignatureArray, ToastMessage, JobProgress } from '../types';

type JobResponse = {
  message: string;
  queueName: string;
  jobId: string;
  version: string;
  isExistingJob: boolean;
};

type FetchResponse = FailureSignatureArray | JobResponse;

/**
 * Hook for handling the failure signature generation process
 */
export const useFetchFailureSignatures = (suiteId: string) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [longRunningMessage, setLongRunningMessage] = useState(false);
  const [toast, setToast] = useState<ToastMessage | null>(null);
  const [jobProgress, setJobProgress] = useState<JobProgress | null>(null);

  /**
   * Query for fetching failure signatures using the V2 endpoint
   */
  const fetchFailureSignatures = useQuery<FetchResponse, Error>({
    queryKey: ['fetchFailureSignatures', suiteId],
    queryFn: async () => {
      const currUser = localStorage.getItem('user');
      const slackId = currUser ? JSON.parse(currUser).slackId : null;
      const response = await sendTSGetRequest(`/failure-signature/v2/${suiteId}${slackId ? `?slackId=${slackId}` : ''}`);
      return response;
    },
    enabled: !!suiteId,
  });

  /**
   * Query for polling the status of the failure signature generation job
   */
  const pollFailureSignatureGenerationJob = useQuery<PollJobResponse<FailureSignatureArray>, Error>({
    queryKey: ['pollJob', 'failureSignatureGenerationJob'],
    queryFn: () => {
      const data = fetchFailureSignatures.data;
      if (!data || !('jobId' in data)) throw new Error('No job ID found');
      return sendTSPollRequest<FailureSignatureArray>(data.jobId);
    },
    enabled: fetchFailureSignatures.isSuccess &&
      fetchFailureSignatures.data !== undefined &&
      'jobId' in fetchFailureSignatures.data,
    refetchInterval: (query) => {
      const data = query.state.data;
      if (!data?.success) return false;
      if (data.jobStatus === 'completed' || (data.jobStatus === 'failed' && !data.message?.includes('Retrying'))) return false;
      return 2000;
    },
  });

  // Update loading and error states based on both queries
  useEffect(() => {
    setIsLoading(fetchFailureSignatures.isLoading || pollFailureSignatureGenerationJob.isLoading);
    setIsError(fetchFailureSignatures.isError || pollFailureSignatureGenerationJob.isError);
  }, [fetchFailureSignatures.isLoading, pollFailureSignatureGenerationJob.isLoading, fetchFailureSignatures.isError, pollFailureSignatureGenerationJob.isError]);

  // Handle immediate results or job creation
  useEffect(() => {
    const fetchData = fetchFailureSignatures.data;

    if (!fetchData) return;

    // If we got an array of signatures directly
    if (Array.isArray(fetchData)) {
      setIsGenerating(false);
      setLongRunningMessage(false);
      setToast({
        title: 'Success',
        message: 'Failure signatures loaded successfully.',
        isSuccess: true,
        key: Date.now(),
      });
      return;
    }

    // If we got a job response
    if ('jobId' in fetchData) {
      setIsGenerating(true);
      setToast({
        title: 'Processing',
        message: fetchData.isExistingJob
          ? 'Using existing failure signature generation job.'
          : 'Starting failure signature generation. This may take a few minutes.',
        isSuccess: true,
        key: Date.now(),
      });

      // Update message after 5 seconds
      setTimeout(() => {
        setLongRunningMessage(true);
        setToast({
          title: 'Processing',
          message: "Failure signature generation is running in the background. You can leave this page - we'll send you a Slack DM when it's ready.",
          isSuccess: true,
          key: Date.now(),
        });
      }, 5000);
    }
  }, [fetchFailureSignatures.data]);

  // Handle job completion
  useEffect(() => {
    const pollData = pollFailureSignatureGenerationJob.data;

    if (!pollData) return;

    // Update job progress when available
    if (pollData.jobProgress) {
      setJobProgress(pollData.jobProgress as JobProgress);
    }

    // Job completed successfully (200)
    if (pollData.success && pollData.jobStatus === 'completed') {
      setIsGenerating(false);
      setLongRunningMessage(false);
      // Refetch signatures after job completion
      fetchFailureSignatures.refetch().then(() => {
        setToast({
          title: 'Success',
          message: 'Failure signature generation completed successfully.',
          isSuccess: true,
          key: Date.now(),
        });
      });
      return;
    }

    // Job is still processing (202)
    if (pollData.success && ['active', 'waiting', 'delayed'].includes(pollData.jobStatus)) {
      setIsGenerating(true);
      return;
    }

    // Job failed but retrying (202)
    if (pollData.success && pollData.jobStatus === 'failed' && pollData.message?.includes('Retrying')) {
      setIsGenerating(true);
      setToast({
        title: 'Processing',
        message: 'Previous attempt failed. Retrying...',
        isSuccess: true,
        key: Date.now(),
      });
      return;
    }

    // Job failed completely (418)
    if (!pollData.success && pollData.error === 'JOB_FAILED') {
      setIsGenerating(false);
      setLongRunningMessage(false);
      setToast({
        title: 'Error',
        message: `Failed to generate failure signature: ${pollData.reason || 'Unknown error'}`,
        isSuccess: false,
        key: Date.now(),
      });
      return;
    }

    // Job not found (404) or other errors
    if (!pollData.success) {
      setIsGenerating(false);
      setLongRunningMessage(false);
      setToast({
        title: 'Error',
        message: pollData.message || 'Failed to generate failure signature. Please try again.',
        isSuccess: false,
        key: Date.now(),
      });
    }
  }, [pollFailureSignatureGenerationJob.data, fetchFailureSignatures.refetch]);

  // Determine currentData based on response type
  const currentData = useMemo(() => {
    if (isError) return null;

    const fetchData = fetchFailureSignatures.data;
    if (!fetchData) return null;

    // If we got an array of signatures directly
    if (Array.isArray(fetchData)) {
      return fetchData; // Return all signatures
    }

    // If we're polling a job
    const pollData = pollFailureSignatureGenerationJob.data;
    if (pollData?.success && pollData.jobStatus === 'completed') {
      if (Array.isArray(pollData.data)) {
        return pollData.data; // Return all signatures from the job
      }
    }

    return null; // Return null if we don't have any data
  }, [fetchFailureSignatures.data, pollFailureSignatureGenerationJob.data, isError]);

  return {
    isGenerating,
    isLoading,
    isError,
    longRunningMessage,
    toast,
    setToast,
    currentData,
    jobProgress,
  };
};
