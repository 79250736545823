import { v4 as uuidv4 } from 'uuid';

import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import {
  Typography,
  Button,
  Box,
  Stack,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';

import type { InvestigationSchedule, InvestigationScheduleEvent, SelectedEvent } from './investigationSchedulingTypes';

interface EventDrawerProps {
  schedule: InvestigationSchedule;
  event: SelectedEvent | null;
  users: string[];
  toggleDrawer: (open: boolean) => void;
  onPreviewUpdate: (event: SelectedEvent | null) => void;
  onReset: () => void;
  onClose: () => void;
}

const EventDrawer: React.FC<EventDrawerProps> = ({ schedule, event, users, toggleDrawer, onPreviewUpdate, onReset, onClose }) => {
  // flag event creation
  const isCreateMode = !event;

  // store original event for reset functionality
  const originalEvent: InvestigationScheduleEvent = event?.event || {
    id: uuidv4(),
    startTime: dayjs().hour(9).minute(0).second(0).valueOf(),
    endTime: dayjs().hour(10).minute(0).second(0).valueOf(),
    userName: '',
    userEmail: '',
    duration: 1,
    capacity: 0,
    type: 'added-spike',
  };

  // state for edited values
  const [assignee, setAssignee] = useState(event?.primary || '');
  const [startTime, setStartTime] = useState(dayjs(originalEvent.startTime));
  const [endTime, setEndTime] = useState(dayjs(originalEvent.endTime));

  // deletion state
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);

  // update preview whenever values change
  useEffect(() => {
    if (!assignee) {
      onPreviewUpdate(null);
      return;
    }

    const updatedEvent = {
      ...originalEvent,
      startTime: startTime.valueOf(),
      endTime: endTime.valueOf(),
      userName: assignee,
      duration: (endTime.valueOf() - startTime.valueOf()) / (1000 * 60 * 60),
    };

    onPreviewUpdate({
      primary: assignee,
      event: updatedEvent,
    });
  }, [assignee, startTime, endTime]);

  // handle time validation
  const handleStartTimeChange = (newValue: dayjs.Dayjs | null) => {
    if (!newValue) return;
    setStartTime(newValue);
    if (newValue.isAfter(endTime)) {
      // keep same duration, just update the date
      const duration = endTime.diff(startTime, 'minute');
      setEndTime(newValue.add(duration, 'minute'));
    }
  };

  const handleEndTimeChange = (newValue: dayjs.Dayjs | null) => {
    if (!newValue) return;
    setEndTime(newValue);
    if (newValue.isBefore(startTime)) {
      // keep same duration, just update the date
      const duration = endTime.diff(startTime, 'minute');
      setStartTime(newValue.subtract(duration, 'minute'));
    }
  };

  // handle reset
  const handleReset = () => {
    setAssignee(event?.primary || '');
    setStartTime(dayjs(originalEvent.startTime));
    setEndTime(dayjs(originalEvent.endTime));
    onReset();
  };

  // handle save
  const handleSave = () => {
    const updatedEvent = {
      ...originalEvent,
      startTime: startTime.valueOf(),
      endTime: endTime.valueOf(),
      userName: assignee,
      duration: (endTime.valueOf() - startTime.valueOf()) / (1000 * 60 * 60),
    };

    if (isCreateMode) {
      // add new event to schedule
      schedule.events = [...schedule.events, updatedEvent];
    } else {
      // update existing event at correct index
      const eventIndex = schedule.events.findIndex((e) => e.id === originalEvent.id);
      if (eventIndex === -1) return;
      schedule.events = [...schedule.events.slice(0, eventIndex), updatedEvent, ...schedule.events.slice(eventIndex + 1)];
    }

    onPreviewUpdate(null);
    toggleDrawer(false);
  };

  // handle delete
  const handleDelete = () => {
    const eventIndex = schedule.events.findIndex((e) => e.id === originalEvent.id);
    if (eventIndex === -1) return;

    // clear the preview
    onPreviewUpdate(null);

    // remove the event from the schedule
    schedule.events.splice(eventIndex, 1);
    setDeleteConfirmOpen(false);
    toggleDrawer(false);
  };

  return (
    <Box sx={{ width: '30vw', p: 3 }} role="presentation" onClick={(e) => e.stopPropagation()}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h6">{isCreateMode ? 'Add Event' : 'Edit Event'}</Typography>
        {!isCreateMode && (
          <IconButton color="error" onClick={() => setDeleteConfirmOpen(true)} aria-label="delete event">
            <DeleteIcon />
          </IconButton>
        )}
      </Box>

      <Stack spacing={3}>
        <Box>
          <FormControl fullWidth>
            <InputLabel id="assignee-label">Assignee</InputLabel>
            <Select
              labelId="assignee-label"
              label="Assignee"
              fullWidth
              value={assignee}
              onChange={(e) => setAssignee(e.target.value)}
              error={!assignee && isCreateMode}
            >
              {users.map((user) => (
                <MenuItem key={user} value={user}>
                  {user}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker label="Start Time" value={startTime} onChange={handleStartTimeChange} />

          <DateTimePicker label="End Time" value={endTime} onChange={handleEndTimeChange} />
        </LocalizationProvider>

        <Stack direction="row" spacing={2}>
          <Button variant="outlined" onClick={handleReset}>
            Reset Changes
          </Button>
          <Button variant="contained" onClick={handleSave} disabled={!assignee}>
            {isCreateMode ? 'Add Event' : 'Save Changes'}
          </Button>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
        </Stack>
      </Stack>
      <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
        <DialogTitle>Confirm Event Deletion</DialogTitle>
        <DialogContent>Are you sure you want to delete this event? This action cannot be undone.</DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
          <Button onClick={handleDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EventDrawer;
