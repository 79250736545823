import React, { useRef, useState, useEffect } from 'react';
import { Chart } from 'react-google-charts';
import { Box, Typography, IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { hslStringToRgbString, rgbaToHex, stringToColor } from '@/utils/colorUtils';

import type { InvestigationSchedule } from './investigationSchedulingTypes';

interface ScheduleBreakdownsProps {
  selectedSchedule: { schedule: InvestigationSchedule; index: number } | null;
}

const CARD_WIDTH = 250; // width of each card
const CARD_GAP = 16; // gap between cards (equivalent to theme spacing(2))

// memoize chart options to prevent unnecessary re-renders
const getChartOptions = () => ({
  height: 200,
  legend: { position: 'bottom' },
  pieSliceText: 'percentage',
  colors: ['#3b82f6', '#eab30b', '#ef4444', '#22c55e'],
  chartArea: { width: '100%', height: '70%' },
  backgroundColor: 'transparent', // make chart background transparent
  tooltip: {
    trigger: 'hover',
    text: 'value',
    textStyle: { fontSize: 12 },
    showColorCode: true,
  },
});

/**
 * Get the background color for a member card in hex with 10% opacity
 */
const getMemberBackgroundColor = (name: string): string => {
  // Get the base color
  const baseColor = stringToColor(name);
  // Convert to RGB format

  const rgbColor = hslStringToRgbString(baseColor);

  // convert to hex with opacity
  const hexColor = rgbaToHex(rgbColor);
  return hexColor.slice(0, 7) + '13'; // 13 is hex for 7.5% opacity
};

const ScheduleBreakdowns: React.FC<ScheduleBreakdownsProps> = ({ selectedSchedule }) => {
  if (!selectedSchedule) return null;

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const checkScroll = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth - 2); // small buffer to account for rounding
    }
  };

  useEffect(() => {
    checkScroll();
    window.addEventListener('resize', checkScroll);
    return () => window.removeEventListener('resize', checkScroll);
  }, []);

  const scroll = (direction: 'left' | 'right') => {
    if (scrollContainerRef.current) {
      const cardFullWidth = CARD_WIDTH + CARD_GAP; // total width including gap
      const currentScroll = scrollContainerRef.current.scrollLeft;
      const visibleCards = Math.floor(scrollContainerRef.current.clientWidth / cardFullWidth);

      // scroll by the width of visible cards
      const scrollAmount = visibleCards * cardFullWidth;
      const newScrollLeft =
        direction === 'left'
          ? Math.floor(currentScroll / cardFullWidth) * cardFullWidth - scrollAmount
          : Math.ceil(currentScroll / cardFullWidth) * cardFullWidth + scrollAmount;

      scrollContainerRef.current.scrollTo({
        left: newScrollLeft,
        behavior: 'smooth',
      });
    }
  };

  const { qaTeam } = selectedSchedule.schedule;

  return (
    <Box sx={{ mt: 2, p: 1 }}>
      <Typography variant="h6" sx={{ mb: 2, pl: 2 }}>
        Team Member Breakdowns
      </Typography>
      <Box sx={{ position: 'relative', mx: 2 }}>
        {showLeftArrow && (
          <IconButton
            onClick={() => scroll('left')}
            sx={{
              position: 'absolute',
              left: -16,
              top: '50%',
              transform: 'translateY(-50%)',
              bgcolor: 'background.paper',
              boxShadow: 2,
              zIndex: 2,
              '&:hover': { bgcolor: 'background.paper' },
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        )}
        {showRightArrow && (
          <IconButton
            onClick={() => scroll('right')}
            sx={{
              position: 'absolute',
              right: -16,
              top: '50%',
              transform: 'translateY(-50%)',
              bgcolor: 'background.paper',
              boxShadow: 2,
              zIndex: 2,
              '&:hover': { bgcolor: 'background.paper' },
            }}
          >
            <ChevronRightIcon />
          </IconButton>
        )}
        <Box
          ref={scrollContainerRef}
          onScroll={checkScroll}
          sx={{
            display: 'flex',
            gap: 2,
            overflowX: 'auto',
            pb: 2,
            scrollSnapType: 'x mandatory',
            // hide scrollbar in different browsers while maintaining functionality
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {qaTeam.members.map((member) => {
            // calculate total hours
            const totalHours =
              member.timeBreakdown.investigation + member.timeBreakdown.externalMeeting + member.timeBreakdown.ooo + member.timeBreakdown.free;

            const data = [
              ['Task', 'Hours'],
              ['Investigation', member.timeBreakdown.investigation],
              ['External Meetings', member.timeBreakdown.externalMeeting],
              ['Out of Office', member.timeBreakdown.ooo],
              ['Available', member.timeBreakdown.free],
            ];

            const backgroundColor = getMemberBackgroundColor(member.name);
            const chartOptions = getChartOptions();

            return (
              <Box
                key={member.email}
                sx={{
                  p: 2,
                  bgcolor: backgroundColor,
                  borderRadius: 1,
                  boxShadow: 1,
                  minWidth: `${CARD_WIDTH}px`,
                  flex: '0 0 auto',
                  scrollSnapAlign: 'start',
                  // use transform to create a new stacking context
                  transform: 'translate3d(0,0,0)',
                  '& .google-visualization-tooltip': {
                    position: 'fixed',
                    zIndex: 9999,
                    pointerEvents: 'none',
                  },
                  '& > div': {
                    // target the chart container
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  {member.name}
                </Typography>
                <Chart chartType="PieChart" data={data} options={chartOptions} />
                <Box sx={{ mt: 1 }}>
                  <Typography variant="body2" color="text.secondary">
                    {`Total hours: ${totalHours}`}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default ScheduleBreakdowns;
