import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Box, Stack } from '@mui/material';
import { useMemo } from 'react';
import { max } from 'lodash';

export default function CustomerOverviewTable({ tableData }) {
  const columns = useMemo(() => {
    return [
      {
        header: 'Customer Name',
        muiTableHeadCellProps: { title: 'Customer Name' },
        accessorKey: 'customerName',
        filterVariant: 'multi-select',
      },
      {
        header: 'Team',
        muiTableHeadCellProps: { title: 'QA Team Managing Customer' },
        accessorKey: 'team',
        filterVariant: 'multi-select',
      },
      {
        header: 'Lead',
        muiTableHeadCellProps: { title: 'Team Lead' },
        accessorKey: 'lead',
        filterVariant: 'multi-select',
      },
      {
        header: 'Manager',
        muiTableHeadCellProps: { title: 'Pack Manager' },
        accessorKey: 'manager',
        filterVariant: 'multi-select',
      },
      {
        header: 'CSM',
        muiTableHeadCellProps: { title: 'Customer Success Manager' },
        accessorKey: 'csm',
        filterVariant: 'multi-select',
      },
      {
        header: 'ARR',
        muiTableHeadCellProps: { title: "Customer's Annual Recurring Revenue" },
        accessorKey: 'arr',
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        muiFilterSliderProps: {
          min: 0,
          max: max(tableData.map((customer) => customer.arr || 0)),
          step: 10_000,
          valueLabelFormat: (value) =>
            value.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 0,
            }),
        },
        Cell: ({ cell }) =>
          (cell.getValue() || 0).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
          }),
        Footer: ({ table }) => {
          const filteredTotal = table.getFilteredRowModel().rows.reduce((acc, row) => acc + (row.original.arr || 0), 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length || 1;
          return (
            <Stack>
              Average:
              <Box>
                {(filteredTotal / filteredRowCount).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}
              </Box>
            </Stack>
          );
        },
      },
      {
        header: 'Tests Under Contract',
        muiTableHeadCellProps: { title: 'Amount of Tests Contracted with Customer' },
        accessorKey: 'testsUnderContract',
        Cell: ({ cell }) => (cell.getValue() || 0).toLocaleString(),
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        muiFilterSliderProps: {
          min: 0,
          max: max(tableData.map((customer) => customer.testsUnderContract || 0)),
          step: 100,
          valueLabelFormat: (value) => value.toLocaleString('en-US', { maximumFractionDigits: 0 }),
        },
        Footer: ({ table }) => {
          const filteredTotal = table.getFilteredRowModel().rows.reduce((acc, row) => acc + (row.original.testsUnderContract || 0), 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length || 1;
          return (
            <Stack>
              Average:
              <Box>{(filteredTotal / filteredRowCount).toLocaleString('en-US', { maximumFractionDigits: 0 })}</Box>
            </Stack>
          );
        },
      },
      {
        header: 'Active Tests',
        muiTableHeadCellProps: { title: 'Amount of Tests that have been implemented, includes Bugged and Maintenance' },
        id: 'testsActive',
        accessorFn: (row) => row.testsActive || 0,
        Cell: ({ cell }) => (cell.getValue() || 0).toLocaleString(),
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        muiFilterSliderProps: {
          min: 0,
          max: max(tableData.map((customer) => customer.testsActive || 0)),
          step: 100,
          valueLabelFormat: (value) => value.toLocaleString('en-US', { maximumFractionDigits: 0 }),
        },
        Footer: ({ table }) => {
          const filteredTotalActive = table.getFilteredRowModel().rows.reduce((acc, row) => acc + (row.original.testsActive || 0), 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length || 1;
          return (
            <Stack>
              Average:
              <Box>{(filteredTotalActive / filteredRowCount).toLocaleString('en-US', { maximumFractionDigits: 0 })}</Box>
            </Stack>
          );
        },
      },
      {
        header: '% Tests in Maintenance',
        muiTableHeadCellProps: { title: 'Percentage of Active Tests in Maintenance' },
        id: 'testsInMaintenance',
        accessorFn: (row) => {
          if (!row.testsActive) return '0%';
          const maintenancePercentage = Math.round(((row.testsInMaintenance || 0) / row.testsActive) * 100);
          return maintenancePercentage + '%';
        },
        Cell: ({ cell }) => cell.getValue().toLocaleString(),
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        muiFilterSliderProps: {
          min: 0,
          max: 100,
          step: 5,
          valueLabelFormat: (value) => value.toLocaleString('en-US', { maximumFractionDigits: 0 }) + '%',
        },
        Footer: ({ table }) => {
          const filteredMaintenancePercentage = table.getFilteredRowModel().rows.reduce((acc, row) => {
            if (!row.original.testsActive) return acc;
            const maintenancePercentage = Math.round(((row.original.testsInMaintenance || 0) / row.original.testsActive) * 100);
            return acc + maintenancePercentage;
          }, 0);
          const filteredRowCount = table.getFilteredRowModel().rows.filter((row) => row.original.testsActive > 0).length || 1;
          return (
            <Stack>
              Average:
              <Box>{Math.round(filteredMaintenancePercentage / filteredRowCount) + '%'}</Box>
            </Stack>
          );
        },
      },
      {
        header: '% Tests Bugged',
        muiTableHeadCellProps: { title: 'Percentage of Active Tests that are Bugged' },
        id: 'testsBugged',
        accessorFn: (row) => {
          if (!row.testsActive) return '0%';
          const buggedPercentage = Math.round(((row.testsBugged || 0) / row.testsActive) * 100);
          return buggedPercentage + '%';
        },
        Cell: ({ cell }) => cell.getValue().toLocaleString(),
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        muiFilterSliderProps: {
          min: 0,
          max: 100,
          step: 5,
          valueLabelFormat: (value) => value.toLocaleString('en-US', { maximumFractionDigits: 0 }) + '%',
        },
        Footer: ({ table }) => {
          const filteredBuggedPercentage = table.getFilteredRowModel().rows.reduce((acc, row) => {
            if (!row.original.testsActive) return acc;
            const buggedPercentage = Math.round(((row.original.testsBugged || 0) / row.original.testsActive) * 100);
            return acc + buggedPercentage;
          }, 0);
          const filteredRowCount = table.getFilteredRowModel().rows.filter((row) => row.original.testsActive > 0).length || 1;
          return (
            <Stack>
              Average:
              <Box>{Math.round(filteredBuggedPercentage / filteredRowCount) + '%'}</Box>
            </Stack>
          );
        },
      },
    ];
  }, [tableData]);

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 15,
      },
      sorting: [{ id: 'customerName', desc: false }],
    },
    columnFilterDisplayMode: 'popover',
    enableColumnActions: true,
    enableColumnFilters: true,
    enableTopToolbar: true,
    enableTableFooter: true,
    enableFacetedValues: true,
  });

  return <MaterialReactTable table={table} />;
}
