import { AxiosError } from 'axios';
import { sendGetRequest } from '../../utils/network';
import { QaeUtilizationFilter, TeamUtilizationFilter } from './timeUtilizationTypes';
import { QaeTimeUtilizationData, TeamTimeUtilizationData } from './timeUtilizationTypes';

export async function getTimeUtilizationInsights(
  filters: QaeUtilizationFilter | TeamUtilizationFilter,
): Promise<QaeTimeUtilizationData | TeamTimeUtilizationData> {
  try {
    const { entity } = filters;

    let url = `/time-utilization/${entity?.type}/${entity?.type === 'team' ? entity.data.name : entity.data.id}`;
    url += `?gte=${filters.gte}&lte=${filters.lte}`;

    const timeUtilizationInsightsResponse = await sendGetRequest(url);

    return timeUtilizationInsightsResponse.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw Error(error.response?.data.message || error.message);
    } else {
      throw error;
    }
  }
}

export function formatTime(ms: number): string {
  const milliseconds = ms % 1000; // calculate milliseconds
  const seconds = Math.floor((ms / 1000) % 60);
  const minutes = Math.floor((ms / (1000 * 60)) % 60);
  const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
  const days = Math.floor(ms / (1000 * 60 * 60 * 24));

  const parts = [];
  if (days > 0) parts.push(`${days} day${days > 1 ? 's' : ''}`);
  if (hours > 0) parts.push(`${hours} hr${hours > 1 ? 's' : ''}`);
  if (minutes > 0) parts.push(`${minutes} min${minutes > 1 ? 's' : ''}`);
  if (seconds > 0 || milliseconds > 0) {
    parts.push(`${seconds}.${milliseconds} sec${seconds !== 1 || milliseconds > 0 ? 's' : ''}`);
  }

  return parts.join(' ');
}
