import { InvestigationBoardData } from '../types/investigation';

export function getTaskFromInvestigations(investigations: InvestigationBoardData, investigationId: string) {
    // Check ongoing tasks
    const ongoingTasks = [
        ...investigations.ongoing.currentUserTasks,
        ...investigations.ongoing.otherUserTasks,
        ...investigations.ongoing.unclaimedTasks,
    ];

    // Check ongoing tasks first
    const ongoingTask = ongoingTasks.find((task) => task.id === investigationId);
    if (ongoingTask) {
        return ongoingTask;
    }

    // Check completed tasks
    const completedTask = investigations.completed.find((task) => task.id === investigationId);
    if (completedTask) {
        return completedTask;
    }

    return null;
}
