import React, { useState } from 'react';
import * as Yup from 'yup';
import { sendPostRequest } from '../../utils/network';
import { getAllJobSources } from '../HiringInternal/HiringUtils/api';
import { useQuery } from '@tanstack/react-query';
import { JobSource } from '../HiringInternal/Types/types';
import { AxiosError } from 'axios';
import { timeSlotOptions } from './TimeSlots';

const generateInitialTimeSlots = () => {
  return timeSlotOptions.reduce((acc, { key }) => {
    acc[key] = false;
    return acc;
  }, {} as Record<string, boolean>);
};

const initialFormState = {
  firstName: '',
  lastName: '',
  email: '',
  location: '',
  referralName: '',
  jobSource: '',
  timeSlot: generateInitialTimeSlots(),
  videoSubmission: '',
  instituteName: '',
  file: null,
};

interface FormErrors {
  firstName?: string;
  lastName?: string;
  email: string;
  location: string;
  referralName: string;
  jobSource: string;
  timeSlot?: Record<string, string>;
  videoSubmission: string;
  instituteName: string;
  file: string;
  [key: string]: string | Record<string, string> | undefined;
}
interface UploadAssignmentFormProps {
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
  setServerError: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function UploadAssignmentForm({ setShowForm, setServerError }: UploadAssignmentFormProps) {
  const [formState, setFormState] = useState(initialFormState);
  const [isReferred, setIsReferred] = useState(false);
  const [fileInputKey, setFileInputKey] = useState('');
  const [errors, setErrors] = useState<FormErrors | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasJobSourcesBeenFetched, setHasJobSourcesBeenFetched] = useState(false);

  const {
    data: jobSources,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['allJobSources'],
    queryFn: getAllJobSources,
    select: (data) => data.map((sources: JobSource) => sources.sourceName),
    enabled: false,
  });

  const formValidationSchema = Yup.object({
    firstName: Yup.string().min(2, 'First name must be at least 2 characters').required('First name is required'),
    lastName: Yup.string().min(2, 'Last name must be at least 2 characters').required('Last name is required'),
    email: Yup.string().email('Invalid email format').min(5, 'Email must be at least 5 characters').required('Email is required'),
    location: Yup.string().min(2, 'Location must be at least 2 characters').required('Location is required'),
    referralName: Yup.string(),
    jobSource: Yup.string().required('Job source is required'),
    timeSlot: Yup.object()
      .test(
        'at-least-one-selected',
        'Please select at least one time slot',
        (value) => Object.values(value || {}).some((v) => v),
      ),
    videoSubmission: Yup.string()
      .url('Enter a valid URL!')
      .test('is-loom-url', 'URL must be a Loom video link!', (value) => {
        if (!value) return false;
        try {
          const url = new URL(value);
          return url.host === 'loom.com' || url.hostname === 'www.loom.com';
        } catch (err) {
          return false;
        }
      })
      .required('A video walkthrough is required'),
    instituteName: Yup.string(),
    file: Yup.mixed()
      .required('File is required')
      .test('fileSize', 'File exceeds the maximum supported size of 20 MB', (value) => {
        if (value instanceof File || value instanceof Blob) {
          return value.size <= 1024 * 1024 * 20; // Check file size
        }
        return false;
      })
      .test('fileType', 'Unsupported file format (7zip and rar) - only zip files are accepted', (value) => {
        if (value instanceof File) {
          return (
            value.type === 'application/zip' ||
            value.type === 'application/x-zip' ||
            value.type === 'application/x-zip-compressed' ||
            value.type === 'application/x-compress' ||
            value.type === 'application/x-compressed' ||
            value.type === 'multipart/x-zip' ||
            value.type === 'application/gzip'
          );
        }
        return false;
      }),
  });

  const handleSubmit = async (e: React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
    setIsSubmitting(true); // Set submitting state to true when button is clicked

    try {
      await formValidationSchema.validate(formState, { abortEarly: false });

      setShowForm(false); // Hide form and reset state after successful submission

      // Prepare FormData by iterating over formState directly
      const formData = new FormData();
      Object.entries(formState).forEach(([key, value]) => {
        if (key === 'timeSlot') {
          formData.append('timeSlot', JSON.stringify(value));
        } else if (key === 'file' && value instanceof File) {
          formData.append('file', value);
        } else if (value !== null) {
          formData.append(key, String(value));
        }
      });

      // Send form data via POST request
      await sendPostRequest('/hiring-public/submission', formData, 'multipart/form-data');

      setFormState(initialFormState); // Reset form state to initial values
      setFileInputKey(Math.random().toString(36)); // Generate a new key for the file input
      setErrors(null); // Clear any validation errors
    } catch (error) {
      // Handle validation errors from Yup
      if (error instanceof Yup.ValidationError) {
        const validationErrors = error.inner.reduce((acc, err) => {
          if (err.path) {
            acc[err.path] = err.message;
          }
          return acc;
        }, {} as FormErrors);

        // Set validation errors in state
        setErrors(validationErrors);
      } else if (error instanceof AxiosError) {
        console.log('Error sending post request for UploadAssignmentForm:', error);
        setServerError(true);
        return;
      } else {
        console.error('An unexpected error occurred:', error);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCheckboxChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsReferred(e.target.checked);
  };

  const handleTimeSlotChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      timeSlot: {
        ...prevState.timeSlot,
        [name]: checked,
      },
    }));
  };

  const handleInputChanges = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    // Narrow the type based on the element's name or type
    if (e.target instanceof HTMLInputElement) {
      // Check if the target is a file input
      if (e.target.name === 'file') {
        const file = e.target.files ? e.target.files[0] : null; // Check if files is not null

        setFormState((prevState) => {
          return { ...prevState, [e.target.name]: file };
        });
      } else {
        setFormState((prevState) => {
          return { ...prevState, [e.target.name]: e.target.value };
        });
      }
    } else if (e.target instanceof HTMLSelectElement) {
      // Handle the case for HTMLSelectElement (dropdown)
      setFormState((prevState) => {
        return { ...prevState, [e.target.name]: e.target.value };
      });
    }
  };

  const handleJobSourceInteraction = () => {
    if (!hasJobSourcesBeenFetched) {
      refetch();
      setHasJobSourcesBeenFetched(true);
    }
  };

  return (
    <div className="flex flex-col w-5/6 items-center" id="form-container">
      <form className="w-5/6 flex flex-col" onSubmit={handleSubmit}>
        {/* First Flex Row */}
        <section className="flex flex-wrap justify-between w-full">
          <div className="flex flex-col w-[48%] inputs">
            <label className="font-semibold" htmlFor="firstName">
              First Name:
            </label>
            <input
              className="m-[10px 0px] text-black bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              id="firstName"
              type="text"
              name="firstName"
              placeholder="First Name"
              onChange={handleInputChanges}
              value={formState.firstName}
            />
            {errors?.firstName && <div className="text-[#ff4c4c] formError">{errors?.firstName}</div>}
          </div>

          <div className="flex flex-col w-[48%] inputs">
            <label className="font-semibold" htmlFor="lastName">
              Last Name:
            </label>
            <input
              className="m-[10px 0px] text-black bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              id="lastName"
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formState.lastName}
              onChange={handleInputChanges}
            />
            {errors?.lastName && <div className="text-[#ff4c4c] formError">{errors?.lastName}</div>}
          </div>
        </section>

        {/* Second Flex Row */}
        <section className="flex flex-wrap justify-between w-full mt-4">
          <div className="flex flex-col w-[48%] inputs">
            <label className="font-semibold" htmlFor="email">
              Email:
            </label>
            <input
              className="m-[10px 0px] text-black bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              id="email"
              type="email"
              autoComplete="email"
              name="email"
              placeholder="Enter your email"
              value={formState.email}
              onChange={handleInputChanges}
            />
            {errors?.email && <div className="text-[#ff4c4c] formError">{errors?.email}</div>}
          </div>

          <div className="flex flex-col w-[48%] inputs">
            <label className="font-semibold" htmlFor="location">
              Location (City, State/Province, Country):
            </label>
            <input
              className="m-[10px 0px] text-black bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              id="location"
              type="text"
              name="location"
              placeholder="(e.g., New York, NY, USA)"
              value={formState.location}
              onChange={handleInputChanges}
            />
            {errors?.location && <div className="text-[#ff4c4c] formError">{errors?.location}</div>}
          </div>
        </section>

        {/* Third Flex Row */}
        <section className="flex flex-wrap justify-between w-full mt-4">
          <div className="flex flex-col w-[48%] inputs">
            <label className="font-semibold" htmlFor="jobSource">
              Job Source:
            </label>
            <select
              className={`m-[10px 0px] bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${formState.jobSource ? 'text-black' : 'text-gray-500'}`}
              id="jobSource"
              name="jobSource"
              onChange={handleInputChanges}
              value={formState.jobSource}
              onClick={handleJobSourceInteraction}
              onFocus={handleJobSourceInteraction}
            >
              <option value="" disabled>
                {isLoading || isFetching ? 'Loading job sources...' : 'Where did you find this job?'}
              </option>

              {!isLoading &&
                !isFetching &&
                !isError &&
                jobSources &&
                jobSources.map((jobSource: string, i: number) => {
                  if (jobSource !== 'BackDated') {
                    return (
                      <option key={i} value={jobSource.toLowerCase()}>
                        {jobSource}
                      </option>
                    );
                  }
                })}
            </select>
            {errors?.jobSource && <div className="text-[#ff4c4c] formError">{errors?.jobSource}</div>}
          </div>

          <div className="flex flex-col w-[48%] inputs">
            <label className="font-semibold" htmlFor="bootcamp">
              Institute name (optional):
            </label>
            <input
              className="m-[10px 0px] text-black bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              id="bootcamp"
              type="text"
              name="instituteName"
              placeholder="Enter the name of the Bootcamp or University"
              value={formState.instituteName}
              onChange={handleInputChanges}
            />
            {errors?.instituteName && <div className="text-[#ff4c4c] formError">{errors?.instituteName}</div>}
          </div>
        </section>

        <div className="flex flex-col w-full mt-4">
          <label className="font-semibold">Time slots willing to work:</label>
          {timeSlotOptions.map(({ key, label, description }) => (
            <div key={key} className="flex items-center inputs">
              <input
                id={key}
                type="checkbox"
                name={key}
                className="m-[10px 0px] mr-3 cursor-pointer"
                onChange={handleTimeSlotChanges}
                checked={formState.timeSlot[key]}
              />
              <label htmlFor={key} className="cursor-pointer">
                <strong>{label}</strong> <em>({description})</em>
              </label>
            </div>
          ))}
          {errors?.timeSlot && (
            <div className="text-[#ff4c4c] formError">
              {Object.values(formState.timeSlot).every((v) => !v) && 'Please select at least one time slot'}
            </div>
          )}
        </div>

        <div className="flex flex-col inputs mt-[3%]">
          <label className="font-semibold" htmlFor="videoSubmission">
            Code walkthrough link:
          </label>
          <input
            className={`m-[10px 0px] text-black bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
            id="videoSubmission"
            type="text"
            name="videoSubmission"
            placeholder="Link to code walkthrough"
            value={formState.videoSubmission}
            onChange={handleInputChanges}
          />
          {errors?.videoSubmission && <div className="text-[#ff4c4c] formError">{errors?.videoSubmission}</div>}
        </div>

        {/* Remaining fields */}
        <div className="flex flex-col w-full mt-6">
          <div className="flex items-center inputs">
            <input id="referral" type="checkbox" name="referral" className="m-[10px 0px] mr-3" onChange={handleCheckboxChanges} />
            <label className="font-semibold" htmlFor="referral">
              I was referred by a current QA Wolf employee
            </label>
          </div>

          {isReferred && (
            <div className="flex flex-col inputs mt-4">
              <label className="font-semibold" htmlFor="referee">
                Enter the name of the employee you were referred by:
              </label>
              <input
                className="text-black m-[10px 0px] bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                id="referee"
                type="text"
                name="referralName"
                placeholder="Name"
                value={formState.referralName}
                onChange={handleInputChanges}
              />

              {errors?.referralName && <div className="text-[#ff4c4c] formError">{errors?.referralName}</div>}
            </div>
          )}

          <div className="flex flex-col mt-4">
            <label className="font-semibold mb-2 ml-4" htmlFor="file">
              Upload your take-home assessment
            </label>
            <input
              className="ml-4"
              id="file"
              type="file"
              name="file"
              key={fileInputKey || ''}
              style={{ color: 'white' }}
              onChange={handleInputChanges}
            />

            {errors?.file && <div className="text-[#ff4c4c] formError">{errors?.file}</div>}
          </div>

          <button
            type="submit"
            className={
              !isSubmitting
                ? 'flex justify-center items-center self-center mt-6 rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                : 'flex justify-center items-center self-center mt-6 rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm cursor-not-allowed'
            }
            disabled={isSubmitting}
          >
            {!isSubmitting ? 'Submit assignment' : 'Submitting...'}
          </button>
        </div>
      </form>
    </div>
  );
}
