import { useEffect, useMemo } from 'react';
import cytoscape from 'cytoscape';
import coseBilkent from 'cytoscape-cose-bilkent';
import Toast from '../Layout/Toast';
import ConfirmationModal from './ConfirmDeletionModal';
import ErrorBoundary from './components/ErrorBoundary';
import { ChartBarIcon } from '@heroicons/react/24/outline';

// Hooks
import { useFetchFailureSignatures } from './hooks/useFetchFailureSignatures';
import { useCytoscapeGraph } from './hooks/useCytoscapeGraph';
import { useDeleteSuite } from './hooks/useDeleteSuite';
import { useSuiteId } from './hooks/useSuiteId';
import { useActiveView } from './hooks/useActiveView';
import { useSelection } from './hooks/useSelection';

// Components
import GraphDisplay from './components/GraphDisplay';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import SearchForm from './components/SearchForm';

// Utils
import { getBlocksData } from './utils/buildingBlockUtils';
import { getGraphElements } from './utils/graphUtils';
import { handleBlockSelection as handleBlockSelectionHelper } from './helpers';

// Register the layout
cytoscape.use(coseBilkent);

/**
 * FailureSignature component for visualizing and analyzing test failures
 * Provides a graph visualization of building blocks and workflows
 */
export default function FailureSignature() {
  // Suite ID and URL handling
  const { suiteId, inputValue, setInputValue, handleSubmit } = useSuiteId();

  // Selection state
  const {
    selectedBlock,
    setSelectedBlock,
    selectedWorkflow,
    setSelectedWorkflow,
    selectedSequenceIndex,
    setSelectedSequenceIndex,
    selectedAttempt,
    setSelectedAttempt,
  } = useSelection();

  // Active view and search
  const { activeView, setActiveView, workflowSearch, setWorkflowSearch, blockSearch, setBlockSearch } = useActiveView(
    selectedWorkflow,
    selectedBlock,
  );

  // Failure signature generation
  const { isGenerating, isLoading, isError, longRunningMessage, toast, setToast, currentData, jobProgress } = useFetchFailureSignatures(suiteId);

  // Delete suite functionality
  const { isDeleting, isDeleteModalOpen, setIsDeleteModalOpen, handleDelete } = useDeleteSuite(suiteId, currentData, setToast);

  // Cytoscape graph handling
  const { cyRef, handleRefreshLayout, handleWorkflowSelection, navigateWorkflowSequence, handleSequenceBlockSelection } = useCytoscapeGraph({
    currentData,
    selectedWorkflow,
    setSelectedWorkflow,
    selectedBlock,
    setSelectedBlock,
    selectedSequenceIndex,
    setSelectedSequenceIndex,
    selectedAttempt,
    setSelectedAttempt,
    activeView,
    setActiveView,
  });

  // Memoize expensive computations
  const elements = useMemo(() => getGraphElements(currentData, selectedAttempt), [currentData, selectedAttempt]);
  const blocksData = useMemo(() => getBlocksData(currentData), [currentData]);

  // Update elements when currentData changes
  useEffect(() => {
    if (currentData && elements.length > 0) {
      // Add console.log to debug
      console.log('CurrentData available:', currentData);
      console.log('Elements generated:', elements);
      // Run layout after elements are set
      setTimeout(() => handleRefreshLayout(), 100);
    }
  }, [currentData, elements]);

  // Extract metadata from currentData
  const metadata = useMemo(() => {
    if (!currentData)
      return {
        suiteName: null,
        environmentId: null,
        customerName: null,
        customerSlug: null,
      };

    return {
      suiteName: currentData[0].suiteName || null,
      environmentId: currentData[0].environmentId || null,
      customerName: currentData[0].sequence.customer.officialName || null,
      customerSlug: currentData[0].sequence.customer.slug || null,
    };
  }, [currentData]);

  /**
   * Handle block selection from the sidebar
   * @param {string} blockId - ID of the block to select
   */
  const handleBlockSelection = (blockId: string) => {
    handleBlockSelectionHelper(
      cyRef.current,
      blockId,
      selectedBlock,
      setSelectedBlock,
      setSelectedWorkflow,
      selectedAttempt,
      setSelectedSequenceIndex,
      currentData,
      setActiveView,
      selectedWorkflow,
      false,
    );
  };

  // Custom error fallback UI
  const errorFallback = (
    <div className="p-6 bg-gray-800 text-white rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Something went wrong with the Failure Signature component</h2>
      <p className="mb-4">
        We encountered an error while rendering the failure signature visualization. Please try refreshing the page or contact support if the issue
        persists.
      </p>
      <button onClick={() => window.location.reload()} className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700">
        Refresh Page
      </button>
    </div>
  );

  return (
    <ErrorBoundary fallback={errorFallback}>
      <div className="flex h-screen bg-gray-900">
        {toast && <Toast {...toast} show={true} onDone={() => setToast(null)} message={toast.message} />}

        <ConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={handleDelete}
          isProcessing={isDeleting}
        />

        {/* Main Content */}
        <div className="flex-1 min-w-0 overflow-hidden">
          <div className="p-6">
            <Header
              customerName={metadata.customerName}
              suiteName={metadata.suiteName}
              customerSlug={metadata.customerSlug}
              environmentId={metadata.environmentId}
              suiteId={suiteId}
              isLoading={isLoading}
              isDeleting={isDeleting}
              currentData={currentData}
              handleDelete={() => handleDelete()}
            />

            <div className="mb-5 flex items-center justify-between">
              <SearchForm inputValue={inputValue} setInputValue={setInputValue} handleSubmit={handleSubmit} />

              {suiteId && (
                <a
                  href={`https://www.task-wolf.com/flow-explorer/${suiteId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center text-blue-400 hover:text-blue-300 transition-colors ml-4"
                  aria-label="Open in Flow Explorer"
                >
                  <ChartBarIcon className="h-5 w-5 mr-1" aria-hidden="true" />
                  Flow Explorer
                </a>
              )}
            </div>

            <GraphDisplay
              isLoading={isLoading}
              isError={isError}
              isGenerating={isGenerating}
              longRunningMessage={longRunningMessage}
              jobProgress={jobProgress}
              elements={elements}
              cyRef={cyRef}
              handleRefreshLayout={handleRefreshLayout}
            />
          </div>
        </div>

        {/* Side Table */}
        <Sidebar
          activeView={activeView}
          setActiveView={setActiveView}
          workflowSearch={workflowSearch}
          setWorkflowSearch={setWorkflowSearch}
          blockSearch={blockSearch}
          setBlockSearch={setBlockSearch}
          currentData={currentData}
          selectedWorkflow={selectedWorkflow}
          selectedBlock={selectedBlock}
          selectedSequenceIndex={selectedSequenceIndex}
          selectedAttempt={selectedAttempt}
          blocksData={blocksData}
          handleWorkflowSelection={handleWorkflowSelection}
          navigateWorkflowSequence={navigateWorkflowSequence}
          handleSequenceBlockSelection={handleSequenceBlockSelection}
          handleBlockSelection={handleBlockSelection}
        />
      </div>
    </ErrorBoundary>
  );
}
