import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useMemo } from 'react';
import dayjs from 'dayjs';

export default function CustomerLogsTable({ customerLogs }) {
  const columns = useMemo(() => [
    {
      header: 'Date',
      accessorKey: 'createdAt',
      filterVariant: 'date-range',
      Cell: ({ cell }) => dayjs(cell.getValue()).format('YYYY MMM DD HH:mm:ssa'),
      sortingFn: 'datetime',
    },
    {
      header: 'Customer',
      accessorKey: 'customerName',
      filterVariant: 'multi-select',
    },
    {
      header: 'Action',
      accessorKey: 'type',
      filterVariant: 'multi-select',
    },
    {
      header: 'From',
      accessorKey: 'fromValue', 
      filterVariant: 'text',
      Cell: ({ cell, row }) => {
        const value = Number(cell.getValue());
        return row.original.type === 'mrrUpdated' 
          ? value.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })
          : value.toLocaleString('en-US', { maximumFractionDigits: 0 });
      },
    },
    {
      header: 'To',
      accessorKey: 'toValue',
      filterVariant: 'text', 
      Cell: ({ cell, row }) => {
        const value = Number(cell.getValue());
        return row.original.type === 'mrrUpdated'
          ? value.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })
          : value.toLocaleString('en-US', { maximumFractionDigits: 0 });
      },
    },
  ], []);

  const table = useMaterialReactTable({
    columns,
    data: customerLogs,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 5,
      },
      sorting: [{ id: 'createdAt', desc: true }],
    },
    enableColumnActions: true,
    enableColumnFilters: true,
    enableTopToolbar: true,
    enableBottomToolbar: true,
    enablePagination: true,
    enableSorting: true,
    enableFacetedValues: true,
    columnFilterDisplayMode: 'popover',
  });

  return <MaterialReactTable table={table} />;
}
