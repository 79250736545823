import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Box,
  Typography,
  ThemeProvider,
  Divider,
  Menu,
  IconButton,
  Badge,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { OutlinesProgressBar } from './OutlinesProgressBar';
import { CreationProgressBar } from './CreationProgressBar';
import { planningTheme } from '../utils/theme';
import { usePlanningContext } from '@/components/context/PlanningContext';
import { Customer, Warning } from '../types/types';
import MilestoneDrawer from '../../ClientMilestones/MainDrawer';
import { ClientSummaryTableRow } from '../../types';
import PlanningDrawer from '../../PlanningDrawer';
import { MaintenanceProgressBar } from './MaintenanceProgressBar';
import WarningIcon from './WarningIcon';
import LoadingRows from './LoadingRows';
import { defaultWarnings, useTableContext } from '../context/FilterContext';
import { useState } from 'react';
import { FilterList, Sort, AutoAwesome, ArrowUpward, ArrowDownward, Visibility, VisibilityOff } from '@mui/icons-material';
import { useFilteredCustomers } from '../hooks';

function CustomerInfoTooltipContent({ customer }: { customer: Customer }) {
  return (
    <Box className="flex gap-2" sx={{ width: 'max-content' }}>
      {/* QA Manager */}
      <Box className="w-full flex flex-col items-center justify-center">
        <Typography variant="caption" fontWeight="bold" width="100%" lineHeight={1.2}>
          Manager
        </Typography>
        <Typography variant="caption" width="100%" lineHeight={1.2}>
          {customer.qaManager.name}
        </Typography>
      </Box>

      <Divider orientation="vertical" flexItem />

      {/* QA Lead */}
      <Box className="w-full flex flex-col items-center justify-center">
        <Typography variant="caption" fontWeight="bold" width="100%" lineHeight={1.2}>
          Lead
        </Typography>
        <Typography variant="caption" width="100%" lineHeight={1.2}>
          {customer.qaLead.name}
        </Typography>
      </Box>

      <Divider orientation="vertical" flexItem />

      {/* CSM */}
      <Box className="w-full flex flex-col items-center justify-center">
        <Typography variant="caption" fontWeight="bold" width="100%" lineHeight={1.2}>
          CSM
        </Typography>
        <Typography variant="caption" width="100%" lineHeight={1.2}>
          {customer.csm.name}
        </Typography>
      </Box>
    </Box>
  );
}

function WarningTooltipContent({ warning }: { warning: Warning }) {
  return (
    <Box>
      <Typography variant="body2" fontWeight="bold">
        {warning.title}
      </Typography>
      <Divider />
      <Typography variant="caption">{warning.description}</Typography>
    </Box>
  );
}

function SortButton({ type, label }: { type: 'maintenance' | 'creation' | 'magic' | 'alphabetical' | 'weeks' | 'outlines'; label?: string }) {
  const { sortType, sortDirection, setSortType, setSortDirection } = useTableContext();
  const isActive = sortType === type;

  const handleClick = () => {
    if (isActive) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortType(type);
      setSortDirection('asc');
    }
  };

  const icon = isActive ? sortDirection === 'asc' ? <ArrowUpward /> : <ArrowDownward /> : <Sort />;

  return (
    <Tooltip title={`Sort by ${label || type}`}>
      <IconButton size="small" onClick={handleClick} color={isActive ? 'primary' : 'default'}>
        {icon}
      </IconButton>
    </Tooltip>
  );
}

export default function PlanningHubTable() {
  const { clientSummaries } = usePlanningContext();

  // Add weighted warnings to each customer
  const customers = (clientSummaries.data ?? []) as unknown as Customer[];

  const {
    sortType,
    sortDirection,
    setSortType,
    setSortDirection,
    warningFilters,
    hideFullyUtilized,
    setHideFullyUtilized,
    hideNoMaintenance,
    setHideNoMaintenance,
    selectAllWarningFilters,
  } = useTableContext();

  const filteredAndSortedCustomers = useFilteredCustomers({
    customers,
    sortType,
    order: sortDirection,
    warningFilters,
    selectAllWarningFilters,
    filters: {
      excludeNoMaint: hideNoMaintenance,
      excludeFullyUtilized: hideFullyUtilized,
      customerNames: [],
      excludedCustomerNames: [],
      warningTypes: [],
      excludedWarningTypes: [],
      excludeBlockedMilestones: false,
    },
  });

  return (
    <ThemeProvider theme={planningTheme}>
      <TableContainer component={Paper} sx={{ px: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '15%' }}>
                <Box className="flex items-center gap-2">
                  <Typography variant="subtitle1">Customer</Typography>
                  <SortButton type="alphabetical" label="name" />
                </Box>
              </TableCell>
              <TableCell sx={{ width: '15%' }}>
                <Box className="flex items-center justify-end gap-1">
                  <Tooltip title="Magic Sort (Cumulative Warning Weight)">
                    <IconButton
                      size="small"
                      onClick={() => {
                        if (sortType === 'magic') {
                          setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                        } else {
                          setSortType('magic');
                          setSortDirection('asc');
                        }
                      }}
                      color={sortType === 'magic' ? 'primary' : 'default'}
                    >
                      {sortType === 'magic' ? sortDirection === 'asc' ? <ArrowDownward /> : <ArrowUpward /> : <AutoAwesome />}
                    </IconButton>
                  </Tooltip>
                  <WarningFilters customers={customers} />
                </Box>
              </TableCell>
              <TableCell sx={{ width: '10%' }}>
                <Box className="flex items-center justify-between" gap={1}>
                  <Typography noWrap variant="subtitle1">
                    Weeks
                  </Typography>
                  <SortButton type="weeks" label="weeks remaining in milestone" />
                </Box>
              </TableCell>
              <TableCell sx={{ width: '20%' }}>
                <Box className="flex items-center justify-between">
                  <Typography variant="subtitle1">Outlines</Typography>
                  <SortButton type="outlines" label="budget utilization" />
                </Box>
              </TableCell>
              <TableCell sx={{ width: '20%' }}>
                <Box className="flex items-center justify-between">
                  <Typography variant="subtitle1">Creation</Typography>
                  <Box className="flex items-center gap-1">
                    <Tooltip title={hideFullyUtilized ? 'Show fully utilized' : 'Hide fully utilized'}>
                      <IconButton
                        size="small"
                        onClick={() => setHideFullyUtilized(!hideFullyUtilized)}
                        color={hideFullyUtilized ? 'primary' : 'default'}
                      >
                        {hideFullyUtilized ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </Tooltip>
                    <SortButton type="creation" label="tests to create" />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={{ width: '20%' }}>
                <Box className="flex items-center justify-between">
                  <Typography variant="subtitle1">Maintenance</Typography>
                  <Box className="flex items-center gap-1">
                    <Tooltip title={hideNoMaintenance ? 'Show no maintenance' : 'Hide no maintenance'}>
                      <IconButton
                        size="small"
                        onClick={() => setHideNoMaintenance(!hideNoMaintenance)}
                        color={hideNoMaintenance ? 'primary' : 'default'}
                      >
                        {hideNoMaintenance ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </Tooltip>
                    <SortButton type="maintenance" label="tests in maintenance" />
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          {clientSummaries.isFetching ? <LoadingRows /> : <TableRows filteredAndSortedCustomers={filteredAndSortedCustomers} />}
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
}

function WarningFilters({ customers }: { customers: Customer[] }) {
  const {
    warningFilters: activeWarningFilters,
    handleToggleWarningFilter,
    handleToggleAllWarningFilters,
    selectAllWarningFilters,
  } = useTableContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const counts = Object.fromEntries(
    defaultWarnings.map((warning) => [
      warning.type,
      customers.reduce((acc, customer) => acc + customer.warnings.filter((w) => w.type === warning.type).length, 0),
    ]),
  );

  return (
    <Box>
      <Tooltip title="Filter warnings">
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} color={activeWarningFilters.length > 0 ? 'primary' : 'default'} size="small">
          <Badge badgeContent={activeWarningFilters.length} color="primary">
            <FilterList />
          </Badge>
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        slotProps={{
          paper: { elevation: 2 },
        }}
      >
        <Box className="flex flex-col gap-2 p-2 pr-3">
          <Typography variant="h6">Customer Warnings</Typography>
          <Box className="flex gap-2 mt-2">
            {defaultWarnings
              .sort((a, b) => b.weight - a.weight)
              .map((filter) => {
                const isActive = activeWarningFilters.some((activeFilter) => activeFilter.type === filter.type);
                return (
                  <Tooltip
                    key={filter.type}
                    placement="top"
                    arrow
                    title={<WarningTooltipContent warning={filter} />}
                    slotProps={{ tooltip: { sx: { maxWidth: 'unset' } } }}
                  >
                    <Box className="relative cursor-pointer" onClick={() => handleToggleWarningFilter(filter.type)}>
                      <Box
                        className={`p-0.5 bg-gray-200 rounded-full transition-colors border-2 ${isActive ? 'border-blue-500' : 'border-gray-200'}`}
                      >
                        <WarningIcon warning={filter} size={24} />
                      </Box>
                      <Box
                        className={`absolute -top-2 -right-2 w-5 h-5 rounded-full bg-${filter.color.replace(
                          'text-',
                          '',
                        )} flex items-center justify-center`}
                      >
                        <p className="text-white font-bold text-xs" style={{ lineHeight: 1 }}>
                          {counts[filter.type]}
                        </p>
                      </Box>
                    </Box>
                  </Tooltip>
                );
              })}
          </Box>
          <Box className="flex flex-col gap-1">
            <FormControlLabel
              control={
                <Checkbox sx={{ pl: 0 }} disableRipple checked={selectAllWarningFilters} onChange={handleToggleAllWarningFilters} size="small" />
              }
              label={selectAllWarningFilters ? 'Deselect All' : 'Select All'}
              sx={{ mx: 0, px: 0, width: 'fit-content' }}
            />
          </Box>
        </Box>
      </Menu>
    </Box>
  );
}

function TableRows({ filteredAndSortedCustomers }: { filteredAndSortedCustomers: Customer[] }) {
  return (
    <TableBody>
      {filteredAndSortedCustomers.map((customer) => {
        const { warnings } = customer;
        return (
          <TableRow key={customer.id} hover>
            <TableCell>
              <Box className="flex items-center justify-between">
                <Box display="flex" alignItems="center">
                  <Box mr={2} display="flex" alignItems="center" gap={0.5}>
                    <PlanningDrawer
                      buttonColor={customer.incompleteProjectPlanTask ? 'warning.main' : 'success.main'}
                      client={customer as unknown as ClientSummaryTableRow}
                      buttonType="icon"
                    />
                    <MilestoneDrawer client={customer as unknown as ClientSummaryTableRow} buttonType="icon" />
                  </Box>
                  <Tooltip
                    placement="top"
                    slotProps={{ tooltip: { sx: { maxWidth: 'unset' } } }}
                    arrow
                    title={<CustomerInfoTooltipContent customer={customer} />}
                  >
                    <Box>
                      <Typography variant="body1" fontWeight="bold">
                        {customer.name}
                      </Typography>
                    </Box>
                  </Tooltip>
                </Box>
              </Box>
            </TableCell>
            <TableCell sx={{ p: 1 }}>
              <Box className="flex items-center gap-1 justify-end">
                {warnings.map((warning, i) => (
                  <Tooltip
                    key={i}
                    title={<WarningTooltipContent warning={warning} />}
                    placement="top"
                    arrow
                    slotProps={{ tooltip: { sx: { maxWidth: 'unset' } } }}
                  >
                    <Box className="p-0.5 bg-gray-200 rounded-full flex-shrink-0">
                      <WarningIcon warning={warning} />
                    </Box>
                  </Tooltip>
                ))}
              </Box>
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>
              {customer.activeMilestone.weeksRemaining ?? '-'}
            </TableCell>
            <TableCell>
              <OutlinesProgressBar
                totalBudget={customer.creation.totalBudget}
                activeTests={customer.creation.activeTests}
                draftTests={customer.creation.draftTests}
                activeMilestone={customer.activeMilestone}
              />
            </TableCell>
            <TableCell>
              <CreationProgressBar
                creation={customer.creation}
                activeMilestone={customer.activeMilestone}
                milestoneBlocker={customer.activeMilestoneBlockers[0]}
              />
            </TableCell>
            <TableCell>
              <MaintenanceProgressBar
                incompleteTestCount={customer.maintenance.incompleteTestCount}
                scheduledTestCount={customer.maintenance.scheduledTestCount}
                completedTestCount={customer.maintenance.completedTestCount}
              />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}
