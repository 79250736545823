export const timeSlotOptions = [
    {
        key: 'normalHours',
        label: 'Normal Hours',
        description: '9am - 5pm, in your timezone',
    },
    {
        key: 'earlyUkHours',
        label: 'Early UK Hours',
        description: '7am - 3pm GMT',
    },
    {
        key: 'ukHours',
        label: 'UK Hours',
        description: '9am - 5pm GMT',
    },
];
