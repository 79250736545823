/**
 * Flattens task data by decoupling parent/child tasks and adding parent information to the child object.
 * @param {import("../../types").QAWTask[]} data - all of the task data
 * @param {boolean} groupCRandMRs - boolean value defining whether to include or exclude testCoverageRequests
 * @returns {import("../../types").QAWTask[]} List of objects containing information for each task.
 * @throws {Error} If the team information is not found.
 */
export function flattenTaskData(data, groupCRandMRs) {
  if (!data.length) {
    return data;
  }

  // If groupCRandMRs is true, do not flatten data
  if (groupCRandMRs) return data;

  // assign empty array to flattenTask
  const flatData = [];

  // iterate through each object in the data array
  for (let parentTask of data) {
    // Tasks without child tasks can be added without flattening
    if (parentTask.childTasks === undefined) {
      flatData.push(parentTask);
      continue;
    }

    // If groupCRandMRs is false, add all non testCoverageRequest and testMaintenance parent tasks
    if (parentTask.type !== 'testCoverageRequest' && parentTask.type !== 'testMaintenance') flatData.push(parentTask);

    // If child tasks, add to the flatData array
    if (parentTask.childTasks.length) {
      for (let childTask of parentTask.childTasks) {
        flatData.push({ ...childTask, team: parentTask.team });
      }
    }
  }

  return flatData;
}

export function deepEqual(obj1, obj2) {
  // Check if both values are strictly equal
  if (obj1 === obj2) {
    return true;
  }

  // Check if either is not an object or is null
  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }

  // Get keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if they have the same number of keys
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check if all keys and their values are equal
  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export function getSelectedRowData(data, rowSelection) {
  const bulkTaskData = [];
  let foundIds = [];
  let targetIds = Object.keys(rowSelection);

  for (let i = 0; i < data.length; i++) {
    let currentTask = data[i];

    // Check task id in data to see if it is in rowSelection
    if (rowSelection[currentTask.id]) {
      // Push ID to found IDs
      foundIds.push(currentTask.id);
      // Remove found ID from the target list
      targetIds = targetIds.filter((id) => id !== currentTask.id);
      // Add data for task to bulkTaskData
      bulkTaskData.push({
        id: currentTask.id,
        type: currentTask.type,
        teamName: currentTask?.team?.name || currentTask?.customer?.officialName,
        assignedToId: currentTask.assignedToId,
        priority: currentTask.priority,
        status: currentTask.status,
        dueAt: currentTask.dueAt,
        dueAtString: currentTask.dueAtString,
        firstAssignedAt: currentTask.firstAssignedAt,
        startedAt: currentTask.startedAt,
        suiteId: currentTask.suiteId,
      });
    }

    // Check if the task has child tasks
    if (currentTask?.childTasks) {
      // Check the child tasks IDs
      for (let j = 0; j < currentTask.childTasks.length; j++) {
        if (rowSelection[currentTask.childTasks[j].id]) {
          foundIds.push(currentTask.childTasks[j].id);
          // Remove found ID from the target list
          targetIds = targetIds.filter((id) => id !== currentTask.childTasks[j].id);
          bulkTaskData.push({
            id: currentTask.childTasks[j].id,
            type: currentTask.childTasks[j].type,
            teamName: currentTask.childTasks[j]?.team?.name || currentTask.childTasks[j]?.customer?.officialName,
            dueAt: currentTask.childTasks[j].dueAt,
          });
        }
      }
    }

    // Stop the loop early if all target IDs are found
    if (targetIds.length === 0) {
      break;
    }
  }

  if (targetIds.length) {
    console.log(`Couldn't locate ${targetIds}`);
  }
  return bulkTaskData;
}

export function getTaskUrl(row, grouped, parentTask = false) {
  const task = row.original;

  // Urls for GROUPED WFs
  if (grouped) {
    // If message, return message url
    if (task.type === 'message') {
      return task.details.url;
    }

    // Get parentTask from child tasks
    let parentTask = row.getParentRow()?.original;

    // Assign slug and base url
    let slug = task.team?.slug || parentTask.team.slug;
    let baseUrl = `https://app.qawolf.com/${slug}`;

    // CR and MR urls
    if (task.childTasks) {
      let typePath = task.type === 'testCoverageRequest' ? 'coverage-requests' : task.type === 'testMaintenance' ? 'maintenance-reports' : '';
      let issueId = task.issue ? task.issue.id : parentTask?.issue ? parentTask.issue.id : '';
      let entityId = task.type === 'testCoverageRequest' ? issueId : task.type === 'testMaintenance' ? issueId : '';
      // Return URL
      if (!typePath) {
        // Fallback on customer page
        return `${baseUrl}`;
      } else if (!entityId) {
        // Fallback on CR or MRs page
        return typePath === 'coverage-requests'
          ? `${baseUrl}/${typePath}?q=${encodeURIComponent(parentTask.issue.name).replace(/%20/g, '+')}`
          : `${baseUrl}/${typePath}`;
      } else {
        return `${baseUrl}/${typePath}/${entityId}`;
      }
    }

    // testCreation and testMaintenance urls
    else {
      // Branch ID gives us the specific env
      let branchId = task.workflow?.branchId || '';
      let entityId = task.workflow?.id || '';
      // Return Url
      if (!branchId) {
        // Fallback on customer page if no branchId
        return `${baseUrl}`;
      } else if (!entityId) {
        // Fallback on env workflows if no entityId
        return `${baseUrl}/environments/${branchId}/workflows`;
      } else {
        return `${baseUrl}/environments/${branchId}/workflows/${entityId}`;
      }
    }
  }

  // Urls for UNGROUPED workflows
  else if (!grouped) {
    // If message, return message url
    if (task.subType === 'none') {
      return task.messageUrl || task.notes.url;
    }

    if (task.type === 'message') {
      return task.details.url;
    }

    // Assign slug and base url
    let slug = task.team?.slug;
    let baseUrl = `https://app.qawolf.com/${slug}`;

    // CR and MR urls
    if (parentTask) {
      let typePath = ['testCreation', 'outline'].includes(task.type)
        ? 'coverage-requests'
        : task.type === 'testMaintenance'
        ? 'maintenance-reports'
        : '';
      let entityId = task.parentIssue.id || '';
      // Return URL
      if (!typePath) {
        // Fallback on customer page
        return `${baseUrl}`;
      } else if (!entityId) {
        // Fallback on CR or MRs page
        return typePath === 'coverage-requests'
          ? `${baseUrl}/${typePath}?q=${encodeURIComponent(task.parentIssue.name).replace(/%20/g, '+')}`
          : `${baseUrl}/${typePath}`;
      } else {
        return `${baseUrl}/${typePath}/${entityId}`;
      }
    }

    // testCreation and testMaintenance urls
    else {
      // Branch ID gives us the specific env
      let branchId = task.workflow?.branchId || '';
      let entityId = task.workflow?.id || '';
      // Return Url
      if (!branchId) {
        // Fallback on customer page if no branchId
        return `${baseUrl}`;
      } else if (!entityId) {
        // Fallback on env workflows if no entityId
        return `${baseUrl}/environments/${branchId}/workflows`;
      } else {
        return `${baseUrl}/environments/${branchId}/workflows/${entityId}`;
      }
    }
  }
}

export function isSavedViewUpdated(view, tableState, defaultTableState) {
  // Create clean copies of the objects without empty values
  const cleanTableState = Object.fromEntries(
    Object.entries(tableState).filter(([, value]) => {
      if (value === null || value === undefined) return false; // remove falsy values
      if (Array.isArray(value) && value.length === 0) return false; // remove empty arrays
      if (typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0) return false; // remove empty objects
      return true;
    }),
  );

  Object.entries(cleanTableState).forEach(([key, value]) => {
    if (deepEqual(value, defaultTableState[key])) {
      delete cleanTableState[key];
    }
  });

  // Compare keys
  const viewKeys = Object.keys(view);
  const tableStateKeys = Object.keys(cleanTableState);

  if (viewKeys.length !== tableStateKeys.length) {
    return true;
  }

  // Compare each key's values
  for (const key of viewKeys) {
    if (!tableStateKeys.includes(key)) {
      return true;
    }

    if (!deepEqual(view[key], cleanTableState[key])) {
      return true;
    }
  }

  return false;
}
