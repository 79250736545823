/* eslint-disable eol-last */
import { updateCandidateSubmission, updateCandidateInfo, archiveSubmissions, deleteSubmissions } from './api';
import { archivedSubmissionStatusMap } from './StatusConstants';
import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import { Submission, Candidate } from '../Types/types';
import { MRT_Row, MRT_TableInstance } from 'material-react-table';
import { HiringData } from '../Types/HiringInternalDashboard/types';
import { ToastData } from '@/components/HQ/contexts/CommunicationsContextTypes';
import React, { Dispatch, SetStateAction } from 'react';

interface PayloadEntry {
  value: number;
  dataKey: string;
  color: string;
}

interface Filters {
  source: string;
}

interface CustomTooltipProps {
  active: boolean;
  payload: PayloadEntry[];
  label: string;
  totalCount: number;
  finalRound: boolean;
  filters: Filters;
}

// Handle submission changes for a specific row
export function useHandleSubmissionChange() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({ submissionId, newInfo }: { submissionId: number; newInfo: Partial<Submission> }) => {
      const isArchived = archivedSubmissionStatusMap.includes(newInfo.status || '');
      await updateCandidateSubmission({ id: submissionId, ...newInfo }, isArchived);

      return { submissionId, newInfo };
    },
    onMutate: async ({ submissionId, newInfo }: { submissionId: number; newInfo: Partial<Submission> }) => {
      // Cancel any active queries related to 'combinedData' to avoid stale data
      await queryClient.cancelQueries({ queryKey: ['combinedData'] });

      // Store the current state of 'combinedData' in case of rollback
      const previousCombinedData = queryClient.getQueryData<HiringData>(['combinedData']);

      // Optimistically update the cache with new information
      queryClient.setQueryData(['combinedData'], (oldData: HiringData | undefined) => {
        if (!oldData || !oldData.submissions) return oldData;

        const updatedSubmissions = oldData.submissions.map((submission: Submission) =>
          submission.id === submissionId ? { ...submission, ...newInfo } : submission,
        );

        return {
          ...oldData,
          submissions: updatedSubmissions,
        };
      });

      return { previousCombinedData };
    },
    onError: (error, variables, context) => {
      if (context?.previousCombinedData) {
        queryClient.setQueryData(['combinedData'], context.previousCombinedData);
      }
    },
    onSettled: () => {
      // Invalidate queries to ensure fresh data is fetched
      queryClient.invalidateQueries({ queryKey: ['combinedData'] });
    },
  });

  return mutation.mutate;
}

// Handle submission changes for a specific row
export function useHandleCandidateInfoChange() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ['singleCandidateMutation'],
    mutationFn: async (newCandidateData: Partial<Candidate>) => {
      await updateCandidateInfo(newCandidateData);
    },
    onMutate: async (newCandidateData: Partial<Candidate>) => {
      // Cancel any active queries related to 'combinedData' to avoid stale data
      await queryClient.cancelQueries({ queryKey: ['combinedData'] });

      // Store the current state of 'combinedData' in case of rollback
      const previousCombinedData = queryClient.getQueryData<HiringData>(['combinedData']);

      // Optimistically update the cache with the new candidate data
      queryClient.setQueryData(['combinedData'], (oldData: HiringData | undefined) => {
        if (!oldData || !oldData.candidates) return oldData;

        // Update the candidate based on the candidate id
        const updatedCandidates = oldData.candidates.map((candidate: Candidate) =>
          candidate.id === newCandidateData.id
            ? { ...candidate, ...newCandidateData } // Merge new data into the existing candidate
            : candidate,
        );

        // Return updated data
        return {
          ...oldData,
          candidates: updatedCandidates,
        };
      });

      return { previousCombinedData };
    },
    onError: (error, variables, context) => {
      // Rollback on error
      if (context?.previousCombinedData) {
        queryClient.setQueryData(['combinedData'], context.previousCombinedData);
      }
      console.error('Error updating candidate info', error.message);
    },
    onSuccess: () => {
      // Optionally update state or show success feedback
      console.log('Candidate updated successfully');
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['combinedData'] });
    },
  });

  return mutation.mutate;
}

/*
 * Separates a camelCase or PascalCase string into individual words
 * and capitalizes the first letter of each word.
 *
 * @param {string} str - The input string in camelCase or PascalCase.
 * @returns {string} - The separated and capitalized string with spaces.
 */
export function separateAndCapitalizeWords(str: string) {
  if (typeof str !== 'string') {
    throw new TypeError('Input must be a string');
  }

  // Insert space before uppercase letters that follow lowercase letters or numbers
  let separated = str.replace(/([a-z0-9])([A-Z])/g, '$1 $2');

  // Insert space before sequences where a single uppercase letter is followed by another uppercase and then a lowercase letter
  separated = separated.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');

  // Capitalize the first letter of each word
  separated = separated.replace(/\b\w/g, (char) => char.toUpperCase());

  return separated;
}

export const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label, totalCount, finalRound, filters }) => {
  if (active && payload) {
    const total = payload.reduce((sum, entry) => sum + entry.value, 0);

    return (
      <div className="custom-tooltip" style={{
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        border: '1px solid #ccc',
        padding: '10px',
      }}>
        <p className="label underline italic font-semibold">{separateAndCapitalizeWords(label)} ({total})</p>
        <div className="custom-tooltip">
          {filters.source === "All" ?
            payload
              .filter((entry) => entry.value !== 0 && entry.dataKey)
              .map((entry, index) => (
                <p key={index} style={{ color: entry.color }}>
                  {finalRound ?
                    `${entry.dataKey}: ${entry.value} (${((entry.value / totalCount) * 100).toFixed(0)}% of ${filters.source} interviews)`
                    :
                    `${entry.dataKey}: ${entry.value} (${((entry.value / totalCount) * 100).toFixed(0)}% of ${filters.source} submissions)`
                  }
                </p>
              ))
            :
            payload
              .filter((entry) => entry.value !== 0 && entry.dataKey)
              .map((entry, index) => (
                <p key={index} style={{ color: entry.color }}>
                  {finalRound ?
                    `${((entry.value / totalCount) * 100).toFixed(0)}% of ${entry.dataKey} interviews`
                    :
                    `${((entry.value / totalCount) * 100).toFixed(0)}% of ${filters.source} submissions`
                  }
                </p>
              ))
          }
        </div>
      </div>
    );
  }

  return null;
};

export const handleArchiveSubmissions = async (
  rows: MRT_Row<Submission>[],
  table: MRT_TableInstance<Submission>,
  setToastData: Dispatch<SetStateAction<ToastData | null>>,
  queryClient: QueryClient,
): Promise<void> => {
  const submissionsToBeArchived = rows.map((row: MRT_Row<Submission>) => row.original.id);
  const result = await archiveSubmissions({ ids: submissionsToBeArchived });

  const { success } = result;

  if (!success) {
    console.log(result);
  }

  const title = success ? 'Success!' : 'Ruh-roh';
  const message = success ? `Submission(s) archived.` : 'Something went wrong! Unable to archive submission(s).';

  setToastData({ title, message, isSuccess: success, content: message, show: true, onDone: () => setToastData(null) });

  await queryClient.refetchQueries({ queryKey: ['combinedData'] });
  table.resetRowSelection();
};

export const handleDeleteSubmissions = async (
  rows: MRT_Row<Submission>[],
  table: MRT_TableInstance<Submission>,
  setToastData: Dispatch<SetStateAction<ToastData | null>>,
  queryClient: QueryClient,
): Promise<void> => {
  const submissionsToBeDeleted = rows.map((row: MRT_Row<Submission>) => row.original.id);
  const result = await deleteSubmissions({ submissionIds: submissionsToBeDeleted });
  const { success } = result;

  if (!success) {
    console.log(result);
  }
  const title = success ? 'Success!' : 'Ruh-roh';
  const message = success ? `Submission(s) deleted.` : 'Something went wrong! Unable to delete submission(s).';

  setToastData({ title, message, isSuccess: success, content: message, show: true, onDone: () => setToastData(null) });

  await queryClient.refetchQueries({ queryKey: ['combinedData'] });
  table.resetRowSelection();
};

export function highlightText(text = '', searchValue = '') {
  if (!searchValue) return text;

  // Case-insensitive split
  const parts = text.split(new RegExp(`(${searchValue})`, 'gi'));

  return parts.map((part, i) => (part.toLowerCase() === searchValue.toLowerCase() ? <mark key={i}>{part}</mark> : part));
}
