import { Navigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { sendTSGetRequest } from '@/utils/tanstackNetwork';
import { logout } from '../LoginPage/authActions';
import WolfLoader from '../WolfLoader/WolfLoader';

export default function ProtectedRoute(props) {
  const { children } = props;

  const { data: isAuthenticated, isPending } = useQuery({
    queryKey: ['auth'],
    queryFn: () => sendTSGetRequest('/checklogin'),
    refetchInterval: 60 * 1000, // Recheck every minute
    select: (data) => data.logged_in,
  });

  if (isPending) {
    return <WolfLoader />;
  }

  if (!isAuthenticated) {
    console.log('User is no longer authenticated');
    logout('true');
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
