import { parseISO, differenceInMinutes } from 'date-fns';
import { InvestigationMetrics, RealInvestigationTask, RealUser, InvestigationInformation } from '../../types/investigation';
import { getBackgroundColor, getElapsed } from '../../../InvestigationBoard/helpers';
import _ from 'lodash';


// Gets Border Color
function getBorderColor(bucket: number) {
    switch (bucket) {
        case 1:
            return 'border-red-500'; // Red for bucket 1
        case 2:
            return 'border-pink-300'; // Pink for bucket 2
        case 3:
            return 'border-yellow-300'; // Yellow for bucket 3
        case 4:
            return 'border-green-500'; // Green for bucket 4
        default:
            return ''; // No additional border color if no bucket or undefined
    }
}

export const getOldestTaskAge = (tasks: RealInvestigationTask[]) => {
    if (tasks.length === 0) return 'N/A';
    const oldestTask = tasks.reduce((oldest, current) => {
        return new Date(oldest.createdAt) < new Date(current.createdAt) ? oldest : current;
    });
    return getElapsed(parseISO(oldestTask.createdAt));
};

export const getRelativeTime = (timestamp: string) => {
    const now = new Date();
    const date = new Date(timestamp);
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    if (diffInSeconds < 60) return 'just now';
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m ago`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h ago`;
    return `${Math.floor(diffInSeconds / 86400)}d ago`;
};

function computeInvestigationProgress(investigationMetrics: InvestigationMetrics) {
    const {
        workflowsDiagnosedAsBugCount,
        workflowsDiagnosedAsMaintenanceCount,
        workflowsOptedOutOfInvestigationCount,
        workflowsWithInvestigationSkippedCount,
        workflowsWithInvestigationSupersededCount,
        workflowsWithInvestigationSubsequentlyCanceledCount,
        workflowsToInvestigateCount,
        workflowsWithInvestigationMovedCount,
    } = investigationMetrics;

    const reviewedInThisSuiteCount = workflowsDiagnosedAsBugCount + workflowsDiagnosedAsMaintenanceCount + workflowsOptedOutOfInvestigationCount;

    const reviewedOrPassedInSubsequentSuiteCount =
        workflowsWithInvestigationSkippedCount + workflowsWithInvestigationSupersededCount + workflowsWithInvestigationSubsequentlyCanceledCount;

    const toBeReviewedCount = workflowsToInvestigateCount + workflowsWithInvestigationMovedCount;

    const alreadyReviewedCount = reviewedInThisSuiteCount + reviewedOrPassedInSubsequentSuiteCount;

    const totalCount = alreadyReviewedCount + toBeReviewedCount;

    const investigationProgressPercent = totalCount === 0 ? 1 : (alreadyReviewedCount) / totalCount;
    const investigationIncompletePercent = 1 - investigationProgressPercent;

    return { investigationProgressPercent, investigationIncompletePercent, totalCount, alreadyReviewedCount, toBeReviewedCount };
}

export function getTaskInformation(task: RealInvestigationTask, user: RealUser, users: RealUser[]): InvestigationInformation {
    const { totalCount: originalFailures, investigationIncompletePercent, investigationProgressPercent, alreadyReviewedCount, toBeReviewedCount } = computeInvestigationProgress(task.suite.investigationMetrics);
    const nested = Boolean(task.childTasks?.length);
    const running = task.suite.statusCounts.created ?? 0;
    const fail = task.suite.statusCounts.fail ?? 0;
    const bug = task.suite.statusCounts.bug ?? 0;
    const maintenance = task.suite.statusCounts.maintenance ?? 0;
    const passedInNewerRun = task.suite.investigationMetrics.workflowsWithInvestigationSkippedCount ?? 0;
    const doNotInvestigate = task.suite.investigationMetrics.workflowsOptedOutOfInvestigationCount ?? 0;
    const canceled = task.suite.statusCounts.canceled ?? 0;
    const investigationProgress = `${Math.min(investigationProgressPercent * 100, 100).toFixed()}`;
    const investigationIncomplete = `${Math.min(investigationIncompletePercent * 100, 100).toFixed()}`;

    const thisSuiteAge = getElapsed(parseISO(task.createdAt));
    const isDeployRun = Boolean(task.suite.trigger?.deploymentProvider);
    const isTimedRun = Boolean(task.suite.trigger?.nextAt);
    const isManualRun = Boolean(task.suite.trigger?.manuallyCreatedBy);
    const isCurrentUser = user.email === task.assignedTo?.email;
    const envNotes = task.suite.environment?.notes ? JSON.parse(task.suite.environment.notes) : {};
    const envNotesLastUpdatedAt = task.suite.environment?.notesUpdatedAt;
    const backgroundColor = getBackgroundColor({
        priority: task.priority,
        type: task.type,
        subType: task.subType,
        isCurrentUser,
    });
    const borderColor = getBorderColor(task.sortingScore.bucket);
    const suiteAge =
        nested || !task.childTasks?.length
            ? getElapsed(parseISO(task.createdAt))
            : getElapsed(parseISO(_.orderBy(task.childTasks, 'createdAt', 'asc')[0].createdAt));
    const investigationsToDo = fail - bug - maintenance - passedInNewerRun - doNotInvestigate;
    const failuresTitle = `Original failures: ${originalFailures} | Todo: ${investigationsToDo} | Running: ${running} | Bug: ${bug} | Maintenance: ${maintenance} | Passed in newer run: ${passedInNewerRun} | DNI: ${doNotInvestigate}`;

    const numberOfHandoffs = task.handovers;
    const usersListIDs = task.usersList ? task.usersList.split(',') : [];
    const usersListUsers = usersListIDs.map((x) => users.find((y) => y.qawId === x.trim())?.name);
    const usersListNames = usersListUsers.join(', ');
    const claimedForOverAnHour = !nested && !!(task.lastAssignedAt) && differenceInMinutes(new Date(), parseISO(task.lastAssignedAt)) > 60;
    const timeSinceClaimed = task.lastAssignedAt ? getElapsed(parseISO(task.lastAssignedAt)) : '';

    return {
        nested,
        running,
        fail,
        bug,
        maintenance,
        passedInNewerRun,
        doNotInvestigate,
        canceled,
        thisSuiteAge,
        suiteAge,
        investigationProgress,
        investigationIncomplete,
        isDeployRun,
        isTimedRun,
        isManualRun,
        isCurrentUser,
        envNotes,
        envNotesLastUpdatedAt,
        numberOfHandoffs,
        usersListNames,
        claimedForOverAnHour,
        timeSinceClaimed,
        failuresTitle,
        investigationsToDo,
        originalFailures,
        backgroundColor,
        borderColor,
        alreadyReviewedCount,
        toBeReviewedCount,
    };
}

// Calculate elapsed time since timestamp
export const calculateElapsedTime = (timestamp: string) => {
    const now = new Date();
    const date = new Date(Number(timestamp) * 1000);
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    if (diffInSeconds < 60) return 'just now';
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m ago`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h ago`;
    return `${Math.floor(diffInSeconds / 86400)}d ago`;
};


