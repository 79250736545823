import { Box, MenuItem, Button, Dialog, DialogContent, DialogTitle, ListItemIcon, Typography, CircularProgress, lighten } from '@mui/material';
import { AccountCircle, SupervisedUserCircle } from '@mui/icons-material';
import { MaterialReactTable, useMaterialReactTable, MRT_ShowHideColumnsButton, MRT_GlobalFilterTextField, MRT_Row } from 'material-react-table';
import { useMemo, useState, useCallback, useEffect } from 'react';
import ArchiveIcon from '@mui/icons-material/Archive'; // for some reason, importing from above errors

// Component and variable imports
import { handleArchiveSubmissions } from '../HiringUtils/helperFunctions';
import { inviteExecsToFinalRoundTeam, initCandidateFinalRound } from '../HiringUtils/api';
import { useQueryClient } from '@tanstack/react-query';
import RenderFinalRoundDetailPanels from '../RenderDetailPanels/RenderFinalRoundDetailPanels';
import Toast from '../../Layout/Toast';
import { useHandleSubmissionChange } from '../HiringUtils/helperFunctions';
// Type imports
import { Submission, RowData, DataForTable_Type } from '../Types/types';
import getHiringColumns from '../HiringUtils/columns';
import { ToastData } from '@/components/HQ/contexts/CommunicationsContextTypes';

function FinalRoundTable({ allSubmissions, currentSubmissions, allCandidates }: DataForTable_Type) {
  const initialToast = {
    title: 'Your title here',
    message: 'Your message here',
    show: false,
    content: '',
    isSuccess: false,
    onDone: () => setToastData(initialToast),
  };

  const [open, setOpen] = useState(false);
  const [selectedFinalRoundRowData, setSelectedFinalRoundRowData] = useState<MRT_Row<RowData>>();
  const [isLoadingSuperday, setIsLoadingSuperday] = useState(false);
  const [toastData, setToastData] = useState<ToastData | null>(null);
  const queryClient = useQueryClient();

  const [value, setValue] = useState(() =>
    currentSubmissions.reduce<Record<number, Submission>>((acc, row) => {
      acc[row.id] = row;
      return acc;
    }, {} as Record<number, Submission>),
  );
  const handleSubmissionChange = useHandleSubmissionChange();

  // Update the state whenever data changes.
  useEffect(() => {
    setValue(
      currentSubmissions.reduce<Record<number, Submission>>((acc, row) => {
        acc[row.id] = row;
        return acc;
      }, {}),
    );
  }, [currentSubmissions]);
  const tableData = useMemo(() => Object.values(value), [value]);

  const handleInitFinalRound = async (superday: string) => {
    try {
      setIsLoadingSuperday(true);

      const candidateData = {
        superday,
        candidateEmail: selectedFinalRoundRowData?.original.candidate.email,
        candidateName: selectedFinalRoundRowData?.original.candidate.firstName,
        submissionId: selectedFinalRoundRowData?.original.id,
      };

      await initCandidateFinalRound(candidateData);

      setIsLoadingSuperday(false);
      setOpen(false);
      setToastData({
        title: 'Success',
        message: 'Candidate superday created',
        isSuccess: true,
        show: true,
        content: '',
        onDone: () => setToastData(initialToast),
      });
    } catch (error) {
      console.log('Error initializing candidate superday:', error);
      setIsLoadingSuperday(false);
      setOpen(false);
      setToastData({
        title: 'Ruh-roh',
        message: 'Something went wrong',
        isSuccess: false,
        show: true,
        content: '',
        onDone: () => setToastData(initialToast),
      });
    }
  };

  const handleInviteExecToFinalRoundTeam = async (row: MRT_Row<RowData>) => {
    try {
      const {
        original: { finalRoundTeamId },
      } = row;

      if (finalRoundTeamId === undefined) {
        throw new Error('Candidate missing a final round team ID.');
      }

      await inviteExecsToFinalRoundTeam({ teamId: finalRoundTeamId });

      setToastData({
        title: 'Success!',
        message: `Invitations to ${row.original.candidate.firstName}'s Final Round Team have been sent.`,
        isSuccess: true,
        show: true,
        content: '',
        onDone: () => setToastData(initialToast),
      });
    } catch (error) {
      console.log('Error inviting execs to final round team:', error);
      setToastData({
        title: 'Ruh-roh',
        message: 'Something went wrong!',
        isSuccess: false,
        show: true,
        content: '',
        onDone: () => setToastData(initialToast),
      });
    }
  };

  // Define our table columns
  const columns = getHiringColumns({
    tableData: [
      {
        allSubmissions,
        allCandidates,
        currentSubmissions,
      },
    ],
    handleSubmissionChange,
    value,
    setValue,
    columnsToReturn: ['name', 'email', 'statusId', 'finalRoundDate', 'locationId', 'removedFromSandbox', 'finalRoundTeam'],
  });

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    displayColumnDefOptions: {
      'mrt-row-select': {
        size: 50, //adjust the size of the row select column
        grow: false,
      },
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 100,
      },
      columnPinning: {
        left: ['mrt-row-select', 'mrt-row-expand'],
        right: ['mrt-row-actions'],
      },
      sorting: [{ id: 'finalRoundDate', desc: false }],
      showGlobalFilter: true,
    },
    enableColumnActions: true,
    enableColumnFilters: true,
    enableTopToolbar: true,
    enableTableFooter: true,
    enableRowActions: true,
    autoResetPageIndex: false,
    enableExpanding: true,
    enableRowSelection: true,
    getRowId: (row) => row.id.toString(),

    renderBottomToolbarCustomActions: ({ table }) => {
      // Calculate total rows
      const rowCount = table.getRowModel().rows.length;
      return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '1rem' }}>
          {selectedFinalRoundRowData ? (
            <Dialog
              open={open}
              onClose={() => {
                setOpen(false);
              }}
            >
              <DialogTitle>Which Superday for {selectedFinalRoundRowData?.original?.candidate.firstName}</DialogTitle>
              <DialogContent>
                <div className="flex w-full justify-center align-center">
                  <Button onClick={() => handleInitFinalRound('superday1')}>Superday 1</Button>
                  <Button onClick={() => handleInitFinalRound('superday2')}>Superday 2</Button>
                  <Button onClick={() => handleInitFinalRound('superday3')}>Superday 3</Button>
                  <Button onClick={() => handleInitFinalRound('superday4')}>Superday 4</Button>
                </div>

                {isLoadingSuperday && (
                  <div className="flex w-full justify-center align-center">
                    <CircularProgress />
                  </div>
                )}
              </DialogContent>
            </Dialog>
          ) : null}
          <Typography variant="subtitle1">Total Rows: {rowCount}</Typography>
        </Box>
      );
    },

    muiTableBodyCellProps: () => ({
      sx: {
        padding: '6px', // Reduce padding between columns in body cells
        width: '30px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    }),

    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }),
    }),

    renderDetailPanel: useCallback(
      ({ row }: { row: MRT_Row<Submission> }) => (
        <RenderFinalRoundDetailPanels
          row={row}
          currentSubmissions={currentSubmissions}
          allCandidates={allCandidates}
          allSubmissions={allSubmissions}
        />
      ),
      [currentSubmissions, allCandidates, allSubmissions],
    ),

    renderTopToolbar: ({ table }) => {
      return (
        <Box
          sx={(theme) => ({
            backgroundColor: lighten(theme.palette.background.default, 0.05),
            display: 'flex',
            gap: '0.5rem',
            p: '8px',
            justifyContent: 'space-between',
            borderBottom: '1px solid #D3D3D3',
          })}
        >
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            {/* import MRT sub-components */}

            {/* ==================HEADER SEARCH BAR================== */}
            <MRT_GlobalFilterTextField table={table} />

            {/* ===============HEADER HIDE COLS BUTTON=============== */}
            <MRT_ShowHideColumnsButton table={table} />

            {/* ============HEADER TOGGLE FILTERS BUTTON============= */}
            {/* <MRT_ToggleFiltersButton table={table} /> */}
            <button
              disabled={!table.getIsSomeRowsSelected()}
              className="custom-button"
              onClick={() => {
                handleArchiveSubmissions(table.getSelectedRowModel().rows, table, setToastData, queryClient);
              }}
            >
              <ArchiveIcon
                style={{
                  color: !table.getIsSomeRowsSelected() ? 'rgb(235, 235, 228)' : 'gray',
                }}
              />
            </button>
          </Box>
        </Box>
      );
    },

    renderRowActionMenuItems: ({ closeMenu, row }) => [
      <MenuItem
        key={0}
        sx={{ m: 0 }}
        onClick={() => {
          handleInviteExecToFinalRoundTeam(row);
          closeMenu();
        }}
      >
        <ListItemIcon>
          <SupervisedUserCircle />
        </ListItemIcon>
        Invite Execs to Final Round Team
      </MenuItem>,
      <MenuItem
        key={1}
        onClick={() => {
          setOpen(true);
          setSelectedFinalRoundRowData(row);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        Init Final Round
      </MenuItem>,
    ],
  });

  return (
    <div>
      <MaterialReactTable table={table} />
      <Toast {...toastData} show={toastData !== null} onDone={() => setToastData(null)} content="" />
    </div>
  );
}

export default FinalRoundTable;
