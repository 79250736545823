import React from 'react';
import CytoscapeComponent from 'react-cytoscapejs';
import { ElementDefinition, Core } from 'cytoscape';
import WolfLoader from '../../WolfLoader/WolfLoader';
import { stylesheet } from '../styles';
import { layout } from '../layout';
import { JobProgress } from '../types';

interface GraphDisplayProps {
  isLoading: boolean;
  isError: boolean;
  isGenerating: boolean;
  longRunningMessage: boolean;
  jobProgress: JobProgress | null;
  elements: ElementDefinition[];
  cyRef: React.MutableRefObject<Core | null>;
  handleRefreshLayout: () => void;
}

const GraphDisplay: React.FC<GraphDisplayProps> = ({
  isLoading,
  isError,
  isGenerating,
  longRunningMessage,
  jobProgress,
  elements,
  cyRef,
  handleRefreshLayout,
}) => {
  // Common graph container styles
  const graphContainerStyle = {
    width: '100%',
    height: 'calc(100vh - 280px)',
    backgroundColor: '#1f2937',
  };

  const renderLoadingState = () => {
    if (!isGenerating) {
      return (
        <div className="flex items-center justify-center h-full">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
        </div>
      );
    }

    return (
      <div className="flex flex-col items-center justify-center h-full space-y-4 p-6">
        {jobProgress ? (
          <>
            <div className="w-full max-w-md">
              <div className="flex justify-between text-sm text-gray-400 mb-2">
                <span>{jobProgress.stage.replace(/_/g, ' ').toLowerCase()}</span>
                <span>{jobProgress.progress}%</span>
              </div>
              <div className="w-full bg-gray-700 rounded-full h-2.5">
                <div className="bg-blue-500 h-2.5 rounded-full transition-all duration-500" style={{ width: `${jobProgress.progress}%` }} />
              </div>
              <div className="mt-2 text-sm text-gray-400">
                {jobProgress.message}
                {jobProgress.totalRuns > 0 && (
                  <div className="mt-1 text-xs">
                    <p>
                      Processed {jobProgress.processedRuns} of {jobProgress.totalRuns} runs
                    </p>
                    {jobProgress.batchesCompleted !== undefined && (
                      <p>
                        Completed {jobProgress.batchesCompleted} of {jobProgress.totalBatches} batches
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
        )}
        {longRunningMessage && (
          <>
            <p className="text-gray-400 text-sm text-center max-w-md">
              This process may take a few minutes. You can leave this page.
            </p>
            <p className="text-gray-400 text-sm text-center max-w-md">
              We'll send you a Slack DM when it's ready.
            </p>
          </>
        )}
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="bg-gray-800 rounded-lg shadow-xl p-6 relative">
        <div style={graphContainerStyle} className="flex items-center justify-center">
          <WolfLoader />
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="bg-gray-800 rounded-lg shadow-xl p-6 relative">
        <div style={graphContainerStyle} className="flex items-center justify-center">
          <div className="text-red-500">Error fetching data</div>
        </div>
      </div>
    );
  }

  if (isGenerating) {
    return <div className="bg-gray-800 rounded-lg shadow-xl p-6 relative">{renderLoadingState()}</div>;
  }

  return (
    <div className="bg-gray-800 rounded-lg shadow-xl p-6 relative">
      <button
        onClick={handleRefreshLayout}
        className="absolute top-4 right-4 z-10 bg-gray-700 hover:bg-gray-600 text-gray-200 p-2 rounded-full shadow-lg transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
        title="Refresh Layout"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path
            fillRule="evenodd"
            d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      {elements && elements.length > 0 ? (
        <CytoscapeComponent
          cy={(cy: Core) => {
            cyRef.current = cy;
          }}
          elements={elements}
          style={graphContainerStyle}
          layout={layout}
          stylesheet={stylesheet}
          boxSelectionEnabled={true}
          autounselectify={false}
        />
      ) : (
        <div style={graphContainerStyle} className="flex items-center justify-center">
          <div className="text-gray-400">No graph data available</div>
        </div>
      )}
    </div>
  );
};

export default GraphDisplay;
