import { useState, useEffect } from 'react';
import { Button, Stack } from '@mui/material';
import { marked } from 'marked';
import { useHandleSubmissionChange } from '../../HiringUtils/helperFunctions';
import './MarkdownPreviewer.css'; // Import CSS file

function MarkdownPreviewer({ notes, row, finalRoundNotesWidth, finalRoundNotesHeight }) {
    const [formState, setFormState] = useState({ notes: notes || '' });
    const [edit, setEdit] = useState(false);

    const handleSubmissionChange = useHandleSubmissionChange(setFormState);

    useEffect(() => {
        setFormState({ notes: notes || '' });
    }, [notes, row]);

    const handleInputChange = (e) => {
        setFormState({ notes: e.target.value });
    };

    const handleSave = () => {
        handleSubmissionChange({
            submissionId: row.id,
            newInfo: formState,
            row,
        });
        setEdit(false);
    };


    return (
        <div style={{ height: '100%', width: '100%', maxWidth: finalRoundNotesWidth || '900px' }}>
            <Stack direction="row" spacing={2}>
                <Button variant={edit ? "outlined" : "contained"} onClick={() => setEdit(false)}>
                    Preview
                </Button>
                <Button variant={edit ? "contained" : "outlined"} onClick={() => setEdit(true)}>
                    Write
                </Button>
                <Button variant={edit ? "contained" : "outlined"} color="secondary" onClick={handleSave}>
                    Save Notes
                </Button>
            </Stack>

            <div>
                {edit && (
                    <textarea
                        value={formState.notes}
                        onChange={handleInputChange}
                        style={{
                            width: '100%',
                            height: finalRoundNotesHeight ? finalRoundNotesHeight : '650px',
                            margin: '1rem 0',
                            padding: '1rem',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            backgroundColor: '#f9f9f9',
                            fontSize: '16px',
                        }}
                        placeholder="Enter Markdown here..."
                    />
                )}
                {!edit && (
                    <div
                        className="markdown-container"
                        style={{
                            width: '100%',
                            height: finalRoundNotesHeight ? finalRoundNotesHeight :'672px',
                            margin: '1rem 0',
                        }}
                        dangerouslySetInnerHTML={{ __html: marked(formState.notes) }}
                    />
                )}
            </div>
        </div>
    );
}

export default MarkdownPreviewer;
