import React, { useEffect, useState, useRef } from 'react';
import { Chart, ReactGoogleChartProps } from 'react-google-charts';

import { Box, Drawer, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import type { FormattedEvent, InvestigationSchedule, SelectedEvent } from './investigationSchedulingTypes';

import EventDrawer from './EventDrawer';

import { formatEventsForTimeline } from './investigationSchedulingUtilities';
import { EventTypeBaseline } from './investigationSchedulingUtilities/formatEventsForTimeline';

interface TimelineChartProps {
  schedule: InvestigationSchedule;
  index: number;
  isScheduleConfirmed: boolean;
  setForecastChartLeftPadding: React.Dispatch<React.SetStateAction<string>>;
}

const TimelineChart: React.FC<TimelineChartProps> = ({ schedule, index, isScheduleConfirmed, setForecastChartLeftPadding }) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<SelectedEvent | null>(null);
  const [previewEvent, setPreviewEvent] = useState<SelectedEvent | null>(null);
  const [formattedEvents, setFormattedEvents] = useState<[EventTypeBaseline, ...FormattedEvent[]] | []>([]);
  const [allUniqueUsers, setAllUniqueUsers] = useState<string[]>([]);
  const [chartHeight, setChartHeight] = useState<string>('auto');
  const chartRef = useRef<HTMLDivElement>(null);

  const formattedEventsWithoutBaseType = formattedEvents.slice(1) as FormattedEvent[];
  const rowsCountedByUniqueEventType = Array.from(new Set([...formattedEventsWithoutBaseType.map((event) => event[0])]));

  // calculate the longest username width using canvas
  useEffect(() => {
    if (rowsCountedByUniqueEventType.length > 0) {
      // create temporary canvas for text measurement
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      if (context) {
        // match the system font stack used in the application
        context.font = '13px Arial';
        const maxWidth = Math.max(...rowsCountedByUniqueEventType.map((name) => context.measureText(name).width));
        // add a small negative buffer (-25px) and convert to pixels
        setForecastChartLeftPadding(`${Math.ceil(maxWidth - 25)}px`);
      }
    }
  }, [rowsCountedByUniqueEventType, setForecastChartLeftPadding]);

  // format the events for preview
  useEffect(() => {
    const events = previewEvent
      ? selectedEvent
        ? schedule.events.map((e) => (e === selectedEvent.event ? previewEvent.event : e))
        : [...schedule.events, previewEvent.event]
      : schedule.events;

    const { formattedEvents, allUniqueUsers } = formatEventsForTimeline(events);
    setFormattedEvents(formattedEvents);
    setAllUniqueUsers(allUniqueUsers);
  }, [schedule.events, previewEvent, selectedEvent]);

  const handlePreviewUpdate = (updatedEvent: SelectedEvent | null) => {
    setPreviewEvent(updatedEvent);
  };

  const handleReset = () => {
    setPreviewEvent(null);
  };

  const handleClose = () => {
    setPreviewEvent(null);
    setSelectedEvent(null);
    setDrawerOpen(false);
  };

  const handleAddEvent = () => {
    setSelectedEvent(null);
    setPreviewEvent(null);
    setDrawerOpen(true);
  };

  // define the chart event handlers
  const chartEvents: ReactGoogleChartProps['chartEvents'] = [
    {
      eventName: 'select',
      callback({ chartWrapper }) {
        if (!chartWrapper) return;
        const selectionIndex = chartWrapper.getChart().getSelection()[0]?.row;

        if (selectionIndex === undefined) {
          console.log(`no valid selection found`);
          return;
        }

        const selectedTimelineEvent = formattedEvents[selectionIndex + 1] as FormattedEvent;

        if (selectedTimelineEvent === undefined) {
          console.warn(`no valid event found at index ${selectionIndex}`);
          return;
        }

        const originalEvent = schedule.events.find(
          (event) => event.startTime === selectedTimelineEvent[4].getTime() && event.endTime === selectedTimelineEvent[5].getTime(),
        );

        if (originalEvent === undefined || originalEvent.userName !== selectedTimelineEvent[1].split(' | ')[0]) {
          console.warn(`no valid original event found at index ${selectionIndex}`);
          return;
        }

        if (!isScheduleConfirmed) {
          setSelectedEvent({
            primary: selectedTimelineEvent[1].split(' | ')[0],
            event: originalEvent,
          });
          setPreviewEvent(null);
          setDrawerOpen(true);
        }
      },
    },
    {
      eventName: 'ready',
      callback() {
        if (chartRef.current) {
          const height = chartRef.current.getBoundingClientRect().height;
          if (height > 0 && chartHeight === 'auto') {
            setChartHeight(`${height + 6}px`);
          }
        }
      },
    },
    {
      eventName: 'error',
      callback(args) {
        console.log('Chart errored. ', args);
      },
    },
  ];

  // define options for timeline chart
  const options: ReactGoogleChartProps['options'] = {
    timeline: {
      showBarLabels: false,
    },
    hAxis: {
      format: 'h aaa',
      minValue: new Date(schedule.scheduleStart).setHours(0, 0, 0, 0),
      maxValue: new Date(schedule.scheduleEnd).setHours(23, 0, 0, 0),
    },
    tooltip: { isHtml: true },
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
        <Typography variant="h6" component="h2">
          {schedule.qaTeam.name} Investigation Schedule {index >= 0 ? index + 1 : ''}
        </Typography>
        {!isScheduleConfirmed && (
          <IconButton color="primary" onClick={handleAddEvent} aria-label="add event" size="small">
            <AddIcon />
          </IconButton>
        )}
      </Box>

      <div ref={chartRef}>
        <Chart chartType="Timeline" data={formattedEvents} height={chartHeight} options={options} chartEvents={chartEvents} />
      </div>

      {!isScheduleConfirmed && (
        <Drawer anchor="right" open={drawerOpen} onClose={handleClose}>
          <EventDrawer
            schedule={schedule}
            event={selectedEvent}
            users={allUniqueUsers}
            toggleDrawer={setDrawerOpen}
            onPreviewUpdate={handlePreviewUpdate}
            onReset={handleReset}
            onClose={handleClose}
          />
        </Drawer>
      )}
    </>
  );
};

export default TimelineChart;
