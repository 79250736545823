import React, { useMemo, useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

//MRT Imports
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ShowHideColumnsButton,
  MRT_Row,
  MRT_TableInstance,
} from 'material-react-table';

//Material UI Imports
import { Box, ListItemIcon, MenuItem, Button, lighten, TextField, Dialog, DialogContent, Autocomplete, DialogTitle, Checkbox } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

//Icons Imports
import { AccountCircle, Gavel } from '@mui/icons-material';
import ArchiveIcon from '@mui/icons-material/Archive'; // for some reason, importing from above errors
import DeleteIcon from '@mui/icons-material/Delete';
import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import MergeIcon from '@mui/icons-material/Merge';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';

// File & Component Imports
import { evaluateSubmissionsWithGPT, mergeCandidates } from '../HiringUtils/api';
import { useHandleSubmissionChange, handleArchiveSubmissions, handleDeleteSubmissions } from '../HiringUtils/helperFunctions';
import { statusMap } from '../HiringUtils/StatusConstants';
import Toast from '../../Layout/Toast';
import CandidateDetailPanel from '../HiringCandidatePanel/CandidateDetailPanel';
import getHiringColumns from '../HiringUtils/columns';

// Type Imports
import { Candidate, Submission, RowData, CandidateSubmissionStatus, DataForTable_Type } from '../Types/types';
import { ToastData } from '@/components/HQ/contexts/CommunicationsContextTypes';
export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: 'rgb(48, 47, 61)',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#f48fb1',
      contrastText: '#ffffff',
    },
    background: {
      default: '#121212',
      paper: '#1e1e1e',
    },
    text: {
      primary: '#ffffff',
      secondary: '#bbbbbb',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    // You can override other component styles here
  },
});

// LIGHT THEME
export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2',
      contrastText: '#ffffff',
    },
    secondary: {
      main: 'rgb(156, 39, 176)',
      contrastText: '#ffffff',
    },
    background: {
      default: '#fafafa',
      paper: '#ffffff',
    },
    text: {
      primary: '#000000',
      secondary: '#333333',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    // Other component customizations can go here
  },
});

const HiringTable: React.FC<DataForTable_Type> = ({ currentSubmissions, allCandidates, allSubmissions }) => {
  const initialToast = {
    title: 'Your title here',
    message: 'Your message here',
    show: false,
    content: '',
    isSuccess: false,
    onDone: () => setToast(initialToast),
  };
  const [toast, setToast] = useState<ToastData | null>(null);
  const [open, setOpen] = useState(false);
  const [primaryCandidate, setPrimaryCandidate] = useState<Candidate | null>(null);
  const [selectedCandidates, setSelectedCandidates] = useState<Candidate[]>([]);
  const [multiUpdateOpen, setMultiUpdateOpen] = useState(false);
  const [statusForMultiUpdate, setStatusForMultiUpdate] = useState('');
  const [currentTable, setCurrentTable] = useState<MRT_TableInstance<RowData> | null>(null);
  const [darkMode, setDarkMode] = useState(false);

  const handlePrimaryCandidateChange = (_: React.SyntheticEvent<Element, Event>, value: Candidate | null) => {
    const newPrimaryCandidate = value ? value : null;
    setPrimaryCandidate(newPrimaryCandidate);
  };

  const handleCandidatesChange = (event: React.SyntheticEvent<Element, Event>, value: Candidate[]) => {
    setSelectedCandidates(value); // Save selected candidates to state
  };

  const handleMergeCandidates = async () => {
    const primaryCandidateId = (primaryCandidate as NonNullable<typeof primaryCandidate>).id;
    const duplicateCandidateIds = (selectedCandidates as Candidate[]).reduce<number[]>((ids: number[], candidate: { id: number }) => {
      ids.push(candidate.id);
      return ids;
    }, []);

    await mergeCandidates(primaryCandidateId, duplicateCandidateIds);
    setOpen(false);
    setPrimaryCandidate(null);
    setSelectedCandidates([]);
    setToast({
      title: 'Merge Candidates',
      message: 'Candidates have been merged',
      isSuccess: true,
      show: true,
      content: '',
      onDone: () => setToast(initialToast),
    });
  };

  const queryClient = useQueryClient();

  const [value, setValue] = useState<Record<number, Submission>>(
    currentSubmissions.reduce<Record<number, Submission>>((acc, row) => {
      acc[row.id] = row;
      return acc;
    }, {} as Record<number, Submission>),
  );

  // Update the state whenever data.currentSubmissions changes.
  useEffect(() => {
    setValue(
      currentSubmissions.reduce<Record<number, Submission>>((acc, row) => {
        acc[row.id] = row;
        return acc;
      }, {} as Record<number, Submission>),
    );
  }, [currentSubmissions]);

  const tableData = useMemo(() => {
    return Object.values(value);
  }, [value]);

  const handleSubmissionChange = useHandleSubmissionChange();

  const handleMultipleCandidateStatusChange = async (rows: MRT_Row<RowData>[]) => {
    rows.map(async (row) => {
      const submissionId = row.original.id;
      handleSubmissionChange({
        submissionId: submissionId,
        newInfo: { status: statusForMultiUpdate as CandidateSubmissionStatus },
      });
    });

    if (currentTable) currentTable.resetRowSelection();
    setStatusForMultiUpdate('');
    setCurrentTable(null);
    setMultiUpdateOpen(false);
    setToast({
      title: 'Success!',
      message: 'Candidate status(es) updated.',
      isSuccess: true,
      show: true,
      content: '',
      onDone: () => setToast(initialToast),
    });
  };

  const handleGPTEvaluation = async () => {
    try {
      setToast({
        title: 'GPT analysis is starting...',
        message: 'Please wait for GPT analysis to finish',
        isSuccess: true,
        show: true,
        content: '',
        onDone: () => setToast(initialToast),
      });

      await evaluateSubmissionsWithGPT();

      setToast({
        title: 'Success',
        message: `Submission(s) have been processed by chatGPT and statuses have been updated`,
        isSuccess: true,
        show: true,
        content: '',
        onDone: () => setToast(initialToast),
      });
    } catch (error) {
      setToast({
        title: 'Ruh-roh',
        message: 'Something went wrong! Unable to process submission(s).',
        isSuccess: false,
        show: true,
        content: '',
        onDone: () => setToast(initialToast),
      });
      console.log('Error evaluating submissions with GPT:', error);
      await queryClient.refetchQueries({ queryKey: ['combinedData'] });
    }
  };

  // Define our table columns
  const columns = getHiringColumns({
    tableData: [
      {
        currentSubmissions: currentSubmissions,
        allCandidates: allCandidates,
        allSubmissions: allSubmissions,
      },
    ],
    handleSubmissionChange,
    columnsToReturn: ['name', 'email', 'jobSource', 'dateSubmitted', 'locationId', 'statusId'],
    value: value,
    setValue: setValue,
  });

  const table = useMaterialReactTable({
    columns,
    defaultColumn: {
      minSize: 20,
      maxSize: 20,
      size: 10,
    },
    enableHiding: true,
    data: tableData,
    columnFilterDisplayMode: 'popover',
    enableColumnFilters: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    autoResetPageIndex: false,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enableRowActions: true,
    enableRowSelection: true,
    enableGlobalFilter: true,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 100,
      },
      showColumnFilters: true,
      showGlobalFilter: true,
      columnPinning: {
        left: ['mrt-row-select', 'mrt-row-expand'],
        right: ['mrt-row-actions'],
      },
      columnFilters: [],

      columnVisibility: { removedFromSandbox: false },
      sorting: [{ id: 'dateSubmitted', desc: false }],
    },
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      shape: 'rounded',
      variant: 'outlined',
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: '100%',
        height: '100%',
        width: '100%',
        overflowX: 'auto',
      },
    },
    muiTableHeadCellProps: {
      sx: {
        padding: '0 4px', // Reduce padding between columns in header cells
      },
    },

    renderBottomToolbarCustomActions: () => {
      const filteredOptions = allCandidates.filter((candidate) => primaryCandidate?.id !== candidate.id);

      return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '1rem', height: 'calc(100vh - 780px)' }}>
          <Dialog
            open={open}
            onClose={() => {
              setOpen(false);
              setPrimaryCandidate(null);
              setSelectedCandidates([]);
            }}
          >
            <DialogTitle>Merge Candidates</DialogTitle>
            <DialogContent>
              <div className="flex flex-col justify-center align-center" style={{ height: '100%' }}>
                <Autocomplete
                  onChange={handlePrimaryCandidateChange}
                  value={primaryCandidate}
                  options={allCandidates} // Filter out already selected candidates
                  getOptionLabel={(option) =>
                    option && option.firstName && option.lastName ? `${option.firstName} ${option.lastName} -  ID:${option.id}` : ''
                  }
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        checked={selected}
                        style={{ marginRight: 8 }}
                      />
                      {/* {console.log(option.firstName, option, "HERE")} */}
                      {`${option.firstName} ${option.lastName} -  ID:${option.id}`}
                    </li>
                  )}
                  renderInput={(params) => <TextField {...params} label="Select Primary Candidate" />}
                  sx={{ width: 500, marginBottom: '1rem', marginTop: '1rem' }}
                />

                <Autocomplete
                  multiple
                  onChange={handleCandidatesChange}
                  value={selectedCandidates}
                  disableCloseOnSelect
                  options={filteredOptions}
                  getOptionLabel={(option) =>
                    option && option.firstName && option.lastName ? `${option.firstName} ${option.lastName} -  ID:${option.id}` : ''
                  }
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        checked={selected}
                        style={{ marginRight: 8 }}
                      />
                      {`${option.firstName} ${option.lastName} -  ID:${option.id}`}
                    </li>
                  )}
                  renderInput={(params) => <TextField {...params} label="Select Candidates to Merge" />}
                  sx={{ width: 500, marginBottom: '1rem' }}
                />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button onClick={() => handleMergeCandidates()}>Merge Candidates</Button>
                </div>
              </div>
            </DialogContent>
          </Dialog>
          <Dialog
            open={multiUpdateOpen}
            onClose={() => {
              setMultiUpdateOpen(false);
              setStatusForMultiUpdate('');
            }}
          >
            <DialogTitle>Update Selected Candidate Status</DialogTitle>
            <DialogContent>
              <h3>Current selected candidates:</h3>
              {table.getSelectedRowModel().rows.map((row, idx) => {
                return (
                  <p key={idx}>
                    {row.original.candidate.firstName} {row.original.candidate.lastName}
                  </p>
                );
              })}

              <select
                id="status"
                name="status"
                style={{
                  textAlign: 'center',
                  padding: '5px 10px',
                  width: '100%',
                  overflow: 'clip',
                  fontSize: '14px',
                  marginTop: '2rem',
                }}
                value={statusForMultiUpdate}
                onChange={(e) => setStatusForMultiUpdate(e.target.value)}
              >
                {statusMap.map((status) => (
                  <option value={status.value} key={status.value}>
                    {status.label}
                  </option>
                ))}
              </select>

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={() => handleMultipleCandidateStatusChange(table.getSelectedRowModel().rows)}>Confirm update</Button>
              </div>
            </DialogContent>
          </Dialog>
        </Box>
      );
    },

    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }),
    }),

    muiTableBodyCellProps: () => ({
      sx: {
        cursor: 'pointer',
        padding: '6px', // Reduce padding between columns in body cells
        width: '30px',
      },
    }),
    renderDetailPanel: ({ row }) => (
      <CandidateDetailPanel
        row={row}
        currentSubmissions={currentSubmissions}
        allCandidates={allCandidates}
        allSubmissions={allSubmissions}
        showTab={true}
      />
    ),

    renderRowActionMenuItems: ({ closeMenu, row }) => [
      <MenuItem
        key={0}
        onClick={() => {
          table.setEditingRow(row);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        Edit Submission
      </MenuItem>,
    ],
    renderTopToolbar: ({ table }) => {
      return (
        <Box
          sx={(theme) => ({
            backgroundColor: lighten(theme.palette.background.default, 0.05),
            display: 'flex',
            gap: '0.5rem',
            p: '8px',
            alignContent: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid #D3D3D3',
          })}
        >
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center', width: '35%', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex' }}>
                  {/* ==================HEADER SEARCH BAR================== */}
                  <MRT_GlobalFilterTextField table={table} />

                  {/* ===============HEADER HIDE COLS BUTTON=============== */}
                  <MRT_ShowHideColumnsButton table={table} />

                  {/* ===============MERGE CANDIDATES BUTTON=============== */}
                  <button
                    className="custom-button"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    <MergeIcon
                      titleAccess="Merge candidates"
                      style={{
                        color: 'gray',
                      }}
                    />
                  </button>

                  {/* ===============ARCHIVE CANDIDATES BUTTON=============== */}
                  <button
                    className="custom-button"
                    disabled={table.getSelectedRowModel().rows.length === 0}
                    onClick={() => {
                      handleArchiveSubmissions(table.getSelectedRowModel().rows, table, setToast, queryClient);
                    }}
                  >
                    <ArchiveIcon
                      style={{
                        color: !table.getIsSomeRowsSelected() ? 'rgb(235, 235, 228)' : 'gray',
                      }}
                    />
                  </button>

                  {/* ===============DELETE CANDIDATES BUTTON=============== */}
                  <button
                    className="custom-button"
                    disabled={table.getSelectedRowModel().rows.length === 0}
                    onClick={() => {
                      handleDeleteSubmissions(table.getSelectedRowModel().rows, table, setToast, queryClient);
                    }}
                  >
                    <DeleteIcon
                      style={{
                        color: !table.getIsSomeRowsSelected() ? 'rgb(235, 235, 228)' : 'gray',
                      }}
                    />
                  </button>

                  {/* ===============MULTI-UPDATE STATUS BUTTON=============== */}
                  <button
                    className="custom-button"
                    disabled={table.getSelectedRowModel().rows.length === 0}
                    onClick={() => {
                      setMultiUpdateOpen(true);
                      setCurrentTable(table);
                    }}
                  >
                    <EditAttributesIcon
                      titleAccess="Edit status"
                      style={{
                        color: !table.getIsSomeRowsSelected() ? 'rgb(235, 235, 228)' : 'gray',
                        fontSize: '36px',
                      }}
                    />
                  </button>
                </div>
              </Box>
            </div>
          </Box>
          <div className="flex">
            <button
              className="custom-button"
              onClick={() => {
                handleGPTEvaluation();
              }}
            >
              <Gavel
                titleAccess="Evaluate submissions with GPT"
                style={{
                  color: 'gray',
                }}
              />
            </button>
            <button className="custom-button" onClick={() => setDarkMode(!darkMode)}>
              {darkMode ? (
                <DarkModeIcon titleAccess="Switch to light mode" />
              ) : (
                <DarkModeOutlinedIcon
                  titleAccess="Switch to dark mode"
                  style={{
                    color: 'gray',
                  }}
                />
              )}
            </button>
          </div>
        </Box>
      );
    },
    // ================================== FOOTER ==================================
  });

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <MaterialReactTable table={table} />
      <Toast {...toast} onDone={() => setToast(null)} show={toast !== null} content="" />
    </ThemeProvider>
  );
};

export default HiringTable;
