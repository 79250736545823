import { useState } from 'react';

/**
 * A modal component for confirming data deletion operations
 */
export default function ConfirmationModal({
  isOpen,
  onClose,
  onConfirm,
  isProcessing,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (options: { deleteAll: boolean; rebuildDictionary: boolean }) => void;
  isProcessing: boolean;
}) {
  const [deleteAll, setDeleteAll] = useState(false);
  const [rebuildDictionary, setRebuildDictionary] = useState(false);

  return isOpen ? (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 rounded-lg p-6 max-w-lg w-full mx-4">
        <h2 className="text-xl font-bold text-red-500 mb-4">⚠️ Warning: Data Deletion</h2>
        <p className="text-gray-200 mb-6">
          You are about to delete and reprocess failure analysis data for this suite. This action cannot be undone.
          Please select which data you want to delete and rebuild:
        </p>

        <div className="space-y-4 mb-6">
          <div>
            <label className="flex items-start space-x-3 cursor-pointer">
              <input
                type="checkbox"
                checked={deleteAll}
                onChange={(e) => setDeleteAll(e.target.checked)}
                className="m-1 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <div>
                <span className="text-gray-200 font-semibold block">Delete All Block Sequences</span>
                <span className="text-gray-400 text-sm">
                  This will remove all analyzed test failure patterns across all test suites for this customer. 
                  Choose this if you want to start fresh with failure pattern analysis.
                </span>
              </div>
            </label>
          </div>

          <div>
            <label className="flex items-start space-x-3 cursor-pointer">
              <input
                type="checkbox"
                checked={rebuildDictionary}
                onChange={(e) => setRebuildDictionary(e.target.checked)}
                className="m-1 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <div>
                <span className="text-gray-200 font-semibold block">Rebuild Building Block Dictionary</span>
                <span className="text-gray-400 text-sm">
                  This will reset and rebuild the base patterns used to analyze test failures.
                  Choose this if you're seeing incorrect or outdated pattern matching.
                </span>
              </div>
            </label>
          </div>
        </div>

        {!deleteAll && !rebuildDictionary && (
          <p className="text-yellow-500 text-sm mb-6">
            ⓘ No options selected: This will only reprocess the current suite's data using existing patterns.
          </p>
        )}

        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            disabled={isProcessing}
            className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
          >
            Cancel
          </button>
          <button
            onClick={() => onConfirm({ deleteAll, rebuildDictionary })}
            disabled={isProcessing}
            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 flex items-center space-x-2"
          >
            {isProcessing ? (
              <>
                <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
                <span>Deleting...</span>
              </>
            ) : (
              <span>Confirm Deletion</span>
            )}
          </button>
        </div>
      </div>
    </div>
  ) : null;
}
