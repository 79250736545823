import { useState, useEffect } from 'react';
import React from 'react';

/**
 * Hook for managing suite ID from URL parameters and user input
 * Handles URL updates and form submission
 */
export const useSuiteId = () => {
  // Current suite ID being used
  const [suiteId, setSuiteId] = useState('');

  // Value in the input field
  const [inputValue, setInputValue] = useState('');

  /**
   * Initialize suiteId from URL params or default
   * Updates the URL and triggers the initial mutation
   */
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const urlSuiteId = params.get('suiteId');
    const defaultSuiteId = 'tommq1z3up28n98irdxl5pa4ogen';

    const initialSuiteId = urlSuiteId || defaultSuiteId;
    setInputValue(initialSuiteId);
    setSuiteId(initialSuiteId);
  }, []);

  /**
   * Handles form submission for loading a suite
   * Updates the URL with the new suite ID
   */
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (inputValue === suiteId) {
      if (window.confirm('Are you sure you want to reload the same suite?')) {
        setSuiteId(inputValue);
        const url = new URL(window.location.href);
        url.searchParams.set('suiteId', inputValue);
        window.history.pushState({}, '', url);
        window.location.reload();
      }
    } else {
      setSuiteId(inputValue);
      const url = new URL(window.location.href);
      url.searchParams.set('suiteId', inputValue);
      window.history.pushState({}, '', url);
    }
  };

  return {
    suiteId,
    inputValue,
    setInputValue,
    handleSubmit,
  };
};
