import { Conversation } from '../data/types';
import { TooltipContent, TooltipTrigger, Tooltip } from '@/components/ui/tooltip';
import { CheckCircle } from 'lucide-react';
import { TooltipProvider } from '@/components/ui/tooltip';
import { formatDate } from '@/components/HQ/utils/date';
import { motion, AnimatePresence } from 'framer-motion';

export function ConversationStatus({ conversation }: { conversation: Conversation }) {
  // Latest external message logic for completed conversations
  const latestExternalMessage = conversation.messages.find((msg) =>
    msg.messageTasks?.find((task) => task.type === 'message' && (msg.sender === 'unknown' || msg.sender === 'external') && task?.assignedToId),
  );
  const latestExternalMessageTask = latestExternalMessage?.messageTasks?.find((task) => task.type === 'message');

  const latestUpdatedDate = formatDate(String(latestExternalMessageTask?.updatedAt));

  return (
    <>
      {conversation.claimed && (
        <div className="flex items-center justify-end">
          <AnimatePresence mode="wait">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <motion.div
                    key="claimed-avatar"
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0, opacity: 0 }}
                    transition={{
                      type: 'spring',
                      stiffness: 400,
                      damping: 15,
                      mass: 0.8,
                    }}
                    className="flex items-center"
                  >
                    <img src={latestExternalMessageTask?.assignedTo?.avatar48 || ''} className="w-4 h-4 rounded-full mr-1" />
                    {conversation.completed && <CheckCircle className="w-3 h-3 text-green-600" />}
                  </motion.div>
                </TooltipTrigger>
                <TooltipContent>
                  <span>
                    <span className="font-semibold">{latestExternalMessageTask?.assignedTo?.name}</span>{' '}
                    {conversation.completed ? (
                      <>
                        <span>marked this conversation as completed on </span>
                        <span className="font-semibold">{latestUpdatedDate}</span>
                      </>
                    ) : (
                      <span>claimed this conversation </span>
                    )}
                  </span>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </AnimatePresence>
        </div>
      )}
    </>
  );
}
