import moment from 'moment';
import { sendTSGetRequest } from '../../../utils/tanstackNetwork';
import { useQuery } from '@tanstack/react-query';
import MainDrawer from './ClientMilestones/MainDrawer';
import { Typography } from '@mui/material';

export default function ClientSummaryBanner({ team }) {
  const {
    data: clientData,
    isError,
    isPlaceholderData,
    isFetching,
    error,
  } = useQuery({
    queryKey: ['clientSummaries'],
    queryFn: () => {
      const qaTeams = JSON.parse(localStorage.getItem('teams') || '[]');
      const params = new URLSearchParams();
      qaTeams.forEach((team) => params.append('qaTeamIds', team.id.toString()));
      return sendTSGetRequest(`/client-summaries?${params.toString()}`);
    },
    select: (data) => data.find((x) => x.id === team.id),
    placeholderData: [],
  });

  const thisWeekTime = moment().startOf('week').unix();

  const incompleteMaintenance = team.rawMaintenance.filter((x) => x.status !== 'done' && x.status !== 'ignore');
  const allMaintenanceTasks = incompleteMaintenance.length;
  const maintenanceThisWeek = team.rawMaintenance.filter((x) => moment(x.dueAt).startOf('week').unix() === thisWeekTime).length;

  const incompleteCrs = team.rawCrs.filter((x) => x.status !== 'done' && x.status !== 'ignore');
  const allCrTasks = incompleteCrs.length;
  const crsThisWeek = team.rawCrs.filter((x) => moment(x.dueAt).startOf('week').unix() === thisWeekTime).length;

  return (
    <div className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-6 md:divide-x md:divide-y-0">
      <div className="px-4 py-5 sm:p-6">
        <div className="text-2xl font-normal text-gray-900">Age in Weeks</div>
        <div className="mt-1 flex items-baseline justify-between md:block lg:flex">
          <div className="overflow-hidden flex items-baseline text-2xl font-semibold text-gray-900">{team.ageInWeeks}</div>
        </div>
      </div>

      <div className="px-4 py-5 sm:p-6">
        <div className="text-2xl font-normal text-gray-900">Weekly Points</div>
        <div className="mt-1 flex items-baseline justify-between md:block lg:flex">
          <div className="flex items-baseline text-2xl font-semibold text-gray-900">
            {team.weeklyPoints}
            <span className="ml-2 text-sm font-medium text-gray-500">
              {team.stepLimit} tests {team.oldPricing ? '(old pricing)' : ''}
            </span>
          </div>
        </div>
      </div>

      <div className="px-4 py-5 sm:p-6">
        <div className="text-2xl font-normal text-gray-900">Open CRs</div>
        <div className="mt-1 flex items-baseline justify-between md:block lg:flex">
          <div className="flex items-baseline text-2xl font-semibold text-gray-900">
            {allCrTasks}
            <span className="ml-2 text-sm font-medium text-gray-500">{crsThisWeek} due this week</span>
          </div>
        </div>
      </div>

      <div className="px-4 py-5 sm:p-6">
        <div className="text-2xl font-normal text-gray-900">Maintenance</div>
        <div className="mt-1 flex items-baseline justify-between md:block lg:flex">
          <div className="flex items-baseline text-2xl font-semibold text-gray-900">
            {allMaintenanceTasks}
            <span className="ml-2 text-sm font-medium text-gray-500">{maintenanceThisWeek} due this week</span>
          </div>
        </div>
      </div>

      <div className="px-4 py-5 sm:p-6">
        <div className="text-2xl font-normal text-gray-900">Open Bugs</div>
        <div className="mt-1 flex items-baseline justify-between md:block lg:flex">
          <div className="flex items-baseline text-2xl font-semibold text-gray-900">
            {team.bugs.openReports}
            <span className="ml-2 text-sm font-medium text-gray-500">{team.bugs.affectedWfs} WFs</span>
          </div>
        </div>
      </div>

      <div className="px-4 py-5 sm:p-6">
        <div className="text-2xl font-normal text-gray-900">Milestones</div>
        <div className="mt-1 flex items-baseline justify-between md:block lg:flex">
          <div className="flex items-baseline text-2xl font-semibold text-gray-900">
            {isError && <Typography variant="caption">{error.message}</Typography>}
            {isFetching && <Typography variant="caption">Loading milestone data...</Typography>}
            {!isError && !isPlaceholderData && !isFetching && clientData && <MainDrawer client={clientData} />}
          </div>
        </div>
      </div>
    </div>
  );
}
