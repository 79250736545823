import { Folder } from '../../Types/MonacoEditor/types';
type FileTree = Folder;

export function getLanguageFromFileName(fileName: string) {
  const extension = fileName.split('.').pop();
  switch (extension) {
    case 'js': return 'javascript';
    case 'ts': return 'typescript';
    case 'html': return 'html';
    case 'css': return 'css';
    case 'json': return 'json';
    case 'md': return 'markdown';
    default: return 'plaintext';
  }
}


export const buildFileTree = (files: { name: string; type: string; content: string }[]): FileTree => {
  const root: FileTree = { folders: {}, files: [] };

  files.forEach((file) => {
    const parts = file.name.split('/').filter((part) => part); // Split the path and remove empty parts
    let current = root;

    parts.forEach((part, index) => {
      if (index === parts.length - 1) {
        // It's a file
        if (!current.files) current.files = [];
        current.files.push({
          name: part.replace(/^_/, ''), // Remove leading underscores if any
          type: file.type,
          content: file.content,
          current: false,
        });
      } else {
        // It's a folder
        if (!current.folders) current.folders = {};
        if (!current.folders[part]) {
          current.folders[part] = { folders: {}, files: [] };
        }
        current = current.folders[part];
      }
    });
  });

  return root;
};
