import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Box, Stack } from '@mui/material';
import { useMemo } from 'react';
import { max, min } from 'lodash';

export default function PackOverviewTable({ tableData }) {
  const columns = useMemo(() => {
    return [
      {
        header: 'Pack Manager',
        muiTableHeadCellProps: { title: 'Pack Manager' },
        accessorKey: 'packManager',
        filterVariant: 'multi-select',
      },
      {
        header: 'Customer ARR',
        muiTableHeadCellProps: { title: "Total sum of Pack's Customer ARR" },
        accessorKey: 'packArr',
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        muiFilterSliderProps: {
          min: 0,
          max: max(tableData.map((pack) => pack.packArr)),
          step: 10_000,
          valueLabelFormat: (value) =>
            value.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 0,
            }),
        },
        Cell: ({ cell }) =>
          cell.getValue().toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
          }),
        Footer: ({ table }) => {
          const filteredTotal = table.getFilteredRowModel().rows.reduce((acc, row) => acc + row.original.packArr, 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length;
          return (
            <Stack>
              Average:
              <Box>
                {(filteredTotal / filteredRowCount).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}
              </Box>
            </Stack>
          );
        },
      },
      {
        header: 'Costs',
        muiTableHeadCellProps: {
          title:
            "Total sum of Hosting Costs (divided between all packs) and Salaries (plus benefits) of Pack's QAEs, Pack's Leads, Pack's Manager, and Director of Engineering (divided between all packs)",
        },
        accessorKey: 'packCost',
        Cell: ({ cell }) =>
          cell.getValue().toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
          }),
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        muiFilterSliderProps: {
          min: 0,
          max: max(tableData.map((pack) => pack.packCost)),
          step: 10_000,
          valueLabelFormat: (value) =>
            value.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 0,
            }),
        },
        Footer: ({ table }) => {
          const filteredTotal = table.getFilteredRowModel().rows.reduce((acc, row) => acc + row.original.packCost, 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length;
          return (
            <Stack>
              Average:
              <Box>
                {(filteredTotal / filteredRowCount).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}
              </Box>
            </Stack>
          );
        },
      },
      {
        header: 'Margin',
        muiTableHeadCellProps: { title: "Difference between Pack's ARR and Pack's Costs" },
        accessorKey: 'packMargin',
        Cell: ({ cell }) =>
          cell.getValue().toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
          }),
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        muiFilterSliderProps: {
          min: 0,
          max: max(tableData.map((pack) => pack.packMargin)),
          step: 10_000,
          valueLabelFormat: (value) =>
            value.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 0,
            }),
        },
        Footer: ({ table }) => {
          const filteredMargin = table.getFilteredRowModel().rows.reduce((acc, row) => acc + row.original.packMargin, 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length;
          return (
            <Stack>
              Average:
              <Box>
                {(filteredMargin / filteredRowCount).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}
              </Box>
            </Stack>
          );
        },
      },
      {
        header: '% Margin',
        muiTableHeadCellProps: { title: "Percentage difference between Pack's ARR and Pack's Costs" },
        id: 'packMarginPercentage',
        accessorFn: (row) => {
          if (row.packArr === 0) return '0%';
          const marginPercentage = Math.round((row.packMargin / row.packArr) * 100);
          return marginPercentage + '%';
        },
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        muiFilterSliderProps: {
          min: min(tableData.map((pack) => (pack.packArr === 0 ? 0 : (pack.packMargin / pack.packArr) * 100))),
          max: max(tableData.map((pack) => (pack.packArr === 0 ? 0 : (pack.packMargin / pack.packArr) * 100))),
          step: 1,
          valueLabelFormat: (value) => value.toLocaleString('en-US', { maximumFractionDigits: 0 }) + '%',
        },
        Footer: ({ table }) => {
          const filteredMarginPercentage = table.getFilteredRowModel().rows.reduce((acc, row) => {
            if (row.original.packArr === 0) return acc;
            const marginPercentage = Math.round((row.original.packMargin / row.original.packArr) * 100);
            return acc + marginPercentage;
          }, 0);
          const filteredRowCount = table.getFilteredRowModel().rows.filter((row) => row.original.packArr !== 0).length || 1;
          return (
            <Stack>
              Average:
              <Box>{Math.round(filteredMarginPercentage / filteredRowCount) + '%'}</Box>
            </Stack>
          );
        },
      },
      {
        header: 'Tests Under Contract',
        muiTableHeadCellProps: { title: "Amount of Tests Contracted with Pack's Customers" },
        accessorKey: 'packTestsUnderContract',
        Cell: ({ cell }) => cell.getValue().toLocaleString(),
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        muiFilterSliderProps: {
          min: 0,
          max: max(tableData.map((pack) => pack.packTestsUnderContract)),
          step: 100,
          valueLabelFormat: (value) => value.toLocaleString('en-US', { maximumFractionDigits: 0 }),
        },
        Footer: ({ table }) => {
          const filteredTotal = table.getFilteredRowModel().rows.reduce((acc, row) => acc + row.original.packTestsUnderContract, 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length;
          return (
            <Stack>
              Average:
              <Box>{(filteredTotal / filteredRowCount).toLocaleString()}</Box>
            </Stack>
          );
        },
      },
      {
        header: 'Active Tests',
        muiTableHeadCellProps: { title: "Amount of Pack's Tests that have been implemented, includes Bugged and Maintenance" },
        id: 'packActiveTests',
        accessorFn: (row) => row.packActiveTests,
        Cell: ({ cell }) => cell.getValue().toLocaleString(),
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        muiFilterSliderProps: {
          min: 0,
          max: max(tableData.map((pack) => pack.packActiveTests)),
          step: 100,
          valueLabelFormat: (value) => value.toLocaleString('en-US', { maximumFractionDigits: 0 }),
        },
        Footer: ({ table }) => {
          const filteredTotalActive = table.getFilteredRowModel().rows.reduce((acc, row) => acc + row.original.packActiveTests, 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length;
          return (
            <Stack>
              Average:
              <Box>{(filteredTotalActive / filteredRowCount).toLocaleString('en-US', { maximumFractionDigits: 0 })}</Box>
            </Stack>
          );
        },
      },
      {
        header: '% Tests in Maintenance',
        muiTableHeadCellProps: { title: "Percentage of Pack's Active Tests in Maintenance" },
        id: 'packTestsInMaintenance',
        accessorFn: (row) => {
          if (row.packActiveTests === 0) return '0%';
          const maintenancePercentage = Math.round((row.packTestsInMaintenance / row.packActiveTests) * 100);
          return maintenancePercentage + '%';
        },
        Cell: ({ cell }) => cell.getValue().toLocaleString(),
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        muiFilterSliderProps: {
          min: 0,
          max: 100,
          step: 5,
          valueLabelFormat: (value) => value.toLocaleString('en-US', { maximumFractionDigits: 0 }) + '%',
        },
        Footer: ({ table }) => {
          const filteredMaintenancePercentage = table.getFilteredRowModel().rows.reduce((acc, row) => {
            if (row.original.packActiveTests === 0) return acc;
            const maintenancePercentage = Math.round((row.original.packTestsInMaintenance / row.original.packActiveTests) * 100);
            return acc + maintenancePercentage;
          }, 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length;
          return (
            <Stack>
              Average:
              <Box>{Math.round(filteredMaintenancePercentage / filteredRowCount) + '%'}</Box>
            </Stack>
          );
        },
      },
      {
        header: '% Tests Bugged',
        muiTableHeadCellProps: { title: "Percentage of Pack's Active Tests that are Bugged" },
        id: 'packTestsBugged',
        accessorFn: (row) => {
          if (row.packActiveTests === 0) return '0%';
          const buggedPercentage = Math.round((row.packTestsBugged / row.packActiveTests) * 100);
          return buggedPercentage + '%';
        },
        Cell: ({ cell }) => cell.getValue().toLocaleString(),
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        muiFilterSliderProps: {
          min: 0,
          max: 100,
          step: 5,
          valueLabelFormat: (value) => value.toLocaleString('en-US', { maximumFractionDigits: 0 }) + '%',
        },
        Footer: ({ table }) => {
          const filteredBuggedPercentage = table.getFilteredRowModel().rows.reduce((acc, row) => {
            if (row.original.packActiveTests === 0) return acc;
            const buggedPercentage = Math.round((row.original.packTestsBugged / row.original.packActiveTests) * 100);
            return acc + buggedPercentage;
          }, 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length;
          return (
            <Stack>
              Average:
              <Box>{Math.round(filteredBuggedPercentage / filteredRowCount) + '%'}</Box>
            </Stack>
          );
        },
      },
      {
        header: 'QA Pack Size (Incl. Leads)',
        muiTableHeadCellProps: { title: 'Amount of Engineers in Pack (Including Leads)' },
        accessorKey: 'packSize',
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        muiFilterSliderProps: {
          min: 0,
          max: max(tableData.map((pack) => pack.packSize)),
          step: 1,
          valueLabelFormat: (value) => value.toLocaleString('en-US', { maximumFractionDigits: 0 }),
        },
        Footer: ({ table }) => {
          const filteredTotal = table.getFilteredRowModel().rows.reduce((acc, row) => acc + row.original.packSize, 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length;
          return (
            <Stack>
              Average:
              <Box>{Math.round(filteredTotal / filteredRowCount)}</Box>
            </Stack>
          );
        },
      },
      {
        header: 'Avg. Active Tests per QAE',
        muiTableHeadCellProps: { title: "Pack's Active Tests divided by amount of QAEs in Pack (Excluding Leads)" },
        id: 'packAverageActiveTestsPerQae',
        accessorFn: (row) => row.packAverageActiveTestsPerQae || 0,
        Cell: ({ cell }) => (cell.getValue() || 0).toLocaleString(),
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        muiFilterSliderProps: {
          min: 0,
          max: max(tableData.map((pack) => pack.packAverageActiveTestsPerQae || 0)),
          step: 5,
          valueLabelFormat: (value) => value.toLocaleString('en-US', { maximumFractionDigits: 0 }),
        },
        Footer: ({ table }) => {
          const filteredTotal = table.getFilteredRowModel().rows.reduce((acc, row) => acc + (row.original.packAverageActiveTestsPerQae || 0), 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length || 1;
          return (
            <Stack>
              Average:
              <Box>{Math.round(filteredTotal / filteredRowCount)}</Box>
            </Stack>
          );
        },
      },
    ];
  }, [tableData]);

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 15,
      },
      sorting: [{ id: 'packManager', desc: false }],
    },
    columnFilterDisplayMode: 'popover',
    enableColumnActions: true,
    enableColumnFilters: true,
    enableTopToolbar: true,
    enableTableFooter: true,
    enableFacetedValues: true,
  });

  return <MaterialReactTable table={table} />;
}
