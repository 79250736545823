export const processTeamViewData = (
  leadsOnly,
  qaEngineersOnly,
  managersOnly,
  allQaEngineeringEEs,
  allCustomersData,
  annualHostingCosts,
  annualQaeBenefitsCosts,
) => {
  return leadsOnly.map((lead) => {
    const teamCost = () => {
      const teamQaeCost = qaEngineersOnly
        .filter((user) => user.team.id === lead.team.id)
        .reduce((acc, user) => acc + user.salary + annualQaeBenefitsCosts, 0);
      const teamLeadCost = lead.salary + annualQaeBenefitsCosts;
      const manager = managersOnly.find((manager) => manager.team.id === lead.team.packId);
      const managerCostPerTeam = manager
        ? (manager.salary + annualQaeBenefitsCosts) / leadsOnly.filter((lead) => lead.team.packId === lead.team.packId).length
        : 0;
      const director = allQaEngineeringEEs.find((user) => user.name === 'Eric Eidelberg');
      const directorCostPerTeam = director ? (director.salary + annualQaeBenefitsCosts) / leadsOnly.length : 0;
      const qaFloatingEngineers = allQaEngineeringEEs.filter((user) => !user.team && user.name !== 'Eric Eidelberg');
      const qaFloatingEngineersCostPerTeam = qaFloatingEngineers.reduce((acc, user) => acc + user.salary + annualQaeBenefitsCosts, 0) / leadsOnly.length;
      const hostingCostPerTeam = annualHostingCosts / leadsOnly.length;
      return teamQaeCost + teamLeadCost + managerCostPerTeam + directorCostPerTeam + qaFloatingEngineersCostPerTeam + hostingCostPerTeam;
    };

    const teamAverageActiveTestsPerQae = () => {
      return (
        allCustomersData.filter((customer) => customer.qaLead.id === lead.qawId).reduce((acc, customer) => acc + customer.metrics.tests.active, 0) /
        qaEngineersOnly.filter((user) => user.team.id === lead.team.id).length
      );
    };

    return {
      teamName: lead.team.name,
      teamId: lead.team.id,
      packId: lead.team.packId,
      packManager: managersOnly.find((manager) => manager.team.id === lead.team.packId),
      teamArr: Math.round(allCustomersData.filter((customer) => customer.qaLead.id === lead.qawId).reduce((acc, customer) => acc + customer.arr, 0)),
      teamTestsUnderContract: allCustomersData
        .filter((customer) => customer.qaLead.id === lead.qawId)
        .reduce((acc, customer) => acc + customer.metrics.tests.expected, 0),
      teamActiveTests: allCustomersData
        .filter((customer) => customer.qaLead.id === lead.qawId)
        .reduce((acc, customer) => acc + customer.metrics.tests.active, 0),
      teamTestsInMaintenance: allCustomersData
        .filter((customer) => customer.qaLead.id === lead.qawId)
        .reduce((acc, customer) => acc + customer.metrics.tests.maintenance, 0),
      teamTestsBugged: allCustomersData
        .filter((customer) => customer.qaLead.id === lead.qawId)
        .reduce((acc, customer) => acc + customer.metrics.tests.bugged, 0),
      teamSize:
        qaEngineersOnly.filter((user) => user.team.id === lead.team.id).length + leadsOnly.filter((user) => user.team.id === lead.team.id).length,
      teamAverageActiveTestsPerQae: Math.round(teamAverageActiveTestsPerQae()),
      teamCost: teamCost(),
      teamMargin: Math.round(
        allCustomersData.filter((customer) => customer.qaLead.id === lead.qawId).reduce((acc, customer) => acc + customer.arr, 0) - teamCost(),
      ),
      teamCustomers: allCustomersData.filter((customer) => customer.qaLead.id === lead.qawId),
    };
  });
};

export const processCustomerViewData = (allCustomersData, leadsOnly) => {
  return allCustomersData.map((customer) => {
    const teamName = leadsOnly.find((lead) => lead.qawId === customer.qaLead.id)?.team?.name || 'No Team';

    return {
      customerName: customer.name || 'No Name',
      team: teamName,
      lead: customer.qaLead?.name || 'No Lead',
      manager: customer.qaManager?.name || 'No Manager',
      csm: customer.csm?.name || 'No CSM',
      arr: customer.arr || 0,
      testsUnderContract: customer.metrics.tests.expected || 0,
      testsActive: customer.metrics.tests.active || 0,
      testsInMaintenance: customer.metrics.tests.maintenance || 0,
      testsBugged: customer.metrics.tests.bugged || 0,
    };
  });
};

export const calculateMetrics = (allCustomersData, totalArr, allQaEngineeringEEs, annualHostingCosts, annualQaeBenefitsCosts) => {
  const totalCost = annualHostingCosts + allQaEngineeringEEs.reduce((acc, user) => acc + user.salary + annualQaeBenefitsCosts, 0);
  const totalTestsUnderContract = allCustomersData.reduce((acc, customer) => acc + customer.stepLimit, 0);
  const totalTestsActive = allCustomersData.reduce((acc, customer) => acc + customer.metrics.tests.active, 0);
  const totalTestsBugged = allCustomersData.reduce((acc, customer) => acc + customer.metrics.tests.bugged, 0);
  const totalTestsMaintenance = allCustomersData.reduce((acc, customer) => acc + customer.metrics.tests.maintenance, 0);

  const activeMaintenanceTasks = allCustomersData.flatMap((customer) =>
    customer.maintenance.flatMap((m) => m.childTasks).filter((t) => t.status !== 'done' && t.workflow?.stepCount),
  );
  const blockedMaintenanceTasks = activeMaintenanceTasks.filter((t) => t.status === 'blocked');
  const totalBlockedTestsMaintenance = blockedMaintenanceTasks.reduce((acc, t) => acc + +t.workflow.stepCount, 0);

  return {
    totalCost,
    totalTestsUnderContract,
    totalTestsActive,
    totalTestsBugged,
    totalTestsMaintenance,
    totalBlockedTestsMaintenance,
    totalMargin: totalArr - totalCost,
  };
};

export const handleTimeTrackingLoaded = (setIsTimeTrackingLoading) => {
  setIsTimeTrackingLoading(false);
};

export const processPackViewData = (
  leadsOnly,
  qaEngineersOnly,
  managersOnly,
  allQaEngineeringEEs,
  allCustomersData,
  annualHostingCosts,
  annualQaeBenefitsCosts,
) => {
  // Group teams by pack
  const packGroups = leadsOnly.reduce((acc, lead) => {
    const packId = lead.team.packId;
    if (!acc[packId]) {
      acc[packId] = [];
    }
    acc[packId].push(lead);
    return acc;
  }, {});

  return Object.entries(packGroups).map(([packId, packLeads]) => {
    const packManager = managersOnly.find((manager) => manager.team.id === parseInt(packId));
    const packQaes = qaEngineersOnly.filter((qae) => packLeads.some((lead) => lead.team.id === qae.team.id));
    const packCustomers = allCustomersData.filter((customer) => 
      packLeads.some((lead) => lead.qawId === customer.qaLead.id),
    );

    const packCost = () => {
      const packQaeCost = packQaes.reduce((acc, user) => acc + user.salary + annualQaeBenefitsCosts, 0);
      const packLeadCost = packLeads.reduce((acc, lead) => acc + lead.salary + annualQaeBenefitsCosts, 0);
      const managerCost = packManager ? packManager.salary + annualQaeBenefitsCosts : 0;
      const director = allQaEngineeringEEs.find((user) => user.name === 'Eric Eidelberg');
      const directorCostPerPack = director ? (director.salary + annualQaeBenefitsCosts) / Object.keys(packGroups).length : 0;
      const qaFloatingEngineers = allQaEngineeringEEs.filter((user) => !user.team && user.name !== 'Eric Eidelberg');
      const qaFloatingEngineersCostPerPack = qaFloatingEngineers.reduce((acc, user) => acc + user.salary + annualQaeBenefitsCosts, 0) / Object.keys(packGroups).length;
      const hostingCostPerPack = annualHostingCosts / Object.keys(packGroups).length;
      return packQaeCost + packLeadCost + managerCost + directorCostPerPack + qaFloatingEngineersCostPerPack + hostingCostPerPack;
    };

    const totalCost = packCost();
    const totalArr = packCustomers.reduce((acc, customer) => acc + customer.arr, 0);

    return {
      packName: packManager?.team?.name || 'No Pack',
      packId,
      packManager: packManager?.name || 'No Manager',
      packArr: Math.round(totalArr),
      packCost: totalCost,
      packMargin: Math.round(totalArr - totalCost),
      packTestsUnderContract: packCustomers.reduce((acc, customer) => acc + customer.metrics.tests.expected, 0),
      packActiveTests: packCustomers.reduce((acc, customer) => acc + customer.metrics.tests.active, 0),
      packTestsInMaintenance: packCustomers.reduce((acc, customer) => acc + customer.metrics.tests.maintenance, 0),
      packTestsBugged: packCustomers.reduce((acc, customer) => acc + customer.metrics.tests.bugged, 0),
      packSize: packQaes.length + packLeads.length,
      packAverageActiveTestsPerQae: Math.round(
        packCustomers.reduce((acc, customer) => acc + customer.metrics.tests.active, 0) / packQaes.length,
      ),
    };
  });
};
