import { useState } from 'react';

import { FaFolder, FaFolderOpen, FaFile } from 'react-icons/fa';
import { FileNodeInterface, File } from '../../Types/MonacoEditor/types';

const FileNode = ({ node, name, onFileSelect, currentFile, level = 0, openOnDefault = false }: FileNodeInterface) => {
    const [isOpen, setIsOpen] = useState(openOnDefault);


    const isFileSelected = (file: File) => {
        return currentFile && currentFile.name === file.name;
    };

    const hasFolders = node.folders && Object.keys(node.folders).length > 0;
    const hasFiles = node.files && node.files.length > 0;


    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            {/* Folder Header */}
            <div
                onClick={toggleOpen}
                style={{
                    cursor: hasFolders || hasFiles ? 'pointer' : 'default',
                    paddingLeft: `${level * 15}px`,
                    display: 'flex',
                    alignItems: 'center',
                    userSelect: 'none',
                }}
            >
                {isOpen ? (
                    <FaFolderOpen style={{ marginRight: '5px' }} />
                ) : (
                    <FaFolder style={{ marginRight: '5px' }} />
                )}
                <span>{name}</span>
            </div>

            {/* Render Subfolders and Files */}
            {isOpen && (
                <div>
                    {/* Render Subfolders */}
                    {hasFolders &&
                        Object.entries(node.folders).map(([folderName, folderNode]) => (
                            <FileNode
                                key={folderName}
                                node={folderNode}
                                name={folderName}
                                onFileSelect={onFileSelect}
                                currentFile={currentFile}
                                level={level + 1}
                                openOnDefault={false}
                            />
                        ))}

                    {/* Render Files */}
                    {hasFiles && (
                        <ul style={{ listStyle: 'none', paddingLeft: `${(level + 1) * 15}px` }}>
                            {node.files.map((file) => (
                                <li
                                    key={file.name}
                                    onClick={() => onFileSelect(file)}
                                    style={{ cursor: 'pointer', marginBottom: '5px', display: 'flex', alignItems: 'center', backgroundColor: `${isFileSelected(file) ? "#404040" : "#111"} ` }}
                                >
                                    <FaFile style={{ marginRight: '5px' }} />
                                    <span>{file.name}</span>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            )}
        </div>
    );
};

export default FileNode;
