import { InvestigationColumn } from './InvestigationColumn';
import { useState, useEffect, Fragment } from 'react';
import _ from 'lodash';
import { XCircleIcon, CheckCircleIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { Menu, Transition } from '@headlessui/react';
import { sendPostRequest } from '../../utils/network';
import { EnvNotesModal } from './EnvNotesModal';
import { NewTaskModal } from './NewTaskModal';
import TeamSelector from '../Layout/TeamSelector';
import ActiveShiftsDisplay from './ActiveShiftsDisplay';
import { useDisabledTasks } from '../../hooks/useDisabledTasks';
import useQueryKeyData from '../../hooks/useQueryKeyData';
import { getTriageStats } from './helpers';
import { useInvestigationBoardQueries, useTaskMutation } from './hooks';
import Toast from '../Layout/Toast';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const taskTypeOrder = {
  message: 0,
  triage: 2,
  // bugVerification: 2,
  projectPlan: 3,
  none: 4,
  promotion: 1,
  suiteInvestigation: 2,
};

export function InvestigationBoard() {
  const maxClaimedTriageTasks = 5;
  const filterSettingsJSON = localStorage.getItem('filterSettings');
  const filterSettings = filterSettingsJSON
    ? JSON.parse(filterSettingsJSON)
    : {
        showUserTasksOnly: false,
        showUserTodosOnly: false,
        showGenericTasks: true,
        showTriageTasks: true,
        showMessageTasks: true,
        showPromotionTasks: true,
        showPrimaryTeamOnly: false,
      };

  const { data: allUsers } = useQueryKeyData(['getAllQAWUsers']);
  const user = allUsers.find((x) => x.qawId === localStorage.getItem('userId'));

  const [showUserTasksOnly, setShowUserTasksOnly] = useState(filterSettings.showUserTasksOnly);
  const [showUserTodosOnly, setShowUserTodosOnly] = useState(filterSettings.showUserTodosOnly);
  const [showGenericTasks, setShowGenericTasks] = useState(filterSettings.showGenericTasks);
  const [showTriageTasks, setShowTriageTasks] = useState(filterSettings.showTriageTasks);
  const [showMessageTasks, setShowMessageTasks] = useState(filterSettings.showMessageTasks);
  const [showPromotionTasks, setShowPromotionTasks] = useState(filterSettings.showPromotionTasks);
  const [primaryTeamOnly, setPrimaryTeamOnly] = useState(filterSettings.showPrimaryTeamOnly);
  const [showSpinner, setShowSpinner] = useState(false);
  const [enabledTeams, setEnabledTeams] = useState([]);
  const [toastData, setToastData] = useState(null);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [notesModalData, setNotesModalData] = useState('');
  const [editingNotes, setEditingNotes] = useState(false);
  const [showNewTaskModal, setShowNewTaskModal] = useState(false);
  const [newTaskModalData, setNewTaskModalData] = useState({});
  const { setDisabledTasks } = useDisabledTasks();

  let triageStats = [];
  let teamTriageSummaries = {};

  /**
   * Takes in the array of all tasks and returns an object with an array of tasks for each columln.
   * @param {import('../../types').QAWTask[]} tasks
   * @returns {{ tasks: {
   *   toDoTasks: import('../../types').QAWTask[],
   *   inProgressTasks: import('../../types').QAWTask[],
   *   messageTasks: import('./types').MessageTask[],
   * }, hasUserClaimedMaxTasks: boolean }} The sorted tasks object.
   */
  const filterTasks = (tasks) => {
    let visibleLeadsIds = enabledTeams.length ? enabledTeams.filter((x) => x.active).map((y) => y.qawId) : ['ckyj0a6ar000x09mfefnp9irj']; // allows seeing Test Client when deselecting all teams

    // ensure tasks are sorted by type & filter out tasks for unselected teams
    let filteredTasks = tasks
      .sort((a, b) => {
        const aSubType = a.subType || a.type;
        const bSubType = b.subType || b.type;
        if (taskTypeOrder[aSubType] < taskTypeOrder[bSubType]) return -1;
        if (taskTypeOrder[aSubType] > taskTypeOrder[bSubType]) return 1;
        return 0;
      })
      .filter((x) => visibleLeadsIds.includes(x.qaLead?.id || x?.qateam?.lead.qawId));

    // Adjust tasks based on primaryTeamOnly state
    if (primaryTeamOnly) {
      filteredTasks = filteredTasks.filter((task) => !task.primaryQaTeam || task.qaLead.id === task.primaryQaTeam?.lead.qawId);
    }

    // partition tasks into toDo and inProgress
    const [todoTasks, inProgressTasks] = _.partition(filteredTasks, (x) => x.status === 'toDo');

    // generate triage stats
    let { stats, groupsSummary } = getTriageStats(
      enabledTeams,
      tasks.filter((x) => x.type === 'suiteInvestigation'),
      todoTasks.filter((x) => x.type === 'suiteInvestigation'),
      inProgressTasks.filter((x) => x.type === 'suiteInvestigation'),
      tasks.filter((x) => x.type === 'message'), // message tasks
    );

    triageStats = stats;
    teamTriageSummaries = groupsSummary;

    // filter tasks based on selected view toggles
    const todoTasksFiltered = todoTasks.filter((x) => {
      if (x.type === 'suiteInvestigation') return showTriageTasks;
      if (x.type === 'promotion') return showPromotionTasks;
      if (x.type === 'message') return showMessageTasks;
      if (x.subType === 'bugVerification') return showGenericTasks;
      if (x.subType === 'projectPlan') return showGenericTasks;
      if (x.subType === 'none') return showGenericTasks;
      return true;
    });

    // filter tasks based on selected view toggles
    const inProgressTasksFiltered = inProgressTasks.filter((x) => {
      if (x.type === 'suiteInvestigation') return showTriageTasks;
      if (x.type === 'promotion') return showPromotionTasks;
      if (x.type === 'message') return showMessageTasks;
      if (x.subType === 'bugVerification') return showGenericTasks;
      if (x.subType === 'projectPlan') return showGenericTasks;
      if (x.subType === 'none') return showGenericTasks;
      return true;
    });

    // sort in progress tasks once more so current user's tasks are on top
    const sortedTasks = {
      toDoTasks: todoTasksFiltered,
      inProgressTasks: _.orderBy(
        inProgressTasksFiltered,
        function (task) {
          if (task.assignedTo?.email === user.email) {
            return 1;
          }

          return 0;
        },
        'desc',
      ),
    };

    const hasUserClaimedMaxTasks =
      inProgressTasks.filter((x) => x.type === 'suiteInvestigation' && x.assignedTo.id === user.qawId).length >= maxClaimedTriageTasks;

    // return the sorted tasks object
    return { tasks: sortedTasks, hasUserClaimedMaxTasks };
  };

  const {
    tasksQuery: {
      data: { tasks, hasUserClaimedMaxTasks },
      isPending,
      isError,
      error,
      refetch,
    },
    userShiftPrefsQuery: { data: userShiftPrefs, refetch: refetchShiftPrefs },
    activeShiftsQuery: { data: activeShifts, refetch: refetchActiveShifts },
  } = useInvestigationBoardQueries(user, filterTasks);

  if (isPending) return <div>Loading...</div>;
  if (isError) return <span>Error: {error.message}</span>;

  const taskMutation = useTaskMutation(refetch, setDisabledTasks);

  useEffect(() => {
    // update localStorage with filter settings
    localStorage.setItem(
      'filterSettings',
      JSON.stringify({ showUserTasksOnly, showUserTodosOnly, showGenericTasks, showTriageTasks, showMessageTasks, showPromotionTasks }),
    );
  }, [showUserTasksOnly, showUserTodosOnly, showMessageTasks, showGenericTasks, showTriageTasks, showPromotionTasks]);

  const allowedActions = ['/check', '/ignore', '/urgent', '/done', '/claim'];
  function suiteAction(task, action) {
    if (!allowedActions.includes(action)) {
      throw new Error(`Action ${action} is not allowed. Allowed actions: ${allowedActions.join(',')}`);
    }

    // @ts-ignore
    taskMutation.mutate({ endpoint: action, task });
  }

  function toggleClaimed(task, doClaim, linkToOpen) {
    if (!task) {
      throw new Error('Task ID was undefined.');
    }

    if (doClaim) {
      if (linkToOpen) window.open(linkToOpen, '_blank');
      // @ts-ignore
      taskMutation.mutate({ endpoint: '/claim', task });
    } else {
      // @ts-ignore
      taskMutation.mutate({ endpoint: '/unclaim', task });
    }
  }

  function getShiftButtonText(text) {
    if (!enabledTeams.length) {
      return 'End My Shift For All Teams';
    }

    if (text === 'End') {
      // For End button, only count teams user is on shift for
      const activeShiftTeams = userShiftPrefs?.suiteConfig?.teams || [];
      const teamCount = activeShiftTeams.length;

      if (teamCount === 1) {
        const team = enabledTeams.find((t) => t.qawId === activeShiftTeams[0]);
        return `End Shift For ${team?.teamName || 'Team'}`;
      }

      return `End Shift For ${teamCount} Teams`;
    }

    // For Start button, show selected teams count
    let teamText;
    if (enabledTeams.length === 1) {
      teamText = enabledTeams[0].teamName;
    } else {
      teamText = enabledTeams.length.toString();
    }
    return `${text} Shift For ${teamText}${teamText.length <= 2 ? ' Teams' : ''}`;
  }

  async function startShift(excludedTaskType) {
    try {
      setShowSpinner(true);
      // if messages or suites only selected, unsubscrbie from the other first
      if (excludedTaskType) {
        await sendPostRequest('/task-unsubscribe', {
          qawId: user.qawId,
          taskType: excludedTaskType,
        });
      }

      const teamIds = enabledTeams.filter((x) => x.active && x.qawId !== 'cky0ip87x002509jo3exj9s4z').map((x) => x.qawId);

      await sendPostRequest('/start-shift', {
        qawId: user.qawId,
        teamIds,
      });

      setToastData({
        title: 'Success!',
        message: 'Started Team Shift',
        isSuccess: true,
      });
    } catch (e) {
      setShowSpinner(false);
      setToastData({
        title: 'Ruh-roh',
        message: `Something went wrong: ${e.message}`,
        isSuccess: false,
      });
    } finally {
      setShowSpinner(false);
      await refetchShiftPrefs();
      await refetchActiveShifts();
    }
  }

  async function endShift() {
    try {
      setShowSpinner(true);

      const teamIds = enabledTeams.filter((x) => x.active).map((x) => x.qawId);

      await sendPostRequest('/end-shift', { qawId: user.qawId, teamIds });

      // unclaim all claimed triage tasks and messages
      const claimedTasks = tasks.inProgressTasks.filter(
        (x) => x.assignedTo.id === user.qawId && (x.type === 'suiteInvestigation' || x.type === 'message'),
      );
      claimedTasks.forEach((t) => {
        // @ts-ignore
        taskMutation.mutate({ endpoint: '/unclaim', task: t });
      });

      setShowSpinner(false);
      setToastData({
        title: 'Success!',
        message: 'Ended Team Shift',
        isSuccess: true,
      });
    } catch (e) {
      setShowSpinner(false);
      setToastData({
        title: 'Ruh-roh',
        message: `Something went wrong: ${e.message}`,
        isSuccess: false,
      });
    } finally {
      setShowSpinner(false);
      await refetchShiftPrefs();
      await refetchActiveShifts();
    }
  }

  return (
    <div className="flex flex-col min-h-screen bg-gray-100 p-4">
      <div className="bg-purple-100 p-2 text-center mb-4 rounded-md">
        <span className="text-purple-800">
          Check out our new HQ experience!{' '}
          <a href="/hq" className="underline hover:text-purple-900 font-medium">
            Try HQ V0
          </a>
        </span>
      </div>
      {showSpinner && (
        <div className="fixed h-screen w-screen bg-gray-400 z-10 bg-opacity-50 -ml-5 -mt-6">
          <div className="fixed top-1/2 left-1/2">
            <img
              className="animate-spin"
              src="https://assets-global.website-files.com/6260298eca091b57c9cf188e/6260298eca091b8710cf18ea_logo.svg"
              alt="logo"
              width="40"
            />
            <h1 className="-ml-2 mt-2 text-white">Updating...</h1>
          </div>
        </div>
      )}

      <div className="pb-2">
        <dl className="grid grid-cols-1 gap-5 sm:grid-cols-4">
          {triageStats.map((item) => (
            <div key={item.name} className="overflow-hidden rounded-lg bg-gray-50 px-4 py-5 shadow sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
              <dd className="mt-1 text-lg font-semibold tracking-tight text-gray-900">{item.stat}</dd>
            </div>
          ))}
        </dl>
      </div>

      <div className="pb-2">
        <div className="overflow-hidden rounded-lg bg-gray-50 px-4 pb-2 shadow">
          <div className="flex flex-row justify-between items-start gap-4">
            <div className="flex-grow overflow-hidden">
              <TeamSelector
                enabledTeams={enabledTeams}
                setEnabledTeams={setEnabledTeams}
                refetchQuery={refetch}
                teamTriageSummaries={teamTriageSummaries}
                activeShift={Boolean(userShiftPrefs?.suiteConfig?.teams?.length)}
                setToastData={setToastData}
              />
            </div>
            <div className="flex flex-col ml-4 items-center min-w-[300px]">
              <div id="button-container" className="mt-2 mb-2 flex">
                {!userShiftPrefs?.suiteConfig?.teams?.length ? (
                  <Menu as="div" className="relative inline-block text-left">
                    <div className="flex">
                      <button
                        type="button"
                        className="inline-flex items-center gap-x-2 rounded-l-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm h-10 hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        disabled={enabledTeams.every((x) => !x.active)}
                        onClick={() => startShift()}
                      >
                        <CheckCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        {getShiftButtonText('Start')}
                      </button>
                      <Menu.Button
                        type="button"
                        className="inline-flex items-center rounded-r-md border-l border-green-700 bg-green-600 px-2 py-2.5 text-sm font-semibold text-white shadow-sm h-10 hover:bg-green-500"
                      >
                        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item key="messagesOnly">
                            {({ active }) => (
                              <button
                                type="button"
                                onClick={() => startShift('suite')}
                                className={classNames(
                                  'w-full text-left',
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'block px-4 py-2 text-sm',
                                )}
                              >
                                Messages Only
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item key="suitesOnly">
                            {({ active }) => (
                              <button
                                type="button"
                                onClick={() => startShift('message')}
                                className={classNames(
                                  'overflow-visible w-full text-left',
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'block px-4 py-2 text-sm',
                                )}
                              >
                                Suites Only
                              </button>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                ) : (
                  <div className="relative inline-block text-left">
                    <button
                      type="button"
                      className="w-max inline-flex items-center gap-x-2 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm h-10 hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                      onClick={endShift}
                    >
                      <XCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                      {getShiftButtonText('End')}
                    </button>
                  </div>
                )}
              </div>
              <div className="flex-1 overflow-hidden">{<ActiveShiftsDisplay shifts={activeShifts} teams={enabledTeams} user={user} />}</div>
            </div>
          </div>
        </div>
      </div>

      {/* Main 3 column grid */}
      <div className="relative grid grid-cols-1 items-start gap-4 lg:grid-cols-2 lg:gap-4">
        <div className="grid grid-cols-1 gap-4">
          <InvestigationColumn
            title="To Do"
            tasks={tasks.toDoTasks.filter((x) => (showUserTodosOnly ? x.assignedTo?.id === user.qawId || !x.assignedTo?.id : true))}
            toggleClaimed={toggleClaimed}
            user={user}
            suiteAction={suiteAction}
            setShowNotesModal={setShowNotesModal}
            setNotesModalData={setNotesModalData}
            setEditingNotes={setEditingNotes}
            showMessageTasks={showMessageTasks}
            setShowMessageTasks={setShowMessageTasks}
            showGenericTasks={showGenericTasks}
            setShowGenericTasks={setShowGenericTasks}
            showTriageTasks={showTriageTasks}
            setShowTriageTasks={setShowTriageTasks}
            hasUserClaimedMaxTasks={hasUserClaimedMaxTasks}
            showPromotionTasks={showPromotionTasks}
            setShowPromotionTasks={setShowPromotionTasks}
            primaryTeamOnly={primaryTeamOnly}
            setPrimaryTeamOnly={setPrimaryTeamOnly}
            showUserTodosOnly={showUserTodosOnly}
            setShowUserTodosOnly={setShowUserTodosOnly}
          />
        </div>

        <div className="grid grid-cols-1 gap-4">
          <InvestigationColumn
            title="In Progress"
            tasks={tasks.inProgressTasks.filter((x) => (showUserTasksOnly ? x.assignedTo.id === user.qawId : true))}
            toggleClaimed={toggleClaimed}
            user={user}
            suiteAction={suiteAction}
            setShowNotesModal={setShowNotesModal}
            setNotesModalData={setNotesModalData}
            setShowNewTaskModal={setShowNewTaskModal}
            setNewTaskModalData={setNewTaskModalData}
            setEditingNotes={setEditingNotes}
            showUserTasksOnly={showUserTasksOnly}
            showMessageTasks={showMessageTasks}
            setShowMessageTasks={setShowMessageTasks}
            setShowUserTasksOnly={setShowUserTasksOnly}
            showGenericTasks={showGenericTasks}
            setShowGenericTasks={setShowGenericTasks}
            showTriageTasks={showTriageTasks}
            setShowTriageTasks={setShowTriageTasks}
            hasUserClaimedMaxTasks={hasUserClaimedMaxTasks}
            showPromotionTasks={showPromotionTasks}
            setShowPromotionTasks={setShowPromotionTasks}
            primaryTeamOnly={primaryTeamOnly}
            setPrimaryTeamOnly={setPrimaryTeamOnly}
            setToastData={setToastData}
          />
        </div>
      </div>
      <EnvNotesModal
        refetch={refetch}
        showNotesModal={showNotesModal}
        setShowNotesModal={setShowNotesModal}
        notesModalData={notesModalData}
        setNotesModalData={setNotesModalData}
        editingNotes={editingNotes}
        setEditingNotes={setEditingNotes}
        setToastData={setToastData}
      />
      <NewTaskModal
        showNewTaskModal={showNewTaskModal}
        setShowNewTaskModal={setShowNewTaskModal}
        newTaskModalData={newTaskModalData}
        setNewTaskModalData={setNewTaskModalData}
        suiteAction={suiteAction}
        setToastData={setToastData}
      />
      <Toast {...toastData} show={toastData !== null} onDone={() => setToastData(null)} />
    </div>
  );
}
