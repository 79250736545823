import dayjs from 'dayjs';
import { sendTSPostRequest, sendTSPutRequest } from '../../../../utils/tanstackNetwork';

/**
 * Calculate the total number of tests completed for the given subMilestones.
 * @param {import('../types').MilestoneProgress[]} subMilestones - An array of subMilestones.
 * @returns {number} The total number of tests completed.
 */
export function getTotalTestsCompleted(subMilestones) {
  return subMilestones.map((subMilestone) => subMilestone.actualTests).reduce((acc, actualTests) => acc + actualTests, 0);
}

/**
 * Calculate the required test velocity to meet the milestone's target test count.
 * @param {number} targetTestCount - The target test count for the milestone.
 * @param {number} totalTestsCompleted - The current number of active tests.
 * @param {number} remainingWeeks - The number of remaining weeks.
 * @returns {number} The required test velocity.
 */
export function getRequiredVelocity(targetTestCount, totalTestsCompleted, remainingWeeks) {
  const remainingTests = targetTestCount - totalTestsCompleted;

  return +(remainingTests / remainingWeeks).toFixed(2);
}

/**
 * Calculate the current average test velocity based on past subMilestones.
 * @param {import('../types').Milestone} milestone - The milestone object.
 * @returns {number} The current average test velocity.
 */
export function getCurrentAvgVelocity(milestone) {
  // Filter for subMilestones that have already passed their due date
  const validSubMilestones = milestone.subMilestones.filter((subMilestone) => dayjs(subMilestone.dueDate).isBefore(dayjs()));
  const totalTestsCompleted = getTotalTestsCompleted(validSubMilestones);
  const numberOfWeeks = validSubMilestones.length;

  if (numberOfWeeks === 0) return 0;

  return +(totalTestsCompleted / numberOfWeeks).toFixed(2);
}

/**
 * Calculates the total number of weeks counting from the beginning of the start date week to the end of the end date week,
 * so short weeks are counted as 1 week.
 * @param {dayjs.Dayjs} startDate - The start date.
 * @param {dayjs.Dayjs} endDate - The end date.
 * @returns {number} The number of weeks between the two dates.
 */
export function getNumberOfWeeks(startDate, endDate) {
  return Math.ceil(endDate.endOf('week').diff(startDate.startOf('week'), 'week', true));
}

/**
 * Get the last incomplete milestone from an array of milestones.
 * @param {import('../types').Milestone[]} milestones - An array of milestones.
 * @returns {import('../types').Milestone|undefined} The last incomplete milestone or undefined if all milestones are complete.
 */
export function getLastIncompleteMilestone(milestones) {
  // Filter out completed milestones and keep only the incomplete ones
  const incompleteMilestones = milestones
    .filter((milestone) => !milestone.completedAt)
    // Sort the incomplete milestones by their externalDueDate in ascending order
    .sort((a, b) => dayjs(a.externalDueDate).diff(dayjs(b.externalDueDate)));

  // If there are no incomplete milestones, return undefined
  if (incompleteMilestones.length === 0) {
    return;
  }

  // Return the last incomplete milestone
  return incompleteMilestones.at(-1);
}

/**
 * Validate the milestone data and return an errors object if there are validation errors.
 * @param {object} data - The milestone data.
 * @param {string} data.name - The name of the milestone.
 * @param {string} data.notes - The notes for the milestone.
 * @param {string} data.targetTestCount - The target test count for the milestone.
 * @param {dayjs.Dayjs} data.startDate - The start date for the milestone.
 * @param {dayjs.Dayjs} data.externalDueDate - The external due date for the milestone.
 * @returns {object|null} An object containing validation errors or null if there are no errors.
 */
export function validateMilestone(data) {
  const { name, targetTestCount, startDate, externalDueDate } = data;

  const errors = {
    name: '',
    targetTestCount: '',
    startDate: '',
    externalDueDate: '',
  };

  // Validate the name field
  if (!name) {
    errors.name = 'Name is required';
  }

  // Validate the target test count field
  if (!targetTestCount) {
    errors.targetTestCount = 'Target Test Count is required';
  }

  // Validate the start date field
  if (!startDate) {
    errors.startDate = 'Start Date is required';
  }

  // Validate the external due date field
  if (!externalDueDate) {
    errors.externalDueDate = 'External Due Date is required';
  }

  // Check if the external due date is before the start date
  if (externalDueDate && dayjs(externalDueDate).isBefore(startDate)) {
    errors.externalDueDate = 'External Due Date must be after Start Date';
  }

  // Return the errors object if there are any errors, otherwise return null
  return errors.name || errors.notes || errors.targetTestCount || errors.startDate || errors.externalDueDate ? errors : null;
}

/**
 * Check if a milestone is current based on its external due date and start date.
 * @param {import('../types').Milestone} milestone - The milestone object.
 * @returns {boolean} Whether the milestone is current.
 */
export function getIsCurrent(milestone) {
  return dayjs(milestone.externalDueDate).isSameOrAfter(dayjs()) && dayjs(dayjs()).isSameOrAfter(milestone.startDate);
}

/**
 * Check if a starts in the future
 * @param {import('../types').Milestone} milestone - The milestone object.
 * @returns {boolean} Whether the milestone starts in the future.
 */
export function getIsFuture(milestone) {
  return dayjs(milestone.startDate).isAfter(dayjs(), 'day');
}

/**
 * Get the styles for a subMilestone based on its required velocity.
 * @param {boolean} hasMetGoal - Whether the milestone has met its goal.
 * @param {'present' | 'past' | 'future'} type - The type of subMilestone.
 * @returns {{rowBgColor: string, testsBgColor: string, weekFontWt: string, weekFontClr: string}} The styles for the subMilestone.
 */
export function getSubmilestoneStyles(hasMetGoal, type) {
  const colorMap = {
    present: {
      rowBgColor: 'white',
      testsBgColor: hasMetGoal ? '#2DAD55' : ' #D06461',
      weekFontWt: 'medium',
      weekFontClr: 'black',
    },
    past: {
      rowBgColor: 'unset',
      testsBgColor: hasMetGoal ? '#2DAD55' : ' #D06461',
      weekFontWt: 'unset',
      weekFontClr: 'DarkSlateGray',
    },
    future: {
      rowBgColor: 'unset',
      testsBgColor: '#808080',
      weekFontWt: 'unset',
      weekFontClr: 'gray',
    },
  };

  return colorMap[type];
}

/**
 * For overriding various MUI form styles.
 */
export const styles = {
  // Removes the blue outline on focus
  '& .MuiOutlinedInput-input ': { boxShadow: 'none' },

  // Removes the arrows from number inputs
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
};

/**
 * Send a request to create a milestone.
 * @param {object} reqBody - The request body for creating a milestone.
 * @returns {Promise<{ milestone: import('../types').Milestone }>} A promise that resolves when the request is complete.
 */
export async function milestoneCreateReq(reqBody) {
  const data = await sendTSPostRequest('/milestones/create', reqBody);
  return data;
}

/**
 * Send a request to create a milestone.
 * @param {string} milestoneId - The id of the milestone.
 * @param {object} reqBody - The request body for creating a milestone.
 * @returns {Promise<{ milestone: import('../types').Milestone }>} A promise that resolves when the request is complete.
 */
export async function milestoneUpdateReq(milestoneId, reqBody) {
  const data = await sendTSPutRequest(`/milestones/${milestoneId}/update-milestone`, reqBody);
  return data;
}

export const descriptionTypeMap = {
  created: 'Created milestone blocker',
  titleUpdated: 'Updated milestone blocker title',
  descriptionUpdated: 'Updated milestone blocker description',
  nextFollowUpDateUpdated: 'Updated milestone blocker next follow up date',
  resolved: 'Resolved milestone blocker',
};

/**
 * Get the description for a milestone blocker activity log.
 * @param {string} type - The type of activity log.
 * @param {string} to - The new value.
 * @param {string} [from] - The old value.
 * @param {import('../types').User[]} qawUsers - The QAW users.
 * @returns {{primary: string, secondary: string | { from: string, to: string }}} The description for the activity log.
 */
export function getDescription(type, to, from, qawUsers) {
  const oldAssignee = type === 'assigneeUpdated' ? qawUsers.find((user) => user.qawId === from) : null;
  const newAssignee = type === 'assigneeUpdated' ? qawUsers.find((user) => user.qawId === to) : null;

  switch (type) {
    case 'created':
      return { primary: `Created milestone blocker`, secondary: milestoneBlockerCategoryMap[to] ?? to };
    case 'titleUpdated':
      return { primary: `Changed title`, secondary: `${from}   →   ${to}` };
    case 'descriptionUpdated':
      return { primary: `Changed description`, secondary: { from, to } };
    case 'nextFollowUpDateUpdated':
      return { primary: `Changed next follow up date`, secondary: `${dayjs(from).format('MMM D, YYYY')}   →   ${dayjs(to).format('MMM D, YYYY')}` };
    case 'resolved':
      return { primary: `Marked milestone blocker as resolved`, secondary: '' };
    case 'assigneeUpdated':
      return {
        primary: from ? `Changed assignee` : `Assigned blocker`,
        secondary: from ? `${oldAssignee?.name}   →   ${newAssignee?.name}` : newAssignee?.name,
      };
    case 'milestoneBlockerCategoryUpdated':
      return {
        primary: `Changed milestone blocker category`,
        secondary: `${milestoneBlockerCategoryMap[from]}   →   ${milestoneBlockerCategoryMap[to]}`,
      };
    default:
      return '';
  }
}

export const milestoneBlockerCategoryMap = {
  budgetLimitReached: 'Test Limit Reached',
  noCreationTasksRemaining: 'No Runway',
  noUnblockedCreationTasksRemaining: 'No Unblocked Runway',
  other: 'Other',
};
