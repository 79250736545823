import { Checkbox } from '@mui/material';
import { type ReadyForReviewChecklistHookReturn } from './useReadyForReviewChecklist';
import { READY_FOR_REVIEW_QUESTIONS } from './ReadyForReviewQuestions';

type ReadyForReviewChecklistProps = Pick<
  ReadyForReviewChecklistHookReturn,
  | 'checkedQuestions'
  | 'checklistCompletionProgress'
  | 'handleQuestionChange'
  | 'isChecklistComplete'
  | 'submitTaskReadyForReview'
  | 'workflowDisplayData'
>;

export function ReadyForReviewChecklist({
  checkedQuestions,
  checklistCompletionProgress,
  handleQuestionChange,
  isChecklistComplete,
  submitTaskReadyForReview,
  workflowDisplayData,
}: ReadyForReviewChecklistProps) {
  return (
    <div className="relative h-full flex flex-col">
      <header className="sticky top-0 left-0 z-50 bg-white p-4 pb-0">
        <h1 className="text-2xl text-center">Ready for Review Checklist</h1>
        <div className="text-base font-bold">
          {/* TODO: Replace "\u00A0" with skeleton loaders; this is a temporary solution */}
          <span className="min-h-[1.5rem] block">{workflowDisplayData.workflowName ?? '\u00A0'}</span>
          <p className="text-xs italic">{workflowDisplayData.workflowParentIssueName ?? '\u00A0'}</p>
          <p className="text-xs italic">
            {workflowDisplayData.workflowTeamName && workflowDisplayData.workflowEnvName
              ? `On ${workflowDisplayData.workflowTeamName}'s ${workflowDisplayData.workflowEnvName} Environment`
              : '\u00A0'}
          </p>
        </div>
        <p className="my-2 text-sm italic flex justify-center">Please review the checklist and ensure that all questions are completed</p>
        {/* Progress bar */}
        <div className="mx-[10%] my-2 h-2.5 rounded-full bg-gray-200">
          <div
            className="h-full rounded-full bg-blue-600 transition-width duration-300 ease-in-out"
            style={{ width: `${checklistCompletionProgress}%` }}
          />
        </div>
      </header>
      {/* Checklist */}
      <section className="flex-1 overflow-y-auto px-4">
        {READY_FOR_REVIEW_QUESTIONS.map((question) => (
          <div key={question.question} className="mb-5">
            <div className="flex items-start gap-2">
              <Checkbox checked={!!checkedQuestions[question.question]} onChange={() => handleQuestionChange(question.question)} />
              <div>
                <label className="block text-xl font-bold">{question.question}</label>
                <p className="mt-2 text-gray-600">{question.descriptionText}</p>
              </div>
            </div>
            <hr className="my-2" />
          </div>
        ))}
      </section>
      <footer className="sticky bottom-0 left-0 right-0 z-50 bg-white p-3">
        <button
          className={`
            w-full mt-0 p-1 rounded-sm text-white
            ${isChecklistComplete ? 'bg-blue-600 hover:bg-blue-700' : 'bg-gray-500 cursor-not-allowed'}
          `}
          disabled={!isChecklistComplete}
          onClick={submitTaskReadyForReview}
        >
          Submit As Ready for Review
        </button>
      </footer>
    </div>
  );
}
