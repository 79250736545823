import React, { createContext, useContext, useEffect, useState } from 'react';
import { Customer, Conversation, Message } from '../data/types';
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { sendGetRequest, sendPostRequest } from '@/utils/network';
import { useSocket } from '../../context/SocketContext';
import QAWTask from '@/types';
import { format, subDays } from 'date-fns';
import {
  CommunicationsContextType,
  MutationContext,
  ToastData,
  TQueryFnData,
  SlackSocketData,
  MessageTask,
  TaskMutationResponse,
  CustomerAskMutationResponse,
} from './CommunicationsContextTypes';
import { StoredQaTeam } from '../../context/QaTeamContext';
import { useQaTeam } from '../../context/QaTeamContext';

const CommunicationsContext = createContext<CommunicationsContextType | undefined>(undefined);

export function CommunicationsProvider({ children }: { children: React.ReactNode }) {
  // =====================
  // === Initialize ===
  // =====================
  const { socket } = useSocket();
  const queryClient = useQueryClient();

  // =====================
  // === Context Hooks ===
  // =====================
  // Get qaTeam context
  const { selectedQaTeamIds, setSelectedQaTeamIds, qaTeams } = useQaTeam();

  // =====================
  // === Local Storage ===
  // =====================
  const currentUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string) : null;
  const secureValue = currentUser?.secure;

  // =====================
  // === React States ===
  // =====================
  const [selectedConversationId, setSelectedConversationId] = useState<number | null | undefined>(null);
  const [toast, setToast] = useState<ToastData | null>(null);

  // Loading states
  const [conversationsLoading, setConversationsLoading] = useState(false);
  const [isTabLoading, setIsTabLoading] = useState(false);

  // Customer ask loading state
  const [customerAskLoading, setCustomerAskLoading] = useState<{ [key: string]: boolean }>({});

  // Currently viewed customer
  const [currentCustomerQawId, setCurrentCustomerQawId] = useState<string | null>(null);

  // =====================
  // === Query Hooks ===
  // =====================
  const customers = useQuery({
    queryKey: ['customers', selectedQaTeamIds],
    queryFn: () => sendGetRequest('/client-teams/get-clients-for-message-hq'),
    enabled: !!currentUser, // Only fetch data when logged in
  });

  // Set up refetch interval for messages
  const messages = useQuery({
    queryKey: ['get-messages-by-qa-team', selectedQaTeamIds],
    queryFn: () =>
      sendPostRequest('/client-messages/get-messages-by-qa-team', {
        qaTeamIds: selectedQaTeamIds,
      }),
    enabled: !!currentUser,
    refetchInterval: 1000 * 60 * 3, // 3 minutes
    staleTime: 1000 * 60 * 3, // 3 minutes
  });

  // Recent Bugs
  const bugs = useQuery({
    queryKey: ['get-recent-bugs', currentCustomerQawId],
    queryFn: () => {
      const startDate = format(subDays(new Date(), 7), 'yyyy-MM-dd');
      const endDate = format(new Date(), 'yyyy-MM-dd');
      const url = `/issues/within-date-range?customerId=${currentCustomerQawId}&startDate=${startDate}&endDate=${endDate}`;
      return sendGetRequest(url);
    },
    enabled: !!currentUser && !!currentCustomerQawId,
  });

  // Recent Suites
  const recentSuites = useQuery({
    queryKey: ['get-recent-suites', currentCustomerQawId],
    queryFn: () => {
      const startDate = format(subDays(new Date(), 7), 'yyyy-MM-dd');
      const endDate = format(new Date(), 'yyyy-MM-dd');
      const url = `/suites/within-date-range?customerId=${currentCustomerQawId}&startDate=${startDate}&endDate=${endDate}`;
      return sendGetRequest(url);
    },
    enabled: !!currentUser && !!currentCustomerQawId,
  });

  // Customer asks
  const customerAsks = useQuery({
    queryKey: ['get-customer-asks', currentCustomerQawId],
    queryFn: () => {
      const startDate = subDays(new Date(), 7);
      const endDate = new Date();
      const url = `/customer-asks/within-date-range?customerId=${currentCustomerQawId}&startDate=${startDate}&endDate=${endDate}`;
      return sendGetRequest(url);
    },
    enabled: !!currentUser && !!currentCustomerQawId,
  });

  // =====================
  // === Mutations =======
  // =====================
  const [sendingInProgress, setSendingInProgress] = useState(false);
  const { mutate: sendSlackMessageMutation } = useMutation<{ message: Message; status: number }, Error, Message, MutationContext>({
    mutationFn: async (message: Message) => {
      if (!sendingInProgress) {
        setSendingInProgress(true);
        const response = await sendPostRequest('/client-messages/send-slack-message', message);
        return { message, status: response.status };
      }
      return Promise.reject(new Error('Message sending in progress'));
    },
    onMutate: async (outboundMessage) => {
      if (!sendingInProgress) {
        // Snapshot the previous value
        const previousMessages = queryClient.getQueryData(['get-messages-by-qa-team', selectedQaTeamIds]);

        if (secureValue && outboundMessage.messageContent !== '') {
          // Optimistically update the cache if there is a secure value in local storage
          queryClient.setQueryData(['get-messages-by-qa-team', selectedQaTeamIds], (oldData: TQueryFnData | undefined) => {
            if (oldData) {
              const newData = {
                ...oldData,
                data: [outboundMessage, ...(oldData?.data || [])],
              };
              return newData;
            } else {
              messages.refetch();
            }
          });
          return { previousMessages } as MutationContext;
        }
      } else {
        setToast({
          title: 'Slow down there buckaroo!',
          message: 'You are sending messages too quickly. Please refresh the page to ensure records are accurate.',
          show: true,
          isSuccess: false,
          onDone: () => {
            setToast(null);
          },
          content: <></>,
        });
      }
    },
    onSettled: () => {
      setSendingInProgress(false);
    },
  });

  type TaskMutationVariables = {
    endpoint: string;
    task: QAWTask;
  };

  type CustomerAskMutationVariables = {
    endpoint: string;
    askId: number;
    userId: number;
  };

  const [isPending, setIsPending] = useState<{ [key: string]: { [key: string]: boolean } }>({});

  const { mutate: taskMutation } = useMutation<TaskMutationResponse, Error, TaskMutationVariables>({
    mutationFn: async ({ endpoint, task }) => {
      setIsPending((prev) => ({ ...prev, [String(task.id)]: { [endpoint]: true } }));
      const { data } = await sendPostRequest(endpoint, { task, taskId: task?.id });
      return {
        success: true,
        data,
        error: null,
        jsonData: data,
      };
    },
    onSettled: async (response, error, { endpoint }) => {
      try {
        await messages.refetch();
        if (error) {
          console.error(error.message);
        }
      } finally {
        if (response?.data?.updatedTasks) {
          const updatedTasks = response.data.updatedTasks;
          setIsPending((prev) => {
            const newState = { ...prev };
            updatedTasks.forEach((task: MessageTask) => {
              newState[task.id] = { [endpoint]: false };
            });
            return newState;
          });
          if (endpoint === '/done') {
            setSelectedConversationId(null);
          }
        }
      }
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const allowedActions = ['/done', '/claim', '/unclaim'];

  function suiteAction(task: QAWTask, action: string) {
    if (!allowedActions.includes(action)) {
      throw new Error(`Action ${action} is not allowed. Allowed actions: ${allowedActions.join(',')}`);
    }
    taskMutation({ endpoint: action, task });
  }

  // Customer ask Mutation
  const { mutate: customerAskMutation } = useMutation<CustomerAskMutationResponse, Error, CustomerAskMutationVariables>({
    mutationFn: async ({ endpoint, askId, userId }) => {
      setCustomerAskLoading((prev) => ({ ...prev, [`${endpoint}-${askId}`]: true }));
      const { data } = await sendPostRequest(`/customer-asks/${endpoint}`, { askId, userId });
      return {
        success: true,
        data,
        error: null,
        jsonData: data,
      };
    },

    // Refetch messages to update customer ask status
    onSuccess: async (response, { endpoint, askId }) => {
      await messages.refetch();
      setCustomerAskLoading((prev) => ({ ...prev, [`${endpoint}-${askId}`]: false }));
    },
  });

  const allowedCustomerAskActions = ['claim', 'unclaim', 'resolve', 'false-positive'];

  function customerAskAction(action: string, askId: number, userId: number) {
    if (!allowedCustomerAskActions.includes(action)) {
      throw new Error(`Action ${action} is not allowed. Allowed actions: ${allowedCustomerAskActions.join(',')}`);
    }
    customerAskMutation({ endpoint: action, askId, userId });
  }

  // ==========================================
  // === Query Data Manipulation/Retrieval ===
  // ==========================================

  // Structure conversations from messages
  const structureConversations = (messages: Message[]) => {
    const allConversations = messages.reduce((acc, message) => {
      const { platform, customerId, channelName, thread } = message;

      const getConversationId = () => {
        return platform === 'slack' ? Number(thread) : message.channelId;
      };
      const conversationId = getConversationId();
      const conversation = acc.find((conv) => conv.id === conversationId);

      if (conversation) {
        conversation.messages.push(message);
      } else {
        acc.push({
          id: conversationId,
          messages: [message],
          assignedTo: null,
          customerId,
          customerQawId: message.customerQawId,
          channelName,
          isInternal: false,
          completed: false,
          title: '',
          description: '',
          threads: [{ id: thread, color: '', completed: false }],
          summary: '',
          claimedBy: '',
          claimed: false,
          participants: [],
          internalResources: [],
          internalNotes: [],
          platform,
          primaryQaTeam: message.primaryQaTeam ?? null,
          onlineQaTeam: message.onlineQaTeam ?? null,
        });
      }
      return acc;
    }, [] as Conversation[]);

    // Determine whether converstions are completed based on message attached message task status
    for (const conv of allConversations) {
      const allMessages = conv.messages;

      // This handles conversations before the message task migration. Each message should have a message task.
      if (allMessages.length && allMessages.every((msg) => !msg.messageTasks?.length && msg.sender === 'external')) {
        conv.completed = true;
        continue;
      }

      const allMessageTasksDoneOrIgnored = allMessages.every((msg) =>
        msg.messageTasks?.every((task) => task.status === 'done' || task.status === 'ignore'),
      );

      // We only want to mark a conv claimed if it's not completed and the latest associated message task is assigned to a user
      // Determine claimed status
      const mostRecentMessageExternal = allMessages.find((msg: Message) => msg.sender === 'external' || msg.sender === 'unknown');
      const mostRecentMessageExternalTask = mostRecentMessageExternal?.messageTasks?.find((task: MessageTask) => task.type === 'message');
      if (mostRecentMessageExternalTask?.assignedTo) {
        conv.claimedBy = `${mostRecentMessageExternalTask.assignedTo?.name}`;
        const assignedTo = mostRecentMessageExternalTask.assignedTo;
        conv.assignedTo = {
          id: String(assignedTo.id),
          name: assignedTo.name,
          email: assignedTo.email,
          qawId: assignedTo.qawId || undefined,
          avatar: assignedTo.avatar48 || '',
          onlineStatus: 'red',
          slackId: assignedTo.slackId || undefined,
          msTeamsId: assignedTo.msTeamsId || undefined,
          discordId: assignedTo.discordId || undefined,
          secure: assignedTo.secure || undefined,
          is_qawolf: assignedTo.is_qawolf || false,
          isQAE: assignedTo.isQAE || false,
        };
        conv.claimed = true;
      } else if (!mostRecentMessageExternalTask?.assignedTo?.id) {
        conv.claimedBy = '';
        conv.assignedTo = null;
        conv.claimed = false;
      }

      // If message tasks are done or ignored, the conversation is completed
      if (allMessageTasksDoneOrIgnored) {
        conv.completed = true;
      } else {
        // If there are message tasks with status inProgress or toDo, the conversation is not completed
        conv.completed = false;
      }
    }

    // Filter conversations by online team
    const filteredConversationsByOnlineTeam = allConversations.filter((conv) => {
      if (conv.onlineQaTeam?.id && selectedQaTeamIds.includes(conv.onlineQaTeam?.id)) {
        return true;
      }
      return false;
    });

    return filteredConversationsByOnlineTeam;
  };

  const conversations = structureConversations(messages.data?.data || []);
  const filteredCustomers = customers.data?.data?.filter((customer: Customer) => selectedQaTeamIds.includes(customer?.onlineTeam?.id));
  const filteredQaTeams = qaTeams.filter((qaTeam: StoredQaTeam) => qaTeam?.active || qaTeam?.id === 11);

  const getConversationsForCustomer = (customerIds: number[]) => {
    return conversations.filter((conv) => customerIds.includes(conv.customerId));
  };

  const getConversation = (conversationId: number) => {
    return conversations.find((conv) => conv.id === conversationId);
  };

  const getCustomerById = (customerId: number) => {
    const foundCustomer = customers.data?.data?.find((customer: Customer) => customer.id === customerId);
    return foundCustomer;
  };

  const getQaTeamById = (qaTeamId: number) => {
    const foundQaTeam = qaTeams.find((qaTeam: StoredQaTeam) => qaTeam.id === qaTeamId);
    return foundQaTeam;
  };

  const getCustomerAsksForCustomer = (customerId: number) => {
    const customerConversations = conversations.filter((conv) => conv.customerId === customerId);
    const customerAsks = customerConversations.flatMap((conv) => conv.messages.flatMap((message) => message.customerAsks ?? []));
    return customerAsks || [];
  };

  const getConversationForAssociatedCustomerAsk = (customerAskId: number) => {
    const conversationsWithCustomerAsks = conversations.filter((conv) => {
      const conversationWithCustomerAsk = conv.messages.flatMap((message) => message.customerAsks).find((ask) => ask?.id === customerAskId);
      return conversationWithCustomerAsk;
    });
    return conversationsWithCustomerAsks[0];
  };

  // Bugs for last 7 days by customer
  const recentBugsForCustomer = bugs.data?.data;

  // Recent Suites for last 7 days by customer
  const recentSuitesForCustomer = recentSuites.data?.data;

  // Recent Customer Asks for last 7 days by customer
  const recentCustomerAsksForCustomer = customerAsks.data?.data;

  // =====================
  // === Socket Handler ===
  // =====================
  const handleSlackMessage = async (socketData: SlackSocketData) => {
    // Only updates messages of selected QA team customers
    if (selectedQaTeamIds.includes(socketData.qaTeamId)) {
      // First, check if we already have the data
      const existingData: TQueryFnData | undefined = queryClient.getQueryData(['get-messages-by-qa-team', selectedQaTeamIds]);

      if (existingData) {
        const latestMessageInExistingData = structureConversations(existingData?.data || [])[0]?.messages[0];

        // Determine if the message is an outbound duplicate based on last message in the current conversation to avoid duplicate message from optimistic update
        const determineIfMessageIsOutboundDuplicate = () => {
          if (latestMessageInExistingData) {
            return (
              latestMessageInExistingData.channelId === Number(socketData?.channelId) &&
              latestMessageInExistingData.user === socketData?.user &&
              latestMessageInExistingData.messageContent === socketData?.messageContent &&
              latestMessageInExistingData.platform === socketData?.platform &&
              latestMessageInExistingData.sender === socketData?.sender
            );
          }
          // Return false if no existing messages
          return false;
        };

        // If the message is not the outbound message, update the query data and we didn't refetch messages. This allows optimistic sending of messages
        if (!determineIfMessageIsOutboundDuplicate()) {
          queryClient.setQueryData(['get-messages-by-qa-team', selectedQaTeamIds], (oldData: TQueryFnData | undefined) => {
            const newMessage: Message = {
              id: Number(socketData.id),
              messageContent: socketData.messageContent,
              user: socketData.user,
              timestamp: socketData.timestamp,
              thread: socketData.thread,
              platform: socketData.platform,
              channelId: Number(socketData.channelId),
              channelName: socketData.channelName,
              sender: socketData.sender,
              customerId: socketData.customerId,
              customerQawId: socketData.customerQawId,
              platformChannelId: socketData.platformChannelId,
              avatar: socketData.avatar,
              messageTasks: socketData.messageTasks,
              channel: socketData?.channel,
              primaryQaTeam: socketData.primaryQaTeam ?? null,
              onlineQaTeam: socketData.onlineQaTeam ?? null,
            };

            //  Handle incoming messages sent by current user
            const newData = {
              ...oldData,
              data: [newMessage, ...(oldData?.data || [])],
            };
            return newData;
          });
        }
      } else {
        await messages.refetch();
      }
    }
  };

  // =====================
  // === Use Effect Hooks ===
  // =====================

  // Handle Slack messages
  useEffect(() => {
    if (socket && currentUser && selectedQaTeamIds.length) {
      socket.on('slack-message', handleSlackMessage);
      return () => {
        socket.off('slack-message');
      };
    }
  }, [socket, currentUser, selectedQaTeamIds]);

  // Set current customer QAW ID based on selected conversation
  useEffect(() => {
    if (selectedConversationId) {
      const conversation = conversations.find((conv) => conv.id === selectedConversationId);
      setCurrentCustomerQawId(conversation?.customerQawId || null);
    }
  }, [selectedConversationId]);

  if (customers.isError || messages.isError) return <span>Error: {customers.error?.message || messages.error?.message}</span>;

  return (
    <CommunicationsContext.Provider
      value={{
        customers: filteredCustomers || [],
        conversations,
        qaTeams: filteredQaTeams || [],
        selectedQaTeamIds,
        setSelectedQaTeamIds,
        isLoading: {
          messages: messages.isLoading,
          customers: customers.isLoading,
          bugs: bugs.isLoading,
          suites: recentSuites.isLoading,
          customerAsks: customerAsks.isLoading,
        },
        getConversationsForCustomer,
        getConversation,
        getCustomerById,
        getQaTeamById,
        selectedConversationId: selectedConversationId || null,
        setSelectedConversationId,
        sendSlackMessageMutation,
        toast,
        setToast,
        suiteAction,
        isPending,
        conversationsLoading,
        setConversationsLoading,
        isTabLoading,
        setIsTabLoading,
        customerAskAction,
        customerAskLoading,
        getCustomerAsksForCustomer,
        getConversationForAssociatedCustomerAsk,
        recentBugsForCustomer,
        recentSuitesForCustomer,
        recentCustomerAsksForCustomer,
      }}
    >
      {children}
    </CommunicationsContext.Provider>
  );
}

export function useCommunications() {
  const context = useContext(CommunicationsContext);
  if (context === undefined) {
    throw new Error('useCommunications must be used within a CommunicationsProvider');
  }
  return context;
}
