import { getScaledBarWidth, getSubMilestoneMetrics, getSubmilestoneStrings, getSubmilestoneColors } from '../utils';
import { Box, Divider, Tooltip, Typography } from '@mui/material';
import { CreationProgressToolTipContentProps, CreationProgressBarProps, SubMilestoneTooltipContentProps } from '../types/types';
import { milestoneBlockerCategoryMap } from '../../ClientMilestones/utils';
import { getCreationWorkToBeDone } from '../utils/getCreationWorkToBeDone';

const CREATION_PROGRESS_BAR_WIDTH_OPTIONS = {
  minValue: 20,
  maxValue: 120,
};

function BlockedOrFullyImplementedBar({ text, bgcolor }: { text: string; bgcolor: 'yellow.main' | 'green.main' | 'red.main' }) {
  return (
    <Box sx={{ bgcolor }} className="w-full h-7 flex items-center justify-center text-white text-xs font-bold">
      {text}
    </Box>
  );
}

function SubMilestoneTooltipContent(props: SubMilestoneTooltipContentProps) {
  return (
    <Box className="flex flex-col justify-center box-border w-72">
      <Box className="w-full flex pt-1 px-2.5 justify-between">
        <Typography fontWeight="bold" variant="caption">
          {props.weekString}
        </Typography>
        <Typography variant="caption">{props.dateString}</Typography>
      </Box>
      <Divider variant="fullWidth" flexItem />
      <Box className="flex box-border">
        <Box className="w-full flex flex-col items-center py-0.5">
          <Typography fontWeight="bold" color={props.velocityColor} variant="caption" lineHeight={1.2}>
            Created vs Required
          </Typography>
          <Typography variant="caption" lineHeight={1.2}>
            {props.velocityString}
          </Typography>
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Box className="w-full flex flex-col items-center py-0.5">
          <Typography fontWeight="bold" color={props.targetTestsColor} variant="caption" lineHeight={1.2}>
            Active vs Expected
          </Typography>
          <Typography variant="caption" lineHeight={1.2}>
            {props.targetString}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

function BottomToolTipContent({ completedThisWeek, scheduledThisWeek, draftTests, workToBeDone }: CreationProgressToolTipContentProps) {
  return (
    <Box className="flex box-border rounded-sm justify-between gap-1">
      {/* Tests Activated This Week */}
      <Box className="flex flex-col w-full justify-center items-center py-1 px-2">
        <Typography variant="caption" color="green.dark" fontWeight="bold" lineHeight={1.2}>
          Completed
        </Typography>
        <Typography variant="caption" lineHeight={1.2}>
          {completedThisWeek}
        </Typography>
      </Box>

      <Divider orientation="vertical" variant="middle" flexItem />

      {/* Tests Scheduled for Creation This Week */}
      <Box className="flex flex-col w-full justify-center items-center py-1 px-2">
        <Typography variant="caption" color="green.main" fontWeight="bold" lineHeight={1.2}>
          Scheduled
        </Typography>
        <Typography variant="caption" lineHeight={1.2}>
          {scheduledThisWeek}
        </Typography>
      </Box>

      <Divider orientation="vertical" variant="middle" flexItem />

      {/* Available Draft Tests */}
      <Box className="flex flex-col w-full justify-center items-center py-1 px-2">
        <Typography noWrap variant="caption" color="green.light" fontWeight="bold" lineHeight={1.2}>
          Draft
        </Typography>
        <Typography variant="caption" lineHeight={1.2}>
          {draftTests}
        </Typography>
      </Box>

      <Divider orientation="vertical" variant="middle" flexItem />

      {/* Total Tests Remaining In Test Budget */}
      <Box className="flex flex-col w-full justify-center items-center py-1 px-2">
        <Typography variant="caption" color="gray.main" fontWeight="bold" lineHeight={1.2}>
          Tests To Create
        </Typography>
        <Typography variant="caption" lineHeight={1.2}>
          {workToBeDone}
        </Typography>
      </Box>
    </Box>
  );
}

function SubMilestoneIndicators({ activeMilestone }: { activeMilestone: CreationProgressBarProps['activeMilestone'] }) {
  return (
    <Box className="w-full flex" gap={0.4}>
      {activeMilestone.subMilestones?.map((data, index) => {
        // Get the metrics for the submilestone
        const { testsAdded, isCurrentWeek, isFutureWeek, requiredVelocity } = getSubMilestoneMetrics(activeMilestone, index);

        // Get the strings and colors for the submilestone
        const { velocityString, targetString, weekString, dateString } = getSubmilestoneStrings(
          data,
          data.actualTests,
          data.targetTests,
          testsAdded,
          requiredVelocity,
          isFutureWeek,
        );
        const { velocityColor, targetTestsColor, overallColor } = getSubmilestoneColors(
          isFutureWeek,
          false,
          requiredVelocity - testsAdded,
          data.targetTests - data.actualTests,
        );

        return (
          <Box key={`creation-${index}`} className="flex-1 flex items-center justify-center">
            <Tooltip
              placement="top"
              title={
                <SubMilestoneTooltipContent
                  weekString={weekString}
                  dateString={dateString}
                  velocityString={velocityString}
                  targetString={targetString}
                  velocityColor={velocityColor}
                  targetTestsColor={targetTestsColor}
                />
              }
              arrow
              slotProps={{ tooltip: { sx: { p: 0 } } }}
            >
              <Box sx={{ bgcolor: overallColor }} className="h-2 w-full flex items-center justify-center">
                {isCurrentWeek && <Box className="w-1 h-1 bg-white rounded-full" />}
              </Box>
            </Tooltip>
          </Box>
        );
      })}
    </Box>
  );
}

function BottomProgressBar({ creation }: CreationProgressBarProps) {
  // Get the remaining tests needed to reach the test budget limit
  const workToBeDone = getCreationWorkToBeDone(creation.activeTests, creation.totalBudget);
  const barWidthPercent = getScaledBarWidth(workToBeDone, CREATION_PROGRESS_BAR_WIDTH_OPTIONS);

  // Get the width percentages for the draft, scheduled, and completed tests
  const draftWidthPercent = Math.min(Math.round((creation.draftTests / workToBeDone) * 100), 100);
  const scheduledWidthPercent = Math.min(Math.round((creation.scheduledThisWeek / workToBeDone) * 100), 100);
  const completedWidthPercent = Math.min(Math.round((creation.completedThisWeek / workToBeDone) * 100), 100);

  return (
    <Box className="font-bold h-full" style={{ width: `${barWidthPercent}%` }}>
      <Tooltip
        arrow
        slotProps={{ tooltip: { sx: { p: 0, maxWidth: 'unset' } } }}
        placement="bottom"
        title={
          <BottomToolTipContent
            completedThisWeek={creation.completedThisWeek}
            scheduledThisWeek={creation.scheduledThisWeek}
            draftTests={creation.draftTests}
            workToBeDone={workToBeDone}
          />
        }
      >
        {/* GRAY BAR: Represents the total amount of tests that need to be created to reach the test budget limit */}
        <Box sx={{ bgcolor: 'gray.main' }} className="w-full h-full overflow-hidden relative">
          {/* LIGHT GREEN BAR: Represents the percentage of draft tests available */}
          <Box sx={{ bgcolor: 'green.light' }} className="absolute left-0 bottom-0 h-full" style={{ width: `${draftWidthPercent}%` }} />
          {/* MEDIUM GREEN BAR: Represents the percentage of tests that have been scheduled for this week */}
          <Box sx={{ bgcolor: 'green.main' }} className="absolute left-0 bottom-0 h-full" style={{ width: `${scheduledWidthPercent}%` }} />
          {/* DARK GREEN BAR: Represents the percentage of tests that have been completed for this week */}
          <Box sx={{ bgcolor: 'green.dark' }} className="absolute left-0 bottom-0 h-full" style={{ width: `${completedWidthPercent}%` }} />

          {/* BLACK DIVIDER: Indicates the end of the scheduled bar */}
          <Box
            className="absolute left-0 bottom-0 h-full box-border"
            style={{
              width: `${scheduledWidthPercent || 1}%`,
              borderRight: '2px solid black',
            }}
          />
        </Box>
      </Tooltip>
    </Box>
  );
}

export function CreationProgressBar({ creation, activeMilestone, milestoneBlocker }: CreationProgressBarProps) {
  // Return a default component if the milestone is blocked or fully implemented
  const isFullyImplemented = creation.activeTests >= creation.totalBudget;
  if (milestoneBlocker || isFullyImplemented) {
    // Get the category of the milestone blocker if it exists
    const category = milestoneBlocker?.milestoneBlockerCategory;

    // Get the text and color based on the milestone blocker category or if the milestone is fully implemented
    const text = category ? milestoneBlockerCategoryMap[category] : milestoneBlocker ? 'Blocked' : 'Fully implemented';
    const bgcolor = isFullyImplemented ? 'green.main' : 'red.main';

    return <BlockedOrFullyImplementedBar text={text} bgcolor={bgcolor} />;
  }

  return (
    <Box className="flex flex-col gap-1 h-7">
      {activeMilestone.id && <SubMilestoneIndicators activeMilestone={activeMilestone} />}
      <BottomProgressBar creation={creation} activeMilestone={activeMilestone} />
    </Box>
  );
}
