import React, { useState, useCallback } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import CandidateDetailPanel from '../HiringCandidatePanel/CandidateDetailPanel';
import FinalRoundDetailPanel from '../FinalRoundView/FinalRoundDetailPanel';
import { MRT_Row } from 'material-react-table';
import { Submission, Candidate } from '../Types/types';

interface RenderFinalRoundDetailPanelData {
    row: MRT_Row<Submission>
    currentSubmissions: Submission[],
    allCandidates: Candidate[],
    allSubmissions: Submission[],
}

const RenderFinalRoundDetailPanels: React.FC<RenderFinalRoundDetailPanelData> = ({ row, currentSubmissions, allCandidates, allSubmissions }) => {
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = useCallback((_: React.SyntheticEvent<Element, Event>, newValue: number) => {
        setTabValue(newValue);
    }, []);

    return (
        <Box sx={{ width: '100%' }}>
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="detail panel tabs"
                variant="fullWidth"
            >
                <Tab label="Final Round Info" />
                <Tab label="Editor" />
                <Tab label="Extra Info" />
            </Tabs>
            {tabValue === 0 && (
                <Box component={'div'}>
                    <FinalRoundDetailPanel row={row} />
                </Box>
            )}
            {tabValue === 1 && (
                <Box component={"div"}>
                    <CandidateDetailPanel
                        row={row}
                        allSubmissions={allSubmissions}
                        currentSubmissions={currentSubmissions}
                        allCandidates={allCandidates}
                        idx={0}
                        showTab={false}
                        height={"100%"}
                    />
                </Box>
            )}
            {tabValue === 2 && (
                <Box component={"div"}>
                    <CandidateDetailPanel
                        row={row}
                        allSubmissions={allSubmissions}
                        currentSubmissions={currentSubmissions}
                        allCandidates={allCandidates}
                        idx={1}
                        showTab={false}
                        height={"100%"}
                    />
                </Box>
            )}
        </Box>
    );
};

export default RenderFinalRoundDetailPanels;
