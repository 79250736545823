import { Box, Divider, Tooltip, Typography } from '@mui/material';
import { BudgetProgressBar } from './BudgetProgressBar';
import type { CreationProgressBarProps, SubMilestoneTooltipContentProps } from '../types/types';
import { getSubmilestoneColors, getSubMilestoneMetrics, getSubmilestoneStrings } from '../utils';
import dayjs from 'dayjs';

interface OutlinesProgressBarProps {
  totalBudget: number;
  activeTests: number;
  draftTests: number;
  activeMilestone: CreationProgressBarProps['activeMilestone'];
}

function SubMilestoneTooltipContent(props: SubMilestoneTooltipContentProps) {
  return (
    <Box className="flex flex-col justify-center box-border">
      <Box className="w-full flex flex-col pt-1 px-2.5">
        <Box className="flex w-full justify-between">
          <Typography fontWeight="bold" variant="caption">
            {props.weekString}
          </Typography>
          <Typography variant="caption">{props.dateString}</Typography>
        </Box>
        {props.isProjectedFullyOutlinedWeek && props.overallOutliningVelocity && (
          <Typography variant="caption">
            Projected to be fully outlined this week at the overall velocity of {Math.floor(props.overallOutliningVelocity)} tests per week
          </Typography>
        )}
      </Box>
      <Divider variant="fullWidth" flexItem />
      <Box className="flex box-border">
        <Box className="w-full flex flex-col items-center py-0.5 px-2">
          <Typography fontWeight="bold" color={props.velocityColor} variant="caption" lineHeight={1.2}>
            Newly Outlined vs Required
          </Typography>
          <Typography variant="caption" lineHeight={1.2}>
            {props.velocityString}
          </Typography>
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Box className="w-full flex flex-col items-center py-0.5 px-2">
          <Typography fontWeight="bold" color={props.targetTestsColor} variant="caption" lineHeight={1.2}>
            Total Outlined vs Expected
          </Typography>
          <Typography variant="caption" lineHeight={1.2}>
            {props.targetString}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

function OutlineProgressIndicators({ activeMilestone }: { activeMilestone: CreationProgressBarProps['activeMilestone'] }) {
  // Find the week number where the milestone is projected to be fully outlined
  const currentWeek = activeMilestone.subMilestones?.find((sm) => dayjs(sm.dueDate).isSame(dayjs(), 'week'));
  const currentOutlinedTests = (currentWeek?.actualTests ?? 0) + (currentWeek?.draftTests ?? 0);
  const initialOutlinedTests = (activeMilestone.initialTestCount ?? 0) + (activeMilestone.initialDraftCount ?? 0);
  const overallOutlinedTestsAdded = currentOutlinedTests - initialOutlinedTests;
  const overallOutliningVelocity = overallOutlinedTestsAdded / (currentWeek?.weekNumber ?? 1);

  const projectedFullyOutlinedWeekNumber = Math.ceil(activeMilestone.targetTestCount / overallOutliningVelocity);

  return (
    <Box className="w-full flex" gap={0.4}>
      {activeMilestone.subMilestones?.map((data, index) => {
        // Get the outline progress metrics for the submilestone
        const { isCurrentWeek, isFutureWeek, requiredOutlineVelocity, outlinedTestsAdded, actualOutlinedTests, isFirstFullOutlineTargetWeek } =
          getSubMilestoneMetrics(activeMilestone, index);

        // Check if the submilestone is projected to be fully outlined based on the overall outlining velocity
        const isProjectedFullyOutlinedWeek = projectedFullyOutlinedWeekNumber === data.weekNumber && isFutureWeek;

        // Get the strings and colors for the submilestone outline progress
        const { velocityString, targetString, weekString, dateString } = getSubmilestoneStrings(
          data,
          actualOutlinedTests,
          data.targetOutlinedTests,
          outlinedTestsAdded,
          requiredOutlineVelocity,
          isFutureWeek,
        );
        const { velocityColor, targetTestsColor, overallColor } = getSubmilestoneColors(
          isFutureWeek,
          isProjectedFullyOutlinedWeek,
          requiredOutlineVelocity - outlinedTestsAdded,
          data.targetOutlinedTests - actualOutlinedTests,
        );

        const borderColor = isFirstFullOutlineTargetWeek ? overallColor.split('.')[0] + '.dark' : overallColor;
        const borderWidth = isFirstFullOutlineTargetWeek ? 2 : 0;

        return (
          <Box key={`creation-${index}`} className="flex-1 flex items-center justify-center">
            <Tooltip
              placement="top"
              title={
                <SubMilestoneTooltipContent
                  velocityString={velocityString}
                  targetString={targetString}
                  weekString={weekString}
                  dateString={dateString}
                  velocityColor={velocityColor}
                  targetTestsColor={targetTestsColor}
                  overallOutliningVelocity={overallOutliningVelocity}
                  isProjectedFullyOutlinedWeek={isProjectedFullyOutlinedWeek}
                />
              }
              arrow
              slotProps={{ tooltip: { sx: { p: 0, maxWidth: 'unset' } } }}
            >
              <Box sx={{ bgcolor: overallColor, borderWidth, borderColor }} className="h-2 w-full flex items-center justify-center">
                {isCurrentWeek && <Box className="w-1 h-1 bg-white rounded-full" />}
              </Box>
            </Tooltip>
          </Box>
        );
      })}
    </Box>
  );
}

export function OutlinesProgressBar({ totalBudget, activeTests, draftTests, activeMilestone }: OutlinesProgressBarProps) {
  return (
    <Box className="flex flex-col gap-1 h-7">
      {activeMilestone.id && <OutlineProgressIndicators activeMilestone={activeMilestone} />}

      <BudgetProgressBar totalBudget={totalBudget} activeTests={activeTests} draftTests={draftTests} />
    </Box>
  );
}
