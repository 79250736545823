import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { hslStringToRgbString, stringToColor } from '../../../utils/colorUtils';
import { CustomTooltip } from '../HiringUtils/helperFunctions';
import { validStatusMapForBarChart as validStatusMap } from '../HiringUtils/StatusConstants';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export default function StatusBarChart({ data, filters }) {
    // Define valid statuses
    let validStatuses = validStatusMap.map((status) => status.value);

    // Initialize an empty object to group counts by status
    const groupedData = {};

    // Initialize array to count total submissions
    let total = [];

    // Process data to calculate counts per status and source
    data.candidates.forEach((submission) => {
        if (
            (
                (
                    dayjs.utc(submission.dateSubmitted).isValid() &&
                    dayjs.utc(filters.gte).isValid() &&
                    dayjs.utc(filters.lte).isValid() &&
                    dayjs.utc(submission.dateSubmitted).isBetween(
                        dayjs.utc(filters.gte),
                        dayjs.utc(filters.lte),
                        "day",
                        "[]",
                    )
                ) ||
                (
                    dayjs.utc(submission.finalRoundDate).isValid() &&
                    dayjs.utc(filters.gte).isValid() &&
                    dayjs.utc(filters.lte).isValid() &&
                    dayjs.utc(submission.finalRoundDate).isBetween(
                        dayjs.utc(filters.gte),
                        dayjs.utc(filters.lte),
                        "day",
                        "[]",
                    )
                )
            ) &&
            validStatuses.includes(submission.status)
        ) {
            total.push(submission);
            // If entry for the status doesn't exist, create it
            if (!groupedData[submission.status]) {
                groupedData[submission.status] = { status: submission.status };
            }
            // Increment count for the corresponding source
            groupedData[submission.status][submission.jobSourceName] =
                (groupedData[submission.status][submission.jobSourceName] || 0) + 1;
        }
    });

    // Get all unique sources to ensure consistency
    const allSources = [
        ...new Set(data.candidates.map((submission) => submission.jobSourceName)),
    ];

    // Ensure every entry in groupedData has a key for each source
    Object.values(groupedData).forEach((entry) => {
        allSources.forEach((source) => {
            if (entry[source] === undefined) {
                entry[source] = 0;
            }
        });
    });

    // Build formattedData in the specific sequence of validStatusMap
    const formattedData = validStatusMap.map(({ value }) => {
        const entry = groupedData[value];
        if (entry) return entry;
        const newEntry = { status: value };
        allSources.forEach((source) => {
            newEntry[source] = 0;
        });
        return newEntry;
    });

    return (
        <div>
            <div style={{ width: '100%', height: '300px', margin: '20px 0px' }}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={1000}
                        height={300}
                        data={formattedData}
                        margin={{
                            right: 10,
                            left: 20,
                            bottom: 80,
                        }}
                    >
                        <CartesianGrid strokeDasharray="2 2" />
                        <XAxis
                            dataKey="status"
                            ticks={validStatusMap.map((status) => status.value)}
                            tick={{
                                fontSize: 12,
                                angle: -30,
                                textAnchor: 'end',
                            }}
                            tickFormatter={(tick) =>
                                validStatusMap.find((status) => status.value === tick)?.label || tick
                            }
                            interval={0}
                        />
                        <YAxis
                            domain={[0, 'dataMax']}
                            label={{
                                value: 'Total Count',
                                angle: -90,
                                position: 'insideLeft',
                            }}
                            interval={0}
                        />
                        <Tooltip
                            content={<CustomTooltip totalCount={total.length} filters={filters} />}
                        />
                        {(filters.source === "All" ? allSources : [filters.source]).map((source) => (
                            <Bar
                                key={source}
                                dataKey={source}
                                stackId="submissions"
                                fill={hslStringToRgbString(stringToColor(source))}
                                opacity={0.65}
                            />
                        ))}
                        <Legend
                            payload={[
                                {
                                    value: `Totals Based on Submission Statuses`,
                                    color: hslStringToRgbString(stringToColor(filters.source)),
                                },
                            ]}
                            verticalAlign="top"
                            height={32}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}
