import { GetScaledBarWidthOptions } from '../types/types';

/**
 * Maps a numeric value to a corresponding bar width using a stepped scale.
 * The width increases linearly in steps between minValue and maxValue until reaching 100.
 * For example, with (value=15, minValue=0, maxValue=20, numBreakpoints=4, minWidth=10):
 * - Values ≤ 0 return 10
 * - Values between 0-5 return 10
 * - Values between 5-10 return 32.5
 * - Values between 10-15 return 55
 * - Values between 15-20 return 77.5
 * - Values ≥ 20 return 100
 */
export default function getScaledBarWidth(value: number, options: GetScaledBarWidthOptions = {}) {
    const {
        numBreakpoints = 10,
        minValue = 0,
        maxValue = 40,
        minWidth = 10,
        maxWidth = 100,
    } = options;

    // Handle min/max cases
    if (value <= minValue) return minWidth;
    if (value >= maxValue) return maxWidth;

    // Calculate the size of each step in both value and width
    const valueStep = (maxValue - minValue) / numBreakpoints;
    const widthStep = (100 - minWidth) / numBreakpoints;

    // Find which step the value falls into
    const stepsAboveMin = Math.floor((value - minValue) / valueStep);
    const resultWidth = minWidth + (stepsAboveMin * widthStep);

    return resultWidth;
}
