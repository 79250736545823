import { Box, Divider, Tooltip, Typography } from '@mui/material';

interface BudgetProgressBarProps {
  totalBudget: number;
  activeTests: number;
  draftTests: number;
}

interface ToolTipContentProps extends Omit<BudgetProgressBarProps, 'expectedOutlinesPercentage'> {
  workToBeDone: number;
  baseColor: 'green' | 'yellow' | 'red';
}

function ToolTipContent({ totalBudget, activeTests, draftTests, workToBeDone, baseColor }: ToolTipContentProps) {
  return (
    <Box className="flex box-border rounded-sm justify-between gap-2 px-2">
      {/* Total Budget */}
      <Box className="flex flex-col w-full justify-center items-center py-1">
        <Typography variant="caption" fontWeight="bold" lineHeight={1.2}>
          Test Budget
        </Typography>
        <Typography variant="caption" lineHeight={1.2}>
          {totalBudget}
        </Typography>
      </Box>

      <Divider orientation="vertical" variant="middle" flexItem />

      {/* Active Tests */}
      <Box className="flex flex-col w-full justify-center items-center py-1">
        <Typography noWrap variant="caption" color={baseColor + '.main'} fontWeight="bold" lineHeight={1.2}>
          Active Tests
        </Typography>
        <Typography variant="caption" lineHeight={1.2}>
          {activeTests}
        </Typography>
      </Box>

      <Divider orientation="vertical" variant="middle" flexItem />

      {/* Draft Tests */}
      <Box className="flex flex-col w-full justify-center items-center py-1">
        <Typography noWrap variant="caption" color={baseColor + '.light'} fontWeight="bold" lineHeight={1.2}>
          Draft Tests
        </Typography>
        <Typography variant="caption" lineHeight={1.2}>
          {draftTests}
        </Typography>
      </Box>

      <Divider orientation="vertical" variant="middle" flexItem />

      {/* Tests Needed To Outline To Facilicate Remaining Creation */}
      <Box className="flex flex-col w-full justify-center items-center py-1">
        <Typography variant="caption" color="gray.main" fontWeight="bold" lineHeight={1.2}>
          Tests To Outline
        </Typography>
        <Typography variant="caption" lineHeight={1.2}>
          {workToBeDone}
        </Typography>
      </Box>
    </Box>
  );
}

export function BudgetProgressBar({ totalBudget, activeTests, draftTests }: BudgetProgressBarProps) {
  const activeTestsPercent = (activeTests / (totalBudget || activeTests || 1)) * 100;
  const roundedPercent = activeTestsPercent < 100 && activeTestsPercent > 99 ? 99 : Math.round(activeTestsPercent);

  const actualOutlinedTests = activeTests + draftTests;
  const actualOutlinedTestsPercent = (actualOutlinedTests / totalBudget) * 100;

  // Get the number of tests needed to outline to facilitate remaining creation
  const workToBeDone = Math.max(totalBudget - actualOutlinedTests, 0);

  // Get the difference between the target outlined tests and the actual outlined tests (drafts + active tests)
  const baseColor = activeTestsPercent >= 80 ? 'green' : activeTestsPercent >= 50 ? 'yellow' : 'red';

  return (
    <Box className="w-full h-full flex">
      <Tooltip
        title={
          <ToolTipContent
            totalBudget={totalBudget}
            activeTests={activeTests}
            draftTests={draftTests}
            workToBeDone={workToBeDone}
            baseColor={baseColor}
          />
        }
        arrow
        placement="bottom"
        slotProps={{ tooltip: { sx: { p: 0, maxWidth: 'unset' } } }}
      >
        <Box className="w-full h-full relative" bgcolor="gray.main">
          <Box className="h-full left-0 absolute" width={`${Math.min(actualOutlinedTestsPercent, 100)}%`} bgcolor={baseColor + '.light'} />
          <Box className="h-full left-0 absolute" width={`${Math.min(activeTestsPercent, 100)}%`} bgcolor={baseColor + '.main'} />
          <Box className="absolute left-0 top-0 w-full h-full flex items-center justify-center">
            <Typography variant="caption" color="white" fontWeight="bold">
              {`${roundedPercent}%`}
            </Typography>
          </Box>
        </Box>
      </Tooltip>
    </Box>
  );
}
