import '../../../css/gantt.css';
import ClientSummaryTable from './ClientSummaryTable/ClientSummaryTable';
import { Box, Select, MenuItem, Typography, FormControl, InputLabel, SelectChangeEvent, Tab, Tabs } from '@mui/material';
import { QaTeamSelector } from './QaTeamSelector';
import { TeamCapacityPanel } from './TeamCapacityPanel';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
dayjs.extend(isoWeek);
import { usePlanningContext } from '../../context/PlanningContext';
import TaskHeatmap from '../TestHeatMap';
import CsmSelector from './CsmSelector';
import InvestigationScheduling from './InvestigationScheduling';
import './styles.css';
import PlanningHubTable from './PlanningHubTable';
import { TableContextProvider } from './PlanningHubTable/context/FilterContext';

export default function Summary() {
  const { selectedWeek, setSelectedWeek, filterBy, setFilterBy, user, selectedTab, setSelectedTab } = usePlanningContext();

  const showInvestigationScheduling =
    filterBy === 'qaTeam' && (user.teamId === 34 || ['erice@qawolf.com', 'laura@qawolf.com'].includes(user.email) || user.isLead || user.isManager);
  const showTeamCapacityPanel = filterBy === 'qaTeam';

  const handleFilterChange = (e: SelectChangeEvent<string>) => {
    setFilterBy(e.target.value as 'qaTeam' | 'csm');
  };

  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ m: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
        <Typography variant="h4">Planning Hub</Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <DatePicker
              value={selectedWeek}
              onChange={(e) => setSelectedWeek(e?.startOf('week') || dayjs())}
              label="Select Week"
              format="MMM D - MMM D"
              showDaysOutsideCurrentMonth
              slotProps={{
                actionBar: {
                  actions: ['today'],
                },
                textField: {
                  InputProps: {
                    value: `${selectedWeek.format('MMM D')} - ${selectedWeek.endOf('week').format('MMM D')}`,
                  },
                },
              }}
            />
          </Box>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel>Filter by</InputLabel>
            <Select value={filterBy} onChange={handleFilterChange} label="Filter by">
              <MenuItem value="qaTeam">QA Team</MenuItem>
              <MenuItem value="csm">CSM</MenuItem>
            </Select>
          </FormControl>
          {filterBy === 'csm' && <CsmSelector />}
          {filterBy === 'qaTeam' && <QaTeamSelector />}
        </Box>
      </Box>

      {/* Tabs Here ------------------------------------------------------------ */}
      <Tabs variant="scrollable" value={selectedTab} onChange={(_, newValue) => setSelectedTab(newValue)} scrollButtons="auto">
        <Tab disableRipple value={0} label="Planning Table" />
        <Tab disableRipple value={1} label="Planning Table (Legacy)" />
        {showInvestigationScheduling && <Tab disableRipple value={2} label="Investigation Scheduling" />}
        <Tab disableRipple value={3} label="Test Heatmap" />
        {showTeamCapacityPanel && <Tab disableRipple value={4} label="QA Team Capacity" />}
      </Tabs>
      <Box sx={{ flex: 1 }}>
        {selectedTab === 0 && (
          <TableContextProvider>
            <PlanningHubTable />
          </TableContextProvider>
        )}
        {selectedTab === 1 && <ClientSummaryTable />}
        {selectedTab === 2 && showInvestigationScheduling && <InvestigationScheduling />}
        {selectedTab === 3 && <TaskHeatmap />}
        {selectedTab === 4 && filterBy === 'qaTeam' && <TeamCapacityPanel />}
      </Box>
    </Box>
  );
}
