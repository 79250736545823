import _ from 'lodash';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useQueries } from '@tanstack/react-query';

import { sendGetRequest } from '../../utils/network';

import { WOLF_LOGO } from '../../constants';

// just defining this here to satisfy eslint warning because FormDataEntryValue is from typescript
/**
 * @typedef {string | File} FormDataEntryValue
 */

/**
 * Generates a hash based on the form submission. This is used to link parent/child cards in the UI
 * @param {{[k: string]: FormDataEntryValue}} cardDataObject
 * @returns {Promise<string>}
 */
export async function generateCardHash(cardDataObject) {
  // generate new text encoder
  const encoder = new TextEncoder();

  // hash the encoded data
  const data = encoder.encode(JSON.stringify(cardDataObject));
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

/**
 * Generates a dynamic card based on the type returned from the form submission
 * @param {{[k: string]: FormDataEntryValue}} cardDataObject
 * @param {string} cardSetId
 * @param {import('../InsightsExplorer/types').QAEFromLocalStorage[]} allUsers
 * @param {import('../InsightsExplorer/types').QATeamFromLocalStorage[]} allTeams
 * @returns {import('./types').ActiveCard[]} activeCard
 */
export function generateCards(cardDataObject, cardSetId, allUsers, allTeams) {
  const cardType = cardDataObject.type;

  switch (cardType) {
    case 'Packs': {
      const packCards = [];

      const packs = cardDataObject.packs
        ?.toString()
        .split(',')
        .map((pack) => pack.trim());

      // create primary aggregate pack card
      /** @type {import('./types').PackLegendCard} */
      const packCard = {
        cardSetId: cardSetId,
        type: cardType,
        isParent: !!cardDataObject.includeIndividualTeams || false,
        cardName: cardDataObject.cardName?.toString(),
        imageUrl: packs.length === 1 ? allUsers.find((user) => user.name === packs[0]).avatar48 : WOLF_LOGO,
        includeIndividualTeams: !!cardDataObject.includeIndividualTeams || false,
        packs: packs,
      };
      packCards.push(packCard);

      // if individual teams are included, create individual team cards
      if (packCard.includeIndividualTeams) {
        // for each pack, we need to create a team card for all teams of that pack
        packs.forEach((pack) => {
          // get pack lead and all teams on pack
          const packLead = allUsers.find((user) => user.name === pack);
          const packTeams = allTeams.filter((team) => team.packId === packLead.teamId);

          // generate a card for each team, and push into the packCards array
          packTeams.forEach((team) => {
            /** @type {import('./types').TeamLegendCard} */
            const teamCard = {
              cardSetId: cardSetId,
              type: 'Teams',
              isParent: false,
              cardName: team.teamName,
              imageUrl: team.imageUrl,
              includeIndividualQAEs: false,
              teams: [team.teamName],
            };

            packCards.push(teamCard);
          });
        });
      }

      return packCards;
    }
    case 'Teams': {
      const teamCards = [];

      const teams = cardDataObject.teams
        ?.toString()
        .split(',')
        .map((team) => team.trim());

      /** @type {import('./types').TeamLegendCard} */
      const teamCard = {
        cardSetId: cardSetId,
        type: cardType,
        isParent: !!cardDataObject.includeIndividualTeams || !!cardDataObject.includeIndividualQAEs || false,
        cardName: cardDataObject.cardName?.toString(),
        imageUrl: teams.length === 1 ? allTeams.find((team) => team.teamName === teams[0]).imageUrl : WOLF_LOGO,
        includeIndividualQAEs: !!cardDataObject.includeIndividualQAEs || false,
        includeIndividualTeams: !!cardDataObject.includeIndividualTeams || false,
        teams: teams,
      };
      teamCards.push(teamCard);

      // if individual teams are included, create individual team cards
      if (teamCard.includeIndividualTeams) {
        // for each team, we need to create a team card
        teams.forEach((team) => {
          const teamFromLocalStorage = allTeams.find((t) => t.teamName === team);
          /** @type {import('./types').TeamLegendCard} */
          const teamCard = {
            cardSetId: cardSetId,
            type: 'Teams',
            isParent: false,
            cardName: teamFromLocalStorage.teamName,
            imageUrl: teamFromLocalStorage.imageUrl,
            includeIndividualQAEs: false,
            teams: [teamFromLocalStorage.teamName],
          };

          teamCards.push(teamCard);
        });
      }

      // if individual qaes are included, create individual qae cards
      if (teamCard.includeIndividualQAEs) {
        // for each team, we need to create a qae card for all qaes of that team
        teams.forEach((team) => {
          const teamFromLocalStorage = allTeams.find((t) => t.teamName === team);
          const teamQAEs = allUsers.filter((user) => user.teamId === teamFromLocalStorage.id && user.isQAE);

          // generate a card for each qae, and push into the teamCards array
          teamQAEs.forEach((qae) => {
            /** @type {import('./types').QAELegendCard} */
            const qaeCard = {
              cardSetId: cardSetId,
              type: 'QAEs',
              isParent: false,
              cardName: qae.name,
              imageUrl: qae.avatar48,
              includeIndividualQAEs: false,
              qaes: [qae.name],
            };

            teamCards.push(qaeCard);
          });
        });
      }

      return teamCards;
    }
    case 'QAEs': {
      const qaeCards = [];

      const qaes = cardDataObject.qaes
        ?.toString()
        .split(',')
        .map((qae) => qae.trim());

      /** @type {import('./types').QAELegendCard} */
      const qaeCard = {
        cardSetId: cardSetId,
        type: cardType,
        isParent: !!cardDataObject.includeIndividualQAEs || false,
        cardName: cardDataObject.cardName?.toString(),
        imageUrl: qaes.length === 1 ? allUsers.find((user) => user.name === qaes[0]).avatar48 : WOLF_LOGO,
        includeIndividualQAEs: !!cardDataObject.includeIndividualQAEs || false,
        qaes: qaes,
      };
      qaeCards.push(qaeCard);

      // if individual qaes are included, create individual qae cards
      if (qaeCard.includeIndividualQAEs) {
        // for each qae, we need to create a qae card
        qaes.forEach((qae) => {
          const qaeFromLocalStorage = allUsers.find((u) => u.name === qae);

          // generate a card for each qae, and push into the qaeCards array
          /** @type {import('./types').QAELegendCard} */
          const qaeCard = {
            cardSetId: cardSetId,
            type: 'QAEs',
            isParent: false,
            cardName: qaeFromLocalStorage.name,
            imageUrl: qaeFromLocalStorage.avatar48,
            includeIndividualQAEs: false,
            qaes: [qaeFromLocalStorage.name],
          };

          qaeCards.push(qaeCard);
        });
      }

      return qaeCards;
    }
    case 'Tiers': {
      const tierCards = [];

      const minMonths = Number(cardDataObject.minMonths);
      const maxMonths = Number(cardDataObject.maxMonths);
      const positions = cardDataObject.positions
        ?.toString()
        .split(',')
        .map((position) => position.trim());

      const qaeMatches = allUsers.filter((user) => {
        const userIsValidPosition = positions.includes(user.position);
        const tenure = dayjs().diff(dayjs(user.startDate), 'month', true);
        const userIsValidTenure = tenure <= maxMonths && tenure >= minMonths;

        return userIsValidPosition && userIsValidTenure && user.isQAE;
      });

      /** @type {import('./types').TierLegendCard} */
      const tierCard = {
        cardSetId: cardSetId,
        type: cardType,
        isParent: !!cardDataObject.includeIndividualQAEs || !!cardDataObject.includeSourceQAE || false,
        cardName: cardDataObject.cardName?.toString(),
        imageUrl: cardDataObject.imageUrl?.toString() || null,
        includeIndividualQAEs: !!cardDataObject.includeIndividualQAEs || false,
        qaeMatches: qaeMatches.map((qae) => qae.qawId),
        positions: positions,
        months: [minMonths, maxMonths],
      };
      tierCards.push(tierCard);

      // if individual qaes are included, create individual qae cards
      if (tierCard.includeIndividualQAEs) {
        // for each qae, we need to create a qae card
        qaeMatches.forEach((qae) => {
          /** @type {import('./types').QAELegendCard} */
          const qaeCard = {
            cardSetId: cardSetId,
            type: 'QAEs',
            isParent: false,
            cardName: qae.name,
            imageUrl: qae.avatar48,
            includeIndividualQAEs: false,
            qaes: [qae.name],
          };

          tierCards.push(qaeCard);
        });
      }

      // if a source qae is included, add a card for that qae
      if (cardDataObject.includeSourceQAE) {
        const sourceQAE = allUsers.find((user) => user.qawId === cardDataObject.includeSourceQAE);

        /** @type {import('./types').QAELegendCard} */
        const sourceQAECard = {
          cardSetId: cardSetId,
          type: 'QAEs',
          isParent: false,
          cardName: sourceQAE.name,
          imageUrl: sourceQAE.avatar48,
          includeIndividualQAEs: false,
          qaes: [sourceQAE.name],
        };

        tierCards.push(sourceQAECard);
      }

      return tierCards;
    }
    default:
      throw new Error(`Invalid card type: ${cardType}`);
  }
}

/**
 * De-duplicates any pre-existing cards, and updates existing card with color transition
 * @param {import('./types').ActiveCard[]} previousCards
 * @param {import('./types').ActiveCard[]} newCards
 * @returns {import('./types').ActiveCard[]}
 */
export function deDuplicateCards(previousCards, newCards) {
  const deDuplicatedCards = [];

  const allNewCardTypes = new Set(newCards.map((card) => card.type));
  const previousCardsOfValidTypes = _.groupBy(
    previousCards.filter((card) => allNewCardTypes.has(card.type)),
    'type',
  );

  for (const card of newCards) {
    const isPreExistingCard = previousCardsOfValidTypes[card.type]?.some((previousCard) => previousCard.cardName === card.cardName);
    if (isPreExistingCard) {
      continue;
    }
    deDuplicatedCards.push(card);
  }

  return deDuplicatedCards;
}

/**
 *
 * @param {import('../InsightsExplorer/types').QAEFromLocalStorage[]} packs
 * @param {{gte: string, lte: string}} dates
 * @returns {import("@tanstack/react-query").UseQueryResult<import("../InsightsExplorer/types").PackInvestigationAggregateResponse>[]}
 */
export function useOrgPlaygroundInsights(packs, dates) {
  const queries = useQueries({
    queries: useMemo(
      () =>
        packs.flatMap((pack) => [
          {
            queryKey: ['impactPlaygroundPackInvestigation', pack.teamId, dates.gte, dates.lte],
            queryFn: async () => {
              try {
                const packUrl = `/general-insights/investigation/pack/${pack.teamId}?gte=${dates.gte}&lte=${dates.lte}`;
                const response = await sendGetRequest(packUrl);
                return response.data;
              } catch (error) {
                throw new Error('Failed to fetch investigaiton insights. Please refresh the page.', error);
              }
            },
            staleTime: 5 * 60 * 1000,
            cacheTime: 15 * 60 * 1000,
            refetchOnWindowFocus: false,
            retry: 3,
          },
          {
            queryKey: ['impactPlaygroundPackCreation', pack.teamId, dates.gte, dates.lte],
            queryFn: async () => {
              try {
                const packUrl = `/general-insights/creation/pack/${pack.teamId}?gte=${dates.gte}&lte=${dates.lte}&types=testCreation&types=outline`;
                const response = await sendGetRequest(packUrl);
                return response.data;
              } catch (error) {
                throw new Error('Failed to fetch creation insights. Please refresh the page.', error);
              }
            },
            staleTime: 5 * 60 * 1000,
            cacheTime: 15 * 60 * 1000,
            refetchOnWindowFocus: false,
            retry: 2,
          },
          {
            queryKey: ['impactPlaygroundPackPlannedCreation', pack.teamId, dates.gte, dates.lte],
            queryFn: async () => {
              try {
                const packUrl = `/general-insights/creation/pack/${pack.teamId}/planned?gte=${dates.gte}&lte=${dates.lte}`;
                const response = await sendGetRequest(packUrl);
                return response.data;
              } catch (error) {
                throw new Error('Failed to fetch planned creation insights. Please refresh the page.', error);
              }
            },
            staleTime: 5 * 60 * 1000,
            cacheTime: 15 * 60 * 1000,
            refetchOnWindowFocus: false,
            retry: 2,
          },
        ]),
      [dates],
    ),
  });

  return queries;
}

/**
 * Builds the chart data for the insights playground chart
 * @param {(import('../InsightsExplorer/types').PackInvestigationAggregateResponse | import('../InsightsExplorer/types').PackTaskSnapshotAggregateResponse | import('../InsightsExplorer/types').PackPlannedCreationAggregateResponse)[]} loadedPackData
 * @param {import('../InsightsExplorer/types').QAEFromLocalStorage[]} allUsers
 * @param {import('../InsightsExplorer/types').QATeamFromLocalStorage[]} allTeams
 * @param {import('./types').ActiveCard[]} activeCards
 * @returns {{[key: string]: number | string}[]}
 */
export function buildPlaygroundChartData(loadedPackData, allUsers, allTeams, activeCards) {
  const chartData = [];

  // group creation data by week
  const creationData = loadedPackData
    .filter((data) => 'packTaskSnapshotsAggregatedWithTrackedTime' in data)
    .map((data) => data.packTaskSnapshotsAggregatedWithTrackedTime)
    .flat();
  const creationDataGroupedByWeek = _.groupBy(creationData, 'week');

  // group investigation data by week
  const investigationData = loadedPackData
    .filter((data) => 'packInvestigationAggregatedWithTrackedTime' in data)
    .map((data) => data.packInvestigationAggregatedWithTrackedTime)
    .flat();
  const investigationDataGroupedByWeek = _.groupBy(investigationData, 'week');

  // group planned creation data by week
  const plannedCreationData = loadedPackData.filter((data) => 'weeklyPlannedTestCounts' in data);

  // get all unique weeks
  const allWeeks = _.uniq([...Object.keys(creationDataGroupedByWeek), ...Object.keys(investigationDataGroupedByWeek)]);
  for (const week of allWeeks) {
    const creationDataForWeek = creationDataGroupedByWeek[week] || [];
    const investigationDataForWeek = investigationDataGroupedByWeek[week] || [];
    const usersContributingForWeek = _.uniq([...creationDataForWeek.map((x) => x.user_email), ...investigationDataForWeek.map((x) => x.user_email)]);

    let weekEntry = {
      week: week,
    };

    // generate org entry for week and add to chart data (averages)
    // - org investigation
    const allInvestigationVelocities = investigationDataForWeek.map((qaeInvestigation) => {
      // calculate velocity for week for QAE
      const hoursTrackedThisWeek = qaeInvestigation.hours_tracked;
      const investigationVelocity = qaeInvestigation.total_failures_investigated_this_week / hoursTrackedThisWeek;

      return investigationVelocity;
    });

    const orgFailuresInvestigatedForWeek = _.sumBy(investigationDataForWeek, 'total_failures_investigated_this_week');
    const orgAverageFailuresInvestigatedForWeek = orgFailuresInvestigatedForWeek / usersContributingForWeek.length;
    const orgInvestigationVelocity = _.mean(allInvestigationVelocities) || 0;

    // - org creation
    const allCreationVelocities = creationDataForWeek.map((qaeCreation) => {
      // calculate velocity for week for QAE
      const hoursTrackedThisWeek = qaeCreation.hours_tracked;
      const creationVelocity = qaeCreation.tests_completed_this_week / hoursTrackedThisWeek;

      return creationVelocity;
    });

    const orgTestsCreatedForWeek = _.sumBy(creationDataForWeek, 'tests_completed_this_week');
    const orgTestsPlannedForWeek = plannedCreationData.reduce((acc, data) => acc + (data.weeklyPlannedTestCounts?.[week]?.packTotal || 0), 0);
    const orgAverageTestsCreatedForWeek = orgTestsCreatedForWeek / usersContributingForWeek.length;
    const orgCreationVelocity = _.mean(allCreationVelocities) || 0;

    const orgCardId = 'ORG_CARD:QA Wolf';

    //! /* NOTE: The keys for `orgEntry` *MUST* match the values for the toggle identifiers in src/components/InsightsPlayrgound/cards/OrgCard.jsx */ !//

    const orgEntry = {
      [`${orgCardId}:Aggregated Average Impact Score`]: roundToTwo(orgInvestigationVelocity + orgCreationVelocity),
      [`${orgCardId}:Aggregated Total Failures Investigated`]: roundToTwo(orgFailuresInvestigatedForWeek),
      [`${orgCardId}:Aggregated Total Tests Planned`]: roundToTwo(orgTestsPlannedForWeek),
      [`${orgCardId}:Aggregated Average Failures Investigated`]: roundToTwo(orgAverageFailuresInvestigatedForWeek),
      [`${orgCardId}:Aggregated Average Failures Investigated Per Hour`]: roundToTwo(orgInvestigationVelocity),
      [`${orgCardId}:Aggregated Total Tests Created`]: roundToTwo(orgTestsCreatedForWeek),
      [`${orgCardId}:Aggregated Average Tests Created`]: roundToTwo(orgAverageTestsCreatedForWeek),
      [`${orgCardId}:Aggregated Average Tests Created Per Hour`]: roundToTwo(orgCreationVelocity),
    };

    weekEntry = { ...weekEntry, ...orgEntry };

    // iterate through active cards and add entries for week to chart data
    for (const card of activeCards) {
      const cardType = card.type;

      // handle Aggregate card
      if (['Packs', 'Teams', 'Tiers'].includes(cardType) || (cardType === 'QAEs' && 'qaes' in card && card.qaes.length > 1)) {
        let qaeIds = /** @type {string[]} */ ([]);

        switch (cardType) {
          case 'Packs': {
            // handle getting all QAE IDs for the relevant pack
            if ('packs' in card) {
              const packIds = allUsers.filter((user) => card.packs.includes(user.name)).map((user) => user.teamId);
              const teamIds = allTeams.filter((team) => packIds.includes(team.packId)).map((team) => team.id);
              qaeIds = allUsers.filter((user) => teamIds.includes(user.teamId) && user.isQAE).map((user) => user.qawId);
            }
            break;
          }
          case 'Teams': {
            // handle getting all QAE IDs for the relevant team(s)
            if ('teams' in card) {
              const teamIds = allTeams.filter((team) => card.teams.includes(team.teamName)).map((team) => team.id);
              qaeIds = allUsers.filter((user) => teamIds.includes(user.teamId) && user.isQAE).map((user) => user.qawId);
            }
            break;
          }
          case 'Tiers':
            // handle getting all QAE IDs for the relevant tier
            if ('qaeMatches' in card) {
              qaeIds = card.qaeMatches;
            }
            break;
          case 'QAEs':
            // handle getting all QAE IDs for included QAEs
            if ('qaes' in card) {
              qaeIds = allUsers.filter((user) => card.qaes.includes(user.name) && user.isQAE).map((user) => user.qawId);
            }
            break;
          default:
            // this should never happen
            console.log('Unknown Card Type: ', cardType);
            break;
        }

        // gather creation and investigation data from this week for the group of QAEs
        const aggregateCreationDataForWeek = creationDataForWeek.filter((data) => qaeIds.includes(data.user_id));
        const aggregateInvestigationDataForWeek = investigationDataForWeek.filter((data) => qaeIds.includes(data.user_id));

        // gather planned creation data from this week for the group of QAEs
        let aggregatePlannedCreationForWeek = 0;

        for (const qaeId of qaeIds) {
          const userName = allUsers.find((user) => user.qawId === qaeId).name;
          const userPlannedCreationForWeek = getUserPlannedTestCountForWeek(plannedCreationData, userName, week);
          aggregatePlannedCreationForWeek += userPlannedCreationForWeek;
        }

        // build the aggregate entry with the filtered data
        const aggregateEntry = buildAggregateEntry(
          card,
          aggregateCreationDataForWeek,
          aggregateInvestigationDataForWeek,
          aggregatePlannedCreationForWeek,
          qaeIds.length,
        );
        weekEntry = { ...weekEntry, ...aggregateEntry };
        continue;
      }

      // handle individual QAE card
      if (cardType === 'QAEs' && 'qaes' in card && card.qaes.length === 1) {
        const userName = card.qaes[0];
        const user = allUsers.find((user) => user.name === userName);

        // gather creation and investigation data from this week for the specific qae
        const qaeCreationDataForWeek = creationDataForWeek.filter((data) => data.user_id === user.qawId);
        const qaeInvestigationDataForWeek = investigationDataForWeek.filter((data) => data.user_id === user.qawId);

        // gather planned creation data from this week for the specific qae
        const qaePlannedCreationForWeek = getUserPlannedTestCountForWeek(plannedCreationData, userName, week);

        // build the individual entry with the filtered data
        const qaeEntry = buildQAEEntry(card, qaeCreationDataForWeek, qaeInvestigationDataForWeek, qaePlannedCreationForWeek);
        weekEntry = { ...weekEntry, ...qaeEntry };
        continue;
      }
    }

    // add week entry to chart data
    chartData.push(weekEntry);
  }

  return chartData.sort((a, b) => a.week.localeCompare(b.week));
}

/**
 * Extracts planned test creation count for a user for a week
 * @param {import('../InsightsExplorer/types').PackPlannedCreationAggregateResponse[]} plannedCreationData
 * @param {string} userName
 * @param {string} week
 * @returns {number}
 */
function getUserPlannedTestCountForWeek(plannedCreationData, userName, week) {
  let res = 0;

  plannedCreationData.forEach((pack) => {
    const weeklyData = pack.weeklyPlannedTestCounts?.[week];

    if (weeklyData) {
      Object.entries(weeklyData).forEach(([teamName, teamData]) => {
        if (teamName !== 'packTotal' && typeof teamData !== 'number') {
          if (userName in teamData) {
            res += teamData[userName];
          }
        }
      });
    }
  });

  return res;
}

/**
 * Builds the chart data entry for a pack card
 * @param {import('./types').ActiveCard} card
 * @param {import('../InsightsExplorer/types').TaskSnapshotTrackedTimeAggregate[]} creationDataForWeek
 * @param {import('../InsightsExplorer/types').InvestigationTrackedTimeAggregate[]} investigationDataForWeek
 * @param {number} plannedCreationForWeek
 * @param {number} usersContributingForWeek
 * @returns {{[key: string]: number}}
 */
function buildAggregateEntry(card, creationDataForWeek, investigationDataForWeek, plannedCreationForWeek, usersContributingForWeek) {
  const cardId = `${card.cardSetId}:${card.cardName}`;

  // - aggregate investigation
  const allInvestigationVelocities = investigationDataForWeek.map((qaeInvestigation) => {
    // calculate velocity for week for QAE
    const hoursTrackedThisWeek = qaeInvestigation.hours_tracked;
    const investigationVelocity = qaeInvestigation.total_failures_investigated_this_week / hoursTrackedThisWeek;

    return investigationVelocity;
  });

  const aggregateFailuresInvestigatedForWeek = _.sumBy(investigationDataForWeek, 'total_failures_investigated_this_week');
  const aggregateAverageFailuresInvestigatedForWeek = aggregateFailuresInvestigatedForWeek / usersContributingForWeek;
  const aggregateAverageInvestigationVelocity = _.mean(allInvestigationVelocities) || 0;

  // - aggregate creation
  const allCreationVelocities = creationDataForWeek.map((qaeCreation) => {
    // calculate velocity for week for QAE
    const hoursTrackedThisWeek = qaeCreation.hours_tracked;
    const creationVelocity = qaeCreation.tests_completed_this_week / hoursTrackedThisWeek;

    return creationVelocity;
  });

  const aggregateTestsCreatedForWeek = _.sumBy(creationDataForWeek, 'tests_completed_this_week');
  const aggregateAverageTestsCreatedForWeek = aggregateTestsCreatedForWeek / usersContributingForWeek;
  const aggregateAverageCreationVelocity = _.mean(allCreationVelocities) || 0;

  //! /* NOTE: The keys for `aggregateEntry` *MUST* match the values for the toggle identifiers in src/components/InsightsPlayrgound/cards/PackCard.jsx & src/components/InsightsPlayrgound/cards/AggregateQAECard.jsx */ !//
  const aggregateEntry = {
    [`${cardId}:Aggregated Average Impact Score`]: roundToTwo(aggregateAverageCreationVelocity + aggregateAverageInvestigationVelocity),
    [`${cardId}:Aggregated Total Failures Investigated`]: roundToTwo(aggregateFailuresInvestigatedForWeek),
    [`${cardId}:Aggregated Average Failures Investigated`]: roundToTwo(aggregateAverageFailuresInvestigatedForWeek),
    [`${cardId}:Aggregated Average Failures Investigated Per Hour`]: roundToTwo(aggregateAverageInvestigationVelocity),
    [`${cardId}:Aggregated Total Tests Created`]: roundToTwo(aggregateTestsCreatedForWeek),
    [`${cardId}:Aggregated Total Tests Planned`]: roundToTwo(plannedCreationForWeek),
    [`${cardId}:Aggregated Average Tests Created`]: roundToTwo(aggregateAverageTestsCreatedForWeek),
    [`${cardId}:Aggregated Average Tests Created Per Hour`]: roundToTwo(aggregateAverageCreationVelocity),
  };

  return aggregateEntry;
}

/**
 * Builds the chart data entry for a qae card
 * @param {import('./types').ActiveCard} card
 * @param {import('../InsightsExplorer/types').TaskSnapshotTrackedTimeAggregate[]} creationDataForWeek
 * @param {import('../InsightsExplorer/types').InvestigationTrackedTimeAggregate[]} investigationDataForWeek
 * @param {number} qaePlannedCreationForWeek
 * @returns {{[key: string]: number}}
 */
function buildQAEEntry(card, creationDataForWeek, investigationDataForWeek, qaePlannedCreationForWeek) {
  const cardId = `${card.cardSetId}:${card.cardName}`;

  // investigation insights for qae
  const failuresInvestigatedForWeek = _.sumBy(investigationDataForWeek, 'total_failures_investigated_this_week');
  const investigationVelocity = failuresInvestigatedForWeek / _.sumBy(investigationDataForWeek, 'hours_tracked') || 0;

  // creation insights for qae
  const testsCreatedForWeek = _.sumBy(creationDataForWeek, 'tests_completed_this_week');
  const creationVelocity = testsCreatedForWeek / _.sumBy(creationDataForWeek, 'hours_tracked') || 0;

  //! /* NOTE: The keys for `qaeEntry` *MUST* match the values for the toggle identifiers in src/components/InsightsPlayrgound/cards/IndividualQAECard.jsx */ !//
  const qaeEntry = {
    [`${cardId}:Impact Score`]: roundToTwo(investigationVelocity + creationVelocity),
    [`${cardId}:Failures Investigated`]: roundToTwo(failuresInvestigatedForWeek),
    [`${cardId}:Tests Created`]: roundToTwo(testsCreatedForWeek),
    [`${cardId}:Tests Planned`]: roundToTwo(qaePlannedCreationForWeek),
    [`${cardId}:Failures Investigated Per Hour`]: roundToTwo(investigationVelocity),
    [`${cardId}:Tests Created Per Hour`]: roundToTwo(creationVelocity),
  };
  return qaeEntry;
}

/**
 * Rounds to 2 decimal points using arithmetic
 * @param {number} number
 * @returns {number}
 */
export function roundToTwo(number) {
  return Math.round(number * 100) / 100;
}
