import { FormEvent, useState } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  MRT_ShowHideColumnsButton,
  MRT_TableInstance,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
} from 'material-react-table';

import { Box, Button, IconButton, Modal, Paper, TextField, Tooltip, Typography } from '@mui/material';
import { ClearAll, Save, Update } from '@mui/icons-material';

import { sendTSPostRequest, sendTSPutRequest } from '@/utils/tanstackNetwork';

import { StoredUser } from '@/components/HowlUtilization/types';
import { ClientSummaryTableRow } from '../../types';
import { CreatePlanningHubCustomerTableViewRequest, PlanningHubCustomerTableView, UpdatePlanningHubCustomerTableViewRequest } from './types';
import { DEFAULT_SAVED_VIEW_ID, usePlanningContext } from '@/components/context/PlanningContext';

/**
 * Renders the upper right corner action buttons for the table. Includes an additional button to save the current view.
 */
export default function ToolbarInternalActions({ table }: { table: MRT_TableInstance<ClientSummaryTableRow> }) {
  const user: StoredUser = JSON.parse(localStorage.getItem('user') ?? '{}');

  const queryClient = useQueryClient();
  const savedViews = queryClient.getQueryData<PlanningHubCustomerTableView[]>(['planningHubCustomerTableViews']);
  const { selectedSavedViewId, setSelectedSavedViewId } = usePlanningContext();
  const selectedSavedView = savedViews?.find((view) => view.id === selectedSavedViewId);

  const [createViewModalOpen, setCreateViewModalOpen] = useState(false);
  const [updateViewModalOpen, setUpdateViewModalOpen] = useState(false);
  const [viewName, setViewName] = useState('');

  const createViewMutation = useMutation({
    mutationKey: ['createPlanningHubCustomerTableView'],
    mutationFn: (reqBody: CreatePlanningHubCustomerTableViewRequest) => sendTSPostRequest('/save-table-view', reqBody),
    onSuccess: (newView: PlanningHubCustomerTableView) => {
      // Update the query cache
      queryClient.setQueryData(['planningHubCustomerTableViews'], (prev: PlanningHubCustomerTableView[]) => {
        return [...prev, newView];
      });

      // Update the selected saved view id
      setSelectedSavedViewId(newView.id);

      // Close the modal
      setCreateViewModalOpen(false);
    },
  });

  const updateViewMutation = useMutation({
    mutationKey: ['updatePlanningHubCustomerTableView'],
    mutationFn: (reqBody: UpdatePlanningHubCustomerTableViewRequest) => sendTSPutRequest('/update-table-view', reqBody),
    onSuccess: (newView: PlanningHubCustomerTableView) => {
      // Update the query cache
      queryClient.setQueryData(['planningHubCustomerTableViews'], (prev: PlanningHubCustomerTableView[]) => {
        return prev.map((view) => (view.id === newView.id ? newView : view));
      });

      // Close the modal
      setUpdateViewModalOpen(false);
    },
  });

  const handleSaveView = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    createViewMutation.mutate({ name: viewName, userQawId: user.qawId, state: table.getState(), tableName: 'planningHubCustomerTable' });
  };

  const handleUpdateView = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateViewMutation.mutate({ id: selectedSavedViewId, name: viewName, state: table.getState() });
  };

  const handleOpenCreateViewModal = () => {
    setCreateViewModalOpen(true);
    setViewName('');
  };

  const handleOpenUpdateViewModal = () => {
    setUpdateViewModalOpen(true);
    setViewName(selectedSavedView?.name ?? '');
  };

  const handleCloseModal = () => {
    setCreateViewModalOpen(false);
    setUpdateViewModalOpen(false);
    setViewName('');
  };

  const handleClearFiltersAndSorting = () => {
    table.setState(table.initialState);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      {selectedSavedViewId !== DEFAULT_SAVED_VIEW_ID && (
        <Tooltip title="Update View">
          <span>
            <IconButton onClick={handleOpenUpdateViewModal} disabled={updateViewMutation.isPending}>
              <Update />
            </IconButton>
          </span>
        </Tooltip>
      )}
      <Tooltip title="Save View">
        <span>
          <IconButton onClick={handleOpenCreateViewModal} disabled={createViewMutation.isPending}>
            <Save />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Clear Filters and Sorting">
        <span>
          <IconButton onClick={handleClearFiltersAndSorting}>
            <ClearAll />
          </IconButton>
        </span>
      </Tooltip>
      <MRT_ToggleGlobalFilterButton table={table} />
      <MRT_ShowHideColumnsButton table={table} />
      <MRT_ToggleDensePaddingButton table={table} />
      <MRT_ToggleFullScreenButton table={table} />
      <Modal open={createViewModalOpen} onClose={handleCloseModal}>
        <Paper sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, p: 3 }}>
          <form onSubmit={handleSaveView} style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <TextField required size="small" label="View Name" value={viewName} onChange={(e) => setViewName(e.target.value)} />
            <Button disabled={createViewMutation.isPending} type="submit" variant="contained" color="primary">
              Save
            </Button>
          </form>
        </Paper>
      </Modal>
      <Modal open={updateViewModalOpen} onClose={handleCloseModal}>
        <Paper sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, p: 3 }}>
          <Typography variant="h6">Update View</Typography>
          <Typography variant="caption">This will update the selected view with the current applied filters and sorting.</Typography>
          <form onSubmit={handleUpdateView} style={{ display: 'flex', flexDirection: 'column', gap: 10, marginTop: '20px' }}>
            <TextField required size="small" label="View Name" value={viewName} onChange={(e) => setViewName(e.target.value)} />
            <Button disabled={updateViewMutation.isPending} type="submit" variant="contained" color="primary">
              Update
            </Button>
          </form>
        </Paper>
      </Modal>
    </Box>
  );
}
