import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import tippy from 'tippy.js';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { classNames } from '../../../InvestigationBoard/helpers';
import { formatDuration } from './investigationInsightsHelpers';

import useQueryKeyData from '../../../../hooks/useQueryKeyData';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(duration);

export function InvestigationTable({ investigationTasks }) {
  const taskHeaders = [
    'Suite',
    'Trigger',
    'Customer',
    'Assignee',
    'Date',
    'Time to Claim',
    'Time to Investigate',
    'Runtime',
    'Failures Investigated',
    'Failures Fixed',
    'Failures Determined as Bug',
    'Failures Determined as Maintenance',
    'Superseding Suite(s)',
  ];
  const [tasks, setTasks] = useState(investigationTasks);

  const { data: allQAWUsers } = useQueryKeyData(['getAllQAWUsers']);
  const allQAEs = allQAWUsers.filter((user) => user.isQAE || user.isManager || user.isLead);

  // TODO: Tooltips for longer suite names (?)
  useEffect(() => {
    tippy('.tooltip', {
      content: (reference) => reference.getAttribute('data-tooltip'),
      //   delay: [100, 100],
    });
  }, []);

  useEffect(() => {
    setTasks(investigationTasks);
  }, [investigationTasks]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flow-root">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full pt-2 align-middle">
            <table className="min-w-full border-spacing-0">
              <thead>
                <tr>
                  {taskHeaders.map((header, idx) => (
                    <th
                      key={idx}
                      scope="col"
                      className={classNames(
                        idx === 0 ? 'px-4 sm:pl-6 lg:pl-8' : '',
                        'sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 px-1 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter',
                      )}
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {(tasks && tasks.length) > 0 &&
                  tasks.map((task, idx) => {
                    const rowClasses =
                      'max-w-[6rem] truncate border-t border-gray-200 whitespace-nowrap py-4 px-2 text-sm text-center text-gray-500 overflow-hidden w-8';
                    const taskDate = dayjs(task.startTime).utc().format('MMM D, YYYY h:mm A');

                    return (
                      <tr className="w-full" key={idx}>
                        <td className="max-w-0 truncate border-t border-gray-200 whitespace-nowrap py-4 pl-4 text-sm font-medium text-gray-500 overflow-hidden w-8">
                          <a
                            href={`https://app.qawolf.com/suites/${task.id}`}
                            target="_blank"
                            rel="noreferrer"
                            className="rounded-md py-1 px-2 mr-2 text-xs font-medium ring-1 ring-inset bg-gray-50 text-gray-700 ring-gray-600/20 hover:ring-2 hover:bg-gray-100"
                          >
                            Open Suite
                          </a>
                        </td>
                        <td className={rowClasses} title={task.triggerName}>
                          <NavLink to={`/flow-explorer/${task.id}`} className="text-blue-300">
                            {task.triggerName}
                          </NavLink>
                        </td>
                        {/* suite trigger name */}
                        <td className={rowClasses}>{task.customerName}</td> {/* suite customer */}
                        <td className={rowClasses}>{allQAEs.find((qae) => qae.email === task.taskClaimedBy)?.name || task.taskClaimedBy}</td>
                        {/* suite assignee */}
                        <td className={rowClasses} title={taskDate}>
                          {taskDate}
                        </td>
                        <td className={rowClasses}>{formatDuration(task.minutesUntilTriageStarted)}</td>
                        {/* time to claim suite */}
                        <td className={rowClasses}>{formatDuration(task.triageTimeInMinutes)}</td>
                        {/* time to investigate suite */}
                        <td className={rowClasses}>{formatDuration(task.runTimeInMinutes)}</td>
                        {/* suite runtime */}
                        <td className={rowClasses}>
                          {task.numberOfRunsPassingOnQaeFlake +
                            task.numberOfRunsPassingOnQaeFix +
                            task.numberOfRunsTriagedAsDoNotInvestigate +
                            task.numberOfRunsTriagedAsBug +
                            task.numberOfRunsTriagedAsMaintenance}
                        </td>
                        {/* number of failures investigated on suite */}
                        <td className={rowClasses}>{task.numberOfRunsPassingOnQaeFix}</td>
                        {/* number of failures fixed on suite */}
                        <td className={rowClasses}>{task.numberOfRunsTriagedAsBug}</td>
                        {/* number of failures determined as bug */}
                        <td className={rowClasses}>{task.numberOfRunsTriagedAsMaintenance}</td>
                        {/* number of failures determined as maintenance */}
                        <td className={rowClasses}>{task.supersededSuiteIds.join(', ')}</td>
                        {/* list of superseding suite ids */}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
