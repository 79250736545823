import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { SuiteFailureData } from '../types';

dayjs.extend(utc);
dayjs.extend(timezone);

export function processHourlyChartData(data: SuiteFailureData[], selectedDay: number) {
  if (!data.length) return [];

  const startTime = dayjs.utc(data[0].forecast.forecastStart);
  const startDay = startTime.day();

  // Initialize array with 24 hours starting from forecast start hour
  const hourlyData = Array.from({ length: 24 }, (_, i) => {
    const hourObj: Record<string, number | string> = {
      hour: (startTime.hour() + i) % 24,
    };
    data.forEach((customer) => {
      hourObj[`customer${customer.customerId}`] = 0;
    });
    return hourObj;
  });

  // Process forecast data
  for (const customer of data) {
    for (const point of customer.forecast.forecastData) {
      const pointTime = dayjs.utc(point.timestamp);
      const dayOffset = pointTime.diff(startTime, 'day');

      // Only process points for selected day offset from start
      if (dayOffset === (7 + selectedDay - startDay) % 7) {
        const hour = pointTime.hour();
        const key = `customer${customer.customerId}`;
        const hourIndex = (24 + hour - startTime.hour()) % 24;
        hourlyData[hourIndex][key] = point.value;
        hourlyData[hourIndex].date = pointTime.format('YYYY-MM-DD HH:mm');
      }
    }
  }

  return hourlyData;
}

export function processWeeklyChartData(data: SuiteFailureData[]) {
  if (!data.length) return [];

  const startTime = dayjs.utc(data[0].forecast.forecastData[0].timestamp);
  const endTime = startTime.add(7, 'day');
  const totalHours = endTime.diff(startTime, 'hour') + 1;

  // Initialize array with actual hours between start and end
  const hourlyData = Array.from({ length: totalHours }, (_, i) => {
    const currentTime = startTime.add(i, 'hour');

    const hourObj: Record<string, number | string> = {
      hour: i,
      time: currentTime.format('dddd MMMM Do, HH:mm'),
      date: currentTime.format('YYYY-MM-DD HH:mm'),
    };

    data.forEach((customer) => {
      hourObj[`customer${customer.customerId}`] = 0;
    });
    return hourObj;
  });

  // Process forecast data
  for (const customer of data) {
    for (const point of customer.forecast.forecastData) {
      const pointTime = dayjs.utc(point.timestamp);
      const hourIndex = pointTime.diff(startTime, 'hour');

      if (hourIndex >= 0 && hourIndex < totalHours) {
        const key = `customer${customer.customerId}`;
        hourlyData[hourIndex][key] = point.value;
      }
    }
  }

  return hourlyData;
}
