import { Menu, Transition } from '@headlessui/react';
import { ArrowUturnLeftIcon, EllipsisHorizontalIcon, ArrowPathIcon, XMarkIcon, RocketLaunchIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react/jsx-runtime';
import { RealInvestigationTask } from '../types/investigation';
import { useHQContext } from '../contexts/HQContext';

interface InvestigationActionMenuProps {
  parentComponent: 'investigationCard' | 'investigationView';
  investigationTask: RealInvestigationTask;
}

function InvestigationActionMenu({ parentComponent, investigationTask }: InvestigationActionMenuProps) {
  const { suiteAction } = useHQContext();

  return (
    <Menu
      as="div"
      className="flex items-center relative rounded-md py-0.5 px-2 text-xs font-medium ring-1 ring-inset bg-gray-50 text-gray-700 ring-gray-600/20 hover:ring-2 hover:bg-gray-100"
    >
      <Menu.Button className="-m-1.5 block p-1.5 text-gray-400 hover:text-gray-500">
        <EllipsisHorizontalIcon className="h-3 w-3" aria-hidden="true" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute z-50 w-32 rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none ${
            window.innerHeight - (document.getElementById('investigation-card')?.getBoundingClientRect()?.top ?? 0) > 200
              ? 'top-full mt-1 origin-top-right'
              : 'bottom-full mb-1 origin-bottom-right'
          } right-0`}
        >
          {parentComponent === 'investigationCard' && (
            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  className={`w-full flex px-3 py-1 text-sm leading-6 text-indigo-600 ${active ? 'bg-gray-100' : ''}`}
                  onClick={() => suiteAction(investigationTask, '/unclaim')}
                >
                  <ArrowUturnLeftIcon className="h-5 w-5 pt-1 mr-1" /> Unclaim
                </button>
              )}
            </Menu.Item>
          )}
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                className={`w-full flex px-3 py-1 text-sm leading-6 text-green-600 ${active ? 'bg-gray-100' : ''}`}
                onClick={() => suiteAction(investigationTask, '/check')}
              >
                <ArrowPathIcon className="h-5 w-5 pt-1 mr-1" /> Check
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                className={`w-full flex px-3 py-1 text-sm leading-6 text-red-500 ${active ? 'bg-gray-100' : ''}`}
                onClick={() => suiteAction(investigationTask, '/ignore')}
              >
                <XMarkIcon className="h-5 w-5 pt-1 mr-1" /> Ignore
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                className={`w-full flex px-3 py-1 text-sm leading-6 text-yellow-600 ${active ? 'bg-gray-100' : ''}`}
                onClick={() => suiteAction(investigationTask, '/urgent')}
              >
                <RocketLaunchIcon className="h-5 w-5 pt-1 mr-1" /> Urgent
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default InvestigationActionMenu;
