import * as React from 'react';
import { createContext, useState, useContext, type ReactNode, useCallback, useEffect } from 'react';
import { StoredQaTeam } from '@/components/context/PlanningContext';
import {
  Workflow,
  WorkflowStatus,
  RealInvestigationTask,
  InternalResource,
  RealUser,
  InvestigationBoardData,
  QueryReturnObject,
  HQTaskAction,
  InvestigationInformation,
  ActiveShift,
  UserShiftPref,
} from '../types/investigation';
import { useTasksQuery, useUserShiftPrefs, useActiveShifts, useTaskMutation } from '../utils/HQHooks';
import { filterTasks } from '../utils/filterTasks';
import useQueryKeyData from '@/hooks/useQueryKeyData';
import { useCommunications } from '@/components/HQ/contexts/CommunicationsContext';
import { useQaTeam } from '@/components/context/QaTeamContext';
import { Conversation } from '@/components/HQ/data/types';
import { ToastData } from './CommunicationsContextTypes';

type HQContextType = {
  selectedQaTeamIds: number[];
  setSelectedQaTeamIds: (ids: number[]) => void;
  allTeamsSelected: boolean;
  setAllTeamsSelected: (selected: boolean) => void;
  qaTeams: StoredQaTeam[];
  workflows: Record<string, Workflow>;
  updateWorkflowStatus: (workflowId: string, newStatus: WorkflowStatus, originalWorkflow?: Workflow) => void;
  retryWorkflow: (workflowId: string) => void;
  retryAllFailedWorkflows: (issueId: string) => void;
  overrideBaseline: (workflowId: string) => void;
  investigations: InvestigationBoardData;
  setInvestigations: React.Dispatch<React.SetStateAction<InvestigationBoardData>>;
  addInternalResource: (investigationId: string, resource: InternalResource) => void;
  claimInvestigation: (investigation: RealInvestigationTask) => void;
  unclaimInvestigation: (investigationId: string) => void;
  updateInvestigation: (updatedInvestigation: RealInvestigationTask) => void;
  allUsers: RealUser[];
  user: RealUser;
  hasUserClaimedMaxTasks: boolean;
  isLoading: boolean;
  hasError: boolean;
  errors: {
    tasks: Error | null;
    shiftPrefs: Error | null;
    activeShifts: Error | null;
  };
  refetchAll: () => void;
  // Individual states if needed
  queries: {
    tasks: QueryReturnObject;
    shiftPrefs: QueryReturnObject;
    activeShifts: QueryReturnObject;
  };
  suiteAction: (task: RealInvestigationTask | Conversation, action: HQTaskAction) => void;
  selectedTaskInformation: InvestigationInformation | null;
  setSelectedTaskInformation: (taskInformation: InvestigationInformation | null) => void;
  activeShifts: ActiveShift[];
  userShiftPrefs: UserShiftPref;
  hqToastData: ToastData | null;
  setHqToastData: (toastData: ToastData | null) => void;
};

const HQContext = createContext<HQContextType | undefined>(undefined);

export function HQContextProvider({ children }: { children: ReactNode }) {
  // Get qaTeam context
  const { selectedQaTeamIds, setSelectedQaTeamIds, allTeamsSelected, setAllTeamsSelected, qaTeams } = useQaTeam();

  // Get all users and current user
  const { data: allUsers } = useQueryKeyData(['getAllQAWUsers']) as { data: RealUser[] };
  const user = allUsers.find((x: RealUser) => x.qawId === localStorage.getItem('userId')) as RealUser;
  const [hqToastData, setHqToastData] = useState<ToastData | null>(null);

  const [workflows, setWorkflows] = useState<Record<string, Workflow>>({});
  const [investigations, setInvestigations] = useState<InvestigationBoardData>({
    ongoing: {
      currentUserTasks: [],
      otherUserTasks: [],
      unclaimedTasks: [],
      currentUserConversations: [],
      otherUserConversations: [],
      unclaimedConversations: [],
    },
    completed: [],
    completedConversations: [],
  });
  const [selectedTaskInformation, setSelectedTaskInformation] = useState<InvestigationInformation | null>(null);

  // Get conversations from communications context
  const { conversations } = useCommunications();

  const sortByOldestMessage = (a: Conversation, b: Conversation) => {
    // Get latest message (messages[0] is the latest because messages are stored in reverse chronological order)
    const aLatestMessage = a.messages[0];
    const bLatestMessage = b.messages[0];

    // If both messages have the same sender type, sort by timestamp
    const aTime = new Date(Number(aLatestMessage.timestamp) * 1000).getTime();
    const bTime = new Date(Number(bLatestMessage.timestamp) * 1000).getTime();

    // First compare by sender type (external > internal)
    if (aLatestMessage.sender !== 'internal' && bLatestMessage.sender === 'internal') {
      return -1;
    }
    if (bLatestMessage.sender !== 'internal' && aLatestMessage.sender === 'internal') {
      return 1;
    }

    // If a conversation has a customer ask, it should be sorted above conversations without customer asks but lower than conversations that require a reply
    const aHasCustomerAsk = a.messages.some((message) => message.customerAsks && message.customerAsks?.length > 0);
    const bHasCustomerAsk = b.messages.some((message) => message.customerAsks && message.customerAsks?.length > 0);

    if (aHasCustomerAsk && !bHasCustomerAsk) {
      return -1;
    }
    if (!aHasCustomerAsk && bHasCustomerAsk) {
      return 1;
    }

    // If both messages have the same sender type, sort by timestamps (oldest first)
    return aTime - bTime;
  };

  const sortByNewestMessage = (a: Conversation, b: Conversation) => {
    const aLastMessage = a.messages[a.messages.length - 1];
    const bLastMessage = b.messages[b.messages.length - 1];
    return new Date(Number(bLastMessage.timestamp)).getTime() - new Date(Number(aLastMessage.timestamp)).getTime();
  };

  // Get conversations for current user and other users
  const currentUserConversations = conversations
    .filter((conversation) => conversation.assignedTo?.qawId === user.qawId && !conversation.completed)
    .sort(sortByOldestMessage);

  const otherUserConversations = conversations
    .filter((conversation) => conversation.assignedTo?.qawId !== user.qawId && conversation.assignedTo !== null && !conversation.completed)
    .sort(sortByOldestMessage);

  const unclaimedConversations = conversations
    .filter((conversation) => conversation.assignedTo === null && !conversation.completed)
    .sort(sortByOldestMessage);

  const completedConversations = conversations.filter((conversation) => conversation.completed).sort(sortByNewestMessage);

  const {
    data: { ongoing = { currentUserTasks: [], otherUserTasks: [], unclaimedTasks: [] }, completed = [], hasUserClaimedMaxTasks = false } = {},
    isPending: isTasksPending,
    isError: isTasksError,
    error: tasksError,
    refetch: refetchTasks,
  } = useTasksQuery(user, filterTasks, selectedQaTeamIds, qaTeams);

  const {
    data: userShiftPrefs,
    isPending: isShiftPrefsPending,
    isError: isShiftPrefsError,
    error: shiftPrefsError,
    refetch: refetchShiftPrefs,
  } = useUserShiftPrefs(user);

  const {
    data: activeShifts,
    isPending: isActiveShiftsPending,
    isError: isActiveShiftsError,
    error: activeShiftsError,
    refetch: refetchActiveShifts,
  } = useActiveShifts();

  // Combine loading states if needed
  const isLoading = isTasksPending || isShiftPrefsPending || isActiveShiftsPending;

  // Combine error states
  const hasError = isTasksError || isShiftPrefsError || isActiveShiftsError;
  const errors = {
    tasks: tasksError,
    shiftPrefs: shiftPrefsError,
    activeShifts: activeShiftsError,
  };

  // Function to refetch all data
  const refetchAll = useCallback(() => {
    refetchTasks();
    refetchShiftPrefs();
    refetchActiveShifts();
  }, [refetchTasks, refetchShiftPrefs, refetchActiveShifts]);

  useEffect(() => {
    setInvestigations({
      ongoing: {
        currentUserTasks: ongoing.currentUserTasks,
        otherUserTasks: ongoing.otherUserTasks,
        unclaimedTasks: ongoing.unclaimedTasks,
        currentUserConversations: currentUserConversations,
        otherUserConversations: otherUserConversations,
        unclaimedConversations: unclaimedConversations,
      },
      completed,
      completedConversations,
    });
  }, [ongoing, completed, conversations]);

  const taskMutation = useTaskMutation(refetchTasks);
  const allowedActions: HQTaskAction[] = ['/check', '/ignore', '/urgent', '/done', '/claim', '/unclaim'];
  function suiteAction(task: RealInvestigationTask | Conversation, action: HQTaskAction) {
    if (!allowedActions.includes(action)) {
      throw new Error(`Action ${action} is not allowed. Allowed actions: ${allowedActions.join(',')}`);
    }

    taskMutation.mutate({ endpoint: action, task });
  }

  const addInternalResource = (investigationId: string, resource: InternalResource) => {
    // setInvestigations((prevInvestigations) =>
    //   prevInvestigations.map((inv) => {
    //     if (inv.id === investigationId) {
    //       return {
    //         ...inv,
    //         internalResources: [...inv.internalResources, resource],
    //       };
    //     }
    //     return inv;
    //   }),
    // );
    console.log(investigationId, resource);
  };

  const claimInvestigation = (investigation: RealInvestigationTask) => {
    suiteAction(investigation, '/claim');
    // setInvestigations((prevInvestigations) => {
    //   investigations.map((inv) => {
    //     if (inv.id === investigationId) {
    //       return {
    //         ...inv,
    //         status: 'inProgress',
    //         assignee: {
    //           id: userId,
    //           name: 'Current User', // This should be replaced with actual user data
    //           avatar: `https://api.dicebear.com/6.x/avataaars/svg?seed=${userId}`,
    //           onlineStatus: 'green' as const,
    //         },
    //       };
    //     }
    //     return inv;
    //   }),
    // );
  };

  const unclaimInvestigation = (investigationId: string) => {
    // setInvestigations((prevInvestigations) =>
    //   prevInvestigations.map((inv) => {
    //     if (inv.id === investigationId) {
    //       const { /* assignee, */ ...rest } = inv;
    //       return {
    //         ...rest,
    //         status: 'toDo',
    //         assignee: null,
    //       };
    //     }
    //     return inv;
    //   }),
    // );
    console.log(investigationId);
  };

  const updateInvestigation = (updatedInvestigation: RealInvestigationTask) => {
    // setInvestigations((prevInvestigations) =>
    //   prevInvestigations.map((inv) => (inv.id === updatedInvestigation.id ? { ...updatedInvestigation } : inv)),
    // );
    console.log(updatedInvestigation);
  };

  const updateWorkflowStatus = useCallback((workflowId: string, newStatus: WorkflowStatus, originalWorkflow?: Workflow) => {
    setWorkflows((prevWorkflows) => ({
      ...prevWorkflows,
      [workflowId]: {
        ...(originalWorkflow || prevWorkflows[workflowId]),
        status: newStatus,
      },
    }));
  }, []);

  const retryWorkflow = useCallback((workflowId: string) => {
    setWorkflows((prevWorkflows) => ({
      ...prevWorkflows,
      [workflowId]: { ...prevWorkflows[workflowId], status: 'queued', startTime: new Date().toISOString() },
    }));
  }, []);

  const retryAllFailedWorkflows = useCallback((issueId: string) => {
    setWorkflows((prevWorkflows) => {
      console.log(prevWorkflows, issueId);
      return prevWorkflows;
      // const updatedWorkflows = { ...prevWorkflows };
      // Object.keys(updatedWorkflows).forEach((workflowId) => {
      //   if (updatedWorkflows[workflowId].status === "failed") {
      //     updatedWorkflows[workflowId] = {
      //       ...updatedWorkflows[workflowId],
      //       status: "queued",
      //       startTime: new Date().toISOString(),
      //     };
      //   }
      // });
      // return updatedWorkflows;
    });
  }, []);

  const overrideBaseline = useCallback((workflowId: string) => {
    setWorkflows((prevWorkflows) => ({
      ...prevWorkflows,
      [workflowId]: { ...prevWorkflows[workflowId], baselineOverride: true },
    }));
  }, []);

  return (
    <HQContext.Provider
      value={{
        selectedQaTeamIds,
        setSelectedQaTeamIds,
        allTeamsSelected,
        setAllTeamsSelected,
        qaTeams,
        workflows,
        updateWorkflowStatus,
        retryWorkflow,
        retryAllFailedWorkflows,
        overrideBaseline,
        investigations,
        setInvestigations,
        addInternalResource,
        claimInvestigation,
        unclaimInvestigation,
        updateInvestigation,
        allUsers,
        user,
        hasUserClaimedMaxTasks,
        isLoading,
        hasError,
        errors,
        refetchAll,
        // Individual states if needed
        queries: {
          tasks: {
            isPending: isTasksPending,
            isError: isTasksError,
            error: tasksError,
            refetch: refetchTasks,
          },
          shiftPrefs: {
            isPending: isShiftPrefsPending,
            isError: isShiftPrefsError,
            error: shiftPrefsError,
            refetch: refetchShiftPrefs,
          },
          activeShifts: {
            isPending: isActiveShiftsPending,
            isError: isActiveShiftsError,
            error: activeShiftsError,
            refetch: refetchActiveShifts,
          },
        },
        suiteAction,
        selectedTaskInformation,
        setSelectedTaskInformation,
        activeShifts,
        userShiftPrefs,
        hqToastData,
        setHqToastData,
      }}
    >
      {children}
    </HQContext.Provider>
  );
}

export function useHQContext() {
  const context = useContext(HQContext);
  if (!context) {
    throw new Error('useHQContext must be used within an HQContextProvider');
  }
  return context;
}
