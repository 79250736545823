import React from 'react';
import { FailureSignatureArray, FailureSignature, BlockSequenceItem, Block, BlockDictionaryEntry } from '../types';
import { getBlockReferences } from '../helpers';

interface SidebarProps {
  activeView: 'workflows' | 'blocks';
  setActiveView: (view: 'workflows' | 'blocks') => void;
  workflowSearch: string;
  setWorkflowSearch: (search: string) => void;
  blockSearch: string;
  setBlockSearch: (search: string) => void;
  currentData: FailureSignatureArray | null;
  selectedWorkflow: string | null;
  selectedBlock: string | null;
  selectedSequenceIndex: number;
  selectedAttempt: { index: number; id: string | null };
  blocksData: Block[];
  handleWorkflowSelection: (signature: FailureSignature) => void;
  navigateWorkflowSequence: (direction: 'prev' | 'next') => void;
  handleSequenceBlockSelection: (blockId: string, index: number) => void;
  handleBlockSelection: (blockId: string) => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  activeView,
  setActiveView,
  workflowSearch,
  setWorkflowSearch,
  blockSearch,
  setBlockSearch,
  currentData,
  selectedWorkflow,
  selectedBlock,
  selectedSequenceIndex,
  selectedAttempt,
  blocksData,
  handleWorkflowSelection,
  navigateWorkflowSequence,
  handleSequenceBlockSelection,
  handleBlockSelection,
}) => {
  const filteredDataBySelectedAttempt: FailureSignatureArray | null =
    currentData?.filter((signature: FailureSignature) => signature.attemptIndex === selectedAttempt.index) ?? null;
  const blockReferences = selectedBlock ? getBlockReferences(selectedBlock, filteredDataBySelectedAttempt) : null;

  const hasCode = (block: Block) => {
    const dictionaryEntryId = filteredDataBySelectedAttempt?.[0]?.dictionary.entries.find(
      (entry: BlockDictionaryEntry) => entry.blockId === block.id,
    )?.id;
    const codeBlob =
      filteredDataBySelectedAttempt?.[0]?.dictionary.entries.find((entry: BlockDictionaryEntry) => entry.id === dictionaryEntryId)?.code_blob ?? null;
    return codeBlob;
  };

  return (
    <div className="w-96 transition-all duration-300 bg-gray-800 border-l border-gray-700 overflow-hidden shrink-0">
      <div className="p-6">
        {/* Navigation Toggle */}
        <div className="flex mb-6 bg-gray-700 rounded-lg p-1">
          <button
            onClick={() => setActiveView('workflows')}
            className={`flex-1 py-2 px-4 rounded-md text-sm font-medium transition-colors ${
              activeView === 'workflows' ? 'bg-blue-600 text-white' : 'text-gray-300 hover:text-white'
            }`}
          >
            Workflows
          </button>
          <button
            onClick={() => setActiveView('blocks')}
            className={`flex-1 py-2 px-4 rounded-md text-sm font-medium transition-colors ${
              activeView === 'blocks' ? 'bg-blue-600 text-white' : 'text-gray-300 hover:text-white'
            }`}
          >
            Building Blocks
          </button>
        </div>

        {/* Search Bar */}
        <div className="mb-4">
          <div className="relative">
            <input
              type="text"
              value={activeView === 'workflows' ? workflowSearch : blockSearch}
              onChange={(e) => (activeView === 'workflows' ? setWorkflowSearch(e.target.value) : setBlockSearch(e.target.value))}
              placeholder={`Search ${activeView === 'workflows' ? 'workflows' : 'building blocks'}...`}
              className="w-full px-4 py-2 rounded bg-gray-700 text-gray-200 border border-gray-600 focus:outline-none focus:border-blue-500 pr-10"
            />
            {((activeView === 'workflows' && workflowSearch) || (activeView === 'blocks' && blockSearch)) && (
              <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                <button
                  onClick={() => (activeView === 'workflows' ? setWorkflowSearch('') : setBlockSearch(''))}
                  className="text-gray-400 hover:text-gray-200 focus:outline-none"
                  aria-label="Clear search"
                >
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="mb-6">
          <h2 className="text-2xl font-bold text-gray-200 mb-2">{activeView === 'workflows' ? 'Workflows' : 'Building Blocks'}</h2>
          <div className="h-px bg-gray-700 w-full"></div>
        </div>

        <div className="space-y-4 overflow-y-auto" style={{ maxHeight: 'calc(100vh - 240px)' }}>
          {activeView === 'workflows' ? (
            filteredDataBySelectedAttempt && filteredDataBySelectedAttempt.length > 0 ? (
              filteredDataBySelectedAttempt
                .filter(
                  (signature: FailureSignature) =>
                    signature.sequence.workflowName.toLowerCase().includes(workflowSearch.toLowerCase()) ||
                    signature.sequence.workflowId.toLowerCase().includes(workflowSearch.toLowerCase()),
                )
                .map((signature: FailureSignature) => (
                  <div
                    key={`${signature.sequence.workflowId}`}
                    data-workflow-id={`${signature.sequence.workflowId}`}
                    onClick={() => handleWorkflowSelection(signature)}
                    className={`rounded-lg transition-all duration-200 cursor-pointer ${
                      selectedWorkflow === signature.sequence.workflowId ? 'bg-blue-600 shadow-lg scale-102' : 'bg-gray-700 hover:bg-gray-600'
                    }`}
                  >
                    <div className="p-4 flex items-center justify-between">
                      <div className="font-medium text-gray-200 text-lg">{signature.sequence.workflowName}</div>
                    </div>
                    {selectedWorkflow === signature.sequence.workflowId && signature.sequence.items.length > 0 && (
                      <div className="px-4 pb-4">
                        <div className="bg-gray-800 rounded-md p-3 space-y-3">
                          <div className="flex justify-between items-center">
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                navigateWorkflowSequence('prev');
                              }}
                              disabled={selectedSequenceIndex === 0}
                              className={`p-2 rounded-full transition-colors ${
                                selectedSequenceIndex === 0
                                  ? 'bg-gray-700 text-gray-400 cursor-not-allowed'
                                  : 'bg-gray-700 hover:bg-gray-600 text-gray-200'
                              }`}
                            >
                              <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                              </svg>
                            </button>
                            <span className="text-gray-300 text-sm">
                              Block {selectedSequenceIndex + 1} of {signature.sequence.items.length}
                            </span>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                navigateWorkflowSequence('next');
                              }}
                              disabled={selectedSequenceIndex === signature.sequence.items.length - 1}
                              className={`p-2 rounded-full transition-colors ${
                                selectedSequenceIndex === signature.sequence.items.length - 1
                                  ? 'bg-gray-700 text-gray-400 cursor-not-allowed'
                                  : 'bg-gray-700 hover:bg-gray-600 text-gray-200'
                              }`}
                            >
                              <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                              </svg>
                            </button>
                          </div>

                          <div className="border-t border-gray-700 pt-3">
                            <div className="text-gray-300 text-sm mb-1">Current Block</div>
                            <div className="text-gray-200">
                              {[...signature.sequence.items].sort((a, b) => a.position - b.position)[selectedSequenceIndex]?.block.name.toString()}
                            </div>
                          </div>
                          <div className="border-t border-gray-700 pt-3">
                            <div className="text-gray-300 text-sm mb-1">Sequence</div>
                            <div className="text-gray-400 text-sm space-y-1">
                              {[...signature.sequence.items]
                                .sort((a, b) => a.position - b.position)
                                .map((item: BlockSequenceItem, index: number) => (
                                  <div
                                    key={item.blockId}
                                    className="flex items-center cursor-pointer hover:bg-gray-700 rounded px-2 py-1 transition-colors"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleSequenceBlockSelection(item.blockId.toString(), index);
                                    }}
                                  >
                                    <span className={index === selectedSequenceIndex ? 'text-blue-400' : ''}>{' → ' + item.block.name}</span>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))
            ) : (
              <div className="text-gray-400 text-center py-8">No workflows found</div>
            )
          ) : blocksData.length > 0 ? (
            blocksData
              .filter((block) => block.name.toLowerCase().includes(blockSearch.toLowerCase()))
              .map((block) => (
                <div
                  key={block.id}
                  data-block-id={block.id}
                  onClick={() => handleBlockSelection(block.id.toString())}
                  className={`rounded-lg transition-all duration-200 cursor-pointer ${
                    selectedBlock === block.id.toString() ? 'bg-blue-600 shadow-lg scale-102' : 'bg-gray-700 hover:bg-gray-600'
                  }`}
                >
                  <div className="p-4">
                    <div className="font-medium text-gray-200 text-lg mb-1">{block.name}</div>
                    <div className="text-sm text-gray-400 mb-2">Type: {block.type.toLowerCase()}</div>
                    {selectedBlock === block.id.toString() && (
                      <>
                        {hasCode(block) && (
                          <div className="mt-3 bg-gray-900 p-3 rounded-md">
                            <pre className="text-sm font-mono text-gray-300 overflow-x-auto">{`${hasCode(block)}`}</pre>
                          </div>
                        )}
                        {blockReferences && (
                          <div className="mt-4 space-y-4">
                            <div className="border-t border-gray-600 pt-4">
                              <h3 className="text-gray-200 font-medium mb-2">Run References</h3>

                              <div className="space-y-3">
                                <div>
                                  <h4 className="text-red-400 font-medium mb-1">Failed Runs ({blockReferences.failed.length})</h4>
                                  <div className="space-y-1">
                                    {blockReferences.failed.map((signature: FailureSignature) => (
                                      <div key={`${signature.sequence.runId}-${signature.attemptId}`} className="text-sm text-gray-300 hover:text-white">
                                        <a
                                          href={`https://app.qawolf.com/runs/${signature.sequence.runId}/attempt/${signature.attemptId}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="hover:text-white"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            window.open(
                                              `https://app.qawolf.com/runs/${signature.sequence.runId}/attempt/${signature.attemptId}`,
                                              '_blank',
                                            );
                                          }}
                                        >
                                          {signature.sequence.workflowName}
                                        </a>
                                      </div>
                                    ))}
                                  </div>
                                </div>

                                <div>
                                  <h4 className="text-green-400 font-medium mb-1">Passed Runs ({blockReferences.passed.length})</h4>
                                  <div className="space-y-1">
                                    {blockReferences.passed.map((signature: FailureSignature) => (
                                      <div key={`${signature.sequence.runId}-${signature.attemptId}`} className="text-sm text-gray-300 hover:text-white">
                                        <a
                                          href={`https://app.qawolf.com/runs/${signature.sequence.runId}/attempt/${signature.attemptId}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="hover:text-white"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            window.open(
                                              `https://app.qawolf.com/runs/${signature.sequence.runId}/attempt/${signature.attemptId}`,
                                              '_blank',
                                            );
                                          }}
                                        >
                                          {signature.sequence.workflowName}
                                        </a>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))
          ) : (
            <div className="text-gray-400 text-center py-8">No building blocks found</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
