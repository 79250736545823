import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { sendPostRequest } from '../../utils/network';
import { getFilterValues } from '../Tasks/api';

// TODO: These types might need to get replaced from types elsewhere
export type User = {
  avatar48: string;
  email: string;
  qawId: string;
  name: string;
};

type Workflow = {
  envName: string;
  name: string;
};

type Team = {
  name: string;
};

type ParentIssue = {
  name: string;
};

// TODO: Update type to be more precise
export type TaskMutationData = {
  assigneeId: string;
  assignedTo: User;
  childTasks: TaskMutationData[];
  dueAt: string;
  dueDate: string;
  id: string;
  notes: string;
  parentIssue: ParentIssue;
  priority: string;
  status: string;
  team: Team;
  type: string;
  workflow: Workflow;
};

// TODO: Temporary type until we add react-query types dependency
type TaskMutationResponse = {
  success: boolean;
  data?: any;
  jsonData?: any;
  error?: { response: any } | unknown;
};

// TODO: Refactor to be a universal hook; all of this logic is nearly identical in columns.js and Task
export function useSubmitReadyForReview({ onSuccess, onError }: { onSuccess: () => void; onError: () => void }) {
  const queryClient = useQueryClient();
  const queryKey = ['allTasks'];
  const { data } = useQuery({
    queryKey: ['filterValues'],
    queryFn: getFilterValues,
    placeholderData: { users: [] },
  });
  const { users } = data as { users: User[] };

  return useMutation<TaskMutationResponse, Error, TaskMutationData>({
    mutationKey: ['updateTask'],

    mutationFn: async (taskData) => {
      const response = await sendPostRequest('/update-task', taskData);
      return {
        success: true,
        data: response.data,
      };
    },
    onMutate: async (taskData) => {
      // Cancel any running queries to prevent race condition showing stale data
      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous data in case we get an error and need to revert
      const previousTasks = queryClient.getQueryData(queryKey);

      // Optimistically update the cache directly with the new data
      queryClient.setQueryData(queryKey, (cachedTasks: TaskMutationData[]) => {
        if (!cachedTasks) return cachedTasks;

        // if an assigneeId is provided, construct the new assignedTo object
        const newAssignedTo = taskData.assigneeId && {
          ...users.find((user) => user.qawId === taskData.assigneeId),
          id: taskData.assigneeId,
          avatarUrl: users.find((user) => user.qawId === taskData.assigneeId)?.avatar48,
        };

        // map over the cached tasks and update the ones that are being updated
        return cachedTasks.map((task) => {
          // check if the current task is the one we need to update
          let isTaskToUpdate = taskData.id === task.id;

          // if yes, let's update it
          if (isTaskToUpdate) {
            return {
              ...task,
              status: taskData.status ?? task.status,
              dueAt: taskData.dueDate ? `${taskData.dueDate}T00:00:00.000Z` : task.dueAt,
              assignedTo: taskData.assigneeId ? newAssignedTo : task.assignedTo,
              notes: taskData.notes ?? task.notes,
              priority: taskData.priority ?? task.priority,
            };
          }

          // if not, this is probably a child task. we need to find the child task in the cache to update it
          // if this task has children, let's check if any of them are the one we need to update
          if (!isTaskToUpdate && task.childTasks?.length) {
            return {
              ...task,
              childTasks: task.childTasks.map((childTask) => {
                // check if the current child task is the one we need to update
                isTaskToUpdate = taskData.id === childTask.id;

                // if yes, let's update it
                if (isTaskToUpdate) {
                  return {
                    ...childTask,
                    status: taskData.status ?? childTask.status,
                    dueAt: taskData.dueDate ? `${taskData.dueDate}T00:00:00.000Z` : childTask.dueAt,
                    assignedTo: taskData.assigneeId ? newAssignedTo : childTask.assignedTo,
                    notes: taskData.notes ?? childTask.notes,
                    priority: taskData.priority ?? childTask.priority,
                  };
                }

                // if not, this task is not the one we're updating. We want to return it unchanged
                return childTask;
              }),
            };
          }

          // if we reach here, this task is not one that we're updating. We want to return it unchanged
          return task;
        });
      });

      // return the previous tasks so we can revert if there's an error
      // if we ever want to implement a 'retry' button, we can add the new values to the object here and
      // access them from the context object in the onError function
      return { previousTasks };
    },
    onError: (err, _, context) => {
      // TODO: Update this when proper types are added
      console.error('Ready for review task update failed', err);

      // Revert the cache to the previous state
      queryClient.setQueryData(queryKey, (context as { previousTasks: TaskMutationData[] }).previousTasks);
      onError();
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
      onSuccess();
    },
  });
}
