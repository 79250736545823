import React, { useEffect, useState, useCallback } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  NetworkReport,
  AllTracesItem,
  OperationSummaryItem,
  ArrayOfMinutesXItem,
  ArrayOfMinutesYItem,
  ShortOperationSummaryItem,
  FailedAttemptsSummary,
  Score,
} from './typesForSuiteNetworkReport';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell, ReferenceLine, Label } from 'recharts';
import WolfLoader from '../WolfLoader/WolfLoader';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Papa from 'papaparse';
import FileSaver from 'file-saver';
import { useGenerateImage } from './useGenerateImage'; // Adjust the path as necessary
import { useParams, useNavigate } from 'react-router-dom'; // Import useParams
import { ClipboardIcon } from '@heroicons/react/24/outline';
import { sendTSPostRequest, sendTSPollRequest, PollJobResponse } from '@/utils/tanstackNetwork';
import OperationsSummaryRows from './components/OperationsSummaryRows';
import { AxiosError } from 'axios';

export default function SuiteNetworkReport() {
  const { suiteId: suiteIdFromUrl } = useParams(); // Get suiteId from URL
  const [suiteId, setSuiteId] = useState(suiteIdFromUrl || ''); // Initialize with suiteId from URL if available
  const [suiteUrl, setSuiteUrl] = useState('');
  const [startTimeOfFirstOperation, setStartTimeOfFirstOperation] = useState('');
  const [arrayOfMinutesX, setArrayOfMinutesX] = useState<ArrayOfMinutesXItem[]>([]);
  const [arrayOfMinutesY, setArrayOfMinutesY] = useState<ArrayOfMinutesYItem[]>([]);
  const [waterfallChartData, setWaterfallChartData] = useState<AllTracesItem[] | undefined>();
  const [displayData, setDisplayData] = useState<AllTracesItem[] | undefined>();
  const [allOperationsSummary, setAllOperationsSummary] = useState<OperationSummaryItem[]>();
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isChartLoading, setIsChartLoading] = useState(true);
  const [operationsPerMinute, setOperationsPerMinute] = useState<ShortOperationSummaryItem | null>(null);
  const [selectedOperations, setSelectedOperations] = useState<OperationSummaryItem[]>([]);
  const [hoveredBarIndex, setHoveredBarIndex] = useState<number | null>(null);
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);
  const [showOrange, setShowOrange] = useState(false);
  const [showRed, setShowRed] = useState(false);
  const [selectedBarIndex, setSelectedBarIndex] = useState<number | null>(null);
  const [failedAttemptsSummary, setFailedAttemptsSummary] = useState<FailedAttemptsSummary | null>(null);
  const [containerHeight, setContainerHeight] = useState(1000);
  const [isWaterFallHeightToggleLoading, setIsWaterFallHeightToggleLoading] = useState(false);
  const [showPassingAttempts, setShowPassingAttempts] = useState(false);
  const [score, setScore] = useState<Score | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false); // State to control alert visibility
  const [status, setStatus] = useState<'idle' | 'pending' | 'success' | 'error'>('idle');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [showOnlyFailureCausing, setShowOnlyFailureCausing] = useState(false);

  const [getDivJpeg, { ref, isLoading }] = useGenerateImage<HTMLDivElement>({
    quality: 0.8,
    type: 'image/jpeg',
  });

  const handleDivDownload = useCallback(async () => {
    const jpeg = await getDivJpeg();
    if (jpeg) {
      FileSaver.saveAs(jpeg, 'chart-container.jpeg');
    }
  }, [getDivJpeg]);

  // Check for existing results
  const checkForExistingReport = useMutation<NetworkReport, Error, { suiteId: string }>({
    mutationKey: ['checkForExistingReport', suiteId],
    mutationFn: ({ suiteId }: { suiteId: string }) =>
      sendTSPostRequest(`/suite-network-report/getNetworkReport`, {
        suiteId,
      }),
    onSuccess: (data) => {
      if (data.message?.includes('no relevant attempts were found.')) {
        // check if report was already generated
        setStatus('error');
        setError(data.message);
      } else if (data.jobId) {
        // job was queued
        setStatus('pending');
      } else {
        setSuiteUrl(data.suiteUrl);
        setDisplayData(data.allTraces || []);
        setScore(data.score);
        setArrayOfMinutesX(data.arrayOfMinutesX);
        setArrayOfMinutesY(data.arrayOfMinutesY);
        setAllOperationsSummary(data.allOperationsSummary);
        setStartTimeOfFirstOperation(data.startTimeOfFirstOperation);
        setFailedAttemptsSummary(data.failedAttemptsSummary);
        setStatus('success');
      }
    },
    onError: (error) => {
      setStatus('error');
      setError(error instanceof AxiosError ? error.response?.data?.error || error.message : error.message);
    },
  });

  // poll for job status
  const pollScheduleJobQuery = useQuery<PollJobResponse<string>>({
    queryKey: ['pollJob', 'suiteNetworkReportJob', suiteId, checkForExistingReport.data?.jobId],
    queryFn: () => {
      if (!checkForExistingReport.data?.jobId) throw new Error('No job ID available');
      return sendTSPollRequest(checkForExistingReport.data.jobId);
    },
    enabled: checkForExistingReport.isSuccess && checkForExistingReport.data.jobId !== undefined,
    refetchInterval: (query) => {
      // if the job is not completed, poll every 5 seconds
      const data = query.state.data;

      if (data?.success === true) {
        return ['completed', 'failed'].includes(data.jobStatus) ? false : 5000;
      }
    },
  });

  // get the results of the report
  const fetchReportResults = useQuery({
    queryKey: ['finalData', suiteId],
    queryFn: () =>
      sendTSPostRequest(`/suite-network-report/getNetworkReport`, {
        suiteId,
      }),
    enabled: false, // Don't run automatically
  });

  // once the job is finished then query the results
  useEffect(() => {
    const data = pollScheduleJobQuery.data;
    if (data?.success && data.jobStatus === 'completed') {
      // Trigger the final data fetch
      fetchReportResults
        .refetch()
        .then((result) => {
          if (result.data) {
            const reportData = result.data;
            setSuiteUrl(reportData.suiteUrl);
            setDisplayData(reportData.allTraces);
            setScore(reportData.score);
            setArrayOfMinutesX(reportData.arrayOfMinutesX);
            setArrayOfMinutesY(reportData.arrayOfMinutesY);
            setAllOperationsSummary(reportData.allOperationsSummary);
            setStartTimeOfFirstOperation(reportData.startTimeOfFirstOperation);
            setFailedAttemptsSummary(reportData.failedAttemptsSummary);
            setStatus('success');
          }
        })
        .catch((error) => {
          setStatus('error');
          setError('Failed to fetch final data');
          console.error('Error fetching final data:', error);
        });
    } else if (data?.success === false) {
      setStatus('error');
      setError('Failed to generate report after completing job');
    }
  }, [pollScheduleJobQuery.data]);

  // Automatically generate report if suiteId is present in URL
  useEffect(() => {
    if (suiteIdFromUrl && status === 'idle') {
      setStatus('pending');
      checkForExistingReport.mutate({ suiteId: suiteIdFromUrl });
    }
  }, [suiteIdFromUrl]);

  // Timer to update the elapsed time
  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (status === 'pending') {
      timer = setInterval(() => {
        setElapsedTime((prev) => prev + 1);
      }, 1000);
    } else {
      setElapsedTime(0);
    }
    return () => clearInterval(timer);
  }, [status]);

  // Timer to measure the chart render time
  useEffect(() => {
    setIsChartLoading(true);
    if (displayData) {
      setWaterfallChartData(displayData); // Set waterfallChartData to transformedData on initial load
      requestAnimationFrame(() => {
        performance.mark('chart-render-start');
        const timer = setTimeout(() => {
          performance.mark('chart-render-end');
          try {
            performance.measure('chart-render', 'chart-render-start', 'chart-render-end');
          } catch (error) {
            console.error('Performance measure error:', error);
          }
          performance.clearMarks('chart-render-start');
          performance.clearMarks('chart-render-end');
          performance.clearMeasures('chart-render');
          setIsChartLoading(false);
        }, 2000);
        return () => clearTimeout(timer);
      });
    }
  }, [displayData]);

  // Function to format the elapsed time
  const formatElapsedTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins} min ${secs < 10 ? '0' : ''}${secs} sec`;
  };

  // Function to handle the input change
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSuiteId(event.target.value.trim());
  };

  // Function to handle the form submission
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (status === 'pending') return; // Already fetching

    // Avoid double-submit if same ID as in URL and already fetched once
    if (suiteId === suiteIdFromUrl && status !== 'idle') return;

    setStatus('pending');
    checkForExistingReport.mutate({ suiteId });
    navigate(`/suite-network-report/${suiteId}`);
  };

  const toggleRow = (index: number) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const downloadCSV = () => {
    const dataToExport = selectedOperations.length > 0 ? selectedOperations : allOperationsSummary || [];
    const csvData = dataToExport
      .map((operation) => {
        const baseData = {
          'Operation Name': operation.operationName,
          'Total Calls For Operation': operation.totalCallsForOperation,
          'Workflows That Called It': operation.totalWorkflowsCallingOperation,
          'Attempt Retries': operation.totalReAttempts,
          'Attempt Failures': operation.totalFailedAttempts,
          'Call Failures': operation.totalFailedCalls,
          'Call Duration 2+ sec': operation.totalCallsLongerThan2,
          'Call Duration 30+ sec': operation.totalCallsLongerThan30,
        };

        // Add expanded data for each workflow
        const expandedData = operation.workflowsCallingOperation
          .map((workflow) => {
            return workflow.attempts.map((attempt) => ({
              ...baseData,
              'Workflow Name': workflow.workflowName,
              'Attempt ID': attempt.attemptId,
              'Attempt URL': attempt.attemptUrl.toString(),
              'Attempt Number': attempt.attemptNumber,
              'Attempt Status': attempt.attemptStatus,
            }));
          })
          .flat();

        return expandedData;
      })
      .flat();

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'operations_summary.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const uniqueTraceRefLines = new Set();

  const [tickArray, setTickArray] = useState<number[]>([0]);
  const [graphDomain, setGraphDomain] = useState<[number, number] | undefined>([tickArray[0], tickArray[tickArray.length - 1]]);
  function setTickArrayAndGraphDomain() {
    if (!arrayOfMinutesY) {
      return; // Exit the function if arrayOfMinutesY is undefined
    }

    const initialTickArray = [];
    for (let i = 0; i < arrayOfMinutesY.length + 1; i++) {
      initialTickArray.push(i * 60000);
    }
    setTickArray([...initialTickArray]);
    setGraphDomain([initialTickArray[0], initialTickArray[initialTickArray.length - 1]]);
  }

  useEffect(() => {
    setTickArrayAndGraphDomain();
  }, [arrayOfMinutesY]);

  const toggleHeight = () => {
    setIsWaterFallHeightToggleLoading(true);
    setTimeout(() => {
      setContainerHeight((prevHeight) => (prevHeight === 1000 ? 9000 : 1000));
      setIsWaterFallHeightToggleLoading(false);
    }, 700);
  };

  const handleCopyUrl = async () => {
    try {
      const currentUrl = window.location.href;
      await navigator.clipboard.writeText(currentUrl);
      setAlertVisible(true); // Show alert
      setTimeout(() => setAlertVisible(false), 3000); // Hide alert after 3 seconds
    } catch (err) {
      console.error('Failed to copy URL: ', err);
    }
  };

  const filterOperations = (operations: OperationSummaryItem[]) => {
    if (!showOnlyFailureCausing) return operations;

    return operations.filter((operation) =>
      operation.workflowsCallingOperation.some((workflow) =>
        workflow.attempts.some((attempt) => attempt.causedAnAttemptToFailFailedTrace || attempt.causedAnAttemptToFailOver30Seconds),
      ),
    );
  };

  return (
    <div>
      {alertVisible && (
        <div
          className="alert alert-success fixed m-4 p-2 bg-green-500 text-white rounded"
          style={{
            top: '1%',
            left: '17%',
            transform: 'translate(50%, -50%)',
            zIndex: 1000, // Ensure it's above other elements
          }}
        >
          URL copied to clipboard!
        </div>
      )}
      <div className="chart-container" ref={ref}>
        <div style={{ display: 'flex' }}>
          <div className="p-4" style={{ width: '20%' }}>
            <div className="flex flex-row">
              <h1 className="mb-4 text-2xl font-bold">Suite Network Report</h1>
              <ClipboardIcon
                title="Copy URL to clipboard"
                className="h-7 w-7 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded ml-2 p-1.5"
                onClick={handleCopyUrl}
              />
            </div>
            <form onSubmit={handleSubmit} className="flex flex-col gap-2" style={{ width: '100%' }}>
              <input type="text" value={suiteId} onChange={handleInputChange} placeholder="Enter Suite ID" />
              <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Generate Report
              </button>
            </form>
          </div>
          {status === 'success' && displayData && (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div className="flex flex-col items-center border border-gray-500 p-5 m-2">
                <h1 className="text-1xl font-bold text-gray-500">Score</h1>
                <p
                  style={{
                    fontSize: '40px',
                    color:
                      typeof score?.precentOfMinutesWithPassing === 'number'
                        ? score.precentOfMinutesWithPassing >= 90
                          ? 'green'
                          : score.precentOfMinutesWithPassing >= 70
                          ? 'orange'
                          : 'red'
                        : 'black', // Default color if the value is not a number
                  }}
                >
                  {`${score?.precentOfMinutesWithPassing?.toFixed(1) ?? 'N/A'}%`}
                </p>
                <p onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="cursor-pointer text-blue-500 flex items-center">
                  How is this score calculated?
                  <span
                    style={{
                      marginLeft: '5px',
                      transform: isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: 'transform 0.3s',
                    }}
                  >
                    ▼
                  </span>
                </p>
                {isDropdownOpen && (
                  <div className="mt-2 p-2 border border-gray-300 bg-gray-100">
                    <p>
                      The color of each minute depends whichever of these is true first( each bar in the chart is an operation ):
                      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li style={{ marginBottom: '5px', color: 'purple' }}>
                          If the number of failed operations that caused an attempt to fail is greater than 4% then the minute will be purple.
                        </li>
                        <li style={{ marginBottom: '5px', color: 'red' }}>
                          If the number of operations that caused an attempt to fail and took more than 30 seconds is greater than 4% then the minute
                          will be red.
                        </li>
                        <li style={{ marginBottom: '5px', color: 'blue' }}>
                          If the number of failed operations that did not cause an attempt to fail is greater than 4% then the minute will be blue.
                        </li>
                        <li style={{ marginBottom: '5px', color: '#ff007b' }}>
                          If the number of operations that did not cause an attempt to fail and took more than 30 seconds is greater than 4% then the
                          minute will be pink.
                        </li>
                        <li style={{ marginBottom: '5px', color: 'orange' }}>
                          If the number of operations that took more than 2 seconds is greater than 4% then the minute will be yellow(mustard).
                        </li>
                        <li style={{ marginBottom: '5px', color: 'green' }}>If none of the above are true then the minute will be green.</li>
                      </ul>
                      The score you see above is the percentage of minutes that are green.
                    </p>
                  </div>
                )}
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column' }}>
                <div className="flex flex-col gap-2 items-center border border-gray-500 p-1">
                  <p className="font-bold text-gray-500">
                    Customer: {suiteUrl.split('/')[3]?.charAt(0).toUpperCase() + suiteUrl.split('/')[3]?.slice(1)}
                  </p>
                  <a
                    href={process.env.PUBLIC_URL + '/flow-explorer/' + suiteId}
                    className="text-blue-500 hover:underline flex items-center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Open this suite in Flow Explorer
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 ml-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                      />
                    </svg>
                  </a>
                  <a href={suiteUrl} className="text-blue-500 hover:underline flex items-center" target="_blank" rel="noopener noreferrer">
                    Open this suite in QA Wolf
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 ml-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                      />
                    </svg>
                  </a>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid black', padding: 10, marginTop: 10 }}>
                  <p className="mb-1 text-gray-500">Start Time Of First Operation: {new Date(startTimeOfFirstOperation).toLocaleString()}</p>
                </div>
              </div>
              <div style={{ padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {operationsPerMinute ? (
                  <div className="ml-4 mt-4 inline-block border border-black p-4 max-w-fit text-gray-500">
                    <h3 className="font-bold">Ops/Endpoints Per Minute</h3>
                    <p>{`Time: ${operationsPerMinute.time}`}</p>
                    <p>{`Total Calls: ${operationsPerMinute.totalCalls}`}</p>
                    <p>{`Total Failed Operations: ${operationsPerMinute.totalFailedOperations}`}</p>
                    <p>{`Total Operations 2+ sec: ${operationsPerMinute.totalOperations2OrOver}`}</p>
                    <p>{`Total Operations 30+ sec: ${operationsPerMinute.totalOperations30OrOver}`}</p>
                  </div>
                ) : (
                  <h3>Hover over a time bar to see Ops/Endpoints per minute</h3>
                )}
              </div>
              <div style={{ padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="ml-4 mt-4 inline-block border border-black p-4 max-w-fit text-gray-500">
                  <h3 className="font-bold">Failed Attempts Summary</h3>
                  <p>{`Total Failed Attempts: ${failedAttemptsSummary?.totalFailedAttempts}`}</p>
                  <p>{`Total Failed Benign 1st Attempts: ${failedAttemptsSummary?.totalFailedBenign1stAttempts}`}</p>
                  <p>{`Total Failed Benign 2nd Attempts: ${failedAttemptsSummary?.totalFailedBenign2ndAttempts}`}</p>
                  <p>{`Total Failed Caused 1st Attempts: ${failedAttemptsSummary?.totalFailedCaused1stAttempts}`}</p>
                  <p>{`Total Failed Caused 2nd Attempts: ${failedAttemptsSummary?.totalFailedCaused2ndAttempts}`}</p>
                </div>
              </div>
            </div>
          )}
        </div>
        {status === 'pending' && (
          <>
            <div className="p-10">
              <WolfLoader customStyles={{ height: '50px', width: '100px' }} customText="" />
            </div>
            <div className="text-blue-500 font-bold ml-4">
              {pollScheduleJobQuery.isSuccess && pollScheduleJobQuery.data?.success && pollScheduleJobQuery.data?.jobStatus === 'completed' ? (
                <>Report generated! Loading data...</>
              ) : (
                <>
                  Generating report for Suite ID: {suiteId}. This usually takes 3-5 minutes for a suite with 150 workflows.
                  <br />
                  <p className="text-gray-500">Elapsed Time: {formatElapsedTime(elapsedTime)}</p>
                </>
              )}
            </div>
          </>
        )}
        {error && <div className="text-red-500 font-bold m-5">Error generating report: {error}</div>}
        {!displayData && status !== 'pending' && !error && (
          <div className="text-gray-500 font-bold m-5">No data available. Please enter a Suite ID and submit to load the report.</div>
        )}
        {status !== 'pending' && displayData && !error && (
          <>
            {isChartLoading ? (
              <div className="flex justify-center items-center flex-col p-10">
                <WolfLoader customStyles={{ height: '50px', width: '100px' }} customText="" />
                <div className="text-blue-500 font-bold mt-10">Rendering Chart...</div>
              </div>
            ) : (
              <>
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-4 mt-2 mr-4"
                  onClick={() => {
                    setIsChartLoading(true);
                    setWaterfallChartData(displayData);
                    setSelectedOperations([]); // Clear selected operations
                    setTickArrayAndGraphDomain();
                    setHoveredBarIndex(null);
                    setSelectedBarIndex(null);
                    setIsChartLoading(false);
                  }}
                >
                  See All Operations In Suite
                </button>
                <button
                  onClick={handleDivDownload}
                  disabled={isLoading}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4 mr-4"
                >
                  {isLoading ? 'Generating...' : 'Download Chart Screenshot'}
                </button>
                <button
                  onClick={toggleHeight}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
                  disabled={isWaterFallHeightToggleLoading}
                >
                  {isWaterFallHeightToggleLoading ? 'Loading...' : 'Toggle Chart Height'}
                </button>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'stretch',
                    marginLeft: '6.4%',
                    marginRight: '1.5%',
                    alignContent: 'stretch',
                    padding: 0,
                  }}
                >
                  <ResponsiveContainer width="100%" height={60}>
                    <BarChart layout="vertical" data={arrayOfMinutesX}>
                      <XAxis type="number" dataKey="value" domain={[0, 100]} hide={true} />
                      <YAxis type="category" dataKey="time" hide={true} />
                      {arrayOfMinutesY.map((minute, minuteIdx) => {
                        return (
                          <Bar
                            key={minuteIdx}
                            dataKey={minute.time}
                            stackId="a"
                            fill={minute.fill}
                            onMouseOver={() => {
                              setHoveredBarIndex(minuteIdx); // Update the hovered bar index
                              setOperationsPerMinute({
                                time: minute.time,
                                totalCalls: minute.totalCalls,
                                totalFailedOperations: minute.totalFailedOperations,
                                totalOperations2OrOver: minute.totalOperations2OrOver,
                                totalOperations30OrOver: minute.totalOperations30OrOver,
                              });
                            }}
                            onMouseOut={() => setHoveredBarIndex(null)} // Reset the hovered bar index
                            onClick={() => {
                              setIsChartLoading(true);

                              setWaterfallChartData(minute.traces);
                              setSelectedOperations(minute.operations); // Update selected operations

                              setTickArray([minute.startTimeOfFirstMin, minute.endTimeOfLastMin]);
                              setGraphDomain([minute.startTimeOfFirstMin, minute.endTimeOfLastMin]);
                              setSelectedBarIndex(minuteIdx); // Set the selected bar index
                              setHoveredBarIndex(null);
                              setTimeout(() => {
                                setIsChartLoading(false);
                              }, 3000); // Adjust the delay as needed
                            }}
                          >
                            <Cell
                              fill={minuteIdx === hoveredBarIndex ? '#3B82F6' : minute.fill} // Change fill on hover
                              stroke={minuteIdx === selectedBarIndex ? '#3B82F6' : 'none'} // Add outline on hover
                              strokeWidth={minuteIdx === selectedBarIndex ? 4 : 0} // Define outline width
                            />
                          </Bar>
                        );
                      })}
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                {waterfallChartData && waterfallChartData.length > 0 ? (
                  <>
                    <ResponsiveContainer width="100%" height={containerHeight}>
                      <BarChart data={waterfallChartData} layout="vertical" margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          domain={graphDomain}
                          type="number"
                          tickFormatter={(tick) => `${(tick / 1000) % 1 === 0 ? tick / 1000 : (tick / 1000).toFixed(2)} seconds`}
                          ticks={tickArray}
                        />
                        <YAxis
                          dataKey="traceStartedDateTime"
                          type="category"
                          tickFormatter={(tick) => new Date(tick).toLocaleTimeString()} // Format the date/time
                          width={100} // Adjust the width as needed
                          reversed={true}
                        />
                        <Tooltip
                          content={({ active, payload }) => {
                            if (active && payload && payload.length) {
                              const data = payload[0].payload;
                              return (
                                <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
                                  <p>{`Op Name: ${data.operationName}`}</p>
                                  <p>{`Op Start Time: ${new Date(data.traceStartedDateTime).toLocaleTimeString()}`}</p>
                                  <p>{`Op Duration: ${
                                    data.traceDurationInMs === -1
                                      ? 'Errored out'
                                      : data.traceDurationInMs > 999
                                      ? (data.traceDurationInMs / 1000).toFixed(2) + ' sec'
                                      : data.traceDurationInMs + ' ms'
                                  }`}</p>
                                  <p>{`Attempt Number: ${data.attemptNumber}`}</p>
                                  <p>{`Attempt URL: ${data.attemptUrl}`}</p>
                                  <p>{`Attempt Status: ${data.attemptStatus}`}</p>
                                  <p>{`Attempt End Time: ${new Date(data.attemptEndedAt).toLocaleTimeString()}`}</p>
                                  <p>{`Caused An Attempt To Fail: ${
                                    data.causedAnAttemptToFailFailedTrace || data.causedAnAttemptToFailOver30Seconds ? 'Yes' : 'No'
                                  }`}</p>
                                </div>
                              );
                            }
                            return null;
                          }}
                        />
                        <Legend
                          content={() => (
                            <ul style={{ listStyleType: 'none', padding: 0 }}>
                              <li>
                                <span style={{ backgroundColor: 'green', width: 20, height: 20, display: 'inline-block', marginRight: 5 }}></span>
                                <span style={{ marginRight: 30 }}>0.5 - 2 sec</span>
                                <span style={{ backgroundColor: '#d4870b', width: 20, height: 20, display: 'inline-block', marginRight: 5 }}></span>
                                <span style={{ marginRight: 30 }}>2 - 30 sec</span>
                                <span style={{ backgroundColor: 'red', width: 20, height: 20, display: 'inline-block', marginRight: 5 }}></span>
                                <span style={{ marginRight: 30 }}>30 sec or greater( Caused An Attempt To Fail )</span>
                                <span style={{ backgroundColor: 'purple', width: 20, height: 20, display: 'inline-block', marginRight: 5 }}></span>
                                <span style={{ marginRight: 30 }}>Failed Request That Caused An Attempt To Fail</span>
                              </li>
                              <li className="mt-2">
                                <span style={{ backgroundColor: 'pink', width: 20, height: 20, display: 'inline-block', marginRight: 5 }}></span>
                                <span style={{ marginRight: 30 }}>30 sec or greater( Did Not Cause An Attempt To Fail )</span>
                                <span style={{ backgroundColor: 'blue', width: 20, height: 20, display: 'inline-block', marginRight: 5 }}></span>
                                <span style={{ marginRight: 30 }}>Failed Request That Did Not Cause An Attempt To Fail</span>
                              </li>
                            </ul>
                          )}
                        />
                        {waterfallChartData?.map((traceRefLine, index) => {
                          const uniqueKey = `${traceRefLine.attemptId}-${traceRefLine.attemptStatus}-${traceRefLine.attemptNumber}`;
                          if (traceRefLine.attemptStatus === 'fail' && !uniqueTraceRefLines.has(uniqueKey)) {
                            uniqueTraceRefLines.add(uniqueKey);

                            let strokeColor;
                            switch (traceRefLine.attemptNumber) {
                              case 1:
                                strokeColor = 'orange';
                                break;
                              case 2:
                                strokeColor = 'red';
                                break;
                            }

                            const shouldRender = (strokeColor === 'orange' && showOrange) || (strokeColor === 'red' && showRed);

                            if (shouldRender) {
                              return (
                                <ReferenceLine
                                  key={`ref-line-${index}`}
                                  x={traceRefLine.attemptEndedAtRefPoint}
                                  stroke={strokeColor}
                                  strokeDasharray={
                                    traceRefLine.causedAnAttemptToFailFailedTrace || traceRefLine.causedAnAttemptToFailOver30Seconds ? '0' : '3 3'
                                  }
                                  label={
                                    <Label position="top" fill={strokeColor} fontSize={12} offset={10}>
                                      {traceRefLine.attemptNumber === 1 ? '1st' : '2nd'}
                                    </Label>
                                  }
                                />
                              );
                            }
                          }
                          return null;
                        })}
                        <Bar dataKey="duration" isAnimationActive={false}>
                          {waterfallChartData?.map((trace, index) => {
                            if (trace.traceDurationInMs > 0 && trace.traceDurationInMs < 500) {
                              return <Cell key={`cell-${index}`} fill="transparent" />;
                            } else {
                              return <Cell key={`cell-${index}`} fill={trace.color} />;
                            }
                          })}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>

                    <div className="toggle-buttons flex flex-row gap-2 m-4">
                      <label>
                        <input className="mr-2" type="checkbox" checked={showOrange} onChange={() => setShowOrange(!showOrange)} />
                        Show 1st Attempt Failures
                      </label>
                      <label>
                        <input className="mr-2" type="checkbox" checked={showRed} onChange={() => setShowRed(!showRed)} />
                        Show 2nd Attempt Failures
                      </label>
                      <div>
                        <span style={{ marginRight: 5, marginLeft: 30, fontWeight: 'bold' }}>- - -</span>
                        <span style={{ marginRight: 30 }}>
                          Attempt Failures Likely <strong>Not</strong> Caused By An Operation
                        </span>
                        <span style={{ marginRight: 5, fontWeight: 'bold' }}>━</span>
                        <span style={{ marginRight: 30 }}>
                          Attempt Failures <strong>Likely</strong> Caused By An Operation
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="p-4">
                    {waterfallChartData && waterfallChartData.length === 0 && (
                      <div className="text-gray-500 font-bold mt-4">Full Suite chart cannot be displayed - too many data requests</div>
                    )}
                    <div>
                      <p className="pt-4 pb-2">
                        Each bar represents one minute. The bar's color indicates the health of the suite during that minute, based on the percentage
                        of operations that were:
                      </p>
                      <ul style={{ listStyleType: 'none', padding: 0 }}>
                        <li>
                          <span style={{ backgroundColor: 'green', width: 20, height: 20, display: 'inline-block', marginRight: 5 }}></span>
                          <span style={{ marginRight: 30 }}>0.5 - 2 sec</span>
                          <span style={{ backgroundColor: '#d4870b', width: 20, height: 20, display: 'inline-block', marginRight: 5 }}></span>
                          <span style={{ marginRight: 30 }}>2 - 30 sec</span>
                          <span style={{ backgroundColor: 'red', width: 20, height: 20, display: 'inline-block', marginRight: 5 }}></span>
                          <span style={{ marginRight: 30 }}>30 sec or greater( Caused An Attempt To Fail )</span>
                          <span style={{ backgroundColor: 'purple', width: 20, height: 20, display: 'inline-block', marginRight: 5 }}></span>
                          <span style={{ marginRight: 30 }}>Failed Request That Caused An Attempt To Fail</span>
                        </li>
                        <li className="mt-2">
                          <span style={{ backgroundColor: 'pink', width: 20, height: 20, display: 'inline-block', marginRight: 5 }}></span>
                          <span style={{ marginRight: 30 }}>30 sec or greater( Did Not Cause An Attempt To Fail )</span>
                          <span style={{ backgroundColor: 'blue', width: 20, height: 20, display: 'inline-block', marginRight: 5 }}></span>
                          <span style={{ marginRight: 30 }}>Failed Request That Did Not Cause An Attempt To Fail</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
      {status !== 'pending' && displayData && !error && (
        <div className="pt-2 mt-4">
          <p className="text-lg font-bold mb-2 text-lg text-center">
            {selectedOperations.length > 0
              ? `Operation/Endpoint Summary For Minute ${operationsPerMinute?.time}`
              : 'Operation/Endpoint Summary For Whole Suite'}
          </p>
          <div className="flex h-15">
            <label className="flex items-center m-2">
              <input type="checkbox" checked={showPassingAttempts} onChange={() => setShowPassingAttempts(!showPassingAttempts)} className="mr-2" />
              Show Passing Attempts
            </label>
            <label className="flex items-center m-2">
              <input
                type="checkbox"
                checked={showOnlyFailureCausing}
                onChange={() => setShowOnlyFailureCausing(!showOnlyFailureCausing)}
                className="mr-2"
              />
              Only Show Ops/Endpoints That Caused Failures
            </label>
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold rounded p-2 m-2" onClick={downloadCSV}>
              Download CSV Of Summary Table
            </button>
          </div>
          <table className="min-w-full bg-white border border-gray-200 text-xs">
            <thead>
              <tr>
                <th className="px-2 py-1 border-b border-gray-200 tooltip relative text-left">Op/Endpoint Name</th>
                <th className="px-2 py-1 border-b border-gray-200 tooltip relative text-left">Total Calls For Op/Endpoint</th>
                <th className="px-2 py-1 border-b border-gray-200 tooltip relative text-left">Workflows That Called It</th>
                <th className="px-2 py-1 border-b border-gray-200 tooltip relative text-left">Attempt Retries</th>
                <th className="px-2 py-1 border-b border-gray-200 tooltip relative text-left">Attempt Failures</th>
                <th className="px-2 py-1 border-b border-gray-200 tooltip relative text-left">Call Failures</th>
                <th className="px-2 py-1 border-b border-gray-200 tooltip relative text-left">Call Duration 2+ sec</th>
                <th className="px-2 py-1 border-b border-gray-200 tooltip relative text-left">Call Duration 30+ sec</th>
              </tr>
            </thead>
            <tbody>
              {selectedOperations.length > 0 ? (
                <OperationsSummaryRows
                  operations={filterOperations(selectedOperations)}
                  expandedRowIndex={expandedRowIndex}
                  toggleRow={toggleRow}
                  showPassingAttempts={showPassingAttempts}
                />
              ) : (
                allOperationsSummary && (
                  <OperationsSummaryRows
                    operations={filterOperations(allOperationsSummary)}
                    expandedRowIndex={expandedRowIndex}
                    toggleRow={toggleRow}
                    showPassingAttempts={showPassingAttempts}
                  />
                )
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
