import { useState } from 'react';

/**
 * Hook for managing selection state in the Failure Signature component
 * Tracks selected blocks, workflows, and sequence indices
 */
export const useSelection = () => {
  // Selected building block ID
  const [selectedBlock, setSelectedBlock] = useState<string | null>(null);

  // Selected workflow ID
  const [selectedWorkflow, setSelectedWorkflow] = useState<string | null>(null);

  // Index of the selected block in the workflow sequence
  const [selectedSequenceIndex, setSelectedSequenceIndex] = useState<number>(0);

  // Selected attempt
  const [selectedAttempt, setSelectedAttempt] = useState<{ index: number; id: string | null }>({ index: 0, id: null });

  return {
    selectedBlock,
    setSelectedBlock,
    selectedWorkflow,
    setSelectedWorkflow,
    selectedSequenceIndex,
    setSelectedSequenceIndex,
    selectedAttempt,
    setSelectedAttempt,
  };
};
