import { ArrowBack, Cancel, ExpandMore, Save, Edit } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, IconButton, List, Snackbar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Content, Inner, Header, Title, ScrollBox, StyledDrawer, SubDrawerBtn } from './subComponents';
import { DataCard } from './DataCard';
import SubMilestone from './SubMilestone';
import { getIsCurrent, getIsFuture, getRequiredVelocity } from './utils';
import dayjs, { Dayjs } from 'dayjs';
import { Milestone, ClientSummaryTableRow, MilestoneProgress } from '../types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { sendTSPutRequest } from '@/utils/tanstackNetwork';
import { usePlanningContext } from '@/components/context/PlanningContext';
import getSubMilestoneMetrics from '../PlanningHubTable/utils/getSubMilestoneMetrics';

export type UpdatedMilestoneValues = {
  name: string;
  notes: string;
  targetTestCount: number;
  startDate: Dayjs;
  externalDueDate: Dayjs;
  testVelocity: number;
  outlineVelocity: number;
  subMilestones: MilestoneProgress[];
  fullyOutlinedWeekNum: number;
};

export function MsDrawer({ milestone, closeParents, client }: { milestone: Milestone; closeParents: () => void; client: ClientSummaryTableRow }) {
  const queryClient = useQueryClient();
  const { user } = usePlanningContext();

  // Set the initial values for the milestone
  const initialMilestoneValues = {
    name: milestone.name,
    notes: milestone.notes,
    targetTestCount: milestone.targetTestCount,
    startDate: dayjs(milestone.startDate),
    externalDueDate: dayjs(milestone.externalDueDate),
    testVelocity: milestone.testVelocity,
    outlineVelocity:
      (milestone.targetTestCount - ((milestone.initialDraftCount ?? 0) + (milestone.initialTestCount ?? 0))) /
      (milestone.subMilestones.find((sm) => sm.targetOutlinedTests === milestone.targetTestCount)?.weekNumber ?? 1),
    subMilestones: milestone.subMilestones,
    fullyOutlinedWeekNum: milestone.subMilestones.find((sm) => sm.targetOutlinedTests === milestone.targetTestCount)?.weekNumber ?? 1,
  };

  // Set to open if it is the current milestone and there are no blockers
  const [isOpen, setIsOpen] = useState(client.activeMilestoneBlockers.length === 0 && getIsCurrent(milestone));
  const [isUpdating, setIsUpdating] = useState(false);
  const [updatedMilestoneValues, setUpdatedMilestoneValues] = useState(initialMilestoneValues);
  const [showResMsg, setShowResMsg] = useState(false);

  const toggleSelf = () => {
    setIsUpdating(false);
    setUpdatedMilestoneValues(initialMilestoneValues);
    setIsOpen((prev) => !prev);
  };

  const closeAll = () => {
    toggleSelf();
    closeParents();
  };

  const isManagerOrQaLeadOrDragonOrEric = user.isManager || client.qaLead.id === user.qawId || user.teamId === 34 || user.id === 9;

  // Determine if the milestone is current, future, or past
  const isCurrentMilestone = getIsCurrent(milestone);
  const isFutureMilestone = getIsFuture(milestone);
  const isPastMilestone = !isCurrentMilestone && !isFutureMilestone;

  // Determine which subMilestone weeks have already passed and which are remaining
  const pastWeeks = isFutureMilestone ? [] : updatedMilestoneValues.subMilestones.filter((sm) => dayjs().isAfter(dayjs(sm.dueDate)));
  const remainingWeeks = isFutureMilestone
    ? updatedMilestoneValues.subMilestones
    : updatedMilestoneValues.subMilestones.filter((sm) => dayjs(sm.dueDate).isAfter(dayjs().startOf('day')));

  const remainingOutlineWeekCount = isFutureMilestone
    ? updatedMilestoneValues.fullyOutlinedWeekNum
    : remainingWeeks.filter((sm) => sm.weekNumber <= updatedMilestoneValues.fullyOutlinedWeekNum).length || 1;

  // Calculate the required velocity and planned velocity for the milestone
  const totalActiveTestCount = client.activeTestCount;
  const requiredVelocity = getRequiredVelocity(updatedMilestoneValues.targetTestCount, totalActiveTestCount, remainingWeeks.length);
  const requiredOutlineVelocity = getRequiredVelocity(
    updatedMilestoneValues.targetTestCount,
    totalActiveTestCount + client.creation.draftTests,
    remainingOutlineWeekCount,
  );

  // Mutation to update the milestone
  const mutation = useMutation({
    mutationKey: ['updateMilestone'],
    mutationFn: ({ milestoneId, updatedMilestoneValues }: { milestoneId: string; updatedMilestoneValues: UpdatedMilestoneValues }) => {
      return sendTSPutRequest(`/milestones/${milestoneId}/update-milestone`, {
        updatedMilestoneValues: {
          ...updatedMilestoneValues,
          startDate: updatedMilestoneValues.startDate.toDate(),
          externalDueDate: updatedMilestoneValues.externalDueDate.toDate(),
          fullyOutlinedWeekNum: updatedMilestoneValues.fullyOutlinedWeekNum,
        },
      });
    },
    onSuccess: ({ milestone: updatedMilestone }: { milestone: Milestone }) => {
      // Refetch the data used for the expanded client data (includes all milestones, etc)
      queryClient.invalidateQueries({ queryKey: ['clientSummary', client.id] });

      // Update the activeMilestone in the cached table data
      // Note: there can be multiple query keys for the same table data. We can't invalidate the queries because that will force the table to reload entirely.
      const tableQueries = queryClient.getQueriesData<ClientSummaryTableRow[]>({ queryKey: ['clientSummaries'], exact: false });
      for (const [queryKey] of tableQueries) {
        queryClient.setQueriesData<ClientSummaryTableRow[]>({ queryKey }, (old) => {
          if (!old) return old;
          return old.map((clientRow) => {
            if (clientRow.id === updatedMilestone.customerId && clientRow.activeMilestone.id === updatedMilestone.id) {
              return {
                ...clientRow,
                activeMilestone: { ...clientRow.activeMilestone, ...updatedMilestone, weeksRemaining: updatedMilestone.subMilestones.length },
              };
            }
            return clientRow;
          });
        });
      }
      setShowResMsg(true);
      setIsUpdating(false);
    },
  });

  const handleUpdate = () => {
    mutation.mutate({
      milestoneId: milestone.id.toString(),
      updatedMilestoneValues: {
        ...updatedMilestoneValues,
        fullyOutlinedWeekNum: updatedMilestoneValues.fullyOutlinedWeekNum,
      },
    });
  };

  // Recalculate the outline targets for all weeks whenever a new fully outlined week is selected or any of the milestone values change
  useEffect(() => {
    const targetTestCount = updatedMilestoneValues.targetTestCount;
    const expectedStartingTestCount = milestone.previousMilestone?.targetTestCount || (milestone.initialTestCount ?? 0);
    const expectedNewOutlinedTestCount = targetTestCount - expectedStartingTestCount;

    const updatedFullyOutlinedWeekNum =
      updatedMilestoneValues.subMilestones.length < updatedMilestoneValues.fullyOutlinedWeekNum
        ? updatedMilestoneValues.subMilestones.length
        : updatedMilestoneValues.fullyOutlinedWeekNum;

    const newOutlineVelocity = expectedNewOutlinedTestCount / updatedFullyOutlinedWeekNum;

    setUpdatedMilestoneValues((prev) => {
      const updatedSubMilestones = [...prev.subMilestones];

      // Recalculate outline targets for all weeks
      for (let i = 0; i < updatedSubMilestones.length; i++) {
        const week = i + 1;
        updatedSubMilestones[i] = {
          ...updatedSubMilestones[i],
          targetOutlinedTests: Math.min(expectedStartingTestCount + Math.round(newOutlineVelocity * week), targetTestCount),
        };
      }

      // Ensure the last week meets the target test count
      updatedSubMilestones[updatedMilestoneValues.fullyOutlinedWeekNum - 1] = {
        ...updatedSubMilestones[updatedMilestoneValues.fullyOutlinedWeekNum - 1],
        targetOutlinedTests: targetTestCount,
      };

      return {
        ...prev,
        outlineVelocity: newOutlineVelocity,
        fullyOutlinedWeekNum: updatedFullyOutlinedWeekNum,
        subMilestones: updatedSubMilestones,
      };
    });
  }, [
    updatedMilestoneValues.fullyOutlinedWeekNum,
    updatedMilestoneValues.externalDueDate,
    updatedMilestoneValues.startDate,
    updatedMilestoneValues.targetTestCount,
    updatedMilestoneValues.testVelocity,
  ]);

  return (
    <Box>
      <SubDrawerBtn onClick={toggleSelf} text={`${milestone.name} ${isCurrentMilestone ? `(Current)` : ``}`} />
      <StyledDrawer anchor="right" open={isOpen} onClose={closeAll} variant="persistent">
        <Inner>
          <Header>
            <IconButton onClick={toggleSelf} disableRipple>
              <ArrowBack />
            </IconButton>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <Title text={`${client.name}`} />

              {/* Only display the update button for current and future milestones */}
              {!isPastMilestone &&
                isManagerOrQaLeadOrDragonOrEric &&
                (!isUpdating ? (
                  <Button
                    sx={{ minWidth: '100px' }}
                    onClick={() => {
                      setIsUpdating((prev) => !prev);
                      setUpdatedMilestoneValues(initialMilestoneValues);
                    }}
                    disableRipple
                    endIcon={<Edit />}
                    variant="outlined"
                    size="small"
                  >
                    Edit
                  </Button>
                ) : (
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', width: '100%', gap: 1 }}>
                    <Button
                      sx={{ minWidth: '100px' }}
                      onClick={() => {
                        setIsUpdating(false);
                        setUpdatedMilestoneValues(initialMilestoneValues);
                      }}
                      disabled={mutation.isPending}
                      disableRipple
                      endIcon={<Cancel />}
                      variant="outlined"
                      size="small"
                    >
                      Cancel
                    </Button>
                    <Button
                      sx={{ minWidth: '100px' }}
                      onClick={handleUpdate}
                      disableRipple
                      endIcon={<Save />}
                      variant="outlined"
                      size="small"
                      disabled={mutation.isPending}
                    >
                      Save
                    </Button>
                  </Box>
                ))}
            </Box>
          </Header>
          <Content sx={{ height: '90vh' }}>
            <ScrollBox sx={{ height: '100%' }}>
              <DataCard
                milestone={milestone}
                isCurrentMilestone={isCurrentMilestone}
                isFutureMilestone={isFutureMilestone}
                requiredVelocity={requiredVelocity}
                requiredOutlineVelocity={requiredOutlineVelocity}
                isUpdating={isUpdating}
                updatedMilestoneValues={updatedMilestoneValues}
                setUpdatedMilestoneValues={setUpdatedMilestoneValues}
                totalActiveTestCount={totalActiveTestCount}
                totalDraftTestCount={client.creation.draftTests}
              />
              <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', p: '.5em' }}>
                <Box sx={{ width: '100%', textAlign: 'center', mx: 0.5, display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography color="yellow.dark">◆</Typography>
                  <Typography sx={{ fontSize: '12px' }} variant="caption" color="gray.dark">
                    Fully outlined target
                  </Typography>
                </Box>
                <Divider orientation="vertical" flexItem />
                <Box sx={{ width: '100%', textAlign: 'center', mx: 0.5 }}>
                  <Typography variant="caption">Newly Outlined / Req'd</Typography>
                </Box>
                <Box sx={{ width: '100%', textAlign: 'center', mx: 0.5 }}>
                  <Typography variant="caption">Total Outlined / Expected</Typography>
                </Box>
                <Box sx={{ width: '100%', textAlign: 'center', mx: 0.5 }}>
                  <Typography variant="caption">Tests Created / Req'd</Typography>
                </Box>
                <Box sx={{ width: '100%', textAlign: 'center', mx: 0.5 }}>
                  <Typography variant="caption">Total Active / Expected</Typography>
                </Box>
              </Box>
              {!!pastWeeks.length && (
                <Accordion
                  sx={{
                    borderRadius: '4px',
                    '&:before': {
                      display: 'none', // Remove the top border
                    },
                  }}
                  disableGutters
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>Past Weeks</AccordionSummary>
                  <AccordionDetails sx={{ p: 0 }}>
                    {pastWeeks.map((sm, i) => {
                      const { requiredOutlineVelocity, requiredVelocity, testsAdded, outlinedTestsAdded } = getSubMilestoneMetrics(milestone, i);

                      return (
                        <Box
                          key={sm.id}
                          className="relative flex w-full"
                          sx={{
                            pl: 3,
                            cursor: isUpdating ? 'pointer' : 'default',
                            bgcolor: isUpdating && sm.weekNumber === updatedMilestoneValues.fullyOutlinedWeekNum ? 'rgba(0, 0, 0, 0.04)' : 'unset',
                            '&:hover': {
                              bgcolor: isUpdating ? 'rgba(0, 0, 0, 0.08)' : 'unset',
                            },
                            borderRadius: 1,
                          }}
                          onClick={() => {
                            if (isUpdating) {
                              setUpdatedMilestoneValues((prev) => ({ ...prev, fullyOutlinedWeekNum: sm.weekNumber }));
                            }
                          }}
                        >
                          {sm.weekNumber === updatedMilestoneValues.fullyOutlinedWeekNum && (
                            <Typography
                              sx={{
                                position: 'absolute',
                                left: 9,
                                top: '50%',
                                transform: 'translateY(-50%)',
                                color: 'yellow.dark',
                                zIndex: 1000,
                              }}
                            >
                              ◆
                            </Typography>
                          )}
                          <SubMilestone
                            key={sm.id}
                            subMilestone={sm}
                            testsAdded={testsAdded}
                            outlinedTestsAdded={outlinedTestsAdded}
                            requiredVelocity={requiredVelocity}
                            requiredOutlineVelocity={requiredOutlineVelocity}
                          />
                        </Box>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              )}
              <List>
                {remainingWeeks.map((sm, i) => {
                  const { requiredOutlineVelocity, requiredVelocity, testsAdded, outlinedTestsAdded, isCurrentWeek } = getSubMilestoneMetrics(
                    milestone,
                    i + pastWeeks.length,
                  );

                  return (
                    <Box
                      key={sm.id}
                      className="relative flex w-full"
                      sx={{
                        pl: 3,
                        cursor: isUpdating ? 'pointer' : 'default',
                        bgcolor:
                          isUpdating && sm.weekNumber === updatedMilestoneValues.fullyOutlinedWeekNum
                            ? 'rgba(0, 0, 0, 0.04)'
                            : isCurrentWeek
                            ? 'white'
                            : 'unset',
                        '&:hover': isUpdating
                          ? {
                              bgcolor: 'rgba(0, 0, 0, 0.08)',
                            }
                          : null,
                        borderRadius: 1,
                      }}
                      onClick={() => {
                        if (isUpdating) {
                          setUpdatedMilestoneValues((prev) => ({ ...prev, fullyOutlinedWeekNum: sm.weekNumber }));
                        }
                      }}
                    >
                      {sm.weekNumber === updatedMilestoneValues.fullyOutlinedWeekNum && (
                        <Typography
                          sx={{
                            position: 'absolute',
                            left: 9,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            color: 'yellow.dark',
                            zIndex: 1000,
                          }}
                        >
                          ◆
                        </Typography>
                      )}
                      <SubMilestone
                        subMilestone={sm}
                        testsAdded={testsAdded}
                        outlinedTestsAdded={outlinedTestsAdded}
                        requiredVelocity={requiredVelocity}
                        requiredOutlineVelocity={requiredOutlineVelocity}
                      />
                    </Box>
                  );
                })}
              </List>
            </ScrollBox>
          </Content>
        </Inner>
      </StyledDrawer>
      <Snackbar
        open={showResMsg}
        ContentProps={{ sx: { bgcolor: 'white', color: 'black' } }}
        autoHideDuration={3000}
        onClose={() => {
          setShowResMsg(false);
        }}
        message={
          !mutation.isError ? '🎉 Updated milestone successfully!' : "⛔️ Couldn't update milestone, please reach out in #dragons for assistance."
        }
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </Box>
  );
}
