import React from 'react';
import { Chart } from 'react-google-charts';
import { Box, Typography } from '@mui/material';
import { hslStringToRgbString, rgbaToHex, stringToColor } from '@/utils/colorUtils';

import type { SuiteFailureData } from '../ProjectedSuiteFailureChart/types';

interface ForecastBreakdownsProps {
  forecastData: SuiteFailureData[];
}

const CARD_WIDTH = 300;

// memoize chart options to prevent unnecessary re-renders
const getChartOptions = (data: [string, string | number][]) => {
  // get colors for each customer (skip header row)
  const colors = data.slice(1).map(([customerName]) => rgbaToHex(hslStringToRgbString(stringToColor(customerName))).slice(0, 7));

  return {
    height: 200,
    legend: { position: 'bottom' },
    pieSliceText: 'percentage',
    chartArea: { width: '100%', height: '85%' },
    backgroundColor: 'transparent',
    tooltip: {
      trigger: 'hover',
      text: 'value',
      textStyle: { fontSize: 12 },
      showColorCode: true,
    },
    colors,
  };
};

const ForecastBreakdowns: React.FC<ForecastBreakdownsProps> = ({ forecastData }) => {
  // prepare data for the pie charts
  const { ownedChartData, coveredChartData, totalOwnedFailures, totalCoveredFailures } = React.useMemo(() => {
    const ownedData: [string, string | number][] = [['Customer', 'Forecasted Failures']];
    const coveredData: [string, string | number][] = [['Customer', 'Forecasted Failures']];
    let ownedTotal = 0;
    let coveredTotal = 0;

    if (!forecastData)
      return {
        ownedChartData: ownedData,
        coveredChartData: coveredData,
        totalOwnedFailures: 0,
        totalCoveredFailures: 0,
      };

    forecastData.forEach((item) => {
      if (item.sumForecastFailures !== 'invalid-forecast-data') {
        if (item.owned) {
          ownedData.push([item.customerName, item.sumForecastFailures]);
          ownedTotal += item.sumForecastFailures;
        }
        if (item.covered) {
          coveredData.push([item.customerName, item.sumForecastFailures]);
          coveredTotal += item.sumForecastFailures;
        }
      }
    });

    return {
      ownedChartData: ownedData,
      coveredChartData: coveredData,
      totalOwnedFailures: ownedTotal,
      totalCoveredFailures: coveredTotal,
    };
  }, [forecastData]);

  // memoize chart options for both charts
  const ownedChartOptions = React.useMemo(() => getChartOptions(ownedChartData), [ownedChartData]);
  const coveredChartOptions = React.useMemo(() => getChartOptions(coveredChartData), [coveredChartData]);

  if (!forecastData || (ownedChartData.length <= 1 && coveredChartData.length <= 1)) {
    return null;
  }

  return (
    <Box sx={{ mt: 2, p: 1 }}>
      <Typography variant="h6" sx={{ mb: 2, pl: 2 }}>
        Customer Breakdown
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, mx: 2 }}>
        {ownedChartData.length > 1 && (
          <Box
            sx={{
              p: 2,
              bgcolor: 'background.paper',
              borderRadius: 1,
              border: '1px solid',
              borderColor: 'divider',
              minWidth: `${CARD_WIDTH}px`,
              flex: '0 0 auto',
              boxShadow: 1,
              transform: 'translate3d(0,0,0)',
              '& .google-visualization-tooltip': {
                position: 'fixed',
                zIndex: 9999,
                pointerEvents: 'none',
              },
              '& > div': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Owned Customers
            </Typography>
            <Chart chartType="PieChart" data={ownedChartData} options={ownedChartOptions} />
            <Box sx={{ mt: 1 }}>
              <Typography variant="body2" color="text.secondary">
                {`Total forecasted failures: ${totalOwnedFailures.toFixed(2)}`}
              </Typography>
            </Box>
          </Box>
        )}
        {coveredChartData.length > 1 && (
          <Box
            sx={{
              p: 2,
              bgcolor: 'background.paper',
              borderRadius: 1,
              border: '1px solid',
              borderColor: 'divider',
              minWidth: `${CARD_WIDTH}px`,
              flex: '0 0 auto',
              boxShadow: 1,
              transform: 'translate3d(0,0,0)',
              '& .google-visualization-tooltip': {
                position: 'fixed',
                zIndex: 9999,
                pointerEvents: 'none',
              },
              '& > div': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Covered Customers
            </Typography>
            <Chart chartType="PieChart" data={coveredChartData} options={coveredChartOptions} />
            <Box sx={{ mt: 1 }}>
              <Typography variant="body2" color="text.secondary">
                {`Total forecasted failures: ${totalCoveredFailures.toFixed(2)}`}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ForecastBreakdowns;
