export const ANNUAL_QAE_BENEFITS_COST = 6_000;
export const HOSTING_COST_PERCENTAGE = 0.055;
export const DEFAULT_DAYS_RANGE = 30;

export const WORKFLOW_STATUS_COLORS = ['#4545E5', '#9EAFF0', '#9E4FE5', '#DB4B4B'];

export const DEFAULT_TABS = [
  { type: 'Pack View', tableData: {}, current: true },
  { type: 'Team View', tableData: {}, current: false },
  { type: 'Customer View', tableData: {}, current: false },
];
