import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { hslStringToRgbString, stringToColor } from '../../../utils/colorUtils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(utc);
dayjs.extend(isoWeek);

export default function SourcesLineChart({ data, filters }) {
    let jobSourceCountsByWk = [];
    let allJobSourceCountsByWk = [];

    // Use filters.gte and filters.lte for the date range
    const oneMonthAgo = dayjs.utc().subtract(1, "month");
    const lowerBound = filters.gte && dayjs.utc(filters.gte).isBefore(oneMonthAgo)
        ? dayjs.utc(filters.gte)
        : oneMonthAgo;
    const upperBound = dayjs.utc(filters.lte);

    data.candidates.forEach((submission) => {
        const submissionDate = dayjs.utc(submission.dateSubmitted);
        if (submissionDate.isValid()) {
            // Compute the start of the week for this submission.
            const weekStart = submissionDate.startOf('isoWeek');
            // Use the start of the week in the filter check.
            if (weekStart.isBetween(lowerBound, upperBound, "day", "[]")) {
                const weekKey = weekStart.format('YYYY-MM-DD');
                const weekNumber = weekStart.isoWeek();

                // For a specific job source
                if (submission.jobSourceName === filters.source) {
                    const existingEntry = jobSourceCountsByWk.find((entry) => entry.weekKey === weekKey);
                    if (existingEntry) {
                        existingEntry.count += 1;
                    } else {
                        jobSourceCountsByWk.push({
                            weekKey,
                            weekNumber,
                            count: 1,
                        });
                    }
                }

                // For "All" job sources
                if (filters.source === "All") {
                    const existingEntry = allJobSourceCountsByWk.find((entry) => entry.weekKey === weekKey);
                    if (existingEntry) {
                        existingEntry[submission.jobSourceName] = (existingEntry[submission.jobSourceName] || 0) + 1;
                    } else {
                        let newObj = { weekKey, weekNumber };
                        data.jobSources.forEach((source) => {
                            newObj[source] = 0;
                        });
                        newObj[submission.jobSourceName] = 1;
                        allJobSourceCountsByWk.push(newObj);
                    }
                }
            }
        }
    });


    // Sort the data by weekKey (lexicographical sort works for ISO week strings)
    const sortedCountsByWk = jobSourceCountsByWk.sort((a, b) => a.weekKey.localeCompare(b.weekKey));
    const allSortedCountsByWk = allJobSourceCountsByWk.sort((a, b) => a.weekKey.localeCompare(b.weekKey));

    return (
        <div style={{ width: '100%', height: '400px', margin: '20px 0px' }}>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    width={500}
                    height={300}
                    data={filters.source === "All" ? allSortedCountsByWk : sortedCountsByWk}
                    margin={{
                        top: 5,
                        right: 10,
                        left: 20,
                        bottom: 20,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="weekNumber"
                        label={{ value: `Week`, position: 'insideBottom', offset: -5 }}
                    />
                    <YAxis label={{ value: 'Total Count', angle: -90, position: 'insideLeft' }} />
                    <Tooltip
                        labelFormatter={(label) => `Week ${label}`}
                        labelStyle={{ fontWeight: '600', textDecoration: 'underline', fontStyle: 'italic' }}
                    />
                    <Legend
                        verticalAlign="top"
                        payload={[
                            { value: `Total Submissions by Job Source Over Time`, color: hslStringToRgbString(stringToColor(filters.source)) },
                        ]}
                    />

                    {filters.source === "All" ?
                        data.jobSources.map((source, index) => (
                            <Line
                                type="monotone"
                                name={source}
                                key={index}
                                dataKey={source}
                                stroke={hslStringToRgbString(stringToColor(source))}
                                activeDot={{ r: 8 }}
                            />
                        ))
                        : <Line
                            type="monotone"
                            name={`Count for ${filters.source}`}
                            dataKey={"count"}
                            stroke={hslStringToRgbString(stringToColor(filters.source))}
                            activeDot={{ r: 8 }}
                        />
                    }
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}
