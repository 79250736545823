import { Box, Button, Divider, Snackbar, TextField, Typography } from '@mui/material';
import { NoInputDatePicker } from './subComponents';
import dayjs, { Dayjs } from 'dayjs';
import { FormEvent, useEffect, useState } from 'react';
import { getLastIncompleteMilestone, milestoneCreateReq, validateMilestone, styles } from './utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { hasOrHave, plural } from '../../../InvestigationBoard/helpers';
import { Milestone } from '../types';
import { ClientSummaryTableRow } from '../types';

const today = dayjs();

interface SubMilestone {
  weekNumber: number;
  dueDate: Dayjs;
  targetTests: number;
  targetOutlinedTests: number;
  isEditingOutline?: boolean;
  isFullyOutlined?: boolean;
}

interface MilestoneFormValues {
  name: string;
  notes: string;
  targetTestCount: string;
  testsPerWeek: string;
  startDate: Dayjs;
  internalDueDate: Dayjs;
  externalDueDate: Dayjs;
}

interface MilestoneRequestData {
  name: string;
  notes: string;
  targetTestCount: number;
  startDate: Date;
  externalDueDate: Date;
  customerId: string;
  lastTargetTestCount: number;
  activeTestCount: number;
  fullyOutlinedWeekNum: number;
}

const DEFAULT_VALUES = {
  netNewTestCount: 50,
  numWeeks: 5,
  fullyOutlinedWeekNum: 3,
};

function getNumberOfWeeks(startDate: Dayjs, endDate: Dayjs) {
  return Math.ceil(
    dayjs(dayjs(endDate)).diff(
      dayjs(startDate),
      'week',
      true, // Return a floating point number and round up
    ),
  );
}

function calculateSubMilestones(initialTestCount: number, targetTestCount: number, startDate: Dayjs, endDate: Dayjs, fullyOutlinedWeekNum: number) {
  const numWeeks = getNumberOfWeeks(startDate, endDate);
  const netNewTestCount = targetTestCount - initialTestCount;
  const creationVelocity = netNewTestCount / numWeeks;
  const outlineVelocity = netNewTestCount / fullyOutlinedWeekNum;

  const subMilestones = [];

  for (let i = 0; i < numWeeks; i++) {
    const weekDueDate = i === 0 ? startDate : i === numWeeks - 1 ? endDate : startDate.add(i, 'week').endOf('week');

    subMilestones.push({
      weekNumber: i + 1,
      dueDate: weekDueDate,
      targetTests: Math.min(initialTestCount + Math.ceil(creationVelocity * (i + 1)), targetTestCount),
      targetOutlinedTests: Math.min(initialTestCount + Math.ceil(outlineVelocity * (i + 1)), targetTestCount),
    });
  }
  return subMilestones;
}

function getSubMilestoneWeekString(milestoneStartDate: Dayjs, milestoneDueDate: Dayjs, subMilestoneDueDate: Dayjs) {
  if (subMilestoneDueDate.isSame(milestoneStartDate, 'week')) {
    return `${milestoneStartDate.format('MM/DD')} - ${subMilestoneDueDate.endOf('week').format('MM/DD')}`;
  } else if (subMilestoneDueDate.isSame(milestoneDueDate, 'week')) {
    return `${milestoneDueDate.startOf('week').format('MM/DD')} - ${milestoneDueDate.format('MM/DD')}`;
  } else {
    return `${subMilestoneDueDate.startOf('week').format('MM/DD')} - ${subMilestoneDueDate.format('MM/DD')}`;
  }
}

export function MsCreationForm({ client, milestones }: { client: ClientSummaryTableRow; milestones: Milestone[] }) {
  const queryClient = useQueryClient();

  // Get data to calculate the expected initial test count
  const activeTestCount = client.activeTestCount;
  const lastIncompleteMilestone = getLastIncompleteMilestone(milestones);
  const lastMilestoneDueDate = lastIncompleteMilestone?.externalDueDate || today;
  const lastTargetTestCount = lastIncompleteMilestone?.targetTestCount || 0;

  // Target test count must be greater than the existing test count and the previous
  // milestone's target test count
  const expectedInitialTestCount = Math.max(activeTestCount, lastTargetTestCount);

  // Default values for the form
  const [values, setValues] = useState({
    name: '',
    targetTestCount: `${expectedInitialTestCount + DEFAULT_VALUES.netNewTestCount}`,
    testsPerWeek: `${Math.ceil(DEFAULT_VALUES.netNewTestCount / DEFAULT_VALUES.numWeeks)}`,
    notes: '',
    startDate: dayjs(lastMilestoneDueDate),
    internalDueDate: dayjs(lastMilestoneDueDate).add(DEFAULT_VALUES.numWeeks - 2, 'weeks'),
    externalDueDate: dayjs(lastMilestoneDueDate).add(DEFAULT_VALUES.numWeeks, 'weeks'),
  });

  // Default fully outlined week number
  const [fullyOutlinedWeekNum, setFullyOutlinedWeekNum] = useState(DEFAULT_VALUES.fullyOutlinedWeekNum);

  // Default sub milestones
  const [subMilestones, setSubMilestones] = useState<SubMilestone[]>(
    calculateSubMilestones(
      expectedInitialTestCount,
      expectedInitialTestCount + DEFAULT_VALUES.netNewTestCount,
      dayjs(lastMilestoneDueDate),
      dayjs(lastMilestoneDueDate).add(DEFAULT_VALUES.numWeeks, 'weeks'),
      DEFAULT_VALUES.fullyOutlinedWeekNum,
    ).map((sm) => ({
      ...sm,
      isFullyOutlined: sm.weekNumber === DEFAULT_VALUES.fullyOutlinedWeekNum,
    })),
  );

  const [errors, setErrors] = useState({
    name: '',
    targetTestCount: '',
    startDate: '',
    externalDueDate: '',
  });

  // Set the minimum start date to the external due date of the last incomplete milestone
  const [minStartDate, setMinStartDate] = useState(dayjs(lastMilestoneDueDate));

  // State for the result message after creating a milestone
  const [showResMsg, setShowResMsg] = useState(false);

  useEffect(() => {
    setSubMilestones(
      calculateSubMilestones(expectedInitialTestCount, +values.targetTestCount, values.startDate, values.externalDueDate, fullyOutlinedWeekNum).map(
        (sm) => ({
          ...sm,
          isFullyOutlined: sm.weekNumber === fullyOutlinedWeekNum,
        }),
      ),
    );
  }, [values.targetTestCount, values.startDate, values.externalDueDate]);

  const updateValues = (key: keyof MilestoneFormValues, val: string) => {
    // Account for start date being less than 1 week before internal due date
    const updatedValues = { ...values } as MilestoneFormValues;
    if (key === 'startDate') {
      const validInternalDueDate = dayjs(val).add(1, 'week');
      if (dayjs(values.internalDueDate).isBefore(validInternalDueDate)) {
        updatedValues.internalDueDate = validInternalDueDate;
        updatedValues.externalDueDate = dayjs(validInternalDueDate).add(2, 'week');
      }
    }

    // Update external due date if internal due date is updated
    if (key === 'internalDueDate') {
      updatedValues.externalDueDate = dayjs(val).add(2, 'week');
    }

    // Update tests per week if start date is updated
    if (key === 'startDate') {
      const numWeeks = getNumberOfWeeks(dayjs(val), values.externalDueDate);
      updatedValues.testsPerWeek = `${Math.ceil((+values.targetTestCount - expectedInitialTestCount) / numWeeks)}`;
    }

    // Update tests per week if external due date is updated
    if (key === 'externalDueDate') {
      const numWeeks = getNumberOfWeeks(values.startDate, dayjs(val));
      updatedValues.testsPerWeek = `${Math.ceil((+values.targetTestCount - expectedInitialTestCount) / numWeeks)}`;
    }

    // Update tests per week if target test count is updated
    if (key === 'targetTestCount') {
      const numWeeks = getNumberOfWeeks(values.startDate, values.externalDueDate);
      const newTestCount = +val - expectedInitialTestCount;

      updatedValues.testsPerWeek = `${Math.ceil(newTestCount / numWeeks)}`;
    }

    // Update target test count if tests per week is updated
    if (key === 'testsPerWeek') {
      const numWeeks = getNumberOfWeeks(values.startDate, values.externalDueDate);
      const newTestCount = +val * numWeeks;

      updatedValues.targetTestCount = `${expectedInitialTestCount + newTestCount}`;
    }

    // Update the values object
    setValues((prev) => ({
      ...prev,
      ...updatedValues,
      [key]: val,
    }));

    // Clear the error message for the updated field
    setErrors((prev) => ({ ...prev, [key]: '' }));
  };

  const {
    mutate: createMilestone,
    isPending,
    isError,
  } = useMutation({
    mutationFn: milestoneCreateReq,
    onSuccess: ({ milestone: newMilestone }, variables: MilestoneRequestData) => {
      // Refetch the client summary data
      queryClient.invalidateQueries({ queryKey: ['clientSummary', client.id] });

      // Determine if the new milestone should be set as the active milestone
      const shouldSetActiveMilestone = dayjs(newMilestone.startDate).isSame(dayjs(), 'week');

      // Update the activeMilestone in the cached table data
      // Note: there can be multiple query keys for the same table data. We can't invalidate the queries because that will force the table to reload entirely.
      const tableQueries = queryClient.getQueriesData<ClientSummaryTableRow[]>({ queryKey: ['clientSummaries'], exact: false });
      for (const [queryKey] of tableQueries) {
        queryClient.setQueriesData<ClientSummaryTableRow[]>({ queryKey }, (old) => {
          if (!old) return old;
          return old.map((clientRow) => {
            // Update the activeMilestone in the cached table data if applicable
            if (clientRow.id === newMilestone.customerId && shouldSetActiveMilestone) {
              return {
                ...clientRow,
                activeMilestone: { ...clientRow.activeMilestone, ...newMilestone, weeksRemaining: newMilestone.subMilestones.length },
              };
            }
            return clientRow;
          });
        });
      }

      // Set the new min start date to the external due date of the milestone that was just created
      const newStartDate = dayjs(variables.externalDueDate);
      setMinStartDate(newStartDate);

      const newExpectedInitialTestCount = variables.targetTestCount;

      // Reset the form values
      setValues({
        name: '',
        targetTestCount: `${newExpectedInitialTestCount + DEFAULT_VALUES.netNewTestCount}`,
        testsPerWeek: `${Math.ceil(DEFAULT_VALUES.netNewTestCount / DEFAULT_VALUES.numWeeks)}`,
        notes: '',
        startDate: newStartDate,
        internalDueDate: dayjs(newStartDate).add(DEFAULT_VALUES.numWeeks - 2, 'week'),
        externalDueDate: dayjs(newStartDate).add(DEFAULT_VALUES.numWeeks, 'week'),
      });

      // Reset the sub milestones
      setSubMilestones(
        calculateSubMilestones(
          newExpectedInitialTestCount,
          newExpectedInitialTestCount + DEFAULT_VALUES.netNewTestCount,
          newStartDate,
          dayjs(newStartDate).add(DEFAULT_VALUES.numWeeks, 'week'),
          fullyOutlinedWeekNum,
        ).map((sm) => ({
          ...sm,
          isFullyOutlined: sm.weekNumber === fullyOutlinedWeekNum,
        })),
      );
    },
    onSettled: () => {
      // Show the toast message
      setShowResMsg(true);
    },
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validate the request data
    const foundErrors = validateMilestone(values);

    if (foundErrors) {
      // Set the errors object
      return setErrors((prev) => ({ ...prev, ...foundErrors }));
    } else {
      // Create the milestone
      createMilestone({
        name: values.name,
        notes: values.notes,
        targetTestCount: +values.targetTestCount,
        startDate: dayjs(values.startDate).toDate(),
        externalDueDate: dayjs(values.externalDueDate).toDate(),
        customerId: client.id,
        lastTargetTestCount,
        activeTestCount,
        fullyOutlinedWeekNum,
      });
    }
  };

  const handleFullyOutlinedWeekNumChange = (weekNumber: number) => {
    setFullyOutlinedWeekNum(weekNumber);

    setSubMilestones((prev) => {
      const updated = [...prev];
      const targetTestCount = +values.targetTestCount;
      const outlineVelocity = (targetTestCount - expectedInitialTestCount) / weekNumber;

      // Recalculate outline targets for all weeks
      for (let i = 0; i < updated.length; i++) {
        const week = i + 1;
        updated[i] = {
          ...updated[i],
          isFullyOutlined: week === weekNumber,
          targetOutlinedTests: Math.min(expectedInitialTestCount + Math.round(outlineVelocity * week), targetTestCount),
        };
      }

      // Ensure the last week meets the target test count
      updated[updated.length - 1] = {
        ...updated[updated.length - 1],
        targetOutlinedTests: targetTestCount,
      };

      return updated;
    });
  };

  return (
    <Box sx={{ p: '1em 4em 1em 4em', display: 'flex', flexDirection: 'column', ...styles }} component="form" onSubmit={handleSubmit}>
      <TextField
        id="name"
        label="Milestone Name"
        sx={{ mb: '.7em' }}
        value={values.name}
        onChange={(e) => updateValues('name', e.target.value)}
        required
        helperText={errors.name}
        error={!!errors.name}
        size="small"
      />
      <TextField
        label="Notes"
        multiline
        minRows={3}
        sx={{ mb: '.7em' }}
        value={values.notes}
        onChange={(e) => updateValues('notes', e.target.value)}
        InputProps={{ inputProps: { min: 1 } }}
        size="small"
      />
      <Box sx={{ display: 'flex', mb: '.7em', width: '100%', gap: '.7em' }}>
        <NoInputDatePicker
          label="Start Date"
          slotProps={{ textField: { sx: { width: '100%' } } }}
          minDate={minStartDate}
          value={values.startDate}
          onChange={(val: string) => updateValues('startDate', val)}
          required
          helperText={errors.startDate}
          error={!!errors.startDate}
          size="small"
        />
        <NoInputDatePicker
          label="External Due Date"
          slotProps={{ textField: { sx: { width: '100%' } } }}
          minDate={dayjs(values.startDate).add(1, 'week')}
          value={values.externalDueDate}
          onChange={(val: string) => updateValues('externalDueDate', val)}
          required
          helperText={errors.externalDueDate}
          error={!!errors.externalDueDate}
          size="small"
        />
      </Box>
      <TextField
        label="Target Total Test Count"
        type="number"
        sx={{ mb: '.7em' }}
        value={values.targetTestCount}
        onChange={(e) => updateValues('targetTestCount', e.target.value)}
        InputProps={{ inputProps: { min: expectedInitialTestCount + 1 } }}
        helperText={errors.targetTestCount}
        error={!!errors.targetTestCount}
        size="small"
      />
      <TextField
        label="Tests Per Week"
        type="number"
        sx={{ mb: '.7em' }}
        value={values.testsPerWeek}
        onChange={(e) => updateValues('testsPerWeek', e.target.value)}
        InputProps={{ inputProps: { min: 1 } }}
        helperText={
          errors.targetTestCount ||
          `*${Math.max(+values.targetTestCount - expectedInitialTestCount, 0)} new tests over ${getNumberOfWeeks(
            values.startDate,
            values.externalDueDate,
          )} weeks${
            milestones.length
              ? `, assuming ${expectedInitialTestCount} tests were activated at the end of the last milestone.`
              : ` (${activeTestCount} test${plural(activeTestCount)} ${hasOrHave(activeTestCount)} already been activated)`
          }`
        }
        error={!!errors.targetTestCount}
        size="small"
      />
      <Button variant="contained" type="submit">
        {isPending ? 'Creating...' : 'Create'}
      </Button>
      <Box className="flex flex-col gap-2 mt-4">
        <Box className="flex justify-between w-full">
          <Box className="w-full flex items-center gap-2">
            <Typography color="yellow.dark">◆</Typography>
            <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              Fully outlined
            </Typography>
          </Box>
          <Box className="w-full">
            <Typography>Outline Target</Typography>
          </Box>
          <Box className="w-full">
            <Typography>Creation Target</Typography>
          </Box>
        </Box>
        <Divider />
        {subMilestones.map((subMilestone) => (
          <Box
            key={subMilestone.weekNumber}
            className="flex w-full"
            onClick={() => handleFullyOutlinedWeekNumChange(subMilestone.weekNumber)}
            sx={{
              cursor: 'pointer',
              bgcolor: subMilestone.isFullyOutlined ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
              '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.08)' },
              p: 1,
              borderRadius: 1,
              position: 'relative',
            }}
          >
            {subMilestone.isFullyOutlined && (
              <Typography
                sx={{
                  position: 'absolute',
                  left: -24,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  color: 'yellow.dark',
                }}
              >
                ◆
              </Typography>
            )}
            <Box className="flex flex-col w-full">
              <Typography fontWeight="bold">Week {subMilestone.weekNumber}</Typography>
              <Typography variant="caption">{getSubMilestoneWeekString(values.startDate, values.externalDueDate, subMilestone.dueDate)}</Typography>
            </Box>
            <Box className="w-full">
              <Typography>{subMilestone.targetOutlinedTests}</Typography>
            </Box>
            <Box className="w-full">
              <Typography>{subMilestone.targetTests}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
      <Snackbar
        open={showResMsg}
        ContentProps={{ sx: { bgcolor: 'white', color: 'black' } }}
        autoHideDuration={3000}
        onClose={() => setShowResMsg(false)}
        message={!isError ? '🎉 Created milestone successfully!' : "⛔️ Couldn't create milestone, please reach out in #dragons for assistance."}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </Box>
  );
}
