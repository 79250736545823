import { sendGetRequest, sendPostRequest } from '../../utils/network';

export async function getOpenTasks() {
  try {
    let response = await sendGetRequest(`/open-tasks`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch open tasks. Please refresh the page.', error);
    throw error;
  }
}

export async function getUserShiftPrefs(user) {
  try {
    const { data } = await sendPostRequest('/user-prefs', {
      qawId: user.qawId,
      page: '/triage',
    });
    return data;
  } catch (error) {
    console.error('Failed to fetch user shift preferences. Please refresh the page.', error);
    throw error;
  }
}

export async function getActiveShifts() {
  try {
    const { data } = await sendGetRequest('/active-shifts');
    return data;
  } catch (error) {
    console.error('Failed to fetch active shifts. Please refresh the page.', error);
    throw error;
  }
}

export async function claimTask({ request }) {
  try {
    let data = Object.fromEntries(await request.formData());
    console.dir(data, { depth: null });
    let result = await sendPostRequest(`/claim`, data);
    console.dir(result, { depth: null });
    return result.data.tasks;
  } catch (error) {
    console.error(error);
    return { error: 'Failed to fetch new tasks info. Please refresh the page.' };
  }
}

export async function subscribe({ request }) {
  try {
    let data = Object.fromEntries(await request.formData());
    console.log(request, data);

    let result = await sendPostRequest(`/subscribe`, data);

    return result.data.tasks;
  } catch (error) {
    return { error: 'Failed to fetch new tasks info. Please refresh the page.' };
  }
}

export async function unclaimTask({ request }) {
  try {
    let data = Object.fromEntries(await request.formData());
    let result = await sendPostRequest(`/unclaim`, data);
    return result.data.tasks;
  } catch (error) {
    return { error: 'Failed to fetch new tasks info. Please refresh the page.' };
  }
}

export async function checkTask({ request }) {
  try {
    let data = Object.fromEntries(await request.formData());
    let result = await sendPostRequest(`/check`, data);
    return result.data.tasks;
  } catch (error) {
    return { error: 'Failed to fetch new tasks info. Please refresh the page.' };
  }
}

export async function ignoreTask({ request }) {
  let data = Object.fromEntries(await request.formData());

  let resultData = await sendPostRequest(`/ignore`, data);

  let tasks = resultData?.data?.tasks;

  if (tasks) {
    return tasks;
  }

  return { error: 'Failed to fetch new tasks info. Please refresh the page.' };
}

export async function urgentTask({ request }) {
  try {
    let data = Object.fromEntries(await request.formData());
    let result = await sendPostRequest(`/urgent`, data);
    return result.data.tasks;
  } catch (error) {
    return { error: 'Failed to fetch new tasks info. Please refresh the page.' };
  }
}

export async function doneTask({ request }) {
  try {
    let data = Object.fromEntries(await request.formData());
    let result = await sendPostRequest(`/done`, data);
    return result.data.tasks;
  } catch (error) {
    return { error: 'Failed to fetch new tasks info. Please refresh the page.' };
  }
}
