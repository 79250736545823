import { FC } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, CircularProgress, Tooltip } from '@mui/material';
import { InvestigationSchedule } from './investigationSchedulingTypes';

interface ConfirmScheduleModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  schedule: InvestigationSchedule;
  isConfirming: boolean;
}

const ConfirmScheduleModal: FC<ConfirmScheduleModalProps> = ({ open, onClose, onConfirm, schedule, isConfirming }) => {
  // organize events by day
  const scheduleByDay = schedule.events.reduce((acc, event) => {
    const date = new Date(event.startTime).toLocaleDateString('en-US', { weekday: 'long' });
    const startTime = new Date(event.startTime);
    const endTime = new Date(event.endTime);
    const durationHours = (endTime.getTime() - startTime.getTime()) / (1000 * 60 * 60);

    if (!acc[date]) acc[date] = [];
    acc[date].push({
      name: event.userName,
      time: `${startTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })} - ${endTime.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
      })}`,
      durationHours,
    });
    return acc;
  }, {} as Record<string, { name: string; time: string; durationHours: number }[]>);

  const getEventColor = (duration: number) => {
    if (duration > 4) return 'error.main';
    if (duration > 3.5 || duration < 1) return 'warning.main';
    return 'text.primary';
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Confirm Investigation Schedule
        <Tooltip
          title={
            <Typography variant="caption">
              •{' '}
              <Typography component="span" color="text.primary">
                Normal duration
              </Typography>{' '}
              (1 - 3.5 hours)
              <br />•{' '}
              <Typography component="span" color="warning.main">
                Warning
              </Typography>{' '}
              (less than 1 hour or 3.5 - 4 hours)
              <br />•{' '}
              <Typography component="span" color="error.main">
                Alert
              </Typography>{' '}
              (more than 4 hours)
            </Typography>
          }
        >
          <InfoIcon
            sx={{
              fontSize: 16,
              color: 'action.active',
              verticalAlign: 'middle',
              ml: 1,
              cursor: 'help',
            }}
          />
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        {Object.entries(scheduleByDay).map(([day, events]) => (
          <div key={day}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 2 }}>
              {day}:
            </Typography>
            {events.map((event, index) => (
              <Typography key={index} variant="body2" color={getEventColor(event.durationHours)}>
                • {event.name}: {event.time}
              </Typography>
            ))}
          </div>
        ))}
        <Typography variant="caption" color="warning.main" sx={{ display: 'block', mt: 2 }}>
          confirming this schedule will create calendar events and send invitations to all investigators.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isConfirming}>
          Cancel
        </Button>
        <Button onClick={onConfirm} variant="contained" color="primary" disabled={isConfirming}>
          {isConfirming ? (
            <>
              <CircularProgress size={20} sx={{ mr: 1 }} />
              Confirming...
            </>
          ) : (
            'Confirm Schedule'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmScheduleModal;
