import { Fragment, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function SaveViewModal({ open, setOpen, mutateTableView }) {
  const [viewName, setViewName] = useState('');

  const handleSave = () => {
    mutateTableView.mutate(viewName, {
      onSuccess: () => {
        setViewName('');
        setOpen(false);
      },
    });
  };

  const handleClose = () => {
    setViewName('');
    setOpen(false);
  };

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleSave();
          },
        }}
      >
        <DialogTitle>Save View</DialogTitle>
        <DialogContent>
          <DialogContentText>All currently active sorting, filtering, and column ordering will be saved.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="email"
            label="Name your view"
            type="text"
            fullWidth
            variant="standard"
            value={viewName}
            onChange={(e) => setViewName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" disabled={!viewName.trim()}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
