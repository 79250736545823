import React from 'react';

const NOTION_LINKS = {
  READY_FOR_REVIEW: 'https://www.notion.so/qawolf/Ready-for-Review-Checklist-Playwright-6838ed6df0454197bfed0b648d5dc270',
  BEST_PRACTICES: 'https://www.notion.so/qawolf/Best-Practices-Playwright-da779a8230754d8eb7e4ea3027237aa1',
} as const;

const ExternalLink = ({ href, children }: { href: string; children: React.ReactNode }) => (
  <a href={href} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800 hover:underline">
    {children}
  </a>
);

export const READY_FOR_REVIEW_QUESTIONS = [
  {
    question: 'Was this workflow given another self-review before setting it as Ready for Review?',
    descriptionText: <>Make another pass to search for possible improvements.</>,
    checklistTypes: ['playwrightTest'],
  },
  {
    question: 'Was the Ready for Review Checklist completed for this workflow?',
    descriptionText: (
      <>
        Go through the <ExternalLink href={NOTION_LINKS.READY_FOR_REVIEW}>Ready for Review Checklist</ExternalLink>.
      </>
    ),
    checklistTypes: ['playwrightTest'],
  },
  {
    question: 'Does the workflow follow current Best Practices?',
    descriptionText: (
      <>
        Consult the <ExternalLink href={NOTION_LINKS.BEST_PRACTICES}>Best Practices</ExternalLink>.
      </>
    ),
    checklistTypes: ['playwrightTest'],
  },
] as const;
