import { sendGetRequest, sendPostRequest, sendPutRequest } from '../../../utils/network';
import { Candidate, Submission, sendCandidateEmailInterface, SendCandidateEmailDataType } from '../Types/types';

export async function getAllSubmissions() {
  try {
    const response = await sendGetRequest(`/hiring-dashboard/submissions`);
    return response.data || [];
  } catch (error) {
    if (error instanceof Error) {
      throw new Error('Failed to fetch new submissions. Please refresh the page.', error);
    } else {
      // Handle the case when error is not an instance of Error (unlikely, but safer)
      throw new Error('An unknown error occurred.');
    }
  }
}

export async function getAllCandidates() {
  try {
    const response = await sendGetRequest(`/hiring-dashboard/candidates`);
    return response.data || [];
  } catch (error) {
    if (error instanceof Error) {
      throw new Error('Failed to fetch candidates. Please refresh the page.', { cause: error });
    } else {
      throw new Error('An unknown error occurred.');
    }
  }
}

export async function getSingleSubmission(id: string) {
  try {
    const response = await sendGetRequest(`/hiring-dashboard/submission/${id}`);
    return response.data || [];
  } catch (error) {
    if (error instanceof Error) {
      throw new Error('Failed to fetch selected submission. Please refresh the page.', { cause: error });
    } else {
      throw new Error('An unknown error occurred.');
    }
  }
}

export async function getSingleCandidate(id: number) {
  try {
    const response = await sendGetRequest(`/hiring-dashboard/candidate/${id}`);
    return response.data || [];
  } catch (error) {
    if (error instanceof Error) {
      throw new Error('Failed to fetch selected candidate. Please refresh the page.', { cause: error });
    } else {
      throw new Error('An unknown error occurred.');
    }
  }
}

export async function getAllJobSources() {
  try {
    const response = await sendGetRequest(`/hiring-public/job-sources`);
    return response.data || [];
  } catch (error) {
    if (error instanceof Error) {
      throw new Error('Failed to fetch new job sources. Please refresh the page.', { cause: error });
    } else {
      throw new Error('An unknown error occurred.');
    }
  }
}

export async function updateCandidateSubmission(submissionData: Partial<Submission>, isArchived: boolean) {
  let formattedSubmissionData;
  if (isArchived) {
    formattedSubmissionData = {
      submission: { ...submissionData, isArchived: true, archivedDate: new Date() },
    };
  } else {
    formattedSubmissionData = {
      submission: { ...submissionData, isArchived: false, archivedDate: null },
    };
  }

  try {
    const response = await sendPutRequest(`/hiring-dashboard/submission`, formattedSubmissionData);
    return response.data || '';
  } catch (error) {
    if (error instanceof Error) {
      throw new Error('Failed to update candidate submission. Please refresh the page.', { cause: error });
    } else {
      throw new Error('An unknown error occurred.');
    }
  }
}

export async function updateCandidateInfo(candidateData: Partial<Candidate>) {
  const formattedCandidateData = {
    candidate: {
      ...candidateData,
    },
  };

  try {
    const response = await sendPutRequest(`/hiring-dashboard/candidate`, formattedCandidateData);
    return response.data || '';
  } catch (error) {
    if (error instanceof Error) {
      throw new Error('Failed to update candidate info. Please refresh the page.', { cause: error });
    } else {
      throw new Error('An unknown error occurred.');
    }
  }
}

export async function initCandidateFinalRound(candidateData: SendCandidateEmailDataType) {
  try {
    const response = await sendPostRequest(`/hiring-dashboard/initCandidateFinalRound`, candidateData);
    return response;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error('Failed to init new final round team. Please refresh the page.', { cause: error });
    } else {
      throw new Error('An unknown error occurred.');
    }
  }
}

export async function inviteEricLauraToFinalRoundTeam(candidateData: Partial<Candidate>) {
  try {
    const response = await sendPostRequest(`/hiring-dashboard/inviteEricLauraToFinalRoundTeam`, candidateData);
    return response.data || '';
  } catch (error) {
    if (error instanceof Error) {
      throw new Error('Failed to invite Eric / Laura Please refresh the page.', { cause: error });
    } else {
      throw new Error('An unknown error occurred.');
    }
  }
}

export async function getCandidateEmails(candidateEmail: string) {
  try {
    const response = await sendPostRequest(`/hiring-dashboard/getCandidateEmails`, { candidateEmail: candidateEmail });
    return response.data || '';
  } catch (error) {
    if (error instanceof Error) {
      throw new Error('Failed to fetch new candidate Emails. Please refresh the page.', { cause: error });
    } else {
      throw new Error('An unknown error occurred.');
    }
  }
}

export async function sendCandidateEmail({ currentEditorState, candidate, singleMessageHeaders }: sendCandidateEmailInterface) {
  try {
    const response = await sendPostRequest(`/hiring-dashboard/sendCandidateEmail`, { currentEditorState, candidate, singleMessageHeaders });
    return response || '';
  } catch (error) {
    if (error instanceof Error) {
      throw new Error('Failed to send candidate email. Please refresh the page.', { cause: error });
    } else {
      throw new Error('An unknown error occurred.');
    }
  }
}
/**
 * Invites executives to the final round team for a specified candidate.
 *
 * This asynchronous function sends a POST request to the hiring dashboard API
 * to invite executives to the final round team for the given candidate/team ID.
 * @async
 * @function inviteExecsToFinalRoundTeam
 * @param {object} params - The parameters object.
 * @param {string} params.teamId - Id of the candidates' final round team
 * @returns {Promise<{ success: boolean, data: any, jsonData: any }>} A promise that resolves to the response data if the invitation is successful
 * @throws {Error} Throws an error if the invitation fails or if there is an issue with the request.
 * @example
 * // Usage example:
 * inviteExecsToFinalRoundTeam({ teamId: '12345' })
 *   .then(data => {
 *     console.log('Invitations sent successfully:', data);
 *   })
 *   .catch(error => {
 *     console.error('Error inviting executives:', error.message);
 *   });
 */
export async function inviteExecsToFinalRoundTeam({ teamId }: { teamId: string }) {
  try {
    const response = await sendPostRequest(`/hiring-dashboard/inviteExecsToFinalRoundTeam`, { teamId });
    return response;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error('Failed to invite Execs to final round team.', { cause: error });
    } else {
      throw new Error('An unknown error occurred.');
    }
  }
}

export async function evaluateSubmissionsWithGPT() {
  try {
    const response = await sendPostRequest(`/hiring-dashboard/evaluateAllPendingSubmissionsWithGPT`);
    return response;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error('Failed to call GPT.', { cause: error });
    } else {
      throw new Error('An unknown error occurred.');
    }
  }
}

export async function archiveSubmissions({ ids }: { ids: number[] }) {
  try {
    const response = await sendPutRequest(`/hiring-dashboard/archiveSubmissions`, { ids });
    return response.data || '';
  } catch (error) {
    if (error instanceof Error) {
      throw new Error('Failed to archive candidate submission.', { cause: error });
    } else {
      throw new Error('An unknown error occurred.');
    }
  }
}

export async function unarchiveSingleSubmission({ id }: { id: number }) {
  try {
    const response = await sendPutRequest(`/hiring-dashboard/unarchiveSubmission`, { id });
    return response.data || '';
  } catch (error) {
    if (error instanceof Error) {
      throw new Error('Failed to unarchive candidate submission.', { cause: error });
    } else {
      throw new Error('An unknown error occurred.');
    }
  }
}

export async function mergeCandidates(primaryCandidateId: number, duplicateCandidateIds: number[]) {
  try {
    const response = await sendPutRequest(`/hiring-dashboard/mergeCandidateSubmissions`, { primaryCandidateId, duplicateCandidateIds });
    return response.data || [];
  } catch (error) {
    if (error instanceof Error) {
      throw new Error('Failed to merge candidates. Please refresh the page.', { cause: error });
    } else {
      throw new Error('An unknown error occurred.');
    }
  }
}

export async function deleteSubmissions({ submissionIds }: { submissionIds: number[] }) {
  try {
    const response = await sendPostRequest(`/hiring-dashboard/deleteSubmission`, { submissionIds });
    return response.data || '';
  } catch (error) {
    if (error instanceof Error) {
      throw new Error('Failed to delete candidate submission.', { cause: error });
    } else {
      throw new Error('An unknown error occurred.');
    }
  }
}
