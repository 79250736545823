import TeamOverviewTable from './tables/TeamOverviewTable';
import CustomerOverviewTable from './tables/CustomerOverviewTable';
import PackOverviewTable from './tables/PackOverviewTable';

export default function ExecutiveOverviewBoard({ activeTab, tableData }) {
  const viewMap = {
    'Pack View': <PackOverviewTable tableData={tableData} />,
    'Team View': <TeamOverviewTable tableData={tableData} />,
    'Customer View': <CustomerOverviewTable tableData={tableData} />,
  };
  return <div>{viewMap[activeTab.type]}</div>;
}
