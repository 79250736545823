import '../types/mui-extensions';
import { createTheme } from '@mui/material';

// Extend the default theme palette type to include custom colors
declare module '@mui/material/styles' {
    interface Palette {
        gray: Palette['primary'];
        green: Palette['primary'];
        red: Palette['primary'];
        blue: Palette['primary'];
        yellow: Palette['primary'];
    }
    interface PaletteOptions {
        gray?: PaletteOptions['primary'];
        green?: PaletteOptions['primary'];
        red?: PaletteOptions['primary'];
        blue?: PaletteOptions['primary'];
        yellow?: PaletteOptions['primary'];
    }
}

export const planningTheme = createTheme({
    palette: {
        green: {
            main: '#22c55e',
            light: '#86efac',
            dark: '#15803d',
            contrastText: '#FFFFFF',
        },
        gray: {
            main: '#d1d5db',
            light: '#f3f4f6',
            dark: '#6b7280',
            contrastText: '#000000',
        },
        red: {
            main: '#ef4444',
            light: '#fecaca',
            dark: '#991a1b',
            contrastText: '#FFFFFF',
        },
        blue: {
            main: '#3b82f6',
            light: '#92c5fd',
            dark: '#1d3a8a',
            contrastText: '#FFFFFF',
        },
        yellow: {
            main: '#eab30b',
            light: '#fde68a',
            dark: '#b45309',
            contrastText: '#000000',
        },
    },
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    paddingTop: '12px',
                    paddingBottom: '12px',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: 'white',
                    color: 'inherit',
                    border: '1px solid #e0e0e0',
                    fontWeight: 'bold',
                    textWrap: 'nowrap',
                    width: 'fit-content',
                    boxSizing: 'border-box',
                    maxWidth: 'unset',
                },
                arrow: {
                    color: 'white',
                    '&::before': {
                        border: '1px solid #e0e0e0',
                    },
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    padding: 0,
                },
                list: {
                    padding: 0,
                },
            },
        },
    },
});
