import { FC } from 'react';
import ReactDOMServer from 'react-dom/server';

import { hslStringToRgbString, rgbaToHex, stringToColor } from '@/utils/colorUtils';

import { FormattedEvent, InvestigationScheduleEvent } from '../investigationSchedulingTypes';
import { GoogleDataTableColumn, GoogleDataTableColumnRoleType } from 'react-google-charts';

// set the baseline for the event types
const eventTypeBaseline: GoogleDataTableColumn[] = [
  // https://developers.google.com/chart/interactive/docs/roles#what-roles-are-available
  { type: 'string', id: 'Name' },
  { type: 'string', id: 'Label' },
  { type: 'string', id: 'style', role: GoogleDataTableColumnRoleType.style },
  { type: 'string', role: GoogleDataTableColumnRoleType.tooltip, p: { html: true } },
  // https://developers.google.com/chart/interactive/docs/customizing_tooltip_content#customizing-html-content
  { type: 'date', id: 'Start' },
  { type: 'date', id: 'End' },
];

export type EventTypeBaseline = typeof eventTypeBaseline;

const formatEventsForTimeline = (
  events: InvestigationScheduleEvent[],
): { formattedEvents: [EventTypeBaseline, ...FormattedEvent[]]; allUniqueUsers: string[] } => {
  // set a placeholder for the formatted events
  const formattedEvents: FormattedEvent[] = [];

  // get all the unique users in the passed in events
  const allUniqueUsers = [...new Set(events.map((event) => event.userName))];

  // create a date formatter for the timeline tooltips
  const dateFormatter = new Intl.DateTimeFormat('en-GB', { weekday: 'long', day: 'numeric' });

  // iterate through the unique users and add an their events to the formatted events
  const typeMap = {
    'added-spike': 'Added Backup',
    spike: 'Backup',
    base: 'Primary',
  };
  for (const user of allUniqueUsers) {
    // get valid events for the user
    const validUserEvents = events.filter((event) => event.userName === user && event.startTime < event.endTime);

    // get the color for the user
    const userColor = rgbaToHex(hslStringToRgbString(stringToColor(user)));

    // add the user's events to the formatted events
    formattedEvents.push(
      ...validUserEvents.map(
        (event): FormattedEvent => [
          typeMap[event.type],
          `${user} | ${dateFormatter.format(new Date(event.startTime))} | ${event.type}`,
          `color: ${userColor.slice(0, 7)}; opacity: 0.75`,
          createCustomHTMLTooltipContent(event),
          new Date(event.startTime),
          new Date(event.endTime),
        ],
      ),
    );
  }

  // sort events in this order: added-spike, spike, base
  return {
    formattedEvents: [
      eventTypeBaseline,
      ...formattedEvents.sort((a, b) => {
        const order = ['Added Backup', 'Backup', 'Primary'];
        return order.indexOf(a[0]) - order.indexOf(b[0]);
      }),
    ],
    allUniqueUsers,
  };
};

function createCustomHTMLTooltipContent(event: InvestigationScheduleEvent): string {
  interface TooltipComponentProps {
    event: InvestigationScheduleEvent;
  }
  const TooltipComponent: FC<TooltipComponentProps> = ({ event }) => {
    // initialize the date formatter
    const dateFormatter = new Intl.DateTimeFormat('en-US', { weekday: 'long', hour: '2-digit', minute: '2-digit' });
    // capitalize first letter of type
    const normalizedType = event.type === 'base' ? 'Primary' : event.type === 'spike' ? 'Backup' : 'Added Backup';

    // get background color based on event type
    const backgroundColor =
      event.type === 'base' ? '#e8f5e9' : event.type === 'spike' ? '#fff3e0' : event.type === 'added-spike' ? '#ffebee' : 'white';

    return (
      <div
        style={{
          padding: '8px',
          backgroundColor,
          border: '1px solid #ccc',
          borderRadius: '4px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
          fontFamily: 'Arial, sans-serif',
          fontSize: '13px',
          minWidth: '200px',
          maxWidth: '600px',
        }}
      >
        <div
          style={{
            borderBottom: '1px solid #ccc',
            marginBottom: '8px',
            paddingBottom: '4px',
            fontWeight: 'bold',
            fontSize: '14px',
            textAlign: 'center',
            whiteSpace: 'nowrap',
          }}
        >
          {event.reAssigned && 'Re-Assigned | '}
          {event.crossedOver && 'Crossed Over | '}
          {event.userName} | {normalizedType}
        </div>
        <div style={{ display: 'grid', gap: '4px' }}>
          <div>
            <strong>Start:</strong> {dateFormatter.format(new Date(event.startTime))}
          </div>
          <div>
            <strong>End:</strong> {dateFormatter.format(new Date(event.endTime))}
          </div>
          <div>
            <strong>Duration:</strong> {event.duration.toFixed(2)} hours
          </div>
          <div>
            <strong>Capacity:</strong> {event.capacity.toFixed(2)}
          </div>
        </div>
      </div>
    );
  };

  const componentAsString = ReactDOMServer.renderToString(<TooltipComponent event={event} />);
  return componentAsString;
}

export default formatEventsForTimeline;
