import { EmailTemplate } from "../../Types/EmailSection/types";

const emailTemplates: EmailTemplate[] = [
  {
    name: "Blank Take Home",
    content: ({ firstName }) => (
      <div>
        Hi {firstName}, <br />
        <br />
        It appears the take-home assessment you've submitted is blank. Just wanted to follow up and make sure the correct one was sent.
        <br />
        <br />
        Sincerely, <br />
        The QA Wolf Hiring Team
      </div>
    ),
  },
  {
    name: "Interview Prep Packet",
    content: ({ firstName }) => (
      <div>
        Hi {firstName}, <br />
        <br />
        Thank you for taking the time to interview with us earlier last week. We enjoyed getting to know you and the opportunity to work together.
        <br />
        <br />
        Although you weren't able to get to a fully working solution for all of the different scenarios, you were quite close. We believe with a bit of guidance, you'd make a great addition to our team! That being said, <a href="https://res.cloudinary.com/huht2rslk/image/upload/v1737582560/hiring%20internal/QA_Wolf_Interview_Prep_Packet.pdf" target='_blank' rel='noopener noreferrer'>this</a> is a <i>“QA Wolf Interview Prep Packet”</i> where we outline some tips and tricks to be successful at our company. We also recommend you study through the <a href='https://playwright.dev/' target='_blank' rel='noopener noreferrer'>Playwright.js</a> documentation, as it will provide a valuable foundation.<br />
        <br />
        That being said, please spend some time with this documentation and reach out in <i>one month</i> to schedule an additional final round interview should you wish to be considered again.
        <br />
        <br />
        We think you have an amazing drive and positive attitude, and look forward to hearing from you soon. ✨
        
        <br />
        <br />
        Sincerely, <br />
        The QA Wolf Hiring Team
      </div>
    ),
  },
  {
    name: "As Mentioned",
    content: ({ firstName }) => (
      <div>
        Hi {firstName}, <br />
        <br />
        Thank you for reaching out and for your interest in QA Wolf 🐺
        <br />
        <br />
        As mentioned in the job post,{" "}
        <a
          href="https://www.task-wolf.com/apply-qae"
          target='_blank'
          rel="noopener noreferrer"
          style={{
            color: 'turquoise',
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          this page
        </a>{" "}
        has all the information you need to get started, including instructions
        for how to apply if you are interested. Due to the volume of applicants
        we receive, we ask that everyone go through our standard process. We
        promise to give you an answer one way or the other within 2 weeks if you
        start our process.
        <br />
        <br />
        Hope that helps, have a great rest of your week!
        <br />
        <br />
        Sincerely, <br />
        The QA Wolf Hiring Team
      </div>
    ),
  },
  {
    name: "Best of Luck",
    content: ({ firstName }) => (
      <div>
        Thanks, {firstName}; best of luck in your search 🍀
        <br />
        <br />
        Sincerely, <br />
        The QA Wolf Hiring Team
      </div>
    ),
  },
  {
    name: "Confirm Meeting",
    content: ({ firstName }) => (
      <div>
         Thanks for confirming, {firstName}; looking forward to our meeting! 🐺
        <br />
        <br />
        Sincerely, <br />
        The QA Wolf Hiring Team
      </div>
    ),
  },
  {
    name: "Reject No Visa",
    content: ({ firstName }) => (
      <div>
        Hi {firstName},
        <br />
        <br />
        As noted in the job post, we do not provide visa sponsorships, or any related asks (letters, etc). You must be physically located in the US, Canada, UK, or Australia and authorized to work there in order to apply to this position.
        <br />
        <br />
        Sincerely, <br />
        The QA Wolf Hiring Team
      </div>
    ),
  },
  {
    name: "Temporary Work Auth",
    content: ({ firstName }) => (
      <div>
        Hi {firstName},
        <br />
        <br />
        Unfortunately, we're looking for candidates with a permanent work authorization in the US, Canada, UK, or Australia. We wish you luck in your future endeavors, though, and please don't hesitate to reach out should your work authorization status change!
        <br />
        <br />
        Sincerely, <br />
        The QA Wolf Hiring Team
      </div>
    ),
  },
  {
    name: "Can't Give Hint",
    content: ({ firstName }) => (
      <div>
        Hi {firstName}, <br />
        <br />
        Thank you for reaching out. While we are unable to provide any more details on the assignment, we've found that candidates who make it to the final round go above and beyond the scope of the assignment.
        <br />
        <br />
        Hope this helps! <br />
        <br />
        Sincerely, <br />
        The QA Wolf Hiring Team
      </div>
    ),
  },
  {
    name: "Looking Forward To It",
    content: ({ firstName }) => (
      <div>
        Thanks, {firstName}; looking forward to hearing from you soon 🐺
        <br />
        <br />
        Sincerely, <br />
        The QA Wolf Hiring Team
      </div>
    ),
  },
  {
    name: "Feedback Requested",
    content: ({ firstName }) => (
      <div>
        Hi {firstName}, <br />
        <br />
        In terms of feedback, unfortunately, it's just a competitive round. Some candidates do lots of scenarios and exploration which helps them stand out. As mentioned in the FAQ, going above and beyond the main prompt can be helpful if you're very enthusiastic about the role. While there wasn't anything obviously wrong with your assignment, we just ended up receiving some stronger submissions for our current opening.
        <br />
        <br />
        Hope that helps, best of luck in your search 🍀
        <br />
        <br />
        Sincerely, <br />
        The QA Wolf Hiring Team
      </div>
    ),
  },
  {
    name: "Queued Final Round",
    content: ({ firstName }) => (
      <div>
        Hey {firstName}, <br />
        <br />
        Great job on the assignment, thanks for sending this through!
        <br />
        <br />
        Unfortunately, our last opening for your location was recently filled, as we have a rolling hiring process that starts and stops as needed depending on how many hires we're making. That being said, we tend to hire every few weeks so you are on my list to reach out to first as soon as we have another opening. Feel free to check in any time as well.
        <br />
        <br />
        Wishing you the best of luck in your search in the meantime 🍀
        <br />
        <br />
        Sincerely, <br />
        The QA Wolf Hiring Team
      </div>
    ),
  },
  {
    name: "Old Take Home",
    content: ({ firstName }) => (
      <div>
        Hi {firstName}, <br />
        <br />
        It appears you've sent an outdated take-home assignment, which we no longer accept. Please see{" "}
        <a
          href="https://www.task-wolf.com/apply-qae"
          target='_blank'
          rel='noreferrer'
          style={{
            color: 'turquoise',
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          this job post
        </a>{" "}
        for the most up-to-date version of our take-home assignment and resubmit if you'd still like to be considered.
        <br />
        <br />
        Sincerely, <br />
        The QA Wolf Hiring Team
      </div>
    ),
  },
  {
    name: "Take-home Cooldown",
    content: ({ firstName }) => (
      <div>
        Hey {firstName}, <br />
        <br />
        Thank you for your continued interest in QA Wolf. Unfortunately, we are not accepting re-submissions at this time. We ask that all candidates looking to reapply observe a six-month cooldown before reapplying, as we want to be fair to all candidates under our standard process.
        <br />
        <br />
        Best of luck in your endeavors 🍀
        <br />
        <br />
        Sincerely, <br />
        The QA Wolf Hiring Team
      </div>
    ),
  },
  {
    name: "Final Round Cooldown",
    content: ({ firstName }) => (
      <div>
        Hi {firstName}, <br />
        <br />
        Thank you so much for your continued interest in QA Wolf! 🐺 It appears that you've had a final round interview recently. We ask that all candidates who've made it to the final round and wish to reapply observe a cooldown period of at least twelve months.
        <br />
        <br />
        We wish you all the best in your job search and look forward to hearing from you in the future.
        <br />
        <br />
        Sincerely, <br />
        The QA Wolf Hiring Team
      </div>
    ),
  },
  {
    name: "Loom Not Reviewed",
    content: ({ firstName }) => (
      <div>
        Hey {firstName}, <br />
        <br />
        We made an initial decision based on the code you provided. Hope that helps, and best of luck in your search! 🍀
        <br />
        <br />
        Sincerely, <br />
        The QA Wolf Hiring Team
      </div>
    ),
  },
  {
    name: "Walk-through Addition",
    content: ({ firstName }) => (
      <div>
        Hey {firstName}, <br />
        <br />
        Great job on the assignment, thanks for sending this through!
        <br />
        <br />
        In addition to your original submission, can you record a short Loom video (about 1 to 2 minutes) walking us through your code and showing a successful execution, please?
        <br />
        <br />
        Thanks in advance, and we look forward to hearing from you 🐺
        <br />
        <br />
        Sincerely, <br />
        The QA Wolf Hiring Team
      </div>
    ),
  },
  {
    name: "Feedback - Final Round",
    content: ({ firstName }) => (
      <div>
        Hey {firstName}, <br />
        <br />
        In terms of feedback, we just ran out of time to get to an accurate, working solution for all of the different scenarios. Hope that helps a bit. We continue to wish you luck in your search 🍀
        <br />
        <br />
        Sincerely, <br />
        The QA Wolf Hiring Team
      </div>
    ),
  },
  {
    name: "Full-time Position",
    content: ({ firstName }) => (
      <div>
        Hi {firstName}, <br />
        <br />
        Unfortunately, we are looking for full-time candidates at this time. If your situation changes, please don't hesitate to reach out.
        <br />
        <br />
        Sincerely, <br />
        The QA Wolf Hiring Team
      </div>
    ),
  },
  {
    name: "Still Hiring",
    content: ({ firstName }) => (
      <div>
        Hi {firstName}, <br />
        <br />
        Thank you for reaching out and for your interest in QA Wolf 🐺
        <br />
        <br />
        We currently still have a few QA Engineer positions open. As mentioned in the job post,{" "}
        <a
          href="https://www.task-wolf.com/apply-qae"
          target='_blank'
          rel='noreferrer'
          style={{
            color: 'turquoise',
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          this page
        </a>{" "}
        has all the information you need to get started, including instructions for how to apply if you are interested. Due to the volume of applicants we receive, we ask that everyone go through our standard process. We promise to give you an answer one way or the other within 2 weeks if you start our process.
        <br />
        <br />
        Hope that helps, have a great rest of your week!
        <br />
        <br />
        Sincerely, <br />
        The QA Wolf Hiring Team
      </div>
    ),
  },
  {
    name: "Position Filled in Area",
    content: ({ firstName }) => (
      <div>
        Hey {firstName},
        <br />
        <br />
        Unfortunately our last opening for your location was recently filled, as we have a rolling hiring process that starts and stops as needed depending on how many hires we're making. That being said, we tend to hire every few weeks so feel free to check in at any time.
        <br />
        <br />
        You're also welcome to take a look at{" "}
        <a
          href="https://www.task-wolf.com/apply-qae"
          target='_blank'
          rel='noreferrer'
          style={{
            color: 'turquoise',
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          this page
        </a>{" "}
        as it has all the information you need to get started, including instructions for how to apply if you are interested. Due to the volume of applicants we receive, we ask that everyone go through our standard process. We'll keep your submission on file and reach out if we have an opening.
        <br />
        <br />
        Wishing you the best of luck in your search in the meantime 🍀
        <br />
        <br />
        Sincerely, <br />
        The QA Wolf Hiring Team
      </div>
    ),
  },
  {
    name: "Feedback Requested - Sharing Candidate Work",
    content: ({ firstName }) => (
      <div>
        Hi {firstName},
        <br />
        <br />
        In terms of feedback, unfortunately, it's just a competitive round. While we cannot share <i>specific</i> examples of other candidates' work, some candidates do lots of scenarios and exploration which helps them stand out. As mentioned in the FAQ, going above and beyond the main prompt can be helpful if you're very enthusiastic about the role. While there wasn't anything obviously wrong with your assignment, we just ended up receiving some stronger submissions for our current opening.
        <br />
        <br />
        Hope that helps, best of luck in your search 🍀
        <br />
        <br />
        Sincerely, <br />
        The QA Wolf Hiring Team
      </div>
    ),
  },
  {
    name: "Reject Take-home",
    content: ({ firstName }) => (
      <div>
        Hi {firstName}, <br />
        <br />
        Thank you for completing our assessment. While you did a great job, other candidates provided submissions that were a stronger match for our needs. This means that unfortunately we won't be moving forward with your application.
        <br />
        <br />
        Thank you also for your interest in QA Wolf, we wish you the best of luck in your endeavors. ✨
        <br />
        <br />
        Sincerely, <br />
        The QA Wolf Hiring Team
      </div>
    ),
  },
  {
    name: "Schedule Final Round",
    content: ({ firstName }) => (
      <div>
        Hi {firstName}, <br />
        <br />
        Circling back here - reaching out to schedule a final round. I just sent
        a calendar invite with a Zoom link for TIME, let me know if that time
        does not work for you.
        <br />
        <br />
        I also invited your email address from this thread to our platform, you
        should see an email with an invite link. If you can't find it, check
        your spam folder as they sometimes go there. Let me know if you do not
        receive it.
        <br />
        <br />
        We will have you create some automated tests in our platform, please
        sign up and play around with it beforehand if you have time. <a href='https://www.loom.com/share/cb0cb89cbb1c470dbedd9be3b29299ae?sid=0f1ac32f-03ef-46fa-a24f-9be1a1e01cb1' target='_blank' rel='noopener noreferrer'>Here is a
          link to a brief overview video</a> of QA Wolf, as well as a link to the <a href='https://playwright.dev/' target='_blank' rel='noopener noreferrer'> Playwright docs</a>. In particular, we recommend automating various
        scenarios on different sites using our platform, as well as practicing
        different types of assertions.
        <br />
        <br />
        Talk soon 🐺 <br />
        The QA Wolf Hiring Team
      </div>
    ),
  },
];

export default emailTemplates;
