import { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { sendTSPostRequest, sendTSPollRequest, PollJobResponse } from '@/utils/tanstackNetwork';
import { FailureSignatureArray, ToastMessage, DeleteOptions } from '../types';

type DeleteJobResponse = {
  message: string;
  queueName: string;
  jobId: string;
  version: string;
};

/**
 * Hook for handling suite deletion and reprocessing
 */
export const useDeleteSuite = (
  suiteId: string,
  currentData: FailureSignatureArray | null,
  setToast: (toast: ToastMessage | null) => void,
) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteOptions, setDeleteOptions] = useState<DeleteOptions | null>(null);

  /**
   * Mutation for triggering the suite deletion job
   */
  const triggerDeleteSuiteMutation = useMutation<DeleteJobResponse, Error>({
    mutationKey: ['triggerDeleteSuite', suiteId],
    mutationFn: async () => {
      // Validate required parameters
      if (!suiteId || typeof suiteId !== 'string') {
        throw new Error('Invalid suiteId: must be a non-empty string');
      }

      if (!currentData?.[0].dictionary.customerId || typeof currentData[0].dictionary.customerId !== 'string') {
        throw new Error('Invalid customerId: must be a non-empty string');
      }

      const response = await sendTSPostRequest('/failure-signature/v2/delete', {
        suiteId,
        customerId: currentData[0].dictionary.customerId,
        deleteAll: deleteOptions?.deleteAll ?? false,
        rebuildDictionary: deleteOptions?.rebuildDictionary ?? false,
      });

      // Validate response format
      if (!response || !response.jobId || !response.queueName || !response.version) {
        throw new Error('Invalid response format from deletion endpoint');
      }

      return response;
    },
    onSuccess: (data) => {
      setToast({
        title: 'Processing',
        message: data.message || 'Starting suite deletion...',
        isSuccess: true,
        key: Date.now(),
      });
    },
    onError: (error) => {
      setIsDeleting(false);
      setToast({
        title: 'Error',
        message: `Failed to delete suite: ${error.message}`,
        isSuccess: false,
        key: Date.now(),
      });
      setIsDeleteModalOpen(false);
    },
  });

  /**
   * Query for polling the status of the deletion job
   */
  const pollDeleteSuiteJob = useQuery<PollJobResponse<{ success: boolean }>, Error>({
    queryKey: ['pollJob', 'deleteSuiteJob', triggerDeleteSuiteMutation.data?.jobId],
    queryFn: () => {
      if (!triggerDeleteSuiteMutation.data?.jobId) throw new Error('No job ID found');
      return sendTSPollRequest<{ success: boolean }>(triggerDeleteSuiteMutation.data.jobId);
    },
    enabled: triggerDeleteSuiteMutation.isSuccess && triggerDeleteSuiteMutation.data?.jobId !== undefined,
    refetchInterval: (query) => {
      const data = query.state.data;
      if (!data?.success) return false;
      if (data.jobStatus === 'completed' || (data.jobStatus === 'failed' && !data.message?.includes('Retrying'))) return false;
      return 5000;
    },
  });

  // Handle poll job success and error
  useEffect(() => {
    const data = pollDeleteSuiteJob.data;
    if (pollDeleteSuiteJob.isSuccess && data) {
      if (data.success && data.jobStatus === 'completed') {
        setIsDeleting(false);
        setToast({
          title: 'Success',
          message: 'Suite deleted successfully.',
          isSuccess: true,
          key: Date.now(),
        });
        setIsDeleteModalOpen(false);
      } else if (data.success && ['active', 'waiting', 'delayed'].includes(data.jobStatus)) {
        // Job is still processing
        setIsDeleting(true);
      } else if (data.success && data.jobStatus === 'failed' && data.message?.includes('Retrying')) {
        // Job failed but retrying
        setToast({
          title: 'Processing',
          message: 'Previous deletion attempt failed. Retrying...',
          isSuccess: true,
          key: Date.now(),
        });
      } else if (!data.success) {
        // Job failed completely
        setIsDeleting(false);
        setToast({
          title: 'Error',
          message: data.message || 'Failed to delete suite. Please try again.',
          isSuccess: false,
          key: Date.now(),
        });
        setIsDeleteModalOpen(false);
      }
    } else if (pollDeleteSuiteJob.isError) {
      setIsDeleting(false);
      setToast({
        title: 'Error',
        message: 'Failed to check deletion status. Please try again.',
        isSuccess: false,
        key: Date.now(),
      });
      setIsDeleteModalOpen(false);
    }
  }, [pollDeleteSuiteJob.data, pollDeleteSuiteJob.isSuccess, pollDeleteSuiteJob.isError]);

  /**
   * Handles the deletion of a suite
   */
  const handleDelete = async (options?: DeleteOptions) => {
    if (!suiteId || !currentData?.[0].dictionary.customerId) {
      setToast({
        title: 'Error',
        message: 'Missing required data for deletion',
        isSuccess: false,
        key: Date.now(),
      });
      return;
    }

    // If no options provided, open the confirmation modal
    if (!options) {
      setIsDeleteModalOpen(true);
      return;
    }

    setIsDeleting(true);
    setDeleteOptions(options);
    triggerDeleteSuiteMutation.mutate();
  };

  return {
    isDeleting,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    handleDelete,
  };
};
